import React from 'react';
//
import { Icon } from '@blueprintjs/core';
import { LinkButton } from '@components/linkButton';

type TSettingsTelephonyCommercialProps = {
  /**
   * Ссылка на телефонию
   * @param {string}
   */
  link: string;
  /**
   * Название телефонии
   * @param {string}
   */
  telephonyName: string;
  /**
   * Промокод
   * @param {string}
   */
  promoCode: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsTelephonyCommercial = ({
  link,
  promoCode,
  telephonyName,
  className = '',
}: TSettingsTelephonyCommercialProps) => {
  return (
    <div className={`flex flex-col items-center py-6 ${className}`}>
      <p className='text-center leading-6 tracking-[0.0275em] mb-6'>
        <span className='text-blackText'>
          Если у вас не подключена телефония, мы рекомендуем оператора
        </span>
        <span className='text-green1 mx-2'>{`«${telephonyName}»`}</span>
        <span className='text-black'>{`1 - й месяц бесплатно по промокуду ${promoCode}`}</span>
      </p>
      <LinkButton href={link} targetBlank className='w-fit'>
        <div className='flex items-center'>
          <span>{telephonyName}</span>
          <Icon icon='share' size={22} />
        </div>
      </LinkButton>
    </div>
  );
};
