import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Report/state';
import {
  deleteReport,
  getReportById,
  getReportList,
  saveReport,
  switchReport,
} from '@redux/Report/thunk';
import {
  deleteReportReducer,
  getReportByIdReducer,
  getReportListReducer,
  reducers,
  saveReportReducer,
  switchReportReducer,
} from '@redux/Report/reducers';

export const slice = createSlice({
  name: 'report',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getReportById.pending, getReportByIdReducer.pending);
    builder.addCase(getReportById.fulfilled, getReportByIdReducer.fulfilled);
    builder.addCase(getReportById.rejected, getReportByIdReducer.rejected);
    builder.addCase(saveReport.pending, saveReportReducer.pending);
    builder.addCase(saveReport.fulfilled, saveReportReducer.fulfilled);
    builder.addCase(saveReport.rejected, saveReportReducer.rejected);
    builder.addCase(getReportList.pending, getReportListReducer.pending);
    builder.addCase(getReportList.fulfilled, getReportListReducer.fulfilled);
    builder.addCase(getReportList.rejected, getReportListReducer.rejected);
    builder.addCase(switchReport.pending, switchReportReducer.pending);
    builder.addCase(switchReport.fulfilled, switchReportReducer.fulfilled);
    builder.addCase(switchReport.rejected, switchReportReducer.rejected);
    builder.addCase(deleteReport.pending, deleteReportReducer.pending);
    builder.addCase(deleteReport.fulfilled, deleteReportReducer.fulfilled);
    builder.addCase(deleteReport.rejected, deleteReportReducer.rejected);
  },
});

export const reportReducer = slice.reducer;

export const {
  onChange,
  wrapText,
  setActive,
  setSendDate,
  addBalloonRP,
  setReportData,
  clearErrorsRP,
  clearSuccessRP,
  setIsRefreshRl,
  deleteBalloonRP,
  addKeyInTextarea,
  setReportListData,
  onChangeCheckbox,
  clearReportsErrors,
  addEmojiInTextarea,
  setReportSendingTime,
  setNewBalloonValueRP,
  setReportIdInProgress,
  clearNewBalloonValueRP,
  setDataCalculatingPeriod,
  setAbandonedTextareaData,
  setEveryWeekSendingPeriod,
  setEveryMonthSendingPeriod,
  setSelectedReportSendPeriod,
  clearReportListDeleteSuccess,
  setSortedReportsList,
} = slice.actions;
