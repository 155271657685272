import { log } from '@helpers/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardApi } from '@api/dashboard/dashboardApi';
import { dashboardMappers } from '@redux/Dashboard/mappers';
import { DashboardsResponseDataSchema, OneDashboardResponseDataSchema } from '@api/dashboard/zod';
import {
  TRejectValue,
  TGetDashboardData,
  TSaveDashboardData,
  TGetMainDashboardData,
} from '@models/index';
import {
  DASHBOARD_TYPES,
  REJECT_RESPONSE_KEY,
  IDashboardRequestData,
  TSaveDashboardResponse,
  DASHBOARD_RESPONSE_DATA_KEY_NAMES,
} from '@api/types';

export const getDashboardData = createAsyncThunk<
  TGetDashboardData,
  IDashboardRequestData,
  TRejectValue
>('dashboard/getDashboardData', async (data, { rejectWithValue }) => {
  return dashboardApi
    .getDashboard(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (DASHBOARD_RESPONSE_DATA_KEY_NAMES.DATA in response.data) {
        const parsedData = OneDashboardResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@dashboard/getDashboardData pars error', parsedData.error.format());
          // throw new Error('Something went wrong');
        }
        return {
          name: data.type,
          data: response.data,
        };
      }
    })
    .catch(error => {
      return rejectWithValue(error.response?.data);
    });
});
export const getMainDashboardData = createAsyncThunk<
  TGetMainDashboardData,
  IDashboardRequestData,
  TRejectValue
>('dashboard/getMainDashboardData', (data, { rejectWithValue }) => {
  return dashboardApi
    .getMainDashboard(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (DASHBOARD_RESPONSE_DATA_KEY_NAMES.META in response.data) {
        const parsedData = DashboardsResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@dashboard/getMainDashboardData pars error', parsedData.error.format());

          // throw new Error(parsedData.error.toString());
        }
        const { meta } = response.data;

        return {
          name: data.type,
          data: response.data.data,
          meta: dashboardMappers.responseToStore(meta),
          force: !!data.force,
        };
      }
    })
    .catch(error => {
      return rejectWithValue(error.response.data);
    });
});

export const saveSelectedDashboardData = createAsyncThunk<
  TSaveDashboardResponse | void,
  TSaveDashboardData,
  TRejectValue
>('dashboard/saveSelectedDashboardData', (data, { dispatch, rejectWithValue }) => {
  return dashboardApi
    .saveDashboard(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (response.data.ok) {
        dispatch(
          getMainDashboardData({
            force: false,
            type: DASHBOARD_TYPES.MAIN,
            startDate: data.startDate,
            endDate: data.endDate,
          }),
        );
      }
    })
    .catch(error => {
      return rejectWithValue(error.response.data);
    });
});
