import React, { memo } from 'react';
//
import { CONFIG } from '@const/config';
import { NavLink } from 'react-router-dom';

interface IErrorMessageProps {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ErrorMessage = memo(({ className = '' }: IErrorMessageProps) => (
  <div className={`flex flex-col items-center justify-center h-[50vh] w-full ${className}`}>
    <p className='break-words mb-4'>
      Произошла ошибка, пожалуйста, повторите попытку позже или обратитесь в
      <NavLink to={CONFIG.SUPPORT_TELEGRAM_LINK} className='text-link ml-1'>
        службу технической поддержки
      </NavLink>
      .
    </p>
  </div>
));
