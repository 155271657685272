import { TInitialState } from '@redux/BlackList/zod';

export const initialState: TInitialState = {
  data: [],
  isLoading: false,
  isRefresh: false,
  isError: false,
  isAddSuccess: false,
  isAddingNumber: false,
  isDeleteSuccess: false,
  isDeleteNumberError: false,
  isAddingNumberError: false,
  isAccessDenied: false,
  isRetries: false,
  retries: 1,
  newNumber: '',
  numberToDelete: '',
  filteredState: [],
  searchString: '',
  successToastMessage: '',
  errorToastMessage: '',
};
