import React, { memo } from 'react';
import { Link } from 'react-router-dom';
//
import logo from '@img/baseTemplate/smalLogoInHeader.png';

interface IHomeLinkProps {
  /**
   * Ссылка в Link компонент
   * @param {string}
   */
  href: string;
  /**
   * Опциональный параметр указывает на то что ссылка находится в Drawer component
   * @param {boolean}
   * @default
   */
  isDrawer?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const HomeLink = memo(({ href, isDrawer, className = '' }: IHomeLinkProps) => (
  <Link
    to={href}
    className={`flex items-center font-bold text-blackText text-h3_body hover:no-underline hover:text-blackText ${className}`}>
    {!isDrawer ? <img src={logo} alt='Домой' title='На главную' height='40' width='27' /> : null}
    <span
      className={`font-bold text-blackText tracking-normal ${
        isDrawer ? 'block text-h3Mobile tracking-[0.0075em]' : 'hidden text-h1_header lg:block'
      } ml-[1.125rem]`}>
      Админ Бот
    </span>
  </Link>
));
