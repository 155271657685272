import React, { useEffect, useMemo, useRef } from 'react';
//
import { Icon } from '@atoms/icon';
import { Toaster } from '@uikit/Toast';

type TToastsMessages = {
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorMessage: string;
  /**
   * Текст сообщения об успехе
   * @param {string}
   */
  successMessage: string;
  /**
   * Флаг показа сообщения об ошибке
   * @param {boolean}
   */
  isShowErrorToast: boolean;
  /**
   * Флаг показа сообщения об ошибке
   * @param {boolean}
   */
  isShowSuccessToast: boolean;
  /**
   * Время показа сообщения
   * @param {number}
   */
  successToastTimeout?: number;
  /**
   * Очищает состояние ошибки
   * @param {() => void}
   */
  clearErrorCallback?: () => void;
  /**
   * Очищает состояние успеха
   * @param {() => void}
   */
  clearSuccessStatusCallback?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const ToastsMessages = ({
  errorMessage,
  successMessage,
  isShowErrorToast,
  isShowSuccessToast,
  successToastTimeout = 2000,
  clearErrorCallback = () => '',
  clearSuccessStatusCallback = () => '',
  className = '',
}: TToastsMessages) => {
  // Объект настроек successToastMessage
  const successToast = useMemo(
    () => ({
      message: successMessage,
      icon: <Icon variant='successToast' className='ml-4' />,
      timeout: successToastTimeout,
    }),
    [successToastTimeout, successMessage],
  );

  // Объект настроек errorToastMessage
  const errorToast = useMemo(
    () => ({
      message: errorMessage,
      icon: <Icon variant='errorToast' className='ml-4' />,
      onDismiss: clearErrorCallback,
      timeout: 0,
    }),
    [errorMessage, clearErrorCallback],
  );

  // реф для сообщения о успехе
  const successToastRef = useRef(successToast);

  // реф для сообщения об ошибке
  const errorToastRef = useRef(errorToast);

  // Эффект показывает сообщение об успешном сохранении настроек
  useEffect(() => {
    if (isShowSuccessToast) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      successToastRef.current.show(successToast);
      setTimeout(clearSuccessStatusCallback, 2000);
    }
  }, [isShowSuccessToast, successToast, clearSuccessStatusCallback]);

  // Эффект показывает сообщение об ошибке при сохранении настроек
  useEffect(() => {
    if (isShowErrorToast) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorToastRef.current.show(errorToast);
    }
  }, [isShowErrorToast, errorToast]);

  const memoizedSuccessToast = useMemo(
    () => (
      <Toaster
        withoutClose
        maxToasts={1}
        view='smooth'
        color='success'
        position='top-right'
        ref={successToastRef}
      />
    ),
    [successToastRef],
  );

  const memoizedErrorToast = useMemo(
    () => (
      <Toaster
        maxToasts={1}
        view='smooth'
        color='danger'
        className='error'
        ref={errorToastRef}
        position='top-right'
        withoutClose={false}
      />
    ),
    [errorToastRef],
  );

  return (
    <div className={className}>
      {memoizedErrorToast}
      {memoizedSuccessToast}
    </div>
  );
};
