import React, { memo, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';

interface INavDropDownProps {
  /**
   * Словарь для составления списка в dropdown
   * @param {{ [key: string]: string }}
   */
  pageNameDict: { [key: string]: string };
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const NavDropDown = memo(({ pageNameDict, className = '' }: INavDropDownProps) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  // Сохраняем значение выбранного пункта меню
  const [selectedMenuItemName, setSelectedMenuItemName] = useState<string>('');

  // Эффект устанавливает имя выбранной страницы
  useEffect(() => {
    setSelectedMenuItemName(pageNameDict[path]);
  }, [path, pageNameDict, selectedMenuItemName, setSelectedMenuItemName]);

  // Подготавливает массив MenuItem для рендеренга
  const links = useMemo(
    () =>
      Object.entries(pageNameDict).map(item => (
        <MenuItem
          key={item[0]}
          text={<span>{item[1]}</span>}
          onClick={() => navigate(item[0])}
          className={` ${selectedMenuItemName === item[1] ? '!bg-ivory' : ''}`}
        />
      )),
    [navigate, pageNameDict, selectedMenuItemName],
  );

  return (
    <Dropdown
      view='outlined'
      color='success'
      position='bottom-left'
      className={`w-[calc(100vw-2.2rem)] ${className}`}
      text={<span>{selectedMenuItemName}</span>}
      content={<Menu view='raised'>{links}</Menu>}
    />
  );
});
