import React, { useMemo } from 'react';
//
import { H2 } from '@atoms/h2';
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { TPaymentData } from '@models/Pay';

type TPayHistoryTable = {
  filteredData: TPaymentData[];
  className?: string;
};

export const PayHistoryTable = ({ filteredData, className = '' }: TPayHistoryTable) => {
  const Head = useMemo(
    () => [
      { id: 'date', label: 'Дата', noSort: true },
      { id: 'period', label: 'Период', noSort: true },
      // { id: 'type', label: 'Тип платежа', noSort: true },
      { id: 'sum', label: 'Сумма', noSort: true },
    ],
    [],
  );

  const getHead = useMemo(() => {
    return filteredData.length ? Head : Head.slice(0, 1);
  }, [filteredData, Head]);

  const getPosition = useMemo(() => {
    return filteredData.length ? ['left', 'left', 'right'] : ['center'];
  }, [filteredData]);

  const getTableData = (tableData: TPaymentData[]) => {
    const emptyData = [
      {
        date: 'Нет данных',
      },
    ];
    if (!tableData.length) {
      return emptyData;
    }
    return tableData.map(item => {
      return {
        ...item,
        period: `до ${item.period}`,
        type: <Chips tag='Карта' type='dense' />,
      };
    });
  };

  return (
    <div className={className}>
      <H2 className='font-bold text-h6Mobile text-black mt-8 mb-6'>Предыдущие платежи</H2>
      <div className='max-w-[57.5rem]'>
        <Table
          pages
          type='def'
          view='flat'
          name={getHead}
          defRows={8}
          color='default'
          checkboxes={false}
          paperClassName='no_shadow'
          rowsPerPageOptions={[5, 8, 20]}
          data={getTableData(filteredData)}
          labelRowsPerPage='Показать строк:'
          position={getPosition}
          style={{ boxShadow: 'none', marginLeft: '6px' }}
        />
      </div>
    </div>
  );
};
