import { Icon } from '@atoms/icon';

type TEmptyCardsListTemplateProps = {
  /**
   * Текстовое описание для пустой страницы
   * @param {string}
   */
  description: string;
  /**
   * Опциональная строка классов
   * @param {string}
   * @default ''
   */
  className?: string;
};

export const EmptyCardsListTemplate = ({
  description,
  className = '',
}: TEmptyCardsListTemplateProps) => {
  return (
    <div className={`flex flex-col justify-center items-center w-full ${className}`}>
      <Icon variant='emptyList' className='mb-4' />
      <p className='text-grayText text-body2 font-normal'>{description}</p>
    </div>
  );
};
