import React, { useState } from 'react';
//
import { Drawer } from '@uikit/Drawer';
import { useAppSelector } from '@hooks/index';
import { getAppData } from '@redux/selectors';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { TSetSelectedPeriodMonth } from '@shared/types';
import { PeriodStatsInput } from '@blocks/periodStatsInput';
import { DashboardHowButton } from '@blocks/dashboardHowButton';
import { PeriodOfReturnStatisticsInfo } from '@blocks/periodOfReturnStatisticsInfo';
import { PeriodOfReturnStatisticsExample } from '@components/periodOfReturnStatisticsExample';
import {
  settingPageText,
  CURRENT_PERIOD_MONTH,
  PASSED_PERIOD_MONTHS,
  churnByMasterNumWords,
  PASSED_PERIOD_ENABLED_MONTHS,
  CURRENT_PERIOD_ENABLED_MONTHS,
} from '@const/settings';

type TPeriodsOfReturnStatisticsProps = {
  /**
   * Текущий период
   * @param {number}
   */
  endRate: number;
  /**
   * Прошлый период
   * @param {number}
   */
  startRate: number;
  /**
   * Callback для изменения
   * @param {TSetSelectedPeriodMonth}
   */
  setSelectedPassedPeriodMonth: TSetSelectedPeriodMonth;
  /**
   * Callback для изменения
   * @param {TSetSelectedPeriodMonth}
   */
  setSelectedCurrentPeriodMonth: TSetSelectedPeriodMonth;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PeriodsOfReturnStatistics = ({
  endRate,
  startRate,
  setSelectedPassedPeriodMonth,
  setSelectedCurrentPeriodMonth,
  className = '',
}: TPeriodsOfReturnStatisticsProps) => {
  const width = useWindowWidth();
  const { appSelectedLanguage } = useAppSelector(getAppData);
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const toggleInfoDrawer = () => {
    setIsShowDrawer(prevState => !prevState);
  };

  return (
    <div className={className}>
      <div className='flex flex-wrap'>
        <p className='font-medium text-black text-h3Mobile leading-8 tracking-[0.0075em] mr-5  mb-2'>
          {settingPageText[appSelectedLanguage].periodsOfReturnStatisticsHeader || 'text not found'}
        </p>
        <DashboardHowButton onClick={toggleInfoDrawer} className='mb-2' />
      </div>
      <div className='max-w-[31rem] w-full'>
        <div className='flex flex-col sm:flex-row mb-8'>
          <PeriodStatsInput
            selectedMonth={startRate}
            monthList={PASSED_PERIOD_MONTHS}
            enabledMonths={PASSED_PERIOD_ENABLED_MONTHS}
            setSelectedMonth={setSelectedPassedPeriodMonth}
            labelText={settingPageText[appSelectedLanguage].startRateLabel || 'text not found'}
            postfixTextArray={churnByMasterNumWords[appSelectedLanguage] || 'text not found'}
            className='mb-6 sm:mb-0 sm:mr-4 w-full sm:max-w-[20rem]'
          />
          <PeriodStatsInput
            selectedMonth={endRate}
            monthList={CURRENT_PERIOD_MONTH}
            previousSelectedMonth={startRate}
            enabledMonths={CURRENT_PERIOD_ENABLED_MONTHS}
            setSelectedMonth={setSelectedCurrentPeriodMonth}
            labelText={settingPageText[appSelectedLanguage].endRateLabel || 'text not found'}
            postfixTextArray={churnByMasterNumWords[appSelectedLanguage] || 'text not found'}
            className='w-full sm:max-w-[10rem]'
          />
        </div>
        <PeriodOfReturnStatisticsExample endRate={endRate} startRate={startRate} />
      </div>
      <Drawer
        isCloseButtonShown
        isOpen={isShowDrawer}
        onClose={toggleInfoDrawer}
        type='icon'
        position='right'
        title='Расчёт возвращаемости'
        size={width < 780 ? '100%' : 540}>
        <PeriodOfReturnStatisticsInfo
          endRate={endRate}
          startRate={startRate}
          toggleInfoDrawer={toggleInfoDrawer}
        />
      </Drawer>
    </div>
  );
};
