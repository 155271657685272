import React from 'react';
//
import { Button } from '@uikit/Button';
import { TOnChangeHandler } from '@shared/types';
import { SettingsCheckbox } from '@blocks/settingsCheckbox';
import { ReactComponent as AlmostDoneIcon } from '@img/settings/almostDone.svg';
import { LinkInTableWithCopyButton } from '@components/linkInTableWithCopyButton';

type TTelephonyAlmostDoneMessageProps = {
  /**
   * Значение для чекбокса подтверждения
   * @param {boolean}
   */
  iAgree: boolean;
  /**
   * Ссылка на webhook
   * @param {boolean}
   */
  webhook: string;
  /**
   * Обработчик чекбокса
   * @param {TOnChangeHandler}
   */
  setIAgreeHandler: TOnChangeHandler;
  /**
   * Callback для подтверждения настройки webhook
   * @param {() => void}
   */
  webhookSetupConfirm: () => void;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TelephonyAlmostDoneMessage = ({
  iAgree,
  webhook,
  isLoading,
  setIAgreeHandler,
  webhookSetupConfirm,
  className = '',
}: TTelephonyAlmostDoneMessageProps) => {
  return (
    <div className={`flex flex-col items-center justify-center pt-6 ${className}`}>
      <AlmostDoneIcon className='mb-8' />
      <p className='text-black leading-6 tracking-[0.0275em] mb-6'>
        Интеграция с телефонией почти настроена
      </p>
      <p className='text-black leading-6 tracking-[0.0275em]'>
        <span className='text-statusGreen leading-6 tracking-[0.0275em]'>
          Настройте вебхук&nbsp;
        </span>
        у вашего оператора
      </p>
      <LinkInTableWithCopyButton
        text={webhook}
        className='cursor-pointer hover:text-green1 leading-6 tracking-[0.0275em] mb-6'
      />
      <div className='flex flex-wrap items-center mb-2'>
        <div className='mb-4 mr-1'>
          <SettingsCheckbox
            htmlFor='test'
            checked={iAgree}
            variant='iSetUpWebhook'
            isShowInfoIcon={false}
            onChangeHandler={setIAgreeHandler}
          />
        </div>
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Подтвердить'
          disabled={!iAgree}
          loading={isLoading}
          onClick={webhookSetupConfirm}
          className='mb-4'
        />
      </div>
    </div>
  );
};
