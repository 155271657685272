import React, { useState } from 'react';
//
import { Button } from '@uikit/Button';
import { CONFIG } from '@const/config';
import { REG_EXP } from '@const/common';
import { useAppDispatch } from '@hooks/index';
import { LinkButton } from '@blocks/linkButton';
import { TOnChangeHandler } from '@shared/types';
import { loginUserByPinThunk } from '@redux/Auth/thunk';
import { PinInputWithErrorText } from '@components/pinInputWithError';
import { setInputError, setPin, setTelegramId } from '@redux/Auth/slice';
import { RemindTelegramIdModal } from '@components/remindTelegramIdModal';
import { TextInputWithErrorText } from '@components/TextInputWithErrorText';

interface IPinLoginProps {
  /**
   * Значение пинкода для авторизации
   * @param {string}
   */
  pinValue: string;
  /**
   * Callback для получения PIN
   */
  getPin: () => void;
  /**
   * Флаг загрузки
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Ошибка интпута, нет значения
   * @param {boolean}
   */
  isInputError: boolean;
  /**
   * Флаг ошибки проверки телеграм id
   * @param {boolean}
   */
  isGetPinError: boolean;
  /**
   * Флаг ошибка проверки пина
   * @param {boolean}
   */
  isChekPinError: boolean;
  /**
   * Значение телеграм id для авторизации
   * @param {string}
   */
  telegramIdValue: string;
  /**
   * Флаг успешной отправки пин кода в телеграм
   * @param {boolean}
   */
  isGetPinSuccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const PinLogin = ({
  getPin,
  pinValue,
  isFetching,
  isInputError,
  isGetPinError,
  isChekPinError,
  isGetPinSuccess,
  telegramIdValue,
  className = '',
}: IPinLoginProps) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalToggle = () => {
    setIsModalOpen(prevState => !prevState);
  };

  const telegramIdOnChangeHandler: TOnChangeHandler = event => {
    const { value } = event.currentTarget;
    const newValue = value.replace(REG_EXP.onlyNumbers, '');

    dispatch(setTelegramId(newValue));

    if (isInputError) {
      dispatch(setInputError(false));
    }
  };

  const pinOnChangeHandler = (value: string) => {
    const newValue = value.replace(REG_EXP.onlyNumbers, '');

    dispatch(setPin(newValue));

    if (newValue.length === 4) {
      dispatch(loginUserByPinThunk({ userId: +telegramIdValue, pin: +value }));
    }

    if (isInputError) {
      dispatch(setInputError(false));
    }
  };

  return (
    <div className={`flex flex-col w-full max-w-[22.5rem] ${className}`}>
      {!isGetPinSuccess ? (
        <TextInputWithErrorText
          fill
          isRequired
          inputName='telegramId'
          onClickCallback={getPin}
          isInputError={isInputError}
          inputValue={telegramIdValue}
          inputPlaceholder='Телеграм ID'
          isShowErrorText={isGetPinError}
          inputHeaderText='Введите телеграм ID'
          onChangeHandler={telegramIdOnChangeHandler}
          inputDataErrorText2='Ошибка, проверьте телеграм ID'
          inputDataErrorText='Для продолжения введите телеграм ID'
          rightButtonInHeader={
            <RemindTelegramIdModal
              buttonText='Напомнить'
              isModalOpen={isModalOpen}
              modalToggle={modalToggle}
              link={CONFIG.PIN_BOT_LINK}
            />
          }
        />
      ) : (
        <PinInputWithErrorText
          inputValue={pinValue}
          isInputError={isInputError}
          isShowErrorText={isChekPinError}
          inputDataErrorText2='Неверный PIN'
          onChangeHandler={pinOnChangeHandler}
          inputHeaderText='Введите PIN, присланный'
          inputDataErrorText='Для продолжения введите PIN'
          rightButtonInHeader={<LinkButton buttonText='Админ ботом' link={CONFIG.PIN_BOT_LINK} />}
        />
      )}
      {!isGetPinSuccess ? (
        <Button
          fill
          dense
          type='action'
          color='success'
          onClick={getPin}
          text='Отправить PIN'
          loading={isFetching}
          disabled={!telegramIdValue}
        />
      ) : null}
    </div>
  );
};
