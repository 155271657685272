import { TReportInfo } from '@models/index';
import { TRPNameList } from '@shared/types';
import { reportEmptyData } from '@data/index';
import { TReportData } from '@models/Report';
import { PayloadAction } from '@reduxjs/toolkit';
import { COMMON_TOAST_MESSAGES } from '@const/common';
import { TReportInitialState } from '@redux/Report/zod';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getKeyDescription, getWrapperText } from '@helpers/index';
import { REPORT_DATA_KEY_NAMES, REPORTS_ERROR_MESSAGE } from '@const/report';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';

export const reducers = {
  setReportData(state: TReportInitialState, action: PayloadAction<{ data: TReportData }>) {
    state.data = action.payload.data;
  },
  setSortedReportsList(state: TReportInitialState, action: PayloadAction<string[]>) {
    state.sortedReportsData = action.payload;
  },
  onChange(
    state: TReportInitialState,
    action: PayloadAction<{
      name: REPORT_DATA_KEY_NAMES.REPORT_NAME | REPORT_DATA_KEY_NAMES.REPORT_DESCRIPTION;
      value: string;
    }>,
  ) {
    const { name, value } = action.payload;
    state.data[name] = value;
  },
  onChangeCheckbox(
    state: TReportInitialState,
    action: PayloadAction<{ name: REPORT_DATA_KEY_NAMES.SEND_TO_ALL_STAFF; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    state.data[name] = checked;
  },
  setActive(
    state: TReportInitialState,
    action: PayloadAction<{ name: REPORT_DATA_KEY_NAMES.IS_ACTIVE; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    state.data[name] = checked;
  },
  setSelectedReportSendPeriod(
    state: TReportInitialState,
    action: PayloadAction<{ sendPeriod: string }>,
  ) {
    state.data.selectedReportSendPeriod = action.payload.sendPeriod;
  },
  setReportSendingTime(state: TReportInitialState, action: PayloadAction<{ time: string }>) {
    state.data.sendingTime = action.payload.time;
  },
  setDataCalculatingPeriod(state: TReportInitialState, action: PayloadAction<{ period: string }>) {
    const { period } = action.payload;
    state.data.selectedReportSendPeriod = '0';
    state.data.selectedDataCalculatingPeriod = period;
  },
  setEveryWeekSendingPeriod(state: TReportInitialState, action: PayloadAction<{ period: string }>) {
    state.data.selectedEveryWeekSendingPeriod = action.payload.period;
  },
  setEveryMonthSendingPeriod(
    state: TReportInitialState,
    action: PayloadAction<{ period: string }>,
  ) {
    state.data.selectedEveryMonthSendingPeriod = action.payload.period;
  },
  setNewBalloonValueRP(
    state: TReportInitialState,
    action: PayloadAction<{ name: string; value: string }>,
  ) {
    const { name, value } = action.payload;
    if (name === 'telegramRecipients' || name === 'whatsappRecipients') {
      state[`${name}NewValue`] = value;
    }
  },
  clearNewBalloonValueRP(state: TReportInitialState, action: PayloadAction<{ name: string }>) {
    const { name } = action.payload;
    if (name === 'telegramRecipients' || name === 'whatsappRecipients') {
      state[`${name}NewValue`] = '';
    }
  },
  addBalloonRP(
    state: TReportInitialState,
    action: PayloadAction<{ keyName: TRPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;
    if (!value) return;
    state.data[keyName] = state.data[keyName].concat(value);
    state[`${keyName}NewValue`] = '';
  },
  deleteBalloonRP(
    state: TReportInitialState,
    action: PayloadAction<{ keyName: TRPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;
    state.data[keyName] = state.data[keyName].filter(item => item !== value);
  },
  setAbandonedTextareaData(
    state: TReportInitialState,
    action: PayloadAction<{
      name: string;
      selectionStart: number;
      selectionEnd: number;
    }>,
  ) {
    const { selectionStart, selectionEnd, name } = action.payload;
    state.blurFromTextareaName = name;
    state.selectionEnd = selectionEnd;
    state.selectionStart = selectionStart;
  },
  wrapText(state: TReportInitialState, action: PayloadAction<{ innerText: string }>) {
    const { innerText } = action.payload;
    const { selectionEnd, selectionStart } = state;
    const textAreaValue = String(state.data.reportDescription);
    state.data.reportDescription = getWrapperText(
      textAreaValue,
      innerText[0],
      selectionStart,
      selectionEnd,
    );
  },
  addKeyInTextarea(
    state: TReportInitialState,
    action: PayloadAction<{ keyName: string; group: string }>,
  ) {
    const { keyName, group } = action.payload;
    const { selectionStart, selectionEnd } = state;
    const textAreaValue = String(state.data.reportDescription);
    const key = `*${getKeyDescription(group, keyName)}*: {${keyName}}\n`;
    state.data.reportDescription =
      textAreaValue.substring(0, selectionEnd) + key + textAreaValue.substring(selectionStart);
    state.selectionEnd = state.data.reportDescription.length;
    state.selectionStart = state.data.reportDescription.length;
  },
  addEmojiInTextarea(state: TReportInitialState, action: PayloadAction<{ innerText: string }>) {
    const { innerText } = action.payload;
    const { selectionEnd, selectionStart } = state;
    const textAreaValue = String(state.data.reportDescription);
    state.data.reportDescription =
      textAreaValue.substring(0, selectionEnd) +
      innerText +
      textAreaValue.substring(selectionStart);
  },
  setSendDate(state: TReportInitialState, action: PayloadAction<{ dateString: string }>) {
    state.data.sendingDate = action.payload.dateString;
  },
  clearErrorsRP(state: TReportInitialState) {
    state.isError = false;
    state.isSavingError = false;
  },
  clearSuccessRP(state: TReportInitialState) {
    state.isSuccess = false;
    state.isSavingSuccess = false;
  },
  setReportListData(state: TReportInitialState, action: PayloadAction<{ data: TReportInfo[] }>) {
    state.reportListData = action.payload.data;
  },
  setIsRefreshRl(state: TReportInitialState, action: PayloadAction<boolean>) {
    state.isRefresh = action.payload;
  },
  setReportIdInProgress(state: TReportInitialState, action: PayloadAction<{ reportId: string }>) {
    state.reportIdInProgress = action.payload.reportId;
  },
  clearReportsErrors(state: TReportInitialState) {
    state.isError = false;
    state.isDeletingError = false;
    state.isReportSwitchingError = false;
  },
  clearReportListDeleteSuccess(state: TReportInitialState) {
    state.isDeletingSuccess = false;
  },
};

export const getReportByIdReducer = {
  pending: (state: TReportInitialState) => {
    state.isError = false;
    state.isLoading = true;
    state.isSuccess = false;
    state.isSavingSuccess = false;
  },
  fulfilled: (state: TReportInitialState, action: PayloadAction<TReportData | void>) => {
    if (action.payload) {
      state.data = action.payload;
    } else {
      state.data = reportEmptyData;
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
  },
  rejected: (state: TReportInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const saveReportReducer = {
  pending: (state: TReportInitialState) => {
    state.isError = false;
    state.isSaving = true;
    state.isSavingError = false;
    state.isSavingSuccess = false;
  },
  fulfilled: (state: TReportInitialState, action: PayloadAction<TReportData | void>) => {
    state.isSaving = false;
    if (action.payload) {
      state.data = action.payload;
    }
    state.isSavingSuccess = true;
    state.successToastMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
  },
  rejected: (state: TReportInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isSavingError = true;
      }
      state.isSaving = false;
    } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail !== REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isSaving = false;
        state.isSavingError = true;
      }
    } else {
      state.isSaving = false;
      state.isSavingError = true;
    }
    state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
  },
};

export const getReportListReducer = {
  pending: (state: TReportInitialState) => {
    const { isRefresh } = state;
    if (!isRefresh) {
      state.isLoading = true;
    }
    state.isError = false;
    state.isSuccess = false;
    state.isDeletingSuccess = false;
  },
  fulfilled: (
    state: TReportInitialState,
    action: PayloadAction<{
      data: TReportInfo[];
      order: string[];
    } | void>,
  ) => {
    if (action.payload) {
      const { order, data } = action.payload;
      const unsortedData = data.map(item => item.id);
      state.sortedReportsData = Array.from(new Set([...order, ...unsortedData]));
      state.reportListData = data;
    } else {
      state.reportListData = [];
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
    state.isRefresh = false;
  },
  rejected: (state: TReportInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      } else if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
      }
    }
    state.isRefresh = false;
  },
};

export const switchReportReducer = {
  pending: (state: TReportInitialState) => {
    state.isReportSwitching = true;
    state.isReportSwitchingError = false;
  },
  fulfilled: (state: TReportInitialState, action: PayloadAction<string | void>) => {
    const reportId = action.payload || '';
    state.reportListData = state.reportListData.map(item => {
      if (item.id === reportId) {
        return { ...item, active: !item.active };
      }
      return item;
    });
    state.reportIdInProgress = '';
    state.isReportSwitching = false;
    state.isReportSwitchingSuccess = true;
  },
  rejected: (state: TReportInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isReportSwitchingError = true;
      }
      state.isReportSwitching = false;
    } else if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isReportSwitching = false;
      } else {
        state.isReportSwitching = false;
        state.isReportSwitchingError = true;
      }
    } else {
      state.isReportSwitching = false;
      state.isReportSwitchingError = true;
    }
    state.errorToastMessage = REPORTS_ERROR_MESSAGE.COMMON_ERROR;
  },
};

export const deleteReportReducer = {
  pending: (state: TReportInitialState) => {
    state.isError = false;
    state.isDeleting = true;
    state.isDeletingSuccess = false;
  },
  fulfilled: (state: TReportInitialState, action: PayloadAction<string>) => {
    const reportId = action.payload;
    state.reportListData = state.reportListData.filter(item => item.id !== reportId);
    state.isDeleting = false;
    state.isDeletingSuccess = true;
    state.successToastMessage = `Шаблон ${state.reportIdInProgress} \n успешно удален`;
  },
  rejected: (state: TReportInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload && REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isDeletingError = true;
      }
      state.isDeleting = false;
    }
    if (action.payload && REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isDeleting = false;
      } else {
        state.isDeleting = false;
        state.isDeletingError = true;
      }
    }
    if (!action.payload) {
      state.isDeleting = false;
      state.isDeletingError = true;
    }
    state.errorToastMessage = REPORTS_ERROR_MESSAGE.COMMON_ERROR;
  },
};
