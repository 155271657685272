import React, { memo, useCallback } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { NotesFolders } from '@components/notesFolders';
import { createFolderThunk } from '@redux/noteList/thunk';
import { NotesListComponent } from '@components/notesListComponent';
import { MAX_FOLDER_LENGTH, WARNING_TOAST_MESSAGES } from '@const/notes';
import { TFolderItem, TNoteItem, TNoteListStatuses, TNoteTag } from '@redux/noteList/zod';
import {
  TOnChangeHandler,
  TSetTagInSearchString,
  TSetActiveFolderByIdHandler,
} from '@shared/types';
import {
  setIsShowNotes,
  setActiveFolder,
  showErrorMessage,
  setIsShowFolders,
  setSearchStringNL,
  setSelectedTagToSearch,
} from '@redux/noteList/slice';

type TProps = {
  /**
   * Флаг загрузки данных
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Флаг режима удаления тегов
   * @param {boolean}
   */
  isTagDeleteMode: boolean;
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  selectedTags: TNoteTag[];
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Значение инпута в модалку создания новой папки
   * @param {string}
   */
  newFolderName: string;
  /**
   * Флаг открытия блока работы с тэгами
   * @param {boolean}
   */
  isSearchByTag: boolean;
  /**
   * Массив с данными по паккам с заметками
   * @param {TFolderItem[]}
   */
  folders: TFolderItem[];
  /**
   * Список заметок
   * @param {TNoteItem[]}
   */
  notesList: TNoteItem[];
  /**
   * Флаг показа карточек заметок
   * @param {boolean}
   */
  isShowNotes: boolean;
  /**
   * Флаг отображения блока с папками
   * @param {boolean}
   */
  isShowFolders: boolean;
  /**
   * Данные выбранной папки
   * @param {TFolderItem | null | undefined}
   */
  activeFolder: TFolderItem | null | undefined;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteListTemplate = memo(
  ({
    tags,
    status,
    folders,
    isLoading,
    notesList,
    isShowNotes,
    activeFolder,
    searchString,
    selectedTags,
    newFolderName,
    isSearchByTag,
    isShowFolders,
    isTagDeleteMode,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();
    const dispatch = useAppDispatch();

    const setSearchHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setSearchStringNL({ value }));
      },
      [dispatch],
    );

    const clearSearchStringHandler = useCallback(() => {
      dispatch(setSearchStringNL({ value: '' }));
    }, [dispatch]);

    const createFolderHandler = useCallback(
      (folderName: string) => (modalCloseCallback: () => void) => () => {
        if (folderName.length > MAX_FOLDER_LENGTH) {
          dispatch(showErrorMessage(WARNING_TOAST_MESSAGES.FOLDER_NAME_TO_LONG));
        } else {
          dispatch(createFolderThunk({ folderName, modalCloseCallback }));
        }
      },
      [dispatch],
    );

    const setActiveFolderHandler: TSetActiveFolderByIdHandler = useCallback(
      folderId => () => {
        if (width < 641 && isShowFolders) {
          dispatch(setIsShowNotes(true));
          dispatch(setIsShowFolders(false));
        }
        dispatch(setActiveFolder(folderId));
      },
      [isShowFolders, width, dispatch],
    );

    const setTagInSearchString: TSetTagInSearchString = useCallback(
      tagId => () => {
        dispatch(setSelectedTagToSearch(tagId));
      },
      [dispatch],
    );

    return (
      <div
        className={`flex flex-col sm:grid sm:gap-[1.5rem] sm:grid-cols-[17.5rem_1fr] lg:grid-cols-[35%_1fr] xl:grid-cols-[28.4%_1fr] ${className}`}>
        <NotesFolders
          status={status}
          folders={folders}
          activeFolder={activeFolder}
          isShowFolders={isShowFolders}
          newFolderName={newFolderName}
          setActiveFolder={setActiveFolderHandler}
          crateFolderHandler={createFolderHandler(newFolderName)}
          className='mb-4 sm:mb-0 overflow-y-auto sm:h-[calc(100vh-6rem)] pr-2 task-drawer'
        />
        <NotesListComponent
          tags={tags}
          status={status}
          isLoading={isLoading}
          notesList={notesList}
          isShowNotes={isShowNotes}
          searchString={searchString}
          activeFolder={activeFolder}
          selectedTags={selectedTags}
          isSearchByTag={isSearchByTag}
          isTagDeleteMode={isTagDeleteMode}
          setSearchStringHandler={setSearchHandler}
          setTagInSearchString={setTagInSearchString}
          clearSearchStringHandler={clearSearchStringHandler}
          className=''
        />
      </div>
    );
  },
);

NoteListTemplate.displayName = 'NoteListTemplate';
