import React, { memo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Loader } from '@blocks/loader';
import { TTaskInfo } from '@models/Tasks';
import { TDeleteTask } from '@shared/types';
import { PATH_NAMES } from '@const/httpConst';
import { useAppDispatch } from '@hooks/index';
import { taskPageNameDict } from '@const/task';
import { TemplateNav } from '@blocks/templateNav';
import { ORDER_VARIANTS } from '@models/Settings';
import { ErrorMessage } from '@blocks/errorMessage';
import { setOrderThunk } from '@redux/Settings/thunk';
import { TaskCardList } from '@components/taskCardList';
import { deleteTask, switchTask } from '@redux/Task/thunks';
import { ToastsMessages } from '@components/toastsMessages';
import { HeaderWithButton } from '@components/headerWithButton';
import { EmptyCardsListTemplate } from '@components/emptyCardsListTemplate';
import { clearTaskListErrors, setSortedTasksList, setTaskIdInProgress } from '@redux/Task/slice';

interface ITasksTemplateProps {
  /**
   * Данные страницы
   * @param {TTaskInfo[]}
   */
  data: TTaskInfo[];
  /**
   * Имя домена для формирования ссылки на таск-трекер
   */
  domain: string;
  /**
   * Флаг ошибки при запросе данных для страницы
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает что запрос данных для страницы в процессе
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг показывает что запрос на удаление задачи в процессе
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Флаг повторного запроса данных для страницы
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Флаг ошибки при сохранении удаление задачи
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаление задачи
   * @param {string}
   */
  isDeletingSuccess: boolean;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг ошибки при переключении статуса задачи
   * @param {boolean}
   */
  isTaskSwitchingError: boolean;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Массив айди задач отсортированный пользователем путем перетаскивания карточек
   * @param {string[]}
   */
  sortedTasksList: string[];
}

export const TasksTemplate = memo(
  ({
    data,
    domain,
    isError,
    isLoading,
    isDeleting,
    isFetching,
    isDeletingError,
    sortedTasksList,
    errorToastMessage,
    isDeletingSuccess,
    selectedFilialAccId,
    successToastMessage,
    isTaskSwitchingError,
  }: ITasksTemplateProps) => {
    const path = useLocation().pathname;
    // Получаем функцию navigate для редиректа на страницу новой задачи
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    // переходит на страницу новой задачи
    const addNewTask = useCallback(() => {
      navigate(PATH_NAMES.NEW_TASK);
    }, [navigate]);

    // тогл, переключает активность шаблона
    const switchTaskHandler: TDeleteTask = useCallback(
      taskId => {
        dispatch(setTaskIdInProgress(taskId));
        dispatch(switchTask({ taskId }));
      },
      [dispatch],
    );

    // удаляет шаблон по ID
    const deleteTaskHandler: TDeleteTask = useCallback(
      taskId => {
        const sortedTasksDataWithoutDeletedOne = sortedTasksList.filter(id => id !== taskId);
        dispatch(setTaskIdInProgress(taskId));
        dispatch(deleteTask({ taskId }));
        dispatch(setSortedTasksList(sortedTasksDataWithoutDeletedOne));
        dispatch(
          setOrderThunk({ target: ORDER_VARIANTS.TASKS, data: sortedTasksDataWithoutDeletedOne }),
        );
      },
      [dispatch, sortedTasksList],
    );

    const hideErrorHandler = useCallback(() => {
      dispatch(clearTaskListErrors());
    }, [dispatch]);

    return (
      <>
        <HeaderWithButton domain={domain} headerText='Задачи' className='mb-10 mt-0' />
        <TemplateNav
          path={path}
          isShowAddButton
          isDisable={false}
          callBack={addNewTask}
          pageNameDict={taskPageNameDict}
          selectedFilialAccId={selectedFilialAccId}
          className='mb-6'
        />
        {isError ? <ErrorMessage /> : null}
        {isLoading ? <Loader className='h-[50Vh]' /> : null}
        {isFetching ? <div className='absolute top-0 right-0 bottom-0 left-0 z-[100]' /> : null}
        {!isLoading && !isError && selectedFilialAccId ? (
          data.length ? (
            <TaskCardList
              data={data}
              isDeleting={isDeleting}
              switchTask={switchTaskHandler}
              deleteTask={deleteTaskHandler}
              isDeletingError={isDeletingError}
              sortedTasksList={sortedTasksList}
              isDeletingSuccess={isDeletingSuccess}
            />
          ) : (
            <EmptyCardsListTemplate description='У вас нет ни одной задачи' className='h-[60vh]' />
          )
        ) : null}
        <ToastsMessages
          errorMessage={errorToastMessage}
          successMessage={successToastMessage}
          clearErrorCallback={hideErrorHandler}
          isShowSuccessToast={isDeletingSuccess}
          isShowErrorToast={isDeletingError || isTaskSwitchingError}
        />
      </>
    );
  },
);
