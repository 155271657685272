import React from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TVideoInstructionButtonProps = {
  /**
   * Текст в кнопку
   * @param {string}
   */
  buttonText: string;
  /**
   * Callback для кнопки
   * @param {() => void}
   */
  onClickCallback: () => void;
  /**
   * Флаг показа отпиональной правой иконки
   * @param {boolean}
   */
  isShowRightIcon: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const VideoInstructionButton = ({
  buttonText,
  onClickCallback,
  isShowRightIcon,
  className = '',
}: TVideoInstructionButtonProps) => {
  const width = useWindowWidth();

  const text = width > 980 ? buttonText : '';

  const iconToRender =
    width > 980 ? <Icon variant='videoInstruction' /> : <Icon variant='videoInstruction' />;

  const rightIconToRender = isShowRightIcon && width > 980 ? <Icon variant='verticalMore' /> : null;

  return (
    <div className={`bg-white rounded-full h-fit ${className}`}>
      <Button
        dense
        text={text}
        type='action'
        view='outlined'
        color='default'
        icon={iconToRender}
        onClick={onClickCallback}
        rightIcon={rightIconToRender}
        className={width < 981 ? '!px-2.5' : ''}
      />
    </div>
  );
};
