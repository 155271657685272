import React from 'react';
import { NavLink } from 'react-router-dom';
//
import { Status } from '@atoms/colorStatus';
import { PATH_NAMES } from '@const/httpConst';
import { TFilialData } from '@models/Accounts';

interface IFilialListItemProps {
  /**
   * Отключает кнопку перехода на страницу настроек
   * @param {boolean}
   */
  disableSettingsButton: boolean;
  /**
   * Данные филиала
   * @param {TFilialData}
   */
  filialData: TFilialData;
  /**
   * Callback для установки выбранного филиала в store при клике на NavLink
   * @param {() => void}
   */
  setChosenFilialId: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialListItem = ({
  filialData,
  setChosenFilialId,
  disableSettingsButton,
  className = '',
}: IFilialListItemProps) => (
  <tr className={`border-b border-quartz1 max-h-16 ${className}`}>
    <td className='px-2 cursor-default align-middle h-16'>
      <NavLink
        onClick={setChosenFilialId}
        to={`${PATH_NAMES.TASKS_ALL}?acc_id=${filialData.accId}`}
        className='flex text-blackText  hover:text-blackText'>
        {filialData.name}
      </NavLink>
    </td>
    <td className='px-2 cursor-default align-middle h-16'>
      <div className='flex items-center'>
        <Status status={filialData.beautyBotIntegration} />
        <span className='font-medium ml-3'>
          {filialData.beautyBotIntegration ? `Подключен` : 'Не подключен'}
        </span>
      </div>
    </td>
    <td className='text-right px-2 align-middle h-16'>
      <NavLink
        onClick={setChosenFilialId}
        to={`${PATH_NAMES.TASKS_ALL}?acc_id=${filialData.accId}`}
        className='flex text-blackText  hover:text-blackText'>
        {filialData.quantityOfTemplates}
      </NavLink>
    </td>
    <td className='px-2 align-middle h-16'>
      <NavLink
        onClick={setChosenFilialId}
        to={`${PATH_NAMES.PAY}?acc_id=${filialData?.accId}`}
        className='text-blackText hover:text-blackText'>
        <div className='flex items-center'>
          <Status status={filialData.process} />
          <span className='flex flex-wrap font-medium ml-3'>
            {filialData.process ? 'Оплачен до' : 'Не оплачен'}
            {filialData.process ? (
              <span className='ml-[0.3125rem]'>{filialData.payPeriod}</span>
            ) : null}
          </span>
        </div>
      </NavLink>
    </td>
    <td className='px-2 align-middle h-16'>
      <NavLink
        onClick={setChosenFilialId}
        to={`${PATH_NAMES.SETTINGS}?acc_id=${filialData?.accId}`}
        className={`flex justify-self-start items-center justify-center border border-dGray px-[0.75rem] py-[0.375rem] bg-white font-medium rounded text-[0.875rem] text-blackText h-[1.75rem] hover:no-underline
             ${
               !disableSettingsButton
                 ? 'hover:text-blackText hover:shadow-[0_0_0_1px_rgb(180,180,187,1)] transition-all duration-300 ease-[cubic-bezier(0.25, 0.46, 0.45, 0.94)]'
                 : 'pointer-events-none text-dGray border-dGray'
             }`}>
        Перейти
      </NavLink>
    </td>
  </tr>
);
