import { ReactNode } from 'react';
//
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

type TDndSortableItemProps = {
  /**
   * Идентефикатор карточки для дропзоны
   * @type {string}
   */
  id: string;
  /**
   * Дочерние элементы карточки
   * @type {ReactNode}
   */
  children: ReactNode;
};

export const SortableItem = ({ id, children }: TDndSortableItemProps) => {
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
