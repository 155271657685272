import React from 'react';
import { Link } from 'react-router-dom';

export const AdminSettingsDrawer = () => {
  return (
    <>
      <h3 className='mb-6 text-black text-h3Mobile font-normal tracking-[0.44px]'>
        Для подключения к Задачнику в Yclients:
      </h3>
      <ul className='list-decimal px-4 mb-2'>
        <li>Админу нужно войти в YCL под своей учётной записью.</li>
        <li>На странице «Журнал записи» открыть приложения (крайняя кнопка справа вверху).</li>
        <li>В отобразившемся окне выбрать вкладку «Задачи».</li>
        <li>Нажать кнопку «Получить доступ».</li>
      </ul>
      <p className='mb-8 text-black text-body_text font-normal tracking-[0.44px]'>
        Задачник станет доступен Админу и Управляющему в Yclients.
      </p>
      <h3 className='mb-6 text-black text-h3Mobile font-normal tracking-[0.44px]'>
        Как подключить уведомления о задачах в Telegram?
      </h3>
      <ul className='list-decimal px-4 mb-2'>
        <li>
          <span className='mr-2'>Войдите в</span>
          <Link className='text-link' to='https://lk.admin-bot.tech/' target='_blank'>
            Личный кабинет Админ Бота
          </Link>
        </li>
        <li>На странице «Настройки» найдите имя администратора</li>
        <li>Скопируйте и отправьте ему ссылку на приглашение</li>
      </ul>
      <p className='text-black text-body_text font-normal tracking-[0.44px]'>
        После подключения, администратор сможет видеть уведомления о новых задачах у себя в
        телеграме
      </p>
    </>
  );
};
