import React, { memo, useCallback, useMemo } from 'react';
//
import { Label } from '@atoms/label';
import { Chips } from '@uikit/Chips';
import { THelpKeys } from '@models/index';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { EVENT_KEY_NAMES, EVENT_TYPE_NAMES } from '@const/common';
import {
  TFocusToInput,
  TOnChangeHandler,
  TAddBalloonOnBlur,
  TDeleteBalloonSet,
  TAddBalloonByEnterKey,
} from '@shared/types';

interface IFormInputProps {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  value: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Опциональный параметр скрывает или показывает иконку в Chips components и меняет его цвет
   * @param {boolean}
   * @default
   */
  noIcon?: boolean;
  /**
   * Значение input
   * @param {boolean}
   */
  newValue: string;
  /**
   * Вариант вариант текста в label, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Скрывает label элемент
   * @param {boolean}
   */
  noLabel?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр placeholder текст в input
   * @param {string}
   * @default
   */
  placeholder?: string;
  /**
   * Опциональный параметр показывает или скрывает иконку информации с всплывающей подсказкой
   * @param {boolean}
   * @default
   */
  isShowInfoIcon?: boolean;
  /**
   * Callback для изменения нового значения
   * @param {TOnChangeHandler}
   */
  setNewValue: TOnChangeHandler;
  /**
   * Опциональный параметр включает или отключает валидацию нового значения, если параметр true
   * в input нельзя ввести текст, только число.
   * @param {boolean}
   * @default
   */
  typeOfValueIsNumber?: boolean;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlur}
   */
  addBalloonOnBlur: TAddBalloonOnBlur;
  /**
   * Callback для очистки нового значения
   * @param {(name: string) => void}
   */
  clearNewValue: (name: string) => void;
  /**
   * Callback для добавления Chips component при нажатии на клавише Enter
   * @param {TAddBalloonByEnterKey}
   */
  addBalloonByEnterKey: TAddBalloonByEnterKey;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FormInput = memo(
  ({
    value,
    noIcon,
    variant,
    noLabel,
    inputId,
    newValue,
    textColor,
    placeholder = '',
    setNewValue,
    clearNewValue,
    deleteBalloon,
    isShowInfoIcon = false,
    addBalloonOnBlur,
    typeOfValueIsNumber,
    addBalloonByEnterKey,
    className = '',
  }: IFormInputProps) => {
    // обработчик ввода нового значения
    const onChangeHandler: TOnChangeHandler = useCallback(
      event => setNewValue(event),
      [setNewValue],
    );

    // Устанавливает фокус на поле ввода, фокус устанавливается при клике на контейнер в котором расположен элемент
    const focusToInput: TFocusToInput = useCallback(event => {
      const inputElement = event.currentTarget.querySelector('input');
      inputElement?.focus();
    }, []);

    // Валидирует введенное значение, валидация происходит если передан опциональный проп "typeOfValueIsNumber"
    const isBalloonValueValid = useCallback(
      (valueToValidate: string) => {
        if (typeOfValueIsNumber) {
          return !Number.isNaN(Number(valueToValidate));
        }
        return true;
      },
      [typeOfValueIsNumber],
    );

    // Добавляет новый "шарик", если введенные данные валидны, в противном случае очищает поле ввода
    const addBalloons = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        if (event.type === EVENT_TYPE_NAMES.BLUR) {
          if (isBalloonValueValid(newValue)) {
            addBalloonOnBlur(event);
          } else {
            clearNewValue(event.currentTarget.name);
          }
        }
        if (event.type === EVENT_TYPE_NAMES.KEYUP) {
          if ('key' in event && event.key === EVENT_KEY_NAMES.ENTER) {
            if (isBalloonValueValid(newValue)) {
              addBalloonByEnterKey(event);
            } else {
              clearNewValue(event.currentTarget.name);
            }
          }
        }
      },
      [newValue, isBalloonValueValid, clearNewValue, addBalloonOnBlur, addBalloonByEnterKey],
    );

    // Подготовка "шариков" для рендеринга
    const balloons = useMemo(
      () =>
        value.map((item, index) => {
          const key = `${index}${item}`;
          return (
            <Chips
              key={key}
              removable
              type='def'
              tag={item}
              color={noIcon ? 'danger' : 'default'}
              onRemove={deleteBalloon(item, inputId)}
              className='tracking-wide mb-2.5 mr-2.5'
            />
          );
        }),
      [value, noIcon, deleteBalloon, inputId],
    );

    return (
      <div className={`flex-col ${className}`} onClick={focusToInput}>
        {!noLabel ? (
          <Label
            htmlFor={inputId}
            variant={variant}
            className={`${textColor || 'text-grayText'} mb-2.5`}>
            {isShowInfoIcon ? <IconWithTooltips tooltips={variant} className='mb-0.5' /> : null}
          </Label>
        ) : null}
        <TextInput
          fill
          type='text'
          id={inputId}
          name={inputId}
          color='default'
          view='outlined'
          value={newValue}
          className='mb-2'
          onBlur={addBalloons}
          onKeyUp={addBalloons}
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
      </div>
    );
  },
);
