import React, { memo, useEffect } from 'react';
//
import { getEventData } from '@helpers/index';
import { PayTemplate } from '@templates/pay';
import { NoAccess } from '@components/noAccess';
import { getPayPageData } from '@redux/Pay/thunk';
import { TPageView } from '@helpers/myTracker/type';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getPayPageState } from '@redux/selectors';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';

const PayPage = memo(() => {
  const dispatch = useAppDispatch();
  const { selectedFilial, isLoading: isAccountsLoading } = useAppSelector(getAccountsData);
  const { data, isError, isLoading, isRetries, isAccessDenied } = useAppSelector(getPayPageState);

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.PAY },
      }),
    );
  }, []);

  // Запрашивает данные страницы оплаты при первом рендеренге и при смене филиала в меню
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getPayPageData());
    }
  }, [dispatch, selectedFilial?.accId]);

  // Запрашивает данные страницы оплаты повторно при ошибке токена, без задержки выдает ошибку 403
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getPayPageData()), 100);
    }
  }, [dispatch, isRetries]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <PayTemplate isError={isError} isLoading={isLoading || isAccountsLoading} data={data} />
      ) : null}
    </>
  );
});

export default PayPage;
