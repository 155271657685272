import React from 'react';
//
import { Menu } from '@uikit/Menu';
import { numWord } from '@helpers/index';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TSetSelectedPeriodMonth } from '@shared/types';

type TPeriodStatsInputProps = {
  /**
   * Массив месяцев доступных к выбору
   * @param {Record<number, number[]>}
   */
  enabledMonths: Record<number, number[]>;
  /**
   * Текст подписи для инпута
   * @param {string}
   */
  labelText: string;

  previousSelectedMonth?: number;
  /**
   * Выбранный месяц
   * @param {number}
   */
  selectedMonth: number;
  /**
   * Устанавливает выбранный месяц
   * @param {TSetSelectedPeriodMonth}
   */
  setSelectedMonth: TSetSelectedPeriodMonth;
  /**
   * Массив строк для формирования текста в элементах дропдауна
   * @param {number[]}
   */
  postfixTextArray: string[];
  /**
   * Список месяцев для dropdown
   */
  monthList: number[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PeriodStatsInput = ({
  monthList,
  labelText,
  enabledMonths,
  selectedMonth,
  setSelectedMonth,
  postfixTextArray,
  previousSelectedMonth,
  className = '',
}: TPeriodStatsInputProps) => {
  // Формирует текст в зависимости от выбранного месяца
  const getMonthText = (monthNumber: number) => {
    return `${monthNumber} ${numWord(monthNumber, postfixTextArray)}`;
  };

  // Обрабатывает изменение имени оператора
  const setSelectedMonthHandler = (month: number) => () => {
    setSelectedMonth(month);
  };

  // Подготавливаем массив элементов для отображения списка
  const dropdownItems = monthList.map(item => {
    const months = previousSelectedMonth ? enabledMonths[previousSelectedMonth] : [];
    const isMenuItemDisabled = previousSelectedMonth ? !months.includes(item) : false;

    return (
      <MenuItem
        key={item}
        disabled={isMenuItemDisabled}
        text={<span>{getMonthText(item)}</span>}
        onClick={setSelectedMonthHandler(item)}
      />
    );
  });

  return (
    <div className={`w-full ${className}`}>
      <p className='font-inter text-grayText text-body_text mb-1 leading-6 tracking-[0.0275em]'>
        {labelText}
      </p>
      <Dropdown
        color='default'
        view='outlined'
        position='bottom-left'
        text={<span>{getMonthText(selectedMonth)}</span>}
        content={
          <Menu className={dropdownItems.length === 1 ? `one_item_ul` : ''} view='raised'>
            {dropdownItems}
          </Menu>
        }
        className='when_send_dropdown !m-0 w-full'
      />
    </div>
  );
};
