import React from 'react';
//
import { Chips } from '@uikit/Chips';
import { Table } from '@uikit/Table/Table';
import { TSettingStaff } from '@models/Settings';
import { LinkInTableWithCopyButton } from '@components/linkInTableWithCopyButton';

interface ISettingsStaffTableProps {
  /**
   * Данные для таблицы
   * @param {TSettingStaff[]}
   */
  data: TSettingStaff[];
  /**
   * Callback для сортировки
   * @param {(collumName: string, order: string, tableName: string) => void}
   */
  sortCallback: (collumName: string, order: string, tableName: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SettingsStaffTable = ({
  sortCallback,
  data,
  className = '',
}: ISettingsStaffTableProps) => {
  const getCallStatusChips = (status: number) => {
    return (
      <Chips
        type='dense'
        color={status ? 'success' : 'danger'}
        tag={status ? 'Подключен' : 'Не подключен'}
        withTick={false}
        icon={null}
        rightIcon={null}
        removable={false}
        onRemove={null}
        disabled={false}
        round
        noClick
      />
    );
  };

  const getStylingText = (text: string) => (
    <span className='leading-6 tracking-[0.0275em]'>{text}</span>
  );

  const Head = [
    { id: 'name', label: 'Сотрудник' },
    { id: 'staffLink', label: 'Ссылка для подключения', noSort: true },
    { id: 'status', label: 'Статус', noSort: true },
  ];

  const getTableData = (tableData: TSettingStaff[]) => {
    const emptyData = [
      {
        name: ' ',
        connectionLink: ' ',
        status: ' ',
      },
    ];
    if (!tableData.length) {
      return emptyData;
    }

    return tableData.map(item => {
      return {
        name: getStylingText(item.name),
        staffLink: <LinkInTableWithCopyButton text={item.staffLink} />,
        status: getCallStatusChips(item.status),
      };
    });
  };

  return (
    <div className={className}>
      <Table
        name={Head}
        data={getTableData(data)}
        position={['left', 'left', 'left']}
        type='def'
        view='flat'
        color='default'
        checkboxes={false}
        paperClassName='no_shadow'
        onSortCallback={(collumName, order) => sortCallback(collumName, order, 'staff')}
        style={{ boxShadow: 'none', marginLeft: '6px' }}
      />
    </div>
  );
};
