export interface IInitialState {
  emojiSearchString: string;
  newEmoji: string;
  isShowEmojiPicker: boolean;
  lastEmoji: string[][];
  textAreaName: string;
  isOpen: boolean;
  stepIdEmoji: number;
}

export const initialState: IInitialState = {
  emojiSearchString: '',
  newEmoji: '',
  isShowEmojiPicker: false,
  lastEmoji: [],
  textAreaName: '',
  isOpen: false,
  stepIdEmoji: 0,
};
