import React, { useEffect } from 'react';
//
import { TTelegramAuthResponseData } from '@models/Auth';

interface ITelegramLoginWidgetProps {
  /**
   * Имя telegram бота
   * @param {string}
   */
  botName: string;
  /**
   * Размер кнопки авторизации
   * @param {'large' | 'medium' | 'small'}
   */
  buttonSize: 'large' | 'medium' | 'small';
  /**
   * Опциональный параметр величина скруглления углов кнопки авторизации
   * @param {number}
   * @default
   */
  cornerRadius?: number;
  /**
   * Опциональный параметр отправлять или нет сообщения от бота
   * @param {boolean}
   * @default
   */
  requestAccess?: boolean;
  /**
   * Опциональный параметр скрывает или показывает аватар пользователя
   * @param {boolean}
   * @default
   */
  usePic?: boolean;
  /**
   * Опциональный параметр callback который вызывается после успешной авторизации
   * в параметры функции передаются данные пользователя
   * @param {(user: TTelegramAuthResponseData) => void}
   * @default
   */
  dataOnAuth?: (user: TTelegramAuthResponseData) => void;
  /**
   * Опциональный параметр если передана ссылка после авторизации происходит редирект по указанной ссылке
   * в search параметры помещаются данные пользователя
   * @param {string}
   * @default
   */
  dataAuthUrl?: string;
  /**
   * Опциональный параметр язык виджета
   * @param {string}
   * @default
   */
  lang?: string;
  /**
   * Версия виджета
   * @param {number}
   */
  widgetVersion: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}
// декларируем наличие у window объекта TelegramLoginWidget с методом callbackOnAuth, чтобы не ругался TS)))
declare global {
  interface IWindow {
    TelegramLoginWidget: { callbackOnAuth: (user: TTelegramAuthResponseData) => void };
  }
}

export const TelegramLoginWidget = ({
  botName,
  buttonSize,
  cornerRadius,
  requestAccess,
  usePic = false,
  dataOnAuth,
  dataAuthUrl = '/',
  lang,
  widgetVersion,
  className = '',
}: ITelegramLoginWidgetProps) => {
  const win = window as unknown as IWindow;

  // вызываем коллбак если "включена" такая опция
  if (dataOnAuth) {
    win.TelegramLoginWidget = {
      callbackOnAuth: (user: TTelegramAuthResponseData) => dataOnAuth(user),
    };
  }

  // формируем тэг script и добавляем его в DOM
  useEffect(() => {
    const script = document.createElement('script');
    const wrapper = document.getElementById('botBtnWrapper');

    script.src = `https://telegram.org/js/telegram-widget.js?${widgetVersion}`;
    script.async = true;
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', buttonSize);
    if (!usePic) script.setAttribute('data-userpic', 'false');
    if (lang) script.setAttribute('data-lang', lang);
    if (cornerRadius) script.setAttribute('data-radius', String(cornerRadius));
    if (dataOnAuth) script.setAttribute('data-onauth', 'TelegramLoginWidget.callbackOnAuth(user)');
    if (!dataOnAuth && dataAuthUrl) script.setAttribute('data-auth-url', dataAuthUrl);
    if (requestAccess) script.setAttribute('data-request-access', 'write');

    wrapper?.appendChild(script);

    return () => {
      wrapper?.removeChild(script);
    };
  });

  // создается обертка для формирования кнопки виджета Телеги
  return <div id='botBtnWrapper' className={className} />;
};
