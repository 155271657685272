import React, { memo, useCallback, useMemo } from 'react';
//
import { numWord } from '@helpers/index';
import { useAppDispatch } from '@hooks/index';
import { BBAdsToast } from '@blocks/bbAdsToast';
import { TSimpleStringObjLevel2 } from '@models/index';
import { EventInputBlock } from '@blocks/eventInputBlock';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { ServiceEmployeeInputs } from '@blocks/serviceEmployeeInputs';
import { addTPBalloon, deleteTPBalloon, setVisitQuantity } from '@redux/Task/slice';
import {
  TTPNameList,
  TOnChangeHandler,
  TDeleteBalloonSet,
  TVisitQuantityOnChangeHandler,
} from '@shared/types';
import {
  crmEvents,
  CRM_EVENTS,
  eventsTypes,
  EVENTS_TYPES,
  eventsTypesI,
  bbIntegration,
  bbIntegrationI,
  workStatistics,
  yclientsEvents,
  telephonyEvents,
  workStatisticsI,
  YCLIENTS_EVENTS,
  WORK_STATISTICS_EVENTS,
} from '@const/task';

interface IEventsBlockProps {
  /**
   * Ссылка по которой будут переходить по нажатию кнопке в рекламе Бьюти бота
   * @param {string}
   */
  hrefForCommercial: string;
  /**
   * Параметр target для ссылки в рекламе Бьюти бота
   * @param {'_blank' | '_self'}
   */
  commercialTargetProps: '_blank' | '_self';
  /**
   * Значение выбранного типа события
   * @param {string}
   */
  selectedEventType: string;
  /**
   * Callback для изменения типа события
   * @param {(eventType: string) => () => void}
   */
  setSelectedEventTypeHandler: (eventType: string) => () => void;
  /**
   * Значение типа события телефонии
   * @param {string}
   */
  selectedTelephonyType: string;
  /**
   * Callback для изменения типа события телефонии
   * @param {(telephonyEvent: string) => () => void}
   */
  setSelectedTelephonyEventHandler: (telephonyEvent: string) => () => void;
  /**
   * Значение типа события Yclients
   * @param {string}
   */
  selectedYclientsType: string;
  /**
   * Callback для изменения типа события Yclients
   * @param {(yclientsEvent: string) => () => void}
   */
  setSelectedYclientsTypeHandler: (yclientsEvent: string) => () => void;
  /**
   * Значение типа события CRM
   * @param {string}
   */
  selectedCrmType: string;
  /**
   * Callback для изменения типа события CRM
   * @param {(crmEvent: string) => () => void}
   */
  setSelectedCrmTypeHandler: (crmEvent: string) => () => void;
  /**
   * Значение размера скидки
   * @param {string}
   */
  discountAmount: string;
  /**
   * Callback для изменения размера скидки
   * @param {TOnChangeHandler}
   */
  setDiscountHandler: TOnChangeHandler;
  /**
   * Значение времени задержки клиента
   * @param {string}
   */
  clientDelayed: string;
  /**
   * Callback для изменения времени задержки клиента
   * @param {TOnChangeHandler}
   */
  setClientDelayHandler: TOnChangeHandler;
  /**
   * Значение типа события интеграции с Бьюти ботом
   * @param {string}
   */
  selectedBBIntegrationType: string;
  /**
   * Callback для изменения типа события интеграции с Бьюти ботом
   * @param {(bbIntegrationType: string) => () => void}
   */
  setSelectedBBIntegrationTypeHandler: (bbIntegrationType: string) => () => void;
  /**
   * Флаг состояния интеграции с Бьюти ботом
   * Если ББ не подключен будут недоступны некоторые виды событий
   * @param {boolean}
   */
  isBBIntegrated: boolean;
  /**
   * Значение типа события статистики
   * @param {string}
   */
  selectedWorkStatisticsType: string;
  /**
   * Callback для изменения типа события статистики
   * @param {(workStatisticsType: string) => () => void}
   */
  setSelectedWorkStatisticsTypeHandler: (workStatisticsType: string) => () => void;
  /**
   * Значение параметра процент пропущенных звонков
   * @param {string}
   */
  missedCallsPercents: string;
  /**
   * Callback для изменения процента пропущенных звонков
   * @param {TOnChangeHandler}
   */
  setMissedCallsPercentsHandler: TOnChangeHandler;
  /**
   * Значение параметра средняя скорость ответа на сообщения в Whatsapp
   * @param {string}
   */
  thresholdWhatsappResponseRate: string;
  /**
   * Callback для изменения средней скорости ответа на сообщения в Whatsapp
   * @param {TOnChangeHandler}
   */
  setThresholdWhatsappResponseRateHandler: TOnChangeHandler;
  /**
   * Значение параметра процент записанных клиентов после общения
   * @param {string}
   */
  thresholdLowEnrolledPercentage: string;
  /**
   * Callback для изменения процента записанных клиентов после общения
   * @param {TOnChangeHandler}
   */
  setThresholdLowEnrolledPercentageHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Массив значений фильтра сотрудников
   * @param {Array<string>}
   */
  staff: Array<string>;
  /**
   * Новое значение фильтра сотрудников
   * @param {string}
   */
  newStaffValue: string;
  /**
   * Массив значений фильтра услуг
   * @param {Array<string>}
   */
  services: Array<string>;
  /**
   * Новое значение фильтра услуг
   * @param {string}
   */
  newServicesValue: string;
  /**
   * Массив значений блока визиты, первое значение в массиве это quantityFrom, второе quantityTo
   * @param {Array<string>}
   */
  visits: Array<string>;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
}

export const EventsBlock = memo(
  ({
    staff,
    visits,
    services,
    isSuccess,
    newStaffValue,
    clientDelayed,
    isBBIntegrated,
    discountAmount,
    className = '',
    selectedCrmType,
    newServicesValue,
    selectedEventType,
    hrefForCommercial,
    categoriesDataList,
    setDiscountHandler,
    missedCallsPercents,
    selectedFilialAccId,
    selectedYclientsType,
    selectedTelephonyType,
    commercialTargetProps,
    setClientDelayHandler,
    selectedBBIntegrationType,
    setSelectedCrmTypeHandler,
    selectedWorkStatisticsType,
    setSelectedEventTypeHandler,
    thresholdWhatsappResponseRate,
    setMissedCallsPercentsHandler,
    thresholdLowEnrolledPercentage,
    setSelectedYclientsTypeHandler,
    setSelectedTelephonyEventHandler,
    setSelectedBBIntegrationTypeHandler,
    setSelectedWorkStatisticsTypeHandler,
    setThresholdWhatsappResponseRateHandler,
    setThresholdLowEnrolledPercentageHandler,
  }: IEventsBlockProps) => {
    // выбирает объект с описанием пунктов меню, если бьюти бот не подключен,
    // выбирается объект с I на конце в нем в некоторых пунктах меню,
    // в конце стоит буква I, к таким пунктам будет в последствии добавлена иконка
    const event = useMemo(() => (isBBIntegrated ? eventsTypes : eventsTypesI), [isBBIntegrated]);
    const integration = useMemo(
      () => (isBBIntegrated ? bbIntegration : bbIntegrationI),
      [isBBIntegrated],
    );
    const statistics = useMemo(
      () => (isBBIntegrated ? workStatistics : workStatisticsI),
      [isBBIntegrated],
    );
    const dispatch = useAppDispatch();

    // Удаляет 'шарик' по его значению
    const deleteBalloon: TDeleteBalloonSet = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TTPNameList;
        dispatch(deleteTPBalloon({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: (name: string, value: string) => void = useCallback(
      (name, value) => {
        const keyName = name as TTPNameList;
        dispatch(addTPBalloon({ keyName, value }));
      },
      [dispatch],
    );

    // Обрабатывает изменения количества визитов
    const visitQuantityOnChangeHandler: TVisitQuantityOnChangeHandler = useCallback(
      ({ newQuantityFromValue, newQuantityToValue }) => {
        dispatch(
          setVisitQuantity({
            quantityFrom: newQuantityFromValue,
            quantityTo: newQuantityToValue,
          }),
        );
      },
      [dispatch],
    );

    // Условие для отображения предупреждения о не подключенном ББ
    const isShowAdd =
      !isBBIntegrated &&
      (selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE ||
        selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE);

    return (
      <div className={className}>
        <p className='text-black text-h3Mobile mb-4'>Типы событий</p>
        <DropdownWithIcon
          items={event}
          selectedItem={selectedEventType}
          callBack={setSelectedEventTypeHandler}
          className='w-full max-w-[47rem]'
        />
        {selectedEventType === EVENTS_TYPES.TELEPHONY ? (
          <div className='mt-4'>
            <DropdownWithIcon
              items={telephonyEvents}
              selectedItem={selectedTelephonyType}
              callBack={setSelectedTelephonyEventHandler}
              className='w-full max-w-[47rem]'
            />
          </div>
        ) : null}
        {selectedEventType === EVENTS_TYPES.YCLIENTS ? (
          <>
            <div className='mt-4'>
              <DropdownWithIcon
                items={yclientsEvents}
                selectedItem={selectedYclientsType}
                callBack={setSelectedYclientsTypeHandler}
                className='w-full max-w-[47rem]'
              />
            </div>
            {selectedYclientsType === YCLIENTS_EVENTS.CLOSING_WITH_DISCOUNT ? (
              <EventInputBlock
                inputValue={discountAmount}
                InputOnChangeCallback={setDiscountHandler}
                textBeforeInput='Размер скидки более'
                textAfterInput='%'
                className='mt-4'
              />
            ) : null}
            {selectedYclientsType === YCLIENTS_EVENTS.CLIENT_WAS_DETAINED ? (
              <EventInputBlock
                inputValue={clientDelayed}
                InputOnChangeCallback={setClientDelayHandler}
                textBeforeInput='Задержка более чем на'
                textAfterInput={numWord(Number(clientDelayed), ['минуты', 'минут', 'минут'])}
                className='mt-4'
              />
            ) : null}
          </>
        ) : null}
        {selectedEventType === EVENTS_TYPES.BB_INTEGRATION ? (
          <>
            <div className='mt-4'>
              <DropdownWithIcon
                items={integration}
                selectedItem={selectedBBIntegrationType}
                callBack={setSelectedBBIntegrationTypeHandler}
                className='w-full max-w-[47rem]'
              />
            </div>
            {!isBBIntegrated && !isBBIntegrated ? (
              <BBAdsToast
                href={hrefForCommercial}
                target={commercialTargetProps}
                className='mt-4 max-w-[47rem]'
              />
            ) : null}
          </>
        ) : null}
        {selectedEventType === EVENTS_TYPES.WORK_STATISTICS ? (
          <>
            <div className='mt-4'>
              <DropdownWithIcon
                items={statistics}
                selectedItem={selectedWorkStatisticsType}
                callBack={setSelectedWorkStatisticsTypeHandler}
                className='w-full max-w-[47rem]'
              />
            </div>
            {selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.MISSED_CALL_STATISTICS ? (
              <EventInputBlock
                inputValue={missedCallsPercents}
                InputOnChangeCallback={setMissedCallsPercentsHandler}
                textBeforeInput='Пропущенных звонков более'
                textAfterInput='%'
                className='mt-4'
              />
            ) : null}
            {selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE ? (
              <EventInputBlock
                inputValue={thresholdWhatsappResponseRate}
                InputOnChangeCallback={setThresholdWhatsappResponseRateHandler}
                textBeforeInput='Средняя скорость ответа на сообщения в Whatsapp более'
                textAfterInput={numWord(Number(clientDelayed), ['минуты', 'минут', 'минут'])}
                className='mt-4'
              />
            ) : null}
            {selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE ? (
              <EventInputBlock
                inputValue={thresholdLowEnrolledPercentage}
                InputOnChangeCallback={setThresholdLowEnrolledPercentageHandler}
                textBeforeInput='Записанных клиентов после общения ниже'
                textAfterInput='%'
                className='mt-4'
              />
            ) : null}
            {isShowAdd ? (
              <BBAdsToast
                href={hrefForCommercial}
                target={commercialTargetProps}
                className='mt-4 max-w-[47rem]'
              />
            ) : null}
          </>
        ) : null}
        {selectedEventType === EVENTS_TYPES.CRM ? (
          <div className='mt-4'>
            <DropdownWithIcon
              items={crmEvents}
              selectedItem={selectedCrmType}
              callBack={setSelectedCrmTypeHandler}
              className='w-full max-w-[47rem]'
            />
            {selectedCrmType === CRM_EVENTS.RECORD_DONE ||
            selectedCrmType === CRM_EVENTS.RECORD_CANCELLED ? (
              <ServiceEmployeeInputs
                staff={staff}
                visits={visits}
                services={services}
                isSuccess={isSuccess}
                newStaffValue={newStaffValue}
                deleteBalloon={deleteBalloon}
                newServicesValue={newServicesValue}
                categoriesDataList={categoriesDataList}
                selectedFilialAccId={selectedFilialAccId}
                addBalloonOnBlurHandler={addBalloonOnBlurHandler}
                visitQuantityOnChangeHandler={visitQuantityOnChangeHandler}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
);
