import React, { memo, useEffect } from 'react';
//
import { getEventData } from '@helpers/index';
import { NoAccess } from '@components/noAccess';
import { getReportList } from '@redux/Report/thunk';
import { TPageView } from '@helpers/myTracker/type';
import { ReportsTemplate } from '@templates/reports';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getReportPageData } from '@redux/selectors';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';

const ReportsListPage = memo(() => {
  const dispatch = useAppDispatch();
  const {
    isError,
    isRefresh,
    isRetries,
    isLoading,
    isDeleting,
    isAccessDenied,
    isDeletingError,
    errorToastMessage,
    sortedReportsData,
    isDeletingSuccess,
    successToastMessage,
    isReportSwitchingError,
    reportListData: data,
  } = useAppSelector(getReportPageData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.REPORTS },
      }),
    );
  }, []);

  // Делаем запрос за списком отчетов
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getReportList());
    }
  }, [selectedFilial?.accId, dispatch]);

  // Делаем запрос за списком отчетов
  useEffect(() => {
    if (isRefresh) {
      dispatch(getReportList());
    }
  }, [isRefresh, dispatch]);

  // Делаем запрос за списком отчетов при ошибки токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getReportList()), 100);
    }
  }, [dispatch, isRetries]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <ReportsTemplate
          data={data}
          isError={isError}
          isLoading={isLoading}
          isFetching={isRefresh}
          isDeleting={isDeleting}
          isDeletingError={isDeletingError}
          sortedReportsData={sortedReportsData}
          errorToastMessage={errorToastMessage}
          isDeletingSuccess={isDeletingSuccess}
          successToastMessage={successToastMessage}
          isReportSwitchingError={isReportSwitchingError}
          selectedFilialAccId={selectedFilial?.accId || ''}
        />
      ) : null}
    </>
  );
});

export default ReportsListPage;
