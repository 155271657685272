import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';
import { REPORT_SEND_PERIOD } from '@const/report';

interface IReportWarningProps {
  /**
   * Выбранный период отправки
   * @param {string}
   */
  period: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportWarning = memo(({ period, className = '' }: IReportWarningProps) => (
  <div className={`bg bg-seashell flex p-4 rounded-lg mt-6 min-h-[5rem] ${className}`}>
    <div className='self-start mr-3 min-w-[1.25rem]'>
      <Icon variant='warningInfo' className='mt-1 h-5 w-5' />
    </div>
    <p className='text-black leading-6 tracking-[0.0275em] m-0'>
      {`Проверьте параметры шаблона. Вы выбрали раннее время доставки отчёта за ${
        period === REPORT_SEND_PERIOD.EVERY_DAY ? '«Сегодня»' : '«Неделю»'
      } данные будут не полными.`}
    </p>
  </div>
));
