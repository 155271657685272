import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Title,
  Colors,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
//
import { itemSort, noDataChartPlugin } from '@helpers/index';
import { FormattedData, TTooltipItems } from '@models/index';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

interface IStackedBarChartProps {
  /**
   * Данные для графика
   * @param {any[]}
   */
  data: FormattedData;
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Флаг включения подсчета суммы в легенде
   * @param {boolean}
   */
  isUseFooter?: boolean;
  /**
   * Позиция заголовка на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | undefined}
   */
  chartTitlePosition?: 'bottom' | 'left' | 'top' | 'right' | undefined;
}

export const StackedBarChart = ({
  data,
  chartTitle,
  isUseFooter,
  chartTitlePosition,
}: IStackedBarChartProps) => {
  const height = (data?.datasets.length || 0) * 18;

  /**
   * Функция добавляет в футер tooltip общую сумму
   * @param tooltipItems
   */
  const footer = (tooltipItems: TTooltipItems) => {
    let sum = 0;
    tooltipItems.forEach(tooltipItem => {
      sum += tooltipItem.parsed.y;
    });
    return isUseFooter ? `Сумма: ${sum} руб.` : '';
  };

  const options = {
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        position: 'nearest' as const,
        itemSort,
        callbacks: {
          footer,
        },
        // eslint-disable-next-line
        filter(e: any) {
          return !!+e.raw;
        },
      },
      title: {
        display: false,
        text: chartTitle,
        position: `${chartTitlePosition || 'bottom'}` as const,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div
      className='chart-container'
      style={{
        position: 'relative',
        height: `clamp(420px, ${height}px, 1000px)`,
        width: `clamp(200px, 99%, 1270px)`,
      }}>
      <Bar options={options} data={data} plugins={[noDataChartPlugin]} />
    </div>
  );
};
