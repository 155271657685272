import { storageDb } from '@api/storageApi';
import { NO_FILIAL_ERROR } from '@data/dict';
import { PayloadAction } from '@reduxjs/toolkit';
import { TAccountsData } from '@models/Accounts';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TInitialState, TSetSelectedFilial } from '@redux/Accounts/zod';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';

export const reducers = {
  setSelectedFilial(state: TInitialState, action: PayloadAction<TSetSelectedFilial>) {
    const { accId, branchId, filialName, rights } = action.payload;
    if (accId === NO_FILIAL_ERROR.TEXT && filialName === NO_FILIAL_ERROR.TEXT) {
      storageDb.setSelectedFilialData({ accId, filialName, branchId });
    } else {
      storageDb.setSelectedFilialData({ accId, filialName, branchId });
      storageDb.setRight(rights);
      [state.selectedFilial] = state.data.filter(item => item.accId === accId);
    }
  },
  setIsAccessDenied(state: TInitialState) {
    state.isAccessDenied = true;
  },
  showNoFilialError(state: TInitialState) {
    state.isNoFilialError = true;
  },
  hideNoFilialError(state: TInitialState) {
    storageDb.setSelectedFilialData({
      accId: state.selectedFilial?.accId || '',
      filialName: state.selectedFilial?.name || '',
      branchId: state.selectedFilial?.branchId || 0,
    });
    state.isNoFilialError = false;
  },
};

const pending = (state: TInitialState) => {
  state.isError = false;
  state.isLoading = true;
};

export const getAccountsReducer = {
  pending,
  fulfilled: (state: TInitialState, action: PayloadAction<TAccountsData | void>) => {
    if (action.payload) {
      const { bannerData, data } = action.payload;
      state.data = data;

      if (bannerData) {
        state.bannerData = bannerData;
      }
    } else {
      state.data = [];
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
  },
  rejected: (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};
