import React, { memo, useCallback } from 'react';
//
import { H1 } from '@atoms/h1';
import { Descendant } from 'slate';
import { Button } from '@uikit/Button';
import { TTaskInfo } from '@models/Tasks';
import { NEW_NOTE_ID } from '@const/notes';
import { PATH_NAMES } from '@const/httpConst';
import { useAppDispatch } from '@hooks/index';
import { QUERY_PARAMS } from '@const/apiConstants';
import { NoteAddTag } from '@components/noteAddTag';
import { setActiveFolder } from '@redux/noteList/slice';
import { NoteAddToTask } from '@components/noteAddToTask';
import { TFolderItem, TNoteTag } from '@redux/noteList/zod';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { NoteControlButtons } from '@components/noteControlButtons';
import RichTextExample from '@components/slateEditor/richTextEditor';
import { TAttachedTemplates, TNonePageStatus, TNote } from '@redux/note/zod';
import { TOnChangeHandlerInputTextarea, TSetActiveFolderHandler } from '@shared/types';
import { setActiveFolderInNote, setDescription, setEditMode, setTitle } from '@redux/note/slice';
import { createNewTagThunk, deleteNoteByIdThunk, moveNoteToFolderThunk } from '@redux/note/thunk';

type TProps = {
  /**
   * Флаг загрузки данных
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Статус запросов на страницы
   * @param {TNonePageStatus}
   */
  status: TNonePageStatus;
  /**
   * Id выбранного фииала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Флаг режима редактирования заметки
   * @param {boolean}
   */
  isNoteEdit: boolean;
  /**
   * Значение поля ввода для добавления новых тегов
   * @param {string}
   */
  newTag: string;
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Флаг создания новой заметки
   * @param {boolean}
   */
  isNewNote: boolean;
  /**
   * Активная папка
   * @param {TFolderItem}
   */
  activeFolder: TFolderItem;
  /**
   * Список папок дла дропдауна
   * @param {TFolderItem[]}
   */
  folderList: TFolderItem[];
  /**
   * Данные о задачах
   * @param {TTaskInfo[]}
   */
  taskInfo: TTaskInfo[];
  /**
   * Флаг отключения кнопки сохранения
   * @param {boolean}
   */
  isSaveButtonDisabled: boolean;
  /**
   * Callback для сохранения заметки
   * @param {() => void}
   */
  saveNoteHandler: () => void;
  /**
   * Список задач для прикрепления заметки
   * @param {TTaskInfo[]}
   */
  taskListToAttachNote: TTaskInfo[];
  /**
   * Список задач к котрым прикреплена заметка
   * @param {TAttachedTemplates[]}
   */
  attachedTemplates: TAttachedTemplates[];
  /**
   * Данные заметки
   * @param {TNote}
   */
  data: TNote;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NoteTemplate = memo(
  ({
    newTag,
    status,
    taskInfo,
    isLoading,
    isNewNote,
    isNoteEdit,
    folderList,
    activeFolder,
    tags: allTags,
    saveNoteHandler,
    attachedTemplates,
    selectedFilialAccId,
    isSaveButtonDisabled,
    taskListToAttachNote,
    data,
    className = '',
  }: TProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { tags, isPrivate, description, title, id } = data;

    const setDescriptionHandler = useCallback(
      (newValue: Descendant[]) => {
        dispatch(setDescription(newValue));
      },
      [dispatch],
    );

    const createNewTagHandler = useCallback(
      (newTagTitle: string) => (closeModalCallback: () => void) => {
        dispatch(createNewTagThunk({ newTagTitle, closeModalCallback }));
      },
      [dispatch],
    );

    const setTitleHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        const { value } = event.currentTarget;

        dispatch(setTitle(value));
      },
      [dispatch],
    );

    const enableNoteEditMode = useCallback(() => {
      dispatch(setEditMode(true));
    }, [dispatch]);

    const moveNoteToFolderHandler: TSetActiveFolderHandler = useCallback(
      newActiveFolder => {
        dispatch(setActiveFolder(newActiveFolder.id));

        if (isNoteEdit) {
          dispatch(setActiveFolderInNote(newActiveFolder));
        } else {
          dispatch(moveNoteToFolderThunk({ noteData: data, activeFolder: newActiveFolder }));
        }
      },
      [data, isNoteEdit, dispatch],
    );

    const deleteNoteById = useCallback(
      (noteId: string) => (modalCloseCallback: () => void) => {
        const navigateToNoteList = () => {
          navigate({
            pathname: PATH_NAMES.NOTE_LIST,
            search: createSearchParams({
              [QUERY_PARAMS.ACC_ID]: selectedFilialAccId,
            }).toString(),
          });
          modalCloseCallback();
        };
        dispatch(deleteNoteByIdThunk({ noteId, modalCloseCallback: navigateToNoteList }));
      },
      [selectedFilialAccId, dispatch, navigate],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        {!isLoading ? (
          <>
            <div className='flex items-center justify-between mb-2 sm:mb-6'>
              <div className={`flex items-center min-h-12 h-fit ${isNoteEdit ? 'w-full' : ''}`}>
                {isNoteEdit ? (
                  <input
                    type='text'
                    value={title}
                    color='default'
                    onChange={setTitleHandler}
                    placeholder='Введите заголовок заметки'
                    className={`border border-dGray block rounded-lg text-h1_header lg:text-h1 md:text-h2Mobile font-bold text-blackHeader m-0 !tracking-[-0.04rem] px-2 py-1 mb-2 ${
                      isNewNote ? '' : 'mr-2'
                    } mb-2 w-full`}
                  />
                ) : (
                  <H1
                    className={`mb-2 break-words ${
                      isNewNote ? '' : 'mr-2'
                    } max-w-[82.5rem] w-full overflow_wrap`}>
                    {title}
                  </H1>
                )}
              </div>
              {(!isNewNote || id !== NEW_NOTE_ID) && !isNoteEdit ? (
                <>
                  <Button
                    dense
                    icon='edit'
                    type='action'
                    view='outlined'
                    color='default'
                    text='Редактировать'
                    onClick={enableNoteEditMode}
                    className='hidden sm:flex !px-4 mb-2'
                    style={{ minWidth: '10.625rem', height: '2.5rem' }}
                  />
                  <Button
                    icon='edit'
                    type='icon'
                    view='outlined'
                    color='default'
                    onClick={enableNoteEditMode}
                    className='sm:hidden mb-2 min-h-10 min-w-10'
                    style={{ minWidth: '2.5rem', height: '2.5rem' }}
                  />
                </>
              ) : null}
            </div>
            <div className='flex items-center justify-end mb-6 w-full'>
              {/* <NoteVisibility isNoteEdit={isNoteEdit} isPrivate={isPrivate} /> */}
              <NoteControlButtons
                status={status}
                folderList={folderList}
                activeFolder={activeFolder}
                deleteNote={deleteNoteById(id)}
                moveNoteToFolder={moveNoteToFolderHandler}
                isNewNote={isNewNote && id === NEW_NOTE_ID}
              />
            </div>
            <div className='flex flex-col lg:grid lg:grid-cols-[66.1%_32%] gap-[1.5rem]'>
              <div className='flex flex-col lg:pr-[13.75%]'>
                <NoteAddTag
                  tags={allTags}
                  newTag={newTag}
                  addedTags={tags}
                  isNoteEdit={isNoteEdit || id === NEW_NOTE_ID}
                  createNewTagHandler={createNewTagHandler(newTag)}
                  className='mb-4'
                />
                {isNoteEdit ? (
                  <>
                    <RichTextExample
                      readOnly={false}
                      value={JSON.parse(description)}
                      onChange={setDescriptionHandler}
                    />
                    <NoteAddToTask
                      isMobile
                      noteId={id}
                      status={status}
                      taskInfo={taskInfo}
                      isPrivate={isPrivate}
                      isNoteEdit={isNoteEdit}
                      attachedTemplates={attachedTemplates}
                      taskListToAttachNote={taskListToAttachNote}
                      className='lg:hidden mb-4 w-full'
                    />
                    <Button
                      dense
                      type='action'
                      view='filled'
                      color='success'
                      text='Сохранить заметку'
                      onClick={saveNoteHandler}
                      loading={status === 'loading'}
                      disabled={isSaveButtonDisabled}
                      className='sticky bottom-3 sm:w-fit'
                    />
                  </>
                ) : (
                  <RichTextExample
                    readOnly
                    value={JSON.parse(description)}
                    onChange={setDescriptionHandler}
                  />
                )}
              </div>
              {id !== NEW_NOTE_ID ? (
                <NoteAddToTask
                  noteId={id}
                  status={status}
                  taskInfo={taskInfo}
                  isPrivate={isPrivate}
                  isNoteEdit={id !== NEW_NOTE_ID}
                  attachedTemplates={attachedTemplates}
                  taskListToAttachNote={taskListToAttachNote}
                  className='hidden lg:flex'
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  },
);

NoteTemplate.displayName = 'NoteTemplate';
