import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { H1 } from '@atoms/h1';
import { TOnChangeHandler } from '@shared/types';
import { QUERY_PARAMS } from '@const/apiConstants';
import { TableWrapper } from '@components/tableWrapper';
import { setAnalyticsDates } from '@redux/Analytics/slice';
import { TTelephonyTablePageData } from '@models/Settings';
import { TelephonyTable } from '@components/telephonyTable';
import { getTelephonyPageData } from '@redux/Settings/thunk';
import { setPhoneSearchString } from '@redux/Settings/slice';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { TPageEdit, TPageView } from '@helpers/myTracker/type';
import { getDateStringToGetData, getEventData } from '@helpers/index';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { setSelectedAllInCallsFilterName } from '@redux/Dashboard/slice';
import { adminsAllInCallsFilterNames, DASHBOARD_KEYS } from '@const/dashboard';
import { getAnalyticsData, getDashboardPageData, getSettingsData } from '@redux/selectors';
import {
  postEvent,
  PAGE_NAMES,
  MY_TRACKER_EVENTS,
  PAGE_CHANGES_TYPE,
} from '@helpers/myTracker/myTracker';

interface IAdminsTableProps {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Данные для таблицы
   * @param {TTelephonyTablePageData}
   */
  telephonyTableData: TTelephonyTablePageData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const AdminsTable = ({
  telephonyTableData,
  selectedFilialAccId,
  className = '',
}: IAdminsTableProps) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tableName = searchParams.get(QUERY_PARAMS.TABLE_NAME);
  const {
    newPage,
    isError,
    isLoading,
    sortDirection,
    sortColumnName,
    firstFilterValue,
    phoneSearchString,
    newPaginationSize,
  } = useAppSelector(getSettingsData);
  const { data, selectedAllInCallsFilterName } = useAppSelector(getDashboardPageData);
  const { analyticsStartDate, analyticsEndDate } = useAppSelector(getAnalyticsData);

  useEffect(() => {
    if (selectedFilialAccId) {
      dispatch(
        getTelephonyPageData({
          page: newPage,
          size: newPaginationSize,
          end_date: getDateStringToGetData(new Date(analyticsEndDate)),
          start_date: getDateStringToGetData(new Date(analyticsStartDate)),
          reverse: sortDirection !== 'asc',
          filter: firstFilterValue,
          sort: sortColumnName,
          phone: '',
        }),
      );
    }
    return () => {
      dispatch(setPhoneSearchString(''));
    };
  }, [
    newPage,
    sortDirection,
    sortColumnName,
    firstFilterValue,
    analyticsEndDate,
    newPaginationSize,
    analyticsStartDate,
    selectedFilialAccId,
    dispatch,
  ]);

  // Устанавливает диапазон дат для получения данных
  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    postEvent<TPageView & TPageEdit>(
      getEventData<TPageView & TPageEdit>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
        eventProperties: {
          name_page: PAGE_NAMES.ANALYTICS_CALL_DETAILS,
          type_changes: PAGE_CHANGES_TYPE.PERIOD,
        },
      }),
    );

    dispatch(setAnalyticsDates({ startDate, endDate }));
  };

  // Переключает фильтры данных
  const selectedAllInCallsFilterNameHandler = (filterName: string) => {
    dispatch(setSelectedAllInCallsFilterName(filterName as DASHBOARD_KEYS));
  };

  // Диспатчит thunk для получения данных таблицы
  const refreshTableDataAfterClearPhone = () => {
    dispatch(
      getTelephonyPageData({
        page: newPage,
        size: newPaginationSize,
        end_date: getDateStringToGetData(new Date(analyticsEndDate)),
        start_date: getDateStringToGetData(new Date(analyticsStartDate)),
        reverse: sortDirection !== 'asc',
        filter: firstFilterValue,
        sort: sortColumnName,
        phone: '',
      }),
    );
  };

  // Обрабатывает изменения в поле ввода телефона
  const setSearchStringHandler: TOnChangeHandler = event => {
    const { value } = event.currentTarget;
    if (!value) {
      refreshTableDataAfterClearPhone();
    }
    dispatch(setPhoneSearchString(value));
  };

  //  Диспатчит thunk для получения данных таблицы с фильтрацией по номеру телефона
  const startSearchHandler = () => {
    postEvent<TPageView & TPageEdit>(
      getEventData<TPageView & TPageEdit>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_CHANGE,
        eventProperties: {
          name_page: PAGE_NAMES.ANALYTICS_CALL_DETAILS,
          type_changes: PAGE_CHANGES_TYPE.SEARCH,
        },
      }),
    );

    dispatch(
      getTelephonyPageData({
        page: newPage,
        size: newPaginationSize,
        end_date: getDateStringToGetData(new Date(analyticsEndDate)),
        start_date: getDateStringToGetData(new Date(analyticsStartDate)),
        reverse: sortDirection !== 'asc',
        filter: firstFilterValue,
        sort: sortColumnName,
        phone: phoneSearchString,
      }),
    );
  };

  // обрабатывает нажатия на номер телефона в таблице, после нажатия осуществляется сортировка по номеру
  const setSearchByClickOnNumber = (phone: string) => () => {
    if (phone !== phoneSearchString) {
      dispatch(setPhoneSearchString(phone));

      dispatch(
        getTelephonyPageData({
          page: newPage,
          size: newPaginationSize,
          end_date: getDateStringToGetData(new Date(analyticsEndDate)),
          start_date: getDateStringToGetData(new Date(analyticsStartDate)),
          reverse: sortDirection !== 'asc',
          filter: firstFilterValue,
          sort: sortColumnName,
          phone,
        }),
      );
    }
  };

  // обрабатывает нажатие на кнопку очищения поля ввода телефона
  const clearSearchStringHandler = () => {
    dispatch(setPhoneSearchString(''));
    refreshTableDataAfterClearPhone();
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {/* <DashboardPathLinks
        className='mb-10'
        secondPageName='Админы'
        secondPageLink={PATH_NAMES.ADMINS}
        thirdPageName={ADMINS_CHARTS[tableName || '']}
        selectedFilialAccId={selectedFilialAccId}
      /> */}
      <H1 className='mb-10 tracking-[-0.01em]'>Детализация звонков</H1>
      <div className='flex flex-wrap items-center justify-between mb-10'>
        <DateRangeDashboardPicker
          startDate={new Date(analyticsStartDate)}
          endDate={new Date(analyticsEndDate)}
          setSendingDate={setDateRangeHandler}
          className='max-w-[17.5rem]'
        />
      </div>
      <TableWrapper
        data={data.admins}
        isLoading={isLoading}
        isChartTypeSwitcherModule
        searchString={phoneSearchString}
        chartDataKeyName={tableName || ''}
        startSearchHandler={startSearchHandler}
        chartFilterNames={adminsAllInCallsFilterNames}
        callback={selectedAllInCallsFilterNameHandler}
        setSearchStringHandler={setSearchStringHandler}
        selectedFilterName={selectedAllInCallsFilterName}
        clearSearchStringHandler={clearSearchStringHandler}
        table={
          <TelephonyTable
            isError={isError}
            isLoading={isLoading}
            data={telephonyTableData}
            phoneSearchString={phoneSearchString}
            setSearchByClickOnNumber={setSearchByClickOnNumber}
          />
        }
      />
      {/* {[tableName].includes(ADMINS_CHARTS_NAMES.ALL_OUT_CALLS) ? (
        <TableWrapper
          isTableView
          isChartSetupModule
          data={data.admins}
          pathName={pathName}
          isChartTypeSwitcherModule
          chartName={ADMINS_CHARTS[tableName || '']}
          chartDataKeyName={tableName || ''}
          tooltipsVariant={(tableName || '') as keyof IHelp}
          selectedFilterName={selectedAllOutCallsFilterName}
          chartFilterNames={adminsAllOutCallsFilterNames}
          callback={selectedAllOutCallsFilterNameHandler}
        />
      ) : null}
      {[tableName].includes(ADMINS_CHARTS_NAMES.ALL_IN_CALLS) ? (
        <TableWrapper
          isTableView
          isChartSetupModule
          data={data.admins}
          pathName={pathName}
          isChartTypeSwitcherModule
          chartName={ADMINS_CHARTS[tableName || '']}
          chartDataKeyName={tableName || ''}
          tooltipsVariant={(tableName || '') as keyof IHelp}
          selectedFilterName={selectedAllInCallsFilterName}
          selectedFilialAccId={selectedFilialAccId}
          chartFilterNames={adminsAllInCallsFilterNames}
          callback={selectedAllInCallsFilterNameHandler}
        />
      ) : null}
      {[tableName].includes(ADMINS_CHARTS_NAMES.WA_AVERAGE_TIME) ? (
        <TableWrapper
          isTableView
          isChartSetupModule
          data={data.admins}
          pathName={pathName}
          isChartTypeSwitcherModule
          chartName={ADMINS_CHARTS[tableName || '']}
          chartDataKeyName={tableName || ''}
          tooltipsVariant={(tableName || '') as keyof IHelp}
          selectedFilterName={selectedAllOutCallsFilterName}
          selectedFilialAccId={selectedFilialAccId}
          chartFilterNames={adminsAllOutCallsFilterNames}
          callback={selectedAllOutCallsFilterNameHandler}
        />
      ) : null} */}
    </div>
  );
};
