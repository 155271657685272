import React from 'react';
import { BarChart } from '@components/barChart';
import { TDashboardData } from '@api/dashboard/types';
import { StackedBarChart } from '@components/stackedBarChart';
import { getOneLineChartDataset, getStackedBarChartDataset } from '@helpers/index';
import {
  ADMINS_CHARTS,
  adminsAllOutCallsFilterNames,
  chartColors,
  DASHBOARD_ADMINS_KEYS,
} from '@const/dashboard';

type TDashboardAdminsChartsProps = {
  /**
   * Название графика
   * @type {DASHBOARD_ADMINS_KEYS}
   */
  chartName: DASHBOARD_ADMINS_KEYS;
  /**
   * Данные графиков
   * @type {TDashboardData}
   */
  data: TDashboardData;
  /**
   * Название активного фильтра
   * @type {string}
   */
  selectedAllOutCallsFilterName: string;
  selectedAllInCallsFilterName: string;
};
export const DashboardAdminsCharts = ({
  data,
  chartName,
  selectedAllOutCallsFilterName,
  selectedAllInCallsFilterName,
}: TDashboardAdminsChartsProps) => {
  if (!data || !data.admins || !data.admins[chartName]) return null;

  const isDoughnutChart =
    chartName === DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME ||
    chartName === DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS;

  if (isDoughnutChart) {
    return (
      <StackedBarChart
        data={getStackedBarChartDataset({
          chartData: data.admins[chartName],
          label:
            chartName === DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS
              ? adminsAllOutCallsFilterNames[selectedAllOutCallsFilterName]
              : ADMINS_CHARTS[chartName],
        })}
        chartTitle={ADMINS_CHARTS[chartName]}
      />
    );
  }

  return (
    <BarChart
      isTimeChart
      data={getOneLineChartDataset({
        chartData: data.admins[chartName],
        key: selectedAllInCallsFilterName,
        chartLabel: ADMINS_CHARTS[chartName],
        borderColor: chartColors['green-30'],
        backgroundColor: chartColors['green-30'],
      })}
      chartTitle={ADMINS_CHARTS[chartName]}
    />
  );
};
