import React from 'react';
import { Button } from '@uikit/Button';

interface ILinkButtonLeftIconProps {
  /**
   * URL в функцию для перехода при клике на кнопку
   * @param {string}
   */
  link: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const LinkButtonLeftIcon = ({ link, className = '' }: ILinkButtonLeftIconProps) => {
  const pageOpenHandler = () => {
    window.open(link, '_blank');
  };
  return (
    <Button
      dense
      type='default'
      view='smooth'
      color='default'
      text='Запись'
      icon={false}
      rightIcon='link'
      onClick={pageOpenHandler}
      className={className}
    />
  );
};
