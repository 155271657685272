import React, { memo, useEffect } from 'react';
//
import { getEventData } from '@helpers/index';
import { NoAccess } from '@components/noAccess';
import { TasksTemplate } from '@templates/tasks';
import { getTasksList } from '@redux/Task/thunks';
import { TPageView } from '@helpers/myTracker/type';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getTaskPageData } from '@redux/selectors';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';

interface ITasksListPage {
  /**
   * Параметр запроса задач, если true запрашиваются регулярные задачи
   * @param {boolean}
   */
  isRt: boolean;
  /**
   * Параметр запроса задач, если true запрашиваются задачи по событию
   * @param {boolean}
   */
  isEt: boolean;
  /**
   * Параметр запроса задач, если true запрашиваются клиентские задачи
   * @param {boolean}
   */
  isCt: boolean;
}

const TasksListPage = memo(({ isRt, isEt, isCt }: ITasksListPage) => {
  const dispatch = useAppDispatch();
  const {
    isError,
    isLoading,
    isRetries,
    isDeleting,
    isRefresh,
    sortedTasksList,
    isAccessDenied,
    isDeletingError,
    isDeletingSuccess,
    errorToastMessage,
    successToastMessage,
    isTaskSwitchingError,
    taskInfoData: data,
  } = useAppSelector(getTaskPageData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    const pageName = isRt
      ? PAGE_NAMES.TASKS_REGULAR
      : isEt
      ? PAGE_NAMES.TASKS_EVENT
      : isCt
      ? PAGE_NAMES.TASKS_CLIENTS
      : PAGE_NAMES.TASKS_ALL;

    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: pageName },
      }),
    );
  }, [isRt, isEt, isCt]);

  // Запрашивает список филиалов повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getTasksList({ rt: isRt, et: isEt, ct: isCt })), 100);
    }
  }, [dispatch, isRetries, isRt, isEt, isCt]);

  // запрашивает список шаблонов при загрузке страницы
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getTasksList({ rt: isRt, et: isEt, ct: isCt }));
    }
  }, [dispatch, isRt, isEt, isCt, selectedFilial?.accId]);

  // запрашивает список шаблонов при удалении шаблона из списка
  useEffect(() => {
    if (isRefresh) {
      dispatch(getTasksList({ rt: isRt, et: isEt, ct: isCt }));
    }
  }, [dispatch, isRt, isEt, isCt, isRefresh]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <TasksTemplate
          data={data}
          isError={isError}
          isLoading={isLoading}
          isFetching={isRefresh}
          isDeleting={isDeleting}
          sortedTasksList={sortedTasksList}
          isDeletingError={isDeletingError}
          errorToastMessage={errorToastMessage}
          /* TODO: Разобраться с доменом */
          domain=''
          isDeletingSuccess={isDeletingSuccess}
          successToastMessage={successToastMessage}
          isTaskSwitchingError={isTaskSwitchingError}
          selectedFilialAccId={selectedFilial?.accId || ''}
        />
      ) : null}
    </>
  );
});

export default TasksListPage;
