import { reportEmptyData } from '@data/index';
import { TReportInitialState } from '@redux/Report/zod';

export const initialState: TReportInitialState = {
  retries: 1,
  isError: false,
  isSaving: false,
  isRefresh: false,
  selectionEnd: 0,
  isLoading: false,
  isRetries: false,
  isSuccess: false,
  selectionStart: 0,
  isSavingError: false,
  isAccessDenied: false,
  isSavingSuccess: false,
  isDeleting: false,
  isDeletingError: false,
  isDeletingSuccess: false,
  isReportSwitching: false,
  isReportSwitchingSuccess: false,
  isReportSwitchingError: false,
  telegramRecipientsNewValue: '',
  whatsappRecipientsNewValue: '',
  reportIdInProgress: '',
  errorToastMessage: '',
  successToastMessage: '',
  blurFromTextareaName: '',
  data: reportEmptyData,
  reportListData: [],
  sortedReportsData: [],
};
