import React from 'react';
//
import { Icon } from '@atoms/icon';

type TStatusProps = {
  /**
   * Значение title в img
   * @param {string}
   */
  title: string;
  /**
   * Статус индикатора
   * @param {boolean}
   */
  status: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const Status = ({ title, status, className = '' }: TStatusProps) => {
  const variant = status ? 'statusOn' : 'statusOff';

  return <Icon title={title} variant={variant} className={`h-5 w-5 ${className}`} />;
};
