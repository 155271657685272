import React from 'react';
import { NavLink } from 'react-router-dom';
//
import { Status } from '@atoms/colorStatus';

interface IFilialCardWithStatusItemProps {
  /**
   * Линк для NavLink
   * @param {string}
   */
  to: string;
  /**
   * Опциональный параметр дата/текст вставляется после статуса
   * @param {string}
   */
  date?: string;
  /**
   * Значение статуса
   * @param {boolean}
   */
  status: boolean;
  /**
   * Имя элемента карточки филиала
   * @param {string}
   */
  itemName: string;
  /**
   * Title для иконки статуса
   * @param {string}
   */
  statusTitle: string;
  /**
   * Callback для обработки onClick на NavLink
   * @param {() => void}
   */
  callback: () => void;
  /**
   * Текст для статуса true
   * @param {string}
   */
  statusTrueText: string;
  /**
   * Текст для статуса false
   * @param {string}
   */
  statusFalseText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialCardWithStatusItem = ({
  to,
  itemName,
  callback,
  status,
  statusTitle,
  statusTrueText,
  statusFalseText,
  date,
  className = '',
}: IFilialCardWithStatusItemProps) => (
  <div className={`flex items-center px-2 border-b border-quartz1 min-h-[4rem] ${className}`}>
    <span className='font-medium px-2 text-grayText text-small_text max-w-[7.125rem] w-full'>
      {itemName}
    </span>
    <NavLink to={to} className='text-blackText hover:text-blackText w-full' onClick={callback}>
      <div className='flex items-center'>
        <Status status={status} title={statusTitle} />
        <div className='flex flex-col ml-3'>
          <span className={`${date ? 'mb-2' : ''} font-medium`}>
            {status ? statusTrueText : statusFalseText}
          </span>
          {date ? <span className='font-medium'>{date}</span> : null}
        </div>
      </div>
    </NavLink>
  </div>
);
