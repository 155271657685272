import React from 'react';
//
import { Label } from '@atoms/label';
import { THelpKeys } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface IFormLabelProps {
  /**
   * Значение параметра htmlFor в label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр показывает или скрывает иконку с всплывающими подсказками
   * @param {boolean}
   * @default
   */
  showInfo?: boolean;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр добавляет к тексту label знак *
   * @param {boolean}
   * @default
   */
  isRequired?: boolean;
  /**
   * Опциональный параметр вариант текста в label, берется по ключу из словаря
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FormLabel = ({
  htmlFor,
  showInfo = true,
  className = '',
  textColor = '',
  isRequired,
  labelTextVariant,
}: IFormLabelProps) => (
  <div className={`flex items-center ${className}`}>
    <Label htmlFor={htmlFor} variant={labelTextVariant} className={`mr-[0.3125rem] ${textColor}`} />
    {isRequired ? <span className='text-statusRed text-[1rem]'>*</span> : null}
    {showInfo ? (
      <IconWithTooltips
        tooltips={labelTextVariant}
        className={isRequired ? 'ml-[0.3125rem]' : ''}
      />
    ) : null}
  </div>
);
