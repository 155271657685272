import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/BlackList/state';
import {
  reducers,
  getBlackListReducer,
  addNumberToBlacklistReducer,
  deleteNumberFromBlacklistReducer,
} from '@redux/BlackList/reducers';
import {
  getBlackList,
  addNumberToBlacklist,
  deleteNumberFromBlacklist,
} from '@redux/BlackList/thunk';

export const blackListSlice = createSlice({
  name: 'blackList',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getBlackList.pending, getBlackListReducer.pending);
    builder.addCase(getBlackList.fulfilled, getBlackListReducer.fulfilled);
    builder.addCase(getBlackList.rejected, getBlackListReducer.rejected);
    builder.addCase(addNumberToBlacklist.pending, addNumberToBlacklistReducer.pending);
    builder.addCase(addNumberToBlacklist.fulfilled, addNumberToBlacklistReducer.fulfilled);
    builder.addCase(addNumberToBlacklist.rejected, addNumberToBlacklistReducer.rejected);
    builder.addCase(deleteNumberFromBlacklist.pending, deleteNumberFromBlacklistReducer.pending);
    builder.addCase(
      deleteNumberFromBlacklist.fulfilled,
      deleteNumberFromBlacklistReducer.fulfilled,
    );
    builder.addCase(deleteNumberFromBlacklist.rejected, deleteNumberFromBlacklistReducer.rejected);
  },
});

export const {
  setNewNumber,
  setSearchString,
  setFilteredList,
  setFullBlackList,
  setNumberToDelete,
  hideSuccessMessages,
} = blackListSlice.actions;
export const blackListReducer = blackListSlice.reducer;
