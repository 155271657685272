import React from 'react';
import { NavLink } from 'react-router-dom';

interface IFilialCardItemProps {
  /**
   * Линк для NavLink
   * @param {string}
   */
  to: string;
  /**
   * Имя элемента карточки филиала
   * @param {string}
   */
  itemName: string;
  /**
   * Значение элемента карточки филиала
   * @param {string}
   */
  itemText: string;
  /**
   * Callback для обработки onClick на NavLink
   * @param {() => void}
   */
  callback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialCardItem = ({
  to,
  itemName,
  itemText,
  callback,
  className = '',
}: IFilialCardItemProps) => (
  <div className={`flex items-center px-2 border-b border-quartz1 min-h-[4rem] ${className}`}>
    <span className='font-medium px-2 text-grayText text-small_text max-w-[7.125rem] w-full'>
      {itemName}
    </span>
    <NavLink
      to={to}
      onClick={callback}
      className='flex text-blackText hover:text-blackText leading-[4rem] w-full'>
      {itemText}
    </NavLink>
  </div>
);
