import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'moment/locale/ru';
import MomentLocaleUtils from 'react-day-picker/moment';
//
import { Icon } from '@atoms/icon';
import { Toaster } from '@uikit/Toast';
import { createPortal } from 'react-dom';
import { ONE_WEEK_IN_MS } from '@const/dashboard';
import { DateRangePicker } from '@uikit/DateTime/DateRangePicker';
import { getCustomShortcuts, getDateString } from '@helpers/index';

interface IDateRangePickerProps {
  /**
   * Дата начала периода
   * @param {Date}
   */
  startDate: Date;
  /**
   * Дата окончания периода
   * @param {Date}
   */
  endDate: Date;
  /**
   * Callback для установки диапазона дат в store
   * @param {({ startDate, endDate }: { startDate: string, endDate: string  }) => void}
   */
  setSendingDate: ({ startDate, endDate }: { startDate: Date; endDate: Date }) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Опциональный параметр текста лэйбла
   * @param {string}
   * @default "''"
   */
  labelText?: string;
  /**
   * Опциональный параметр строка классов лэйбла
   * @param {string}
   * @default "''"
   */
  labelClass?: string;
}

export const DateRangeDashboardPicker = ({
  endDate,
  startDate,
  setSendingDate,
  className = '',
  labelText = '',
  labelClass = '',
}: IDateRangePickerProps) => {
  const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false);
  const [dates, setDates] = useState<Date[]>([startDate, endDate]);
  const [showError, setShowError] = useState<boolean>(false);

  const showDatePickerHandler = () => {
    setIsShowDatePicker(prevState => !prevState);
  };

  const sendDatesHandler = () => {
    if (dates[1].getTime() - dates[0].getTime() > ONE_WEEK_IN_MS) {
      setSendingDate({ startDate: dates[0], endDate: dates[1] });
      showDatePickerHandler();
    } else {
      setShowError(true);
    }
  };

  const setDateHandler = ([start, end]: Date[]) => {
    setDates([start, end]);
    setShowError(false);
  };

  // Объект настроек errorToastMessage
  const errorToast = useMemo(
    () => ({
      message: 'Не правильно \n указан диапазон дат, \n минимальный диапазон 7 дней',
      icon: <Icon variant='errorToast' className='ml-4' />,
      onDismiss: () => {
        setShowError(false);
      },
      timeout: 0,
    }),
    [],
  );

  // реф для сообщения об ошибке
  const errorToastRef = useRef(errorToast);

  // Эффект показывает сообщение об ошибке при сохранении настроек
  useEffect(() => {
    if (showError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorToastRef.current.show(errorToast);
    }
  }, [showError, errorToast]);

  const memoizedErrorToast = useMemo(
    () => (
      <Toaster
        maxToasts={1}
        view='smooth'
        color='danger'
        className='error'
        ref={errorToastRef}
        position='top-right'
        withoutClose={false}
      />
    ),
    [errorToastRef],
  );

  return (
    <div className={`relative sm:max-w-[20rem] w-full ${className}`}>
      {labelText ? (
        <span className={`text-grayText inline-block mb-2 ${labelClass}`}>{labelText}</span>
      ) : null}
      <div
        onClick={showDatePickerHandler}
        className='border border-dGray rounded flex items-center justify-between text-blackText text-body2 leading-6 tracking-[0.0275em] px-3 h-10 w-full'>
        {`${getDateString(startDate)} - ${getDateString(endDate)}`}
        <Icon variant='calendar' />
      </div>
      {isShowDatePicker ? (
        <DateRangePicker
          shortcuts={getCustomShortcuts()}
          locale='ru'
          view='raised'
          color='success'
          highlightCurrentDay
          onChange={setDateHandler}
          localeUtils={MomentLocaleUtils}
          className='!absolute top-20 z-[50]'
          value={[dates[0], dates[1]]}
        />
      ) : null}
      {isShowDatePicker
        ? createPortal(
            <div
              onClick={sendDatesHandler}
              className='absolute top-0 right-0 bottom-0 left-0 z-[1]'
            />,
            document.body,
          )
        : null}
      {memoizedErrorToast}
    </div>
  );
};
