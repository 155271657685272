import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
//
import { InfoIcon } from '@atoms/infoIcon';
import { Status } from '@atoms/colorStatus';
import { getEventData, log } from '@helpers/index';
import { TPageView } from '@helpers/myTracker/type';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';

type TSettingsTelephonyHeaderProps = {
  /**
   * Статус подключения телефонии
   * @param {boolean}
   */
  connectionStatus: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsTelephonyHeader = ({
  connectionStatus,
  className = '',
}: TSettingsTelephonyHeaderProps) => {
  return (
    <div className={`flex items-center justify-between ${className}`}>
      <div className='flex items-baseline'>
        <h2 className='font-medium text-black text-h3Mobile leading-8 tracking-[0.004em] mr-3'>
          Телефония
        </h2>
        <Link
          onClick={() => {
            postEvent<TPageView>(
              getEventData<TPageView>({
                eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
                eventProperties: { name_page: PAGE_NAMES.TELEPHONY_CONNECT_INSTRUCTION },
              }),
            );
          }}
          to='https://botsarmy.teamly.ru/space/8ae91d5a-2d96-4dd7-b6a9-9532a355d217/article/c3ba2262-535e-42a0-af56-03efcc5af217'
          target='_blank'
          className='text-captionSmall text-green1 mr-2 flex items-center'>
          <InfoIcon className='mr-1' onMouseOut={log} onMouseOver={log} />
          <span className='mr-2'>Инструкция по подключению телефонии</span>
          <Icon icon='share' className='text-green1' />
        </Link>
      </div>
      <div className='flex items-center'>
        <Status status={connectionStatus} className='mr-2' />
        <span className='font-medium leading-6 tracking-[0.022em]'>
          {connectionStatus ? 'Подключена' : 'Не подключена'}
        </span>
      </div>
    </div>
  );
};
