import { TRequestStatus } from '@api/types';
import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TAccountResponseData } from '@models/Accounts';

/**
 * Api страницы списка филиалов
 * @module accountsApi
 */

export const accountsApi = {
  /**
   * Метод выполняет запрос за списком филиалов
   * @method getAccounts
   * @return {Promise<AxiosResponse<TAccountResponseData> | TRequestStatus>>}
   */
  getAccounts() {
    return apiService.get<TAccountResponseData | TRequestStatus>(END_POINTS_URL.ACCOUNTS);
  },
};
