import React, { memo, useCallback, useMemo, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Drawer } from '@uikit/Drawer';
import { Callout } from '@uikit/Callout';
import { KeyText } from '@blocks/keyText';
import { keyButtons } from '@const/report';
import { KeyButton } from '@blocks/keyButton';
import { TClickHandler } from '@shared/types';
import { getSelectedKeyDict } from '@helpers/index';

interface IReportAdditionalInfoProps {
  /**
   * Флаг меняет стили компонента для корректного отображения на мобилке
   * @param {boolean}
   */
  forMobile: boolean;
  /**
   * Callback для оборачивания выделенного текста в спец символы
   * @param {TClickHandler}
   */
  wrapSelectedTextInTextarea: TClickHandler;
  /**
   * Callback для добавления ключа с описанием в textarea
   * @param {(keyName: string, groupName: string) => void}
   */
  setKeyInTextarea: (keyName: string, groupName: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportAdditionalInfo = memo(
  ({
    forMobile,
    setKeyInTextarea,
    wrapSelectedTextInTextarea,
    className = '',
  }: IReportAdditionalInfoProps) => {
    // Состояние компонента для мобильного вида
    const [isOpen, setIsOpen] = useState(() => false);

    // Имя выбранной группы ключей
    const [groupName, setGroupName] = useState(() => '');

    // Состояние компонента Drawer
    const [drawerOpen, setDrawerOpen] = useState(() => false);

    // Открывает/закрывает компонент
    const setIsOpenToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

    // Открывает/закрывает Drawer
    const drawerOpenToggle = useCallback(() => {
      setDrawerOpen(prevState => !prevState);
    }, []);

    // Вызывает callback для добавления ключа в textarea
    const setKeyHandler = useCallback(
      (keyName: string, group: string) => () => {
        setKeyInTextarea(keyName, group);
      },
      [setKeyInTextarea],
    );

    // Формирует строку стилей для скрытия блоков в мобильном виде
    const addStyle = useMemo(() => (forMobile ? 'hidden lg: flex' : 'flex'), [forMobile]);

    // Открывает Drawer и устанавливает название выбранной группы ключей
    const openDrawerWithKeys = useCallback(
      (group: string) => () => {
        setGroupName(group);
        setDrawerOpen(true);
      },
      [setGroupName, setDrawerOpen],
    );

    // Закрывает drawer
    const closeDrawer = useCallback(() => {
      setDrawerOpen(false);
    }, []);

    // Массив кнопок для отображения Drawer с соответствующим содержимым
    const buttonsToRender = useMemo(
      () =>
        Object.entries(keyButtons).map(item => (
          <div key={item[0]} className='mb-2 w-full'>
            <Button
              fill
              type='default'
              text={item[1]}
              color='success'
              view='outlined'
              onClick={openDrawerWithKeys(item[0])}
            />
          </div>
        )),
      [openDrawerWithKeys],
    );

    // Массив ключей-кнопок для отображения в компоненте Drawer
    const drawerKeyButtons = useMemo(
      () =>
        Object.entries(getSelectedKeyDict(groupName)).map(item => (
          <KeyButton
            key={item[0]}
            keyName={item[0]}
            keyDescription={item[1]}
            callback={setKeyHandler(item[0], groupName)}
            className='mb-2'
          />
        )),
      [groupName, setKeyHandler],
    );

    const memoizedDrawerCallout = useMemo(
      () => (
        <Callout type='def' view='smooth' color='primary' icon='info-sign'>
          <span className='font-normal text-blackText leading-6 tracking-[0.0275em]'>
            Вы можете нажать на показатель и он подставится в текст.
          </span>
        </Callout>
      ),
      [],
    );

    const memoizedDrawer = useMemo(
      () => (
        <Drawer
          position='right'
          isOpen={drawerOpen}
          hasBackdrop={false}
          onClose={drawerOpenToggle}
          className='!w-[90%] !max-w-[29rem]'>
          <div className='p-7 task-drawer'>
            <Icon
              variant='darkCross'
              onClick={closeDrawer}
              className='cursor-pointer h-[0.875rem] w-[0.875rem]'
            />
            <p className='text-blackText text-h3_body mt-5 mb-4'>{`Показатели группы ${keyButtons[groupName]}`}</p>
            {memoizedDrawerCallout}
            <div className='flex flex-col pl-1 pr-1 mt-4'>{drawerKeyButtons}</div>
          </div>
        </Drawer>
      ),
      [
        groupName,
        drawerOpen,
        closeDrawer,
        drawerKeyButtons,
        drawerOpenToggle,
        memoizedDrawerCallout,
      ],
    );

    return (
      <div className={className}>
        <div className='border border-[#D2D2D6] rounded-lg px-4 py-3'>
          <Icon variant='formatting' className='hidden mt-2 mb-5 xl:block h-10 w-10' />
          <h3
            className={`relative flex justify-between font-bold text-blackText text-[1.25rem] ${
              isOpen ? 'mb-2' : ''
            } xl:mb-2 translate-all  ease-out duration-300 w-full`}>
            Показатели
            <span
              onClick={setIsOpenToggle}
              className='absolute top-0 right-0 bottom-0 left-0 flex items-center justify-end xl:hidden'>
              <Icon
                variant='caretDown'
                className={`${
                  isOpen ? 'rotate-180' : ''
                } translate-all ease-out duration-300 h-[16px] w-[16px]`}
              />
            </span>
          </h3>
          <div
            className={`overflow-hidden max-h-0 ${
              isOpen ? 'max-h-[100vh]' : ''
            } xl:max-h-[100vh] translate-all  ease-out duration-300`}>
            <p className='mb-6'>
              Вы можете подставить в сообщение любую информацию — для этого выберите группу и
              вставьте в текст любой из показателей.
            </p>
            <div className='flex flex-col items-center justify-center px-px mb-6 w-full'>
              {buttonsToRender}
            </div>
            <p className='flex items-center mb-2'>Спецсимволы для форматирования текста:</p>
            <p className='flex flex-col mb-6'>
              <span className='addText'>
                <KeyText keyName='*текст*:' callBack={wrapSelectedTextInTextarea} />
                <span className='font-bold ml-1'>текст жирным</span>
              </span>
              {/* <span className='addText'>
                <KeyText keyName='_текст_:' callBack={wrapSelectedTextInTextarea} />
                <span className='font-italic ml-1'>текст курсивом</span>
              </span>
              <span className='addText'>
                <KeyText keyName='~текст~:' callBack={wrapSelectedTextInTextarea} />
                <span className='line-through ml-1'>зачеркнутый текст</span>
              </span>
              <span className='addText'>
                <KeyText keyName='«текст«:' callBack={wrapSelectedTextInTextarea} />
                <span className='font-mono ml-1'>моноширинный текст</span>
              </span> */}
            </p>
            <div className={addStyle}>
              <Callout type='def' view='smooth' color='primary' icon='info-sign'>
                Чтобы добавить форматирование, выделите текст и нажмите символ форматирования,
                например *текст*
              </Callout>
            </div>
          </div>
        </div>
        {memoizedDrawer}
      </div>
    );
  },
);
