import { createSlice } from '@reduxjs/toolkit';
import { AnalyticsInitialState } from '@redux/Analytics/state';
import { getAdminsAnalyticsThunk } from '@redux/Analytics/thunk';
import { getAdminsAnalyticsReducer, reducers } from '@redux/Analytics/reducers';

export const AnalyticsSlice = createSlice({
  name: 'analytics',
  initialState: AnalyticsInitialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getAdminsAnalyticsThunk.pending, getAdminsAnalyticsReducer.pending);
    builder.addCase(getAdminsAnalyticsThunk.fulfilled, getAdminsAnalyticsReducer.fulfilled);
    builder.addCase(getAdminsAnalyticsThunk.rejected, getAdminsAnalyticsReducer.rejected);
  },
});

export const analyticsReducer = AnalyticsSlice.reducer;
export const {
  setAnalyticsStart,
  setAnalyticsEnd,
  setSelectedTaskType,
  setSelectedTaskId,
  setSelectedAdminId,
  setSelectedCharts,
  setAnalyticsDates,
} = AnalyticsSlice.actions;
