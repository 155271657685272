import React from 'react';

import { WhenTime } from '../whenTime';

interface IReportSendTimeProps {
  /**
   * Время передается в формате HH:MM
   * @param {string}
   */
  sendingTime: string;
  /**
   * Опциональный параметр включает округление минут до 00
   * @param {boolean}
   */
  isSetOnlyHour?: boolean;
  /**
   * Callback для установки времени
   * @param {(value: string) => void}
   */
  setSendingTime: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportSendTime = ({
  sendingTime,
  setSendingTime,
  isSetOnlyHour,
  className = '',
}: IReportSendTimeProps) => (
  <div className={`flex items-center ${className}`}>
    <span className='text-black mr-2'>В</span>
    <WhenTime
      whenTime={sendingTime}
      isSetOnlyHour={isSetOnlyHour}
      onChangeHandler={setSendingTime}
    />
  </div>
);
