import { TSettingsNotificationResponseData } from '@models/Settings';

export const defaultNotificationSettings: TSettingsNotificationResponseData = {
  owner: {
    task_completed: true,
    task_created: true,
    task_overdue: true,
    task_postpone: true,
  },
  performer: {
    task_completed: true,
    task_created: true,
    task_overdue: true,
    task_postpone: true,
  },
};
