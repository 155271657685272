import React from 'react';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { TOnclickHandler } from '@shared/types';

type TDeleteModalProps = {
  /**
   * Строка с текстом в диалог
   * @param {string}
   */
  text: string;
  /**
   * Строка в заголовок модалки
   * @param {string}
   */
  title: string;
  /**
   * Флаг состояния модального окна открыто/закрыто
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Флаг состояния запроса на удаление, если значение true кнопки в модалке не активны
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Callback для закрытия модального окна
   * @param {TOnclickHandler}
   */
  modalToggleCallback: TOnclickHandler;
  /**
   * Callback для удаления
   * @param {TOnclickHandler}
   */
  deleteTaskCallback: TOnclickHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const DeleteModal = ({
  isOpen,
  title,
  text,
  isDeleting,
  modalToggleCallback,
  deleteTaskCallback,
  className = '',
}: TDeleteModalProps) => {
  return (
    <div className={className}>
      <Dialog
        type='default'
        view='raised'
        color='default'
        title={title}
        text={<span>{text}</span>}
        className='whitespace-pre-line'
        backdropOpacity={40}
        leftButton={
          <Button
            dense
            type='action'
            text='Отмена'
            view='outlined'
            color='default'
            disabled={isDeleting}
            onClick={modalToggleCallback}
            className='!min-w-[6rem]'
          />
        }
        rightButton={
          <Button
            dense
            type='action'
            view='filled'
            color='danger'
            text='Удалить'
            loading={isDeleting}
            disabled={isDeleting}
            onClick={deleteTaskCallback}
            className='!min-w-[6rem]'
          />
        }
        isOpen={isOpen}
        onClose={isDeleting ? modalToggleCallback : undefined}
      />
    </div>
  );
};
