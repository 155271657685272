import React from 'react';
//
import { numWord } from '@helpers/index';
import { useAppSelector } from '@hooks/index';
import { getAppData } from '@redux/selectors';
import roundedLine from '@img/settings/roundedLine.svg';
import { churnByMasterNumWords, settingPageText } from '@const/settings';

type TPeriodOfReturnStatisticsExampleProps = {
  /**
   * Текущий период
   * @param {number}
   */
  endRate: number;
  /**
   * Прошлый период
   * @param {number}
   */
  startRate: number;
  /**
   * Флаг показывает подписи для отображения блока в drawer
   * @param {boolean}
   */
  isBlockInDrawer?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const PeriodOfReturnStatisticsExample = ({
  endRate,
  startRate,
  isBlockInDrawer,
  className = '',
}: TPeriodOfReturnStatisticsExampleProps) => {
  const { appSelectedLanguage } = useAppSelector(getAppData);

  const blockStyle =
    'bg-aquaSpring rounded-lg flex flex-col items-center items-center justify-center h-[4rem]';

  const textStyle =
    'text-stormGray font-medium leading-5 tracking-[0.018em] text-[0.5rem] m:text-small_text sm:text-[1rem]';

  const allPeriodMonthText = `${startRate + endRate} ${numWord(
    startRate + endRate,
    churnByMasterNumWords[appSelectedLanguage] || 'text not found',
  )}`;

  const previousMonthsText = `(${startRate} ${numWord(
    startRate,
    churnByMasterNumWords[appSelectedLanguage] || 'text not found',
  )})`;

  const currentMonthsText = `(${endRate} ${numWord(
    endRate,
    churnByMasterNumWords[appSelectedLanguage] || 'text not found',
  )})`;

  return (
    <div className={className}>
      <div className='relative -bottom-2 flex flex-col items-center justify-end pb-4 border-l border-r border-black h-10 max-w-[81%] w-full'>
        <span className='flex items-center justify-center bg-stormGray text-white text-[0.625rem] py-0.5 px-2 mb-2 h-[1.125rem] w-fit'>
          {allPeriodMonthText}
        </span>
        <span className='block border border-stormGray bg-stormGray h-px w-full line arrow-right arrow-left' />
      </div>
      <div className='flex'>
        <div className={`${blockStyle} w-[43.816%] mr-2`}>
          <span className={textStyle}>
            {settingPageText[appSelectedLanguage].startRatePlaceholder || 'text not found'}
          </span>
          <span className={textStyle}>{previousMonthsText}</span>
        </div>
        <div className={`${blockStyle} w-[35.752%] mr-2`}>
          <span className={textStyle}>
            {settingPageText[appSelectedLanguage].endRatePlaceholder || 'text not found'}
          </span>
          <span className={textStyle}>{currentMonthsText}</span>
        </div>
        <div className={`${blockStyle} w-[17.203%]`}>
          <span className={textStyle}>{settingPageText[appSelectedLanguage].today}</span>
        </div>
      </div>
      <div className='flex mb-2'>
        <div className='flex flex-col w-[43.816%] items-center justify-center mr-2'>
          <p className='text-small_text text-center mt-0.5'>🤩‍🤩🤩👨🏻👩🏻👩🏼‍🦰👩🏻‍🦳🧑🏼‍🦰👨🏻🧒🏼👱🏻‍♀️👱🏻‍♂️</p>
          {isBlockInDrawer ? (
            <p className='text-small_text text-center mt-0.5'>12 клиентов</p>
          ) : null}
        </div>
        <div className='flex flex-col w-[35.752%] items-center justify-center mr-2'>
          <p className='text-small_text text-center mt-0.5'>🤩‍🤩🤩</p>
          {isBlockInDrawer ? (
            <p className='text-small_text text-center mt-0.5'>3 вернулись</p>
          ) : null}
        </div>
        <div className='flex items-end w-[17.203%] text-[0.625rem]'>
          {isBlockInDrawer ? `(3/12)*100 = 25%` : ''}
        </div>
      </div>
      <div className='flex'>
        <div className='relative flex w-[79.568%]'>
          {!isBlockInDrawer ? (
            <span className='font-medium text-[0.625rem] text-stormGray text-center pt-2 pr-[3rem] w-full'>
              {settingPageText[appSelectedLanguage].returned}
            </span>
          ) : null}
          <img
            alt='line'
            src={roundedLine}
            className={`absolute -bottom-4 ${
              isBlockInDrawer ? 'sm:-bottom-[1.5rem]' : 'sm:-bottom-[0.5rem]'
            } ml-8 max-w-[63%] m:max-w-[70%] w-full`}
          />
        </div>
        <div className='flex w-[17.203%]' />
      </div>
    </div>
  );
};
