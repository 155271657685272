import React, { memo } from 'react';
//
import { Button } from '@uikit/Button';

interface IBbAdsSideNav {
  /**
   * URL картинки которая отображается в верхней части блока
   * @param {string}
   */
  imgURL: string;
  /**
   * Callback для открытия страницы при клике на кнопку
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ABNewAdsSideNav = memo(
  ({ changePageOnClick, imgURL, className = '' }: IBbAdsSideNav) => (
    <div
      className={`border border-lightGray1 flex flex justify-start flex-col rounded-lg max-w-[17.5rem] ${className}`}>
      <div className='bg-lightGray flex items-center justify-center min-w-[4rem]'>
        <img src={imgURL} alt='Commercial' className='block pointer-events-none rounded-t-lg' />
      </div>
      <div className='p-4'>
        <Button
          dense
          type='action'
          view='filled'
          color='success'
          text='Подробнее'
          className='!min-w-[8.875rem] transition-all duration-300 ease-out'
          onClick={changePageOnClick}
        />
      </div>
    </div>
  ),
);
