import React, { memo, useCallback, useEffect, useMemo } from 'react';
//
import { Button } from '@uikit/Button';
import { Spinner } from '@uikit/Spinner';
import { EVENT_KEY_NAMES } from '@const/common';
import { NoAccess } from '@components/noAccess';
import { AppDrawer } from '@components/appDrawer';
import { ErrorMessage } from '@blocks/errorMessage';
import { TPageView } from '@helpers/myTracker/type';
import { BlackListInput } from '@atoms/blackListInput';
import { BlackListItem } from '@components/blackListItem';
import { ToastsMessages } from '@components/toastsMessages';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { BlackListSearchInput } from '@components/blackListSearchInput';
import { getEventData, numWord, validatePhoneNumber } from '@helpers/index';
import { getAccountsData, getAppData, getBlackListData } from '@redux/selectors';
import {
  getBlackList,
  addNumberToBlacklist,
  deleteNumberFromBlacklist,
} from '@redux/BlackList/thunk';
import {
  setNewNumber,
  setSearchString,
  setNumberToDelete,
  hideSuccessMessages,
} from '@redux/BlackList/slice';

type TBlackListProps = {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const BlackListTemplate = memo(({ className = '' }: TBlackListProps) => {
  const dispatch = useAppDispatch();
  const { showAppDrawer } = useAppSelector(getAppData);
  const {
    isError,
    isLoading,
    isRefresh,
    isRetries,
    isAddSuccess,
    isAccessDenied,
    isAddingNumber,
    isDeleteSuccess,
    errorToastMessage,
    isAddingNumberError,
    isDeleteNumberError,
    successToastMessage,
  } = useAppSelector(getBlackListData);

  // получаем объект selectedFilial
  const { selectedFilial } = useAppSelector(getAccountsData);

  useEffect(() => {
    postEvent<TPageView>(
      getEventData<TPageView>({
        eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
        eventProperties: { name_page: PAGE_NAMES.SETTINGS_BLACKLIST },
      }),
    );
  }, []);

  // запрашивает список номеров при добавлении/удалении номера из списка
  useEffect(() => {
    if (isRefresh) {
      dispatch(getBlackList());
    }
  }, [dispatch, isRefresh]);

  // Запрашивает список номеров повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getBlackList()), 100);
    }
  }, [dispatch, isRetries]);

  // Запрашивает список номеров при загрузке страницы и смене филиала
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getBlackList());
    }
  }, [dispatch, selectedFilial?.accId]);

  // получаем данные из store/blacklist
  const { newNumber, searchString, filteredState } = useAppSelector(getBlackListData);

  // Проверяет введен ли номер и вызывает callBack для добавления номера в базу.
  const addNumber = useCallback(() => {
    dispatch(addNumberToBlacklist({ number: newNumber }));
  }, [dispatch, newNumber]);

  // Вызывает callBack для удаления номера из базы.
  const deleteNumber = useCallback(
    (number: string) => () => {
      dispatch(setNumberToDelete({ number }));
      dispatch(deleteNumberFromBlacklist(number));
    },
    [dispatch],
  );

  // Обработчик события Change, добавляет введенные данные в state.
  const inputHandler: TOnChangeHandler = useCallback(
    event => {
      dispatch(setNewNumber({ value: event.target.value }));
    },
    [dispatch],
  );

  // Очищает введенное значение
  const clearSearchString = useCallback(() => {
    dispatch(setSearchString({ value: '' }));
  }, [dispatch]);

  // Обработчик события Change, добавляет данные в state для выполнения фильтрации по номеру.
  const setSearch: TOnChangeHandler = useCallback(
    event => {
      const { value } = event.currentTarget;

      dispatch(setSearchString({ value }));
    },
    [dispatch],
  );

  // Обработчик события keyDown, добавляет номер при нажатии на enter
  const onKeyDownAddNumberHandler: TKeyboardClickEvent = useCallback(
    event => {
      if (event.key === EVENT_KEY_NAMES.ENTER && newNumber) {
        dispatch(addNumberToBlacklist({ number: newNumber }));
      }
    },
    [dispatch, newNumber],
  );

  // Подготавливает список чипсов с телефонами для рендеринга
  const blacklist = useMemo(
    () =>
      filteredState.map(item => {
        return (
          <div key={item.number} className='mr-2 mb-2'>
            <BlackListItem
              number={item.number}
              isDeleteError={isDeleteNumberError}
              deleteNumber={deleteNumber(item.number)}
            />
          </div>
        );
      }),
    [isDeleteNumberError, deleteNumber, filteredState],
  );

  // Скрывает всплывашку с сообщением для предотвращения повторного показа при входе на страницу
  const hideSuccessToastHandler = () => {
    dispatch(hideSuccessMessages());
  };

  // Проверяет введенный номер на валидность
  const isNumberValid = validatePhoneNumber(newNumber).length > 10;

  const onCloseDrawerHandler = () => {
    dispatch(setShowAppDrawer(false));
    dispatch(setAppDrawerData({ title: '', description: '' }));
  };

  return (
    <div className={`flex flex-col relative ${className}`}>
      {isError ? <ErrorMessage /> : null}
      {isAccessDenied ? <NoAccess /> : null}
      {!isError && !isLoading && !isAccessDenied ? (
        <>
          <div className='flex flex-col'>
            <div className='flex flex-col sm:flex-row justify-between mb-2 sm:mb-4'>
              <div className='mb-2 sm:mb-0'>
                <div className='flex items-center'>
                  <h2 className='font-inter text-[1.25rem] font-bold text-blackHeader tracking-[0.0075em] mr-2'>
                    Общий список
                  </h2>
                  <IconWithTooltips tooltips='blackList' className='mb-1' />
                </div>
                <span className='text-gray3 text-small_text tracking-[0.033em]'>
                  {filteredState.length
                    ? `${filteredState.length} ${numWord(filteredState.length, [
                        'номер',
                        'номера',
                        'номеров',
                      ])}`
                    : 'нет номеров'}
                </span>
              </div>
              <div className='flex flex-wrap items-center font-medium'>
                <BlackListSearchInput
                  value={searchString}
                  onChangeHandler={setSearch}
                  placeholder='Поиск по номеру'
                  clearSearchString={clearSearchString}
                />
              </div>
            </div>
            <div className='flex flex-wrap mb-2 w-full'>
              <div className='flex flex-col w-full'>
                <span className='font-inter text-black tracking-[0.0275em] leading-6 mb-2'>
                  Для добавления введите номера в международном формате, без знака +
                </span>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='flex flex-col mr-4 w-full'>
                    <BlackListInput
                      value={newNumber}
                      onChangeHandler={inputHandler}
                      placeholder='Номер телефона'
                      onKeyDownHandler={onKeyDownAddNumberHandler}
                      className='w-full'
                    />
                    <span className='font-inter text-[0.75rem] text-stormGray tracking-[0.033em] mt-0.5'>
                      Пример: 79123456789
                    </span>
                  </div>
                  <Button
                    dense
                    icon='plus'
                    fill={false}
                    type='action'
                    view='outlined'
                    color='default'
                    text='Добавить'
                    onClick={addNumber}
                    loading={isAddingNumber}
                    disabled={isAddingNumber || !isNumberValid}
                    className='w-full mt-4 sm:mt-0 sm:!w-[10rem]'
                  />
                </div>
              </div>
            </div>
          </div>
          <ul className='flex flex-wrap items-center relative mt-4'>
            {isRefresh ? (
              <Spinner
                size={40}
                color='default'
                className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mr-2 z-50'
              />
            ) : null}
            {!filteredState.length ? <p className='m-0'>Нет номеров.</p> : blacklist}
          </ul>
          <ToastsMessages
            errorMessage={errorToastMessage}
            successMessage={successToastMessage}
            isShowErrorToast={isAddingNumberError || isDeleteNumberError}
            isShowSuccessToast={isAddSuccess || isDeleteSuccess}
            clearSuccessStatusCallback={hideSuccessToastHandler}
          />
          <AppDrawer showAppDrawer={showAppDrawer} onCloseHandler={onCloseDrawerHandler} />
        </>
      ) : null}
    </div>
  );
});
