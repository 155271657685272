import React from 'react';
//
import { Icon } from '@atoms/icon';
import { useAppDispatch } from '@hooks/index';
import { getPinData } from '@redux/Auth/thunk';
import { PinLogin } from '@components/pinLogin';
import logo from '@img/loginPage/adminBotSmall.jpg';
import { TTelegramAuthResponseData } from '@models/Auth';
import { TelegramLoginWidget } from '@blocks/telegramLoginWidget';
import { setInputError, setGetPinSuccess } from '@redux/Auth/slice';

interface ILoginProps {
  /**
   * Значение пинкода для авторизации
   * @param {string}
   */
  pinValue: string;
  /**
   * Флаг загрузки
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Ошибка интпута, нет значения
   * @param {boolean}
   */
  isInputError: boolean;
  /**
   * Флаг ошибки проверки телеграм id
   * @param {boolean}
   */
  isGetPinError: boolean;
  /**
   * Значение телеграм id для авторизации
   * @param {string}
   */
  telegramIdValue: string;
  /**
   * Флаг ошибка проверки пина
   * @param {boolean}
   */
  isChekPinError: boolean;
  /**
   * Флаг успешной отправки пин кода в телеграм
   * @param {boolean}
   */
  isGetPinSuccess: boolean;
  /**
   * Callback который вызывает виджет telegram после успешной авторизации
   * в callback параметром передаются данные пользователя
   * @param {(authData: TTelegramAuthResponseData) => void}
   */
  loginUser: (authData: TTelegramAuthResponseData) => void;
  /**
   * Флаг ошибки при запросе на авторизацию в ЛК Админ бота
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const LoginTemplate = ({
  isError,
  pinValue,
  loginUser,
  isFetching,
  isInputError,
  isGetPinError,
  isChekPinError,
  isGetPinSuccess,
  telegramIdValue,
  className = '',
}: ILoginProps) => {
  const dispatch = useAppDispatch();

  // Создаем callback для передачи в компонент
  const auth = (data: TTelegramAuthResponseData) => {
    loginUser(data);
  };

  const getPin = () => {
    if (telegramIdValue) {
      dispatch(getPinData(telegramIdValue));
    } else {
      dispatch(setInputError(true));
    }
  };

  const backButtonHandler = () => {
    dispatch(setGetPinSuccess(false));
  };

  return (
    <div className={`flex flex-col items-center justify-center h-screen ${className}`}>
      <img src={logo} alt='Логотип' height='263' width='180' />
      <p
        className={`font-inter font-bold leading-[1.875rem] mt-4 text-black text-h6Mobile tracking-[0.0075em] ${
          isGetPinSuccess ? 'mb-0' : 'mb-[3.25rem]'
        }`}>
        Авторизуйтесь через Телеграм
      </p>
      {isGetPinSuccess ? (
        <button
          onClick={backButtonHandler}
          className='bg-none border-none cursor-pointer flex items-center leading-5 mt-[0.375rem] mb-6 font-medium text-green1 text-tooltips tracking-[0.018em]'>
          <Icon variant='greenChevronLeft' className='mr-[0.375rem]' />
          Назад
        </button>
      ) : null}
      <PinLogin
        getPin={getPin}
        pinValue={pinValue}
        isFetching={isFetching}
        isInputError={isInputError}
        isGetPinError={isGetPinError}
        isChekPinError={isChekPinError}
        telegramIdValue={telegramIdValue}
        isGetPinSuccess={isGetPinSuccess}
      />
      <TelegramLoginWidget
        botName='salon_adminbot'
        buttonSize='medium'
        usePic
        widgetVersion={21}
        dataOnAuth={auth}
        className='mt-10'
      />
      {isError ? (
        <p className='mt-4'>Ошибка, перезагрузите страницу и попробуйте еще раз.</p>
      ) : null}
    </div>
  );
};
