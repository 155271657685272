import React from 'react';
//
import { H1 } from '@atoms/h1';
import { PATH_NAMES } from '@const/httpConst';
import { TOnChangeHandler } from '@shared/types';
import { useSearchParams } from 'react-router-dom';
import { getDashboardPageData } from '@redux/selectors';
import { TableWrapper } from '@components/tableWrapper';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DashboardPathLinks } from '@blocks/dashboardPathLinks';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import {
  setFinancesDates,
  setSelectedAllAmountFilterName,
  setSelectedEarnedByOutCallsFilterName,
} from '@redux/Dashboard/slice';
import {
  FINANCES_CHART_TITLES,
  FINANCES_CHARTS_NAMES,
  financesAllAmountFilterName,
  financesEarnedByOutCallsFilterName,
} from '@const/dashboard';

interface IFinanceTableProps {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const FinanceTable = ({ selectedFilialAccId, className = '' }: IFinanceTableProps) => {
  const [searchParams] = useSearchParams();
  const tableName = searchParams.get('table_name');
  const dispatch = useAppDispatch();
  const {
    data,
    financesEndDate,
    financesStartDate,
    allAmountSelectedFilterName,
    earnedByOutCallsSelectedFilterName,
  } = useAppSelector(getDashboardPageData);

  const setSelectedAllAmountFilterNameHandler = (filterName: string) => {
    dispatch(setSelectedAllAmountFilterName(filterName));
  };

  const setSelectedEarnedByOutCallsFilterNameHandler = (filterName: string) => {
    dispatch(setSelectedEarnedByOutCallsFilterName(filterName));
  };

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setFinancesDates({ startDate, endDate }));
  };

  const setSearchStringHandler: TOnChangeHandler = () => {
    // const { value } = event.currentTarget;
    // dispatch(setPhoneSearchString(value));
  };

  const startSearchHandler = () => {
    // console.log('startSearchHandler');
  };

  const clearSearchStringHandler = () => {
    // dispatch(setPhoneSearchString(''));
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardPathLinks
        className='mb-10'
        secondPageName='Салон'
        secondPageLink={PATH_NAMES.FINANCES}
        thirdPageName={FINANCES_CHART_TITLES[tableName || '']}
        selectedFilialAccId={selectedFilialAccId}
      />
      <H1 className='mb-10 tracking-[-0.01em]'>{FINANCES_CHART_TITLES[tableName || '']}</H1>
      <div className='flex flex-wrap items-center justify-between mb-10'>
        <DateRangeDashboardPicker
          startDate={financesStartDate}
          endDate={financesEndDate}
          setSendingDate={setDateRangeHandler}
        />
      </div>
      {[tableName].includes(FINANCES_CHARTS_NAMES.ALL_AMOUNT) ? (
        <TableWrapper
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
          isChartSetupModule
          data={data.finance}
          isChartTypeSwitcherModule
          chartDataKeyName={tableName || ''}
          selectedFilterName={allAmountSelectedFilterName}
          chartFilterNames={financesAllAmountFilterName}
          callback={setSelectedAllAmountFilterNameHandler}
        />
      ) : null}
      {[tableName].includes(FINANCES_CHARTS_NAMES.AMOUNT_SERVICE_RATE) ? (
        <TableWrapper
          data={data.finance}
          chartDataKeyName={tableName || ''}
          callback={() => ''}
          chartFilterNames={{}}
          selectedFilterName=''
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
        />
      ) : null}
      {[tableName].includes(FINANCES_CHARTS_NAMES.OP_RECORDS) ? (
        <TableWrapper
          data={data.finance}
          chartDataKeyName={tableName || ''}
          callback={() => ''}
          chartFilterNames={{}}
          selectedFilterName=''
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
        />
      ) : null}
      {[tableName].includes(FINANCES_CHARTS_NAMES.EARNED_BY_OUT_CALS) ? (
        <TableWrapper
          isChartSetupModule
          data={data.finance}
          isChartTypeSwitcherModule
          chartDataKeyName={tableName || ''}
          selectedFilterName={earnedByOutCallsSelectedFilterName}
          chartFilterNames={financesEarnedByOutCallsFilterName}
          callback={setSelectedEarnedByOutCallsFilterNameHandler}
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
        />
      ) : null}
    </div>
  );
};
