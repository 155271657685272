import React from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Callout } from '@uikit/Callout';
import { TOOLTIPS_TEXT } from '@data/dict';

interface IFirstTooltipProps {
  /**
   * Параметр настраивает вид Callout
   * @param {string}
   */
  view: string;
  /**
   * Параметр настраивает цвет Callout
   * @param {string}
   */
  color: string;
  /**
   * Текст в Callout
   * @param {string}
   */
  bodyText: string;
  /**
   * Опциональный параметр title в Callout
   * @param {string}
   */
  title?: string;
  /**
   * Callback срабатывает при нажатии на кнопку в Callout
   * @param {() => void}
   */
  callback?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FirstTooltip = ({
  view,
  color,
  bodyText,
  title = '',
  className = '',
  callback = () => '',
}: IFirstTooltipProps) => {
  return (
    <Callout className={`!px-[0.75rem] ${className}`} view={view} color={color} title={title}>
      <div className='flex items-start'>
        <Icon variant='iconTooltip' className='pt-[0.25rem] mr-2' />
        <div className='flex flex-col'>
          <div className='mb-4'>{bodyText}</div>
          <Button
            dense
            type='default'
            view='filled'
            color='default'
            text={TOOLTIPS_TEXT.firstTooltipButtonText}
            onClick={callback}
            className='w-[85px]'
          />
        </div>
      </div>
      <div className='absolute bottom-[-0.6rem] w-0 h-0 border-l-[10px] border-l-transparent border-t-[12px] border-t-[#4a4b57] border-r-[10px] border-r-transparent left-[50%]' />
    </Callout>
  );
};
