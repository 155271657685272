import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import {
  TAnalyticsResponseData,
  TGetAnalyticsAdminRequest,
  TSaveAnalyticsMetaRequest,
} from '@models/Analytics';

export const api = {
  admins: {
    get: (data: TGetAnalyticsAdminRequest) => {
      return apiService.get<TAnalyticsResponseData>(END_POINTS_URL.DASHBOARD, data);
    },
  },
  meta: {
    post: (data: TSaveAnalyticsMetaRequest) => {
      return apiService.post(END_POINTS_URL.DASHBOARD_SAVE, data);
    },
  },
};
