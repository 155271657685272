import { END_POINTS_URL } from '@const/apiConstants';
import {
  TReportCardData,
  TReportResponseData,
  TSwitchedReportData,
  TReportSaveRequestData,
  TReportOperationRequestData,
} from '@models/Report';

import { apiService } from '../ApiService';
import { TRequestStatus, TGetResponseWithData } from '../types';

/**
 * API страницы отчета статистики и списка отчетов
 * @module reportApi
 */

export const reportApi = {
  /**
   * Метод делает запрос за списком отчетов
   * @method getReportListData
   * @return {Promise<AxiosResponse<TGetResponseWithData<TReportCardData[]> | TRequestStatus, any>>}
   */
  getReportListData() {
    return apiService.get<TGetResponseWithData<TReportCardData[]> | TRequestStatus>(
      END_POINTS_URL.REPORT_LIST,
    );
  },
  /**
   * Метод делает запрос за отчетом по ID
   * @method getReportById
   * @param data {TReportOperationRequestData} данные отчета
   * @return {Promise<AxiosResponse<TRequestStatus | TReportResponseData, any>>}
   */
  getReportById(data: TReportOperationRequestData) {
    return apiService.get<TReportResponseData | TRequestStatus>(END_POINTS_URL.ONE_REPORT, {
      stats_id: data.id,
    });
  },
  /**
   * Метод сохраняет изменения в существующем отчете или создает новый
   * @method saveReport
   * @param data {TReportSaveRequestData} измененные или новые данные отчета
   * @return {Promise<AxiosResponse<TReportResponseData, any>>}
   */
  saveReport(data: TReportSaveRequestData) {
    return apiService.post<TReportResponseData>(END_POINTS_URL.SAVE_REPORT, data.data);
  },
  /**
   * Метод переключает активность отчета
   * @method switchReportById
   * @param data {TReportOperationRequestData}
   * @return {Promise<AxiosResponse<ISwitchedReportData, any>>}
   */
  switchReportById(data: TReportOperationRequestData) {
    return apiService.post<TSwitchedReportData>(
      END_POINTS_URL.SWITCH_REPORT_ACTIVITY,
      {},
      {
        params: {
          stats_id: data.id,
        },
      },
    );
  },
  /**
   * Метод удаляет отчет по ID
   * @method deleteReportById
   * @param data {TReportOperationRequestData}
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteReportById(data: TReportOperationRequestData) {
    return apiService.post<TRequestStatus>(
      END_POINTS_URL.DELETE_REPORT,
      {},
      {
        params: {
          stats_id: data.id,
        },
      },
    );
  },
};
