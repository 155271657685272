import React, { memo } from 'react';
//
import { Loader } from '@blocks/loader';
import { NoteCard } from '@components/noteCard';
import { COMMON_NOTE_FOLDER } from '@const/notes';
import { NotesSearch } from '@components/notesSearch';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { NoteListHeader } from '@components/noteListHeader';
import { TOnChangeHandler, TSetTagInSearchString } from '@shared/types';
import { TFolderItem, TNoteItem, TNoteListStatuses, TNoteTag } from '@redux/noteList/zod';

type TProps = {
  /**
   * Флаг загрузки данных
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Статус запросов на страницы
   * @param {TNoteListStatuses}
   */
  status: TNoteListStatuses;
  /**
   * Флаг режима удаления тегов
   * @param {boolean}
   */
  isTagDeleteMode: boolean;
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  tags: TNoteTag[];
  /**
   * Список всех тегов на филиале
   * @param {TNoteTag[]}
   */
  selectedTags: TNoteTag[];
  /**
   * Строка поиска для выделения найденного текста
   * @param {string}
   */
  searchString: string;
  /**
   * Флаг показа карточек заметок
   * @param {boolean}
   */
  isShowNotes: boolean;
  /**
   * Данные выбранной папки
   * @param {TFolderItem}
   */
  activeFolder: TFolderItem | null | undefined;
  /**
   * Список заметок
   * @param {TNoteItem[]}
   */
  notesList: TNoteItem[];
  /**
   * Флаг открытия блока работы с тэгами
   * @param {boolean}
   */
  isSearchByTag: boolean;
  /**
   * Callback для очистки поисковой строки
   * @param {() => void}
   */
  clearSearchStringHandler: () => void;
  /**
   * Callback для обработки изменения поисковой строки
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Устанавливает тэг в поисковую строку
   * @param {TSetTagInSearchString}
   */
  setTagInSearchString: TSetTagInSearchString;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const NotesListComponent = memo(
  ({
    tags,
    status,
    isLoading,
    notesList,
    isShowNotes,
    activeFolder,
    searchString,
    selectedTags,
    isSearchByTag,
    isTagDeleteMode,
    setTagInSearchString,
    setSearchStringHandler,
    clearSearchStringHandler,
    className = '',
  }: TProps) => {
    const width = useWindowWidth();

    const notesCardToRender = notesList.map(note => (
      <NoteCard
        key={note.id}
        cardData={note}
        searchString={searchString}
        setTagInSearchString={setTagInSearchString}
      />
    ));

    return (
      <>
        {isLoading ? <Loader className='h-[50vh]' /> : null}
        {!isLoading ? (
          <div className={`flex flex-col ${className}`}>
            {/* header */}
            {width > 641 && isShowNotes ? (
              <NoteListHeader
                status={status}
                searchString={searchString}
                activeFolder={activeFolder}
                notDeletableFolderName={COMMON_NOTE_FOLDER}
                className='mb-6'
              />
            ) : null}
            {isShowNotes ? (
              <>
                <NotesSearch
                  tags={tags}
                  status={status}
                  searchString={searchString}
                  selectedTags={selectedTags}
                  isSearchByTag={isSearchByTag}
                  isTagDeleteMode={isTagDeleteMode}
                  setTagInSearchString={setTagInSearchString}
                  setSearchStringHandler={setSearchStringHandler}
                  clearSearchStringHandler={clearSearchStringHandler}
                  className='mb-6'
                />
                <span className='font-medium text-gray3 text-small_text leading-4 tracking-[0.168em] mb-2'>
                  {`ЗАМЕТКИ (${notesList.length})`}
                </span>
                <div
                  className={`grid gap-[0.5rem] m:grid-cols-2notes xl:grid-cols-3notes auto-rows-min overflow-y-auto sm:h-[calc(100vh-6rem)] pr-2 task-drawer ${
                    isSearchByTag && !isTagDeleteMode ? 'z-[51]' : ''
                  }`}>
                  {notesCardToRender}
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </>
    );
  },
);

NotesListComponent.displayName = 'NotesListComponent';
