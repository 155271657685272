import React, { memo } from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { SearchInput } from '@uikit/Inputs/SearchInput';

interface IBlackListSearchInputProps {
  /**
   * Значения поисковой строки
   * @param {string}
   */
  value: string;
  /**
   * Значение placeholder в input
   * @param {string}
   */
  placeholder: string;
  /**
   * Callback вызывается для очистки введенного значения в input
   * @param {() => void}
   */
  clearSearchString: () => void;
  /**
   * Callback для обработки ввода поисковой строки
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BlackListSearchInput = memo(
  ({
    onChangeHandler,
    placeholder,
    value,
    clearSearchString,
    className = '',
  }: IBlackListSearchInputProps) => (
    <SearchInput
      type='def'
      value={value}
      view='outlined'
      color='default'
      className={className}
      placeholder={placeholder}
      onChange={onChangeHandler}
      clearSearchString={clearSearchString}
    />
  ),
);
