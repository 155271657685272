import React, { memo, useEffect, useMemo, useState } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { Dropdown } from '@uikit/Dropdown';
import { FormLabel } from '@blocks/formLabel';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { getDropdownList, getSecondDropdownListTitle, sortObj } from '@helpers/index';
import { TDeleteBalloonSet, TGetCategoryListHandler, TOnChangeHandler } from '@shared/types';

interface IInputWithDropdownProps {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  addedValues: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Значение input
   * @param {boolean}
   */
  newValue: string;
  /**
   * Параметр передает данные по успешном завершении получения данных страницы,
   * используется для отправки запроса к ЕР для получения списка для dropdown.
   * Запрос за списком уходит только если этот параметр true.
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Вариант текста в label, из словаря берется текст и подставляется в label
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными для отображения выпадающего списка
   * @param {{ [key: string]: TSimpleStringObj }}
   */
  categoriesDataList: { [key: string]: TSimpleStringObj };
  /**
   * Опциональный параметр включает или отключает валидацию нового значения, если параметр true
   * в input нельзя ввести текст, только число.
   * @param {boolean}
   * @default
   */
  typeOfValueIsNumber?: boolean;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlur}
   */
  addBalloonOnBlur: (name: string, value: string) => void;
  /**
   * Опциональный параметр флаг отключения
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Флаг по которому изменяется цвет чипса на красный и добавляется иконка
   * @param {boolean}
   * @default
   */
  isDanger?: boolean;
  hideFirstDropdown?: boolean;
  getCategoryListHandler: TGetCategoryListHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Опциональный параметр отвечающий за то что поле ввода принимает только 1 значение
   * @type {boolean}
   * @default false
   * */
  isSoloValue?: boolean;
}

export const InputWithDropdownFromEP = memo(
  ({
    inputId,
    newValue,
    addedValues,
    deleteBalloon,
    addBalloonOnBlur,
    labelTextVariant,
    isFetchingSuccess,
    categoriesDataList,
    typeOfValueIsNumber,
    selectedFilialAccId,
    disabled = false,
    isDanger = false,
    getCategoryListHandler,
    hideFirstDropdown = false,
    className = '',
    isSoloValue = false,
  }: IInputWithDropdownProps) => {
    const [action, setAction] = useState('add');
    const [filterValue, seFilterValue] = useState('');
    const [isShowDropdown, setIsShowDropdown] = useState(false);

    useEffect(() => {
      if (isSoloValue && newValue) {
        seFilterValue(categoriesDataList?.[inputId]?.[newValue]);
      }
    }, [isSoloValue, newValue, inputId, categoriesDataList]);

    const dropdownList = useMemo(
      () =>
        categoriesDataList[inputId] && inputId === 'clientCategory'
          ? (Object.fromEntries(
              Object.entries(categoriesDataList[inputId]).map(item => item.reverse()),
            ) as TSimpleStringObj)
          : categoriesDataList[inputId],
      [inputId, categoriesDataList],
    );
    const [filteredList, setFilteredList] = useState<TSimpleStringObj>({});
    const [filteredBySearchStringList, setFilteredBySearchStringList] = useState<TSimpleStringObj>(
      {},
    );
    const isGetCategoryList = useMemo(
      () => !Object.entries(dropdownList || {}).length,
      [dropdownList],
    );

    useEffect(() => {
      if (inputId && isFetchingSuccess && selectedFilialAccId && isGetCategoryList) {
        getCategoryListHandler({ categoryName: inputId });
      }
    }, [
      inputId,
      isFetchingSuccess,
      isGetCategoryList,
      selectedFilialAccId,
      getCategoryListHandler,
    ]);

    useEffect(() => {
      const moduloValues = addedValues.map(item => item.replace(/^-*/gm, ''));
      const newList = Object.fromEntries(
        Object.entries(dropdownList || []).filter(item => !moduloValues.includes(item[0])),
      );
      setFilteredList(newList || []);
    }, [dropdownList, inputId, addedValues]);

    // Валидирует введенное значение, валидация происходит если передан опциональный проп "typeOfValueIsNumber"
    const isBalloonValueValid = (valueToValidate: string) => {
      const valueWithOutMinus = valueToValidate.replace(/^-*/gm, '');
      if (!valueWithOutMinus) return false;
      if (typeOfValueIsNumber) {
        return !Number.isNaN(Number(valueToValidate));
      }
      return true;
    };

    // Добавляет новый "шарик", если введенные данные валидны, в противном случае очищает поле ввода
    const addBalloons = (balloonValue: string) => () => {
      if (isBalloonValueValid(balloonValue)) {
        const newList = Object.fromEntries(
          Object.entries(filteredList).filter(item => !balloonValue.includes(item[0])),
        );
        setFilteredList(newList);

        if (action === 'add' && !newValue.includes('-')) {
          addBalloonOnBlur(inputId, balloonValue);
          if (isSoloValue) {
            seFilterValue(filteredList[Number(balloonValue)]);
          }
        } else {
          addBalloonOnBlur(inputId, `-${balloonValue}`);
        }
        if (isShowDropdown) {
          setIsShowDropdown(false);
          if (!isSoloValue) seFilterValue('');
        }
      }
    };

    const deleteBalloonHandler: TDeleteBalloonSet = (item, inputName) => () => {
      const deletedItem = Object.fromEntries(
        Object.entries(dropdownList).filter(listItem => listItem[1] === item.replace(/-/gm, '')),
      );
      const newSortedList = sortObj(Object.assign(filteredList, deletedItem));

      setFilteredList(newSortedList);

      deleteBalloon(item, inputName)();
    };

    // Подготовка "шариков" для рендеринга
    const balloons = addedValues.map((item, index) => {
      const key = `${index}${item}`;

      const chipsText = `${categoriesDataList?.[inputId]?.[item] || ''} (${item})`;

      // const findBalloonData = Object.entries(categoriesDataList?.[inputId]).filter(
      //   balloonItem => balloonItem[1] === item,
      // );
      //
      // const chipsText = `${findBalloonData.length ? findBalloonData[0][0] : ''} (${item})`;

      return (
        <Chips
          key={key}
          removable
          type='def'
          tag={chipsText}
          color={isDanger ? 'danger' : 'default'}
          icon={hideFirstDropdown ? null : isDanger ? 'disable' : null}
          onRemove={deleteBalloonHandler(item, inputId)}
          className='tracking-wide mr-2.5 mb-2.5'
        />
      );
    });

    const actionsList = getDropdownList(labelTextVariant);
    const secondDropdownTitle = getSecondDropdownListTitle(labelTextVariant);

    const itemsToRenderInFirstDropdown = Object.entries(actionsList).map(item => (
      <MenuItem key={item[0]} onClick={() => setAction(item[0])} text={<span>{item[1]}</span>} />
    ));

    const itemsToRenderInSecondDropdown = Object.entries(
      filterValue ? filteredBySearchStringList : filteredList,
    ).map(item => {
      if (item[0] === 'masters' || item[0] === 'admins') {
        return (
          <span key={item[0]} className='inline-block pt-1 pl-5 text-small_text text-dGray'>
            {item[1]}
          </span>
        );
      }
      if (item[0].includes('divider')) {
        return <span key={item[0]} className='border-b border-quartz1 block my-2 w-full' />;
      }
      return (
        <MenuItem
          key={item[0]}
          onClick={item[1] !== 'Ничего не найдено' ? addBalloons(item[0]) : undefined}
          text={<span>{`${item[1]} ${item[0] ? `(${item[0]})` : ''}`}</span>}
        />
      );
    });

    const menuToRender = (
      <Menu
        view='raised'
        className={`dropdown-list-scrollbar ${
          Object.entries(itemsToRenderInSecondDropdown).length === 1 ? `one_item_ul` : ''
        } max-h-[25vh] overflow-y-auto`}>
        {itemsToRenderInSecondDropdown}
      </Menu>
    );

    const fetchStaffList = () => {
      if (isGetCategoryList) {
        getCategoryListHandler({ categoryName: inputId });
      }
    };

    const dropdownVisibleHandler = () => {
      setIsShowDropdown(true);
    };

    const filterInputHandler: TOnChangeHandler = event => {
      const { value } = event.currentTarget;

      if (value) {
        const newArray = Object.entries(filteredList).filter(
          item =>
            item[0]?.toLowerCase().includes(value.toLowerCase()) ||
            item[1]?.toLowerCase().includes(value.toLowerCase()),
        );
        const newList = Object.fromEntries(
          newArray.length ? newArray : [['Ничего не найдено', '']],
        );
        seFilterValue(value);
        setFilteredBySearchStringList(newList);
      } else {
        seFilterValue(value);
        setIsShowDropdown(true);
        setFilteredList(filteredList);
      }
    };

    const dropdownCloseHandler = () => {
      setIsShowDropdown(false);
      setFilteredList(filteredList);

      if (isSoloValue && newValue) {
        seFilterValue(categoriesDataList?.[inputId]?.[newValue]);
      } else {
        seFilterValue('');
      }
    };

    return (
      <div className={`flex flex-col ${className}`}>
        {!hideFirstDropdown ? (
          <FormLabel
            showInfo={false}
            htmlFor={inputId}
            labelTextVariant={labelTextVariant}
            className='text-body_text mb-2.5'
          />
        ) : null}
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          {!hideFirstDropdown ? (
            <div className='sm:mr-2 mb-2 w-full sm:w-fit'>
              <Dropdown
                color='default'
                view='outlined'
                position='bottom-left'
                text={<span>{actionsList[action]}</span>}
                className={`when_send_dropdown !m-0 sm:!min-w-[17.5rem] w-full ${className}`}
                content={
                  <Menu
                    view='raised'
                    className={
                      Object.entries(itemsToRenderInFirstDropdown).length === 1 ? `one_item_ul` : ''
                    }>
                    {itemsToRenderInFirstDropdown}
                  </Menu>
                }
                disabled={disabled}
              />
            </div>
          ) : null}
          <div
            className={`relative mb-2 w-full ${
              hideFirstDropdown ? 'w-full' : 'sm:!max-w-[29rem]'
            }`}>
            <TextInput
              fill
              type='text'
              clearButton
              error={false}
              view='outlined'
              color='default'
              success={false}
              value={filterValue}
              onClick={fetchStaffList}
              onChange={filterInputHandler}
              onFocus={dropdownVisibleHandler}
              placeholder={secondDropdownTitle}
              clearSearchString={() => seFilterValue('')}
              className={`${isShowDropdown ? 'z-[60]' : ''} ${
                filterValue ? '' : 'chevron_in_after'
              }`}
            />
            <div className={`${isShowDropdown ? 'block' : 'hidden'} absolute top-[3rem] z-[70]`}>
              {menuToRender}
            </div>
          </div>
        </div>
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
        {isShowDropdown
          ? createPortal(<Overlay onClickCallback={dropdownCloseHandler} />, document.body)
          : null}
      </div>
    );
  },
);
