import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//
import { IInitialState, initialState } from '@redux/emoji/initialState';

export const emojiSlice = createSlice({
  name: 'emoji',
  initialState,
  reducers: {
    setHideEmojiPicker(
      state: IInitialState,
      action: PayloadAction<{ name: string; isOpen: boolean; stepIdEmoji?: number }>,
    ) {
      state.textAreaName = action.payload.name;
      state.isOpen = action.payload.isOpen;
      state.stepIdEmoji = action.payload.stepIdEmoji || 0;
    },
  },
});

export const emojiReducer = emojiSlice.reducer;

export const { setHideEmojiPicker } = emojiSlice.actions;
