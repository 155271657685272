import React from 'react';

interface ILinkButtonProps {
  /**
   * URL в функцию для перехода при клике на кнопку
   * @param {string}
   */
  link: string;
  /**
   * Текст в кнопку
   * @param {string}
   */
  buttonText: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const LinkButton = ({ link, buttonText, className = '' }: ILinkButtonProps) => {
  const openLinkByClick = () => {
    window.open(link, '_blank');
  };

  return (
    <button
      onClick={openLinkByClick}
      className={`bg-none border-none leading-5 text-green1 text-inner_text tracking-[0.018em] underline text-center w-fit ${className}`}>
      {buttonText}
    </button>
  );
};
