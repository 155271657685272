import { TSPNameList } from '@shared/types';
import { emptySettingsData } from '@data/index';
import { PayloadAction } from '@reduxjs/toolkit';
import { INPUT_VARIANTS, TChangeAdminStatusData } from '@models/index';
import { HTTP_STATUS_CODE } from '@const/apiConstants';
import { simpleSort, validatePhoneNumber, validateValue } from '@helpers/index';
import { TSettingsInitialState } from '@redux/Settings/zod';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { COMMON_TOAST_MESSAGES, REG_EXP } from '@const/common';
import {
  TIME_PERIOD,
  TIME_OFFSET,
  SETTINGS_DATA_KEY_NAMES,
  CURRENT_PERIOD_ENABLED_MONTHS,
} from '@const/settings';
import {
  TSettingsData,
  TSettingStaff,
  TStaffListItem,
  TAdminsListItem,
  TSettingsNotificationResponseData,
  TSettingsTelephonyData,
  TConnectionType,
  CONNECTION_TYPE,
  TTelephonyTablePageData,
} from '@models/Settings';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';

export const reducers = {
  changeSettingsInputValue(
    state: TSettingsInitialState,
    action: PayloadAction<{ name: string; value: string }>,
  ) {
    const { name, value } = action.payload;
    // eslint-disable-next-line
    // @ts-ignore
    state.data[name] = value;
  },
  setCustomerChurnTime(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.data.customerChurnTime = validateValue({
      value: action.payload.replace(REG_EXP.onlyNumbers, ''),
      lowLimit: 1,
      upLimit: 365,
      initialValue: '1',
    });
  },
  changeSettingsCheckboxChecked(
    state: TSettingsInitialState,
    action: PayloadAction<{ name: string; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    // eslint-disable-next-line
    // @ts-ignore
    state.data[name] = checked;
  },
  setParamsCalcStat(
    state: TSettingsInitialState,
    action: PayloadAction<{ name: string; value: number }>,
  ) {
    const { name, value } = action.payload;

    if (name === SETTINGS_DATA_KEY_NAMES.START_RATE) {
      const { endRate } = state.data.paramsCalcStats;

      const months = CURRENT_PERIOD_ENABLED_MONTHS[value];

      if (!months.includes(endRate)) {
        [state.data.paramsCalcStats.endRate] = months;
      }

      state.data.paramsCalcStats.startRate = value;
    } else {
      state.data.paramsCalcStats.endRate = value;
    }
  },
  addBalloonSP(
    state: TSettingsInitialState,
    action: PayloadAction<{ keyName: TSPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;
    if (!value) return;
    if (keyName === SETTINGS_DATA_KEY_NAMES.EMPLOYEES_TO_IGNORE) {
      state.data[keyName] = state.data[keyName].concat(value);
    }
    // eslint-disable-next-line
    // @ts-ignore
    state[`${keyName}NewValue`] = '';
  },
  deleteBalloonSP(
    state: TSettingsInitialState,
    action: PayloadAction<{ keyName: TSPNameList; value: string }>,
  ) {
    const { keyName, value } = action.payload;

    if (keyName === SETTINGS_DATA_KEY_NAMES.EMPLOYEES_TO_IGNORE) {
      state.data[keyName] = state.data[keyName].filter(item => item !== value);
    }
  },
  setSortTypeDataSP(
    state: TSettingsInitialState,
    action: PayloadAction<{ collumName: string; order: string }>,
  ) {
    const { collumName, order } = action.payload;

    const key = collumName as unknown as keyof TSettingStaff;

    state.tableData = simpleSort<TSettingStaff>(state.tableData, key, order);
  },
  setSortTypeDataSPAdmins(
    state: TSettingsInitialState,
    action: PayloadAction<{ collumName: string; order: string }>,
  ) {
    const { collumName, order } = action.payload;

    const key = collumName as unknown as keyof TAdminsListItem;

    state.adminsTableData = simpleSort<TAdminsListItem>(state.adminsTableData, key, order);
  },
  setIsChurnTimeAllowEdit(state: TSettingsInitialState, action: PayloadAction<boolean>) {
    state.isChurnTimeAllowEdit = action.payload;
  },
  setChurnTimeAllowDelay(state: TSettingsInitialState, action: PayloadAction<number>) {
    state.churnTimeAllowEditDelay = action.payload;
  },
  setSelectedRights(
    state: TSettingsInitialState,
    action: PayloadAction<{ name: string; selectedRight: string; checked: boolean }>,
  ) {
    const { checked, name, selectedRight } = action.payload;

    state.adminsTableData = state.adminsTableData.map(admin => {
      if (admin.name === name) {
        const newAdminRights: string[] = [];
        if (checked) {
          newAdminRights.push(...admin.adminRights, selectedRight);
        } else {
          newAdminRights.push(
            ...admin.adminRights.filter(rightItem => rightItem !== selectedRight),
          );
        }
        return { ...admin, adminRights: newAdminRights };
      }
      return admin;
    });
  },
  changeAdminStatus(state: TSettingsInitialState, action: PayloadAction<TChangeAdminStatusData>) {
    const { state: newMasterState, id } = action.payload;

    state.adminsTableData = state.adminsTableData.map(item => {
      if (item.id === id) {
        return { ...item, state: newMasterState };
      }
      return item;
    });
  },
  clearPostError(state: TSettingsInitialState) {
    state.isPostError = false;
  },
  clearSuccessStatus(state: TSettingsInitialState) {
    state.isAccessDenied = false;
    state.successToastMessage = '';
    state.isSaveSettingSuccess = false;
  },
  clearAdminsTableStatuses(state: TSettingsInitialState) {
    state.errorToastMessage = '';
    state.successToastMessage = '';
    state.isAdminsTableDataError = false;
    state.isAdminsTableDataSavingError = false;
  },
  setSettingsNotification(
    state: TSettingsInitialState,
    action: PayloadAction<TSettingsNotificationResponseData | null>,
  ) {
    state.notificationsSettings = action.payload;
  },
  clearNotificationsStatus(state: TSettingsInitialState) {
    state.isErrorNotificationSave = false;
    state.isSuccessNotificationSave = false;
  },
  setTelephonyData(state: TSettingsInitialState, action: PayloadAction<TSettingsTelephonyData>) {
    state.telephonyData = action.payload;
  },
  setFormStep(state: TSettingsInitialState, action: PayloadAction<number>) {
    state.formStep = action.payload;
  },
  setSelectedOperator(state: TSettingsInitialState, action: PayloadAction<string>) {
    const selectedOperator = action.payload;

    const { yclients, manual, yclientsDocs, manualDocs, webhook, fields } =
      state.telephonyData.operators.filter(operator => operator.name === selectedOperator)[0];
    const possibleOperatorTypes = { yclients, manual };

    state.telephonyData.connectionType = possibleOperatorTypes.yclients
      ? CONNECTION_TYPE.YCLIENTS
      : CONNECTION_TYPE.MANUAL;

    if (state.telephonyData.selectedOperator !== selectedOperator && fields) {
      fields.forEach(({ name, type }) => {
        switch (type) {
          case INPUT_VARIANTS.TEXT:
            state.telephonyData[name] = '';
            break;
          case INPUT_VARIANTS.CHECKBOX:
            state.telephonyData[name] = false;
            break;
          case INPUT_VARIANTS.NUMBER:
            state.telephonyData[name] = 0;
            break;
          default:
            break;
        }
      });
    }
    state.webhook = webhook;
    state.manualDocs = manualDocs;
    state.yclientsDocs = yclientsDocs;
    state.telephonyData.selectedOperator = selectedOperator;
    state.telephonyData.possibleConnectionTypes = possibleOperatorTypes;
  },
  setTelephonyNumber(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.telephonyData.phoneNumber = action.payload;
  },
  setEditMode(state: TSettingsInitialState, action: PayloadAction<boolean>) {
    state.isEditMode = action.payload;
    state.formStep = 0;
    state.connectionSuccess = false;
    state.connectionTestSuccess = false;
    state.isShowAlmostDoneMessage = false;
    state.isShowSuccessMessage = false;
    state.telephonyData.connectionStatus = false;
  },
  setConnectionType(state: TSettingsInitialState, action: PayloadAction<TConnectionType>) {
    const connectionType = action.payload;

    state.connectionSuccess = false;
    state.connectionTestError = false;
    state.connectionTestSuccess = false;
    state.isShowAlmostDoneMessage = false;

    state.telephonyData.connectionType = connectionType;
  },
  setApiKey(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.telephonyData.apiKey = action.payload;
  },
  setDomain(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.telephonyData.domain = action.payload;
  },
  setTextInputValue(
    state: TSettingsInitialState,
    action: PayloadAction<{
      field: string;
      value: string;
    }>,
  ) {
    const { field, value } = action.payload;
    state.telephonyData[field] = value;
  },
  setBooleanInputValue(
    state: TSettingsInitialState,
    action: PayloadAction<{
      field: string;
      value: boolean;
    }>,
  ) {
    const { field, value } = action.payload;
    state.telephonyData[field] = value;
  },
  setTableData(state: TSettingsInitialState, action: PayloadAction<TTelephonyTablePageData>) {
    state.telephonyTableData = action.payload;
  },
  setPaginationSize(state: TSettingsInitialState, action: PayloadAction<number>) {
    state.newPage = 0;
    state.telephonyTableData.paginator.currentPage = 0;
    state.newPaginationSize = action.payload;
  },
  setCurrentPage(state: TSettingsInitialState, action: PayloadAction<number>) {
    state.newPage = action.payload;
  },
  setFirstFilter(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.firstFilterValue = action.payload;
  },
  setSecondFilter(state: TSettingsInitialState, action: PayloadAction<string>) {
    state.secondFilterValue = action.payload;
  },
  setSortedColumnAndOrder(
    state: TSettingsInitialState,
    action: PayloadAction<{ columnName: string; order: string }>,
  ) {
    const { columnName, order } = action.payload;
    state.sortDirection = order;
    state.sortColumnName = columnName;
  },
  setPhoneSearchString(state: TSettingsInitialState, action: PayloadAction<string>) {
    const phoneSearchString = action.payload.replace(REG_EXP.onlyNumbers, '');

    if (validatePhoneNumber(phoneSearchString).length < 14) {
      state.phoneSearchString = phoneSearchString;
    }
  },
};

export const getSettingsReducer = {
  pending: (state: TSettingsInitialState) => {
    const { isRefresh } = state;
    if (!isRefresh) {
      state.isLoading = true;
    }
    state.isError = false;
    state.isPosting = false;
    state.isSuccess = false;
    state.isPostError = false;
    state.isAccessDenied = false;
    state.isSaveSettingSuccess = false;
  },
  fulfilled: (state: TSettingsInitialState, action: PayloadAction<TSettingsData | void>) => {
    if (action.payload) {
      const { churnTimeAllowUpdate } = action.payload;

      const offset =
        (new Date(churnTimeAllowUpdate).getTimezoneOffset() + TIME_OFFSET.MOSCOW) *
        TIME_PERIOD.MINUTES_IN_HOUR *
        TIME_PERIOD.MILLISECONDS_IN_SECOND *
        -1;
      const delay = new Date(churnTimeAllowUpdate).getTime() - new Date().getTime() + offset;

      state.data = action.payload;
      state.churnTimeAllowEditDelay = delay;

      state.isChurnTimeAllowEdit = delay <= 0;
    } else {
      state.data = emptySettingsData;
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
    state.isRefresh = false;
    state.isSuccess = true;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isLoading = true;
      } else {
        state.isError = true;
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const postSettingsReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isPosting = true;
    state.isSaveSettingSuccess = false;
  },
  fulfilled: (state: TSettingsInitialState, action: PayloadAction<TSettingsData | void>) => {
    if (action.payload) {
      state.isPosting = false;
      state.data = action.payload;
      state.isSaveSettingSuccess = true;
      state.successToastMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
    }
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        // state.isPosting = false;
      } else {
        state.isPostError = true;
        state.isPosting = false;
        state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
      }
    } else {
      state.isPostError = true;
      state.isPosting = false;
      state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
    }
  },
};

export const getSettingsPageStaffListReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isGettingStaffListError = false;
  },
  fulfilled: (
    state: TSettingsInitialState,
    action: PayloadAction<{ data: TStaffListItem[]; categoryName: string } | void>,
  ) => {
    if (!action.payload) return;

    // const { admins, masters } = action.payload;

    // const adminsListLength = admins.length;

    // const adminsHeaderInDropdown = ['admins', 'АДМИНЫ'];

    const { data } = action.payload;

    const newStaffList = data.map(item => [String(item.id), item.name]);

    // const newAdminsList = data.map(item => [String(item.id), item.name]);

    // newAdminsList.unshift(adminsHeaderInDropdown);

    // const mastersHeaderInDropdown = [
    //   ['divider1', ''],
    //   ['masters', 'МАСТЕРА'],
    // ];

    // const newMatersList = masters.map(item => [item.name, String(item.id)]);
    //
    // newMatersList.unshift(...mastersHeaderInDropdown);
    //
    // const newStaffList = [...newAdminsList, ...newMatersList];
    //
    // state.adminsListLength = adminsListLength;

    if (newStaffList.length) {
      state.data.staffList = Object.fromEntries(newStaffList);
    } else {
      state.data.staffList = { '-': 'Нет сотрудников' };
    }

    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
  },
  rejected: (state: TSettingsInitialState) => {
    state.isGettingStaffListError = true;
  },
};

export const getSmartScheduleTableDataReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isTableDataLoading = true;
    state.isGetTableDateError = false;
    state.isGettingTableDataSuccess = false;
  },
  fulfilled: (state: TSettingsInitialState, action: PayloadAction<TSettingStaff[] | void>) => {
    if (action.payload) {
      state.tableData = action.payload;
      state.isGettingTableDataSuccess = true;
    }
    state.isTableDataLoading = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isTableDataLoading = true;
      } else {
        state.isError = true;
        state.isGetTableDateError = true;
        state.isTableDataLoading = false;
      }
    }
  },
};

export const getAdminsTableDataReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isAdminsTableDataLoading = true;
    state.isAdminsTableDataSuccess = false;
    state.isAdminsTableDataError = false;
  },
  fulfilled: (state: TSettingsInitialState, action: PayloadAction<TAdminsListItem[] | void>) => {
    if (action.payload) {
      state.adminsTableData = action.payload;
      state.isAdminsTableDataSuccess = true;
    }
    state.isAdminsTableDataLoading = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        // state.isAdminsTableDataLoading = false;
      } else {
        state.isAdminsTableDataError = true;
        state.isAdminsTableDataLoading = false;
      }
    } else {
      state.isAdminsTableDataError = true;
      state.isAdminsTableDataLoading = false;
    }
  },
};

export const saveAdminsTableDataReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isAdminsTableDataSaving = true;
  },
  fulfilled: (state: TSettingsInitialState, action: PayloadAction<TAdminsListItem[] | void>) => {
    if (action.payload) {
      state.adminsTableData = action.payload;
      state.successToastMessage = COMMON_TOAST_MESSAGES.SAVE_SUCCESS;
    }
    state.isAdminsTableDataSaving = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isAdminsTableDataSaving = true;
      } else {
        state.isAdminsTableDataSaving = false;
        state.isAdminsTableDataSavingError = true;
        state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
      }
    } else {
      state.isAdminsTableDataSaving = false;
      state.isAdminsTableDataSavingError = true;
      state.errorToastMessage = COMMON_TOAST_MESSAGES.COMMON_SAVE_ERROR_MESSAGE;
    }
  },
};

export const getNotificationsThunkReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isLoading = true;
    state.isError = false;
    state.isSuccess = false;
    state.notificationsSettings = null;
  },
  fulfilled: (
    state: TSettingsInitialState,
    action: PayloadAction<TSettingsNotificationResponseData>,
  ) => {
    if (action.payload) {
      state.notificationsSettings = action.payload;
      state.isSuccess = true;
    }
    state.isLoading = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isTableDataLoading = true;
      } else {
        state.isError = true;
        state.isGetTableDateError = true;
        state.isTableDataLoading = false;
      }
    }
  },
};

export const saveNotificationsReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isLoading = true;
    state.isError = false;
    state.isSuccess = false;
    state.isSuccessNotificationSave = false;
    state.isErrorNotificationSave = false;
  },
  fulfilled: (
    state: TSettingsInitialState,
    action: PayloadAction<TSettingsNotificationResponseData>,
  ) => {
    if (action.payload) {
      state.notificationsSettings = action.payload;
      state.isSuccess = true;
    }
    state.isSuccessNotificationSave = true;
    state.isLoading = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isTableDataLoading = true;
      } else {
        state.isError = true;
        state.isErrorNotificationSave = true;
        state.isGetTableDateError = true;
        state.isTableDataLoading = false;
      }
    }
  },
};

export const getSettingsTelephonyReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isError = false;
    state.isLoading = true;
    state.isSuccess = false;
    state.isAccessDenied = false;
    state.connectionTestError = false;
    state.isShowSuccessMessage = false;
    state.isShowAlmostDoneMessage = false;
    state.isShowConnectionErrorMessage = false;
  },
  fulfilled: (
    state: TSettingsInitialState,
    action: PayloadAction<TSettingsTelephonyData | void>,
  ) => {
    if (action.payload) {
      const telephonyData = action.payload;
      state.telephonyData = telephonyData;

      if (telephonyData.connectionStatus) {
        const { yclientsDocs, manualDocs, webhook } = telephonyData.operators.filter(
          operator => operator.name.toLowerCase() === telephonyData.selectedOperator.toLowerCase(),
        )[0];

        state.webhook = webhook;
        state.manualDocs = manualDocs;
        state.yclientsDocs = yclientsDocs;

        state.isShowSuccessMessage = true;
        state.formStep = 2;
      }
    }
    state.isLoading = false;
    state.isSuccess = true;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        // state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const testSettingsTelephonyReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isTestingConnection = true;
    state.connectionError = false;
    state.connectionTestError = false;
    state.isShowSuccessMessage = false;
    state.connectionTestSuccess = false;
    state.isShowAlmostDoneMessage = false;
    state.isShowConnectionErrorMessage = false;
  },
  fulfilled: (state: TSettingsInitialState) => {
    state.formStep = 1;
    state.isTestingConnection = false;
    state.connectionTestSuccess = true;
    state.isShowAlmostDoneMessage = true;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isTestingConnection = true;
      } else if (status === HTTP_STATUS_CODE.NOT_FOUND || +status >= 500) {
        state.formStep = 0;
        state.isError = true;
      } else {
        state.formStep = 1;
        state.connectionTestError = true;
        state.isTestingConnection = false;
      }
    } else {
      state.formStep = 1;
      state.connectionTestError = true;
      state.isTestingConnection = false;
    }
  },
};

export const saveSettingsTelephonyReducer = {
  pending: (state: TSettingsInitialState) => {
    state.connectionInProgress = true;
    state.connectionError = false;
    state.connectionTestError = false;
    state.isShowSuccessMessage = false;
    state.connectionTestSuccess = false;
    state.isShowConnectionErrorMessage = false;
  },
  fulfilled: (state: TSettingsInitialState) => {
    state.formStep = 2;
    state.connectionSuccess = true;
    state.isShowSuccessMessage = true;
    state.connectionInProgress = false;
    state.telephonyData.connectionStatus = true;
    state.isShowAlmostDoneMessage = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.connectionInProgress = true;
      } else if (status === HTTP_STATUS_CODE.NOT_FOUND || +status >= 500) {
        state.formStep = 0;
        state.isError = true;
      } else {
        state.formStep = 2;
        state.connectionError = true;
        state.connectionInProgress = false;
        state.isShowSuccessMessage = false;
        state.isShowAlmostDoneMessage = false;
      }
    } else {
      state.formStep = 2;
      state.connectionError = true;
      state.connectionInProgress = false;
      state.isShowSuccessMessage = false;
      state.isShowAlmostDoneMessage = false;
    }
  },
};

export const getTelephonyPageDataReducer = {
  pending: (state: TSettingsInitialState) => {
    state.isError = false;
    state.isLoading = true;
  },
  fulfilled: (
    state: TSettingsInitialState,
    action: PayloadAction<TTelephonyTablePageData | void>,
  ) => {
    if (action.payload) {
      state.telephonyTableData = action.payload;
    }
    state.isLoading = false;
  },
  rejected: (state: TSettingsInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};
