/**
 * Константы для страницы ЧС
 * @module blackListConstants
 */

/**
 * Текст для всплывающих подсказок
 * @enum BLACK_LIST_TOAST_MESSAGES
 */
export enum BLACK_LIST_TOAST_MESSAGES {
  DELETE_ERROR = 'Ошибка удаления. \n Перезагрузите страницу \n и попробуйте ещё раз',
  ADD_ERROR = 'Ошибка добавления. \n Перезагрузите страницу \n и попробуйте ещё раз',
  DELETE_SUCCESS = 'Номер удален',
  ADD_SUCCESS = 'Номер добавлен',
  NUMBER_ALREADY_IN_LIST = 'Номер уже в списке',
}

/**
 * Список действий для API страницы ЧС
 */
export enum BLACKLIST_ACTION {
  ADD_NUMBER = 'add',
  DELETE_NUMBER = 'delete',
}
