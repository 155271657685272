import React, { memo } from 'react';
//
import { Label } from '@atoms/label';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, IWithChildren } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface ISettingInputWithLabel extends IWithChildren {
  /**
   * Значение в input
   * @param {string}
   */
  value: string;
  /**
   * Значение параметра htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Вариант текста подсказки, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant?: THelpKeys;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр отключает input
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр добавляет к тексту label знак * и атрибут 'required' в input
   * @param {boolean}
   * @default
   */
  isRequire?: boolean;
  /**
   * Опциональный параметр placeholder текст в input
   * @param {string}
   * @default
   */
  placeHolder?: string;
  /**
   * Опциональный параметр показывает или скрывает иконку информации с всплывающей подсказкой
   * @param {boolean}
   * @default
   */
  isShowInfoIcon?: boolean;
  /**
   * Опциональный параметр текст сообщения об ошибке
   * @param {string}
   * @default
   */
  errorHelperText?: string;
  /**
   * Callback для очистки введенного значения в input
   * @param {() => void}
   */
  clearSearchString: () => void;
  /**
   * Callback для изменения значения в input
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const InputWithLabel = memo(
  ({
    value,
    htmlFor,
    variant,
    children,
    disabled = false,
    textColor = 'text-grayText',
    isRequire,
    placeHolder = '',
    isShowInfoIcon = false,
    onChangeHandler,
    errorHelperText,
    clearSearchString,
    className = '',
  }: ISettingInputWithLabel) => (
    <div className={`flex flex-col ${className}`}>
      <Label
        htmlFor={htmlFor}
        className={`font-inter text-body_text ${textColor} font-medium mb-1`}>
        {children}
        {isRequire ? <span className='text-red'>*</span> : ''}
        {isShowInfoIcon && variant ? <IconWithTooltips tooltips={variant} /> : null}
      </Label>
      <TextInput
        type='text'
        view='outlined'
        id={htmlFor}
        value={value}
        name={htmlFor}
        disabled={disabled}
        required={isRequire}
        onChange={onChangeHandler}
        placeholder={placeHolder}
        error={isRequire && !value}
        errorHelperText={errorHelperText}
        clearButton={!disabled && !!value}
        color={isRequire && !value ? 'danger' : 'default'}
        clearSearchString={disabled ? null : clearSearchString}
        className='!w-full'
      />
    </div>
  ),
);
