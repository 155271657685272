import { LineChart } from '@components/lineChart';
import { TDashboardData } from '@api/dashboard/types';
import { DoughnutChart as Doughnut } from '@components/doughnutChart';
import { chartColors, DASHBOARD_FINANCES_KEYS } from '@const/dashboard';
import {
  getOneLineChartDataset,
  getDoughnutChartDataset,
  getDataForDoughnutChart,
  getDataForDoughnutChartFromEaredByOutCalls,
} from '@helpers/index';

type TDoughnutChartProps = {
  chartName: DASHBOARD_FINANCES_KEYS;
  data: TDashboardData;
  keyName: string;
  chartTitle: string;
  getRotationAngeCallback: (chartData: number[]) => number;
};
export const DoughnutChart = ({
  data,
  keyName,
  chartName,
  chartTitle,
  getRotationAngeCallback,
}: TDoughnutChartProps) => {
  if (chartName === DASHBOARD_FINANCES_KEYS.ALL_AMOUNT) {
    return (
      <Doughnut
        data={getDataForDoughnutChart({
          data: data.finance[chartName],
          keyName,
          chartTitle,
        })}
      />
    );
  }

  if (chartName === DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE) {
    return (
      <Doughnut
        data={getDoughnutChartDataset({
          chartData: data.finance[chartName],
          chartTitle,
          getRotationAngeCallback,
        })}
      />
    );
  }

  if (chartName === DASHBOARD_FINANCES_KEYS.OP_RECORDS) {
    return (
      <LineChart
        data={getOneLineChartDataset({
          chartData: data.finance[chartName],
          key: DASHBOARD_FINANCES_KEYS.OP_RECORDS,
          chartLabel: 'Записанные клиенты',
          borderColor: chartColors['green-30'],
          backgroundColor: chartColors['green-30'],
        })}
        chartTitle=''
      />
    );
  }

  if (chartName === DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS) {
    return (
      <Doughnut
        data={getDataForDoughnutChartFromEaredByOutCalls({
          data: data.finance[chartName],
          keyName,
          chartTitle,
        })}
      />
    );
  }

  return null;
};
