import React, { useEffect, useRef } from 'react';
//
import { EVENT_KEY_NAMES } from '@const/common';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';

interface IPinInputProps {
  /**
   * Значение пинкода для авторизации
   * @param {string}
   */
  pin: string;
  /**
   * Callback для обработки изменения пина
   * @param {(value: string) => void}
   */
  onChangeCallback: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PinInput = ({ pin, onChangeCallback, className = '' }: IPinInputProps) => {
  const ref0 = useRef<HTMLInputElement>(null);
  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);

  const refsArray = [ref0, ref1, ref2, ref3];

  useEffect(() => {
    ref0?.current?.focus();
  }, []);

  useEffect(() => {
    if (!pin) {
      ref0?.current?.focus();
    }
  }, [pin]);

  const onChangeHandler: TOnChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (Number.isNaN(+value)) return;

    const newValue = [...pin.split('')];
    newValue.splice(+name, 1, value);
    onChangeCallback(newValue.join(''));

    if (!value) {
      // refsArray[+name - 1]?.current?.focus();
      return;
    }
    refsArray[+name + 1]?.current?.focus();
  };

  const selectOnFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.select();
  };

  const onKeyUpHandler: TKeyboardClickEvent = event => {
    const { key, currentTarget } = event;
    const { name } = currentTarget;

    const isMoveFocusToLeft =
      key === EVENT_KEY_NAMES.BACKSPACE && pin.length >= +name && !pin[+name];

    if (isMoveFocusToLeft) {
      refsArray[+name - 1]?.current?.focus();
    }
  };

  return (
    <div className={`flex items-center justify-center w-full ${className}`}>
      <div className='flex justify-between w-[11.5rem]'>
        <div className='h-10 w-10'>
          <TextInput
            name={0}
            value={pin[0]}
            autoComplete='off'
            inputMode='numeric'
            onChange={onChangeHandler}
            onClick={selectOnFocus}
            type='text'
            view='outlined'
            color='default'
            ref={ref0}
          />
        </div>
        <div className='h-10 w-10'>
          <TextInput
            name={1}
            value={pin[1]}
            autoComplete='off'
            inputMode='numeric'
            onChange={onChangeHandler}
            onClick={selectOnFocus}
            onKeyDown={onKeyUpHandler}
            type='text'
            view='outlined'
            color='default'
            ref={ref1}
          />
        </div>
        <div className='h-10 w-10'>
          <TextInput
            name={2}
            value={pin[2]}
            autoComplete='off'
            inputMode='numeric'
            onChange={onChangeHandler}
            onClick={selectOnFocus}
            onKeyDown={onKeyUpHandler}
            type='text'
            view='outlined'
            color='default'
            ref={ref2}
          />
        </div>
        <div className='h-10 w-10'>
          <TextInput
            name={3}
            value={pin[3]}
            autoComplete='off'
            inputMode='numeric'
            onChange={onChangeHandler}
            onClick={selectOnFocus}
            onKeyDown={onKeyUpHandler}
            type='text'
            view='outlined'
            color='default'
            ref={ref3}
          />
        </div>
      </div>
    </div>
  );
};
