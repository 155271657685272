import { TInitialState } from '@redux/Pay/zod';
import { payerEmptyData, payLinkEmptyData, payPageEmptyData } from '@data/index';

export const initialState: TInitialState = {
  isLoading: false,
  isError: false,
  isChangeModalOpen: false,
  isConnectAutoPayModalOpen: false,
  isAutoPayDisableModalOpen: false,
  autoPayLink: '',
  isFetchingAutoPayLink: false,
  isFetchingAutoPayLinkError: false,
  isFetchingAutoPayLinkSuccess: false,
  isPayerDataSaving: false,
  changeCardDataLink: '',
  changeCardIsLoading: false,
  changeCardIsError: false,
  changeCardIsSuccess: false,
  turnOffAutoPaymentIsLoading: false,
  turnOffAutoPaymentIsError: false,
  turnOffAutoPaymentIsSuccess: false,
  isAccessOnPayPageDenied: false,
  isAccessDenied: false,
  retries: 1,
  isRetries: false,
  data: payPageEmptyData,
  payerData: payerEmptyData,
  payerFormData: payerEmptyData,
  payLink: payLinkEmptyData,
  successToastMessage: '',
  errorToastMessage: '',
  selectedCardTotalAmount: 0,
  selectedCardMonthAmount: 3,
  isGetInvoiceLoading: false,
  isGetInvoiceError: false,
  isGetInvoiceSuccess: false,
  isGetLinkLoading: false,
  isGetLinkError: false,
  selectedCardNumber: 0,
};
