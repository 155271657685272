import React, { useEffect, useMemo } from 'react';
//
import { Loader } from '@blocks/loader';
import { DASHBOARD_TYPES } from '@api/types';
import { PATH_NAMES } from '@const/httpConst';
import { NoAccess } from '@components/noAccess';
import { AdminsTemplate } from '@templates/admins';
import { ErrorMessage } from '@blocks/errorMessage';
import { MastersTemplate } from '@templates/masters';
import { PATH_TO_CHART_NAME } from '@const/dashboard';
import { FinancesTemplate } from '@templates/finances';
import { DashboardTemplate } from '@templates/dashboard';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getSettingsTelephonyThunk } from '@redux/Settings/thunk';
import { getDateStringToGetData, getFourteenDaysBackData } from '@helpers/index';
import { getDashboardData, getMainDashboardData } from '@redux/Dashboard/thunks';
import { getAccountsData, getDashboardPageData, getSettingsData } from '@redux/selectors';

interface IDashboardPageProps {
  path: PATH_NAMES;
}

const DashboardPage = ({ path }: IDashboardPageProps) => {
  const dispatch = useAppDispatch();
  const { selectedFilial } = useAppSelector(getAccountsData);
  const selectedFilialAccId = selectedFilial?.accId || '';
  const {
    data,
    isError,
    isRetries,
    isLoading,
    adminsEndDate,
    isAccessDenied,
    isFetchingMeta,
    mastersEndDate,
    adminsStartDate,
    financesEndDate,
    analyticsEndDate,
    mastersStartDate,
    financesStartDate,
    analyticsStartDate,
    isFetchingMetaSuccess,
    selectedAdminsDashboard,
    selectedMastersDashboard,
    selectedFinancesDashboard,
  } = useAppSelector(getDashboardPageData);
  const { telephonyData } = useAppSelector(getSettingsData);

  const requestStartData = useMemo(() => {
    return path === PATH_NAMES.FINANCES
      ? financesStartDate
      : path === PATH_NAMES.MASTERS
      ? mastersStartDate
      : path === PATH_NAMES.ADMINS
      ? adminsStartDate
      : path === PATH_NAMES.DASHBOARD
      ? analyticsStartDate
      : new Date();
  }, [path, financesStartDate, mastersStartDate, adminsStartDate, analyticsStartDate]);

  const requestEndData = useMemo(() => {
    return path === PATH_NAMES.FINANCES
      ? financesEndDate
      : path === PATH_NAMES.MASTERS
      ? mastersEndDate
      : path === PATH_NAMES.ADMINS
      ? adminsEndDate
      : path === PATH_NAMES.DASHBOARD
      ? analyticsEndDate
      : new Date();
  }, [path, financesEndDate, mastersEndDate, adminsEndDate, analyticsEndDate]);

  // Эффект запрашивает все данные дашбордов повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => {
        dispatch(
          getMainDashboardData({
            type: DASHBOARD_TYPES.MAIN,
            startDate: getDateStringToGetData(analyticsStartDate),
            endDate: getDateStringToGetData(analyticsEndDate),
          }),
        );
      }, 100);
    }
  }, [dispatch, analyticsStartDate, analyticsEndDate, isRetries]);

  // Эффект запрашивает все данные дашбордов при загрузке страницы и смене филиала
  useEffect(() => {
    if (selectedFilialAccId) {
      dispatch(
        getMainDashboardData({
          type: DASHBOARD_TYPES.MAIN,
          startDate: getFourteenDaysBackData().fourteenDaysAgo,
          endDate: getDateStringToGetData(new Date()),
          force: true,
        }),
      );
    }
  }, [dispatch, selectedFilialAccId]);

  // Эффект запрашивает все данные нужной страницы дашбордов повторно при ошибке токена
  // нужный тип дашборда берется на основе URL(path)
  useEffect(() => {
    if (path === PATH_NAMES.DASHBOARD) return;
    if (isRetries) {
      setTimeout(() => {
        dispatch(
          getDashboardData({
            force: true,
            type: PATH_TO_CHART_NAME[path],
            startDate: getDateStringToGetData(requestStartData),
            endDate: getDateStringToGetData(requestEndData),
          }),
        );
      }, 100);
    }
  }, [dispatch, path, requestStartData, requestEndData, isRetries]);

  // Эффект запрашивает все данные нужной страницы дашбордов при загрузке страницы и смене филиала
  // нужный тип дашборда берется на основе URL(path)
  useEffect(() => {
    if (selectedFilialAccId && !isFetchingMeta && isFetchingMetaSuccess) {
      dispatch(
        getDashboardData({
          force: true,
          type: PATH_TO_CHART_NAME[path],
          startDate: getDateStringToGetData(requestStartData),
          endDate: getDateStringToGetData(requestEndData),
        }),
      );
      dispatch(getSettingsTelephonyThunk());
    }
  }, [
    path,
    dispatch,
    isFetchingMeta,
    requestEndData,
    requestStartData,
    selectedFilialAccId,
    isFetchingMetaSuccess,
  ]);

  return (
    <div>
      {isAccessDenied ? (
        <NoAccess />
      ) : (
        <>
          {isLoading && isFetchingMeta ? <Loader className='h-[50Vh]' /> : null}
          {!isLoading && !isFetchingMeta && !isError && path === PATH_NAMES.DASHBOARD ? (
            <DashboardTemplate selectedFilialAccId={selectedFilialAccId} />
          ) : null}
          {!isLoading && !isError && !isFetchingMeta && path === PATH_NAMES.FINANCES ? (
            <FinancesTemplate
              data={data}
              pathName={PATH_NAMES.FINANCES}
              selectedFilialAccId={selectedFilialAccId}
              selectedCategories={selectedFinancesDashboard}
            />
          ) : null}
          {!isLoading && !isError && !isFetchingMeta && path === PATH_NAMES.MASTERS ? (
            <MastersTemplate
              data={data}
              pathName={PATH_NAMES.MASTERS}
              selectedFilialAccId={selectedFilialAccId}
              selectedCategories={selectedMastersDashboard}
            />
          ) : null}
          {!isLoading && !isError && !isFetchingMeta && path === PATH_NAMES.ADMINS ? (
            <AdminsTemplate
              data={data}
              pathName={PATH_NAMES.ADMINS}
              selectedCharts={selectedAdminsDashboard}
              selectedFilialAccId={selectedFilialAccId}
              showTelephony={telephonyData.connectionStatus}
              showBBIntegrationData={selectedFilial?.beautyBotIntegration || false}
            />
          ) : null}
          {!isAccessDenied && isError ? <ErrorMessage /> : null}
        </>
      )}
    </div>
  );
};

export default DashboardPage;
