import React, { useCallback, useMemo } from 'react';
//
import { H1 } from '@atoms/h1';
import { TDashboardData } from '@api/dashboard/types';
import { ChartWrapper } from '@components/chartWrapper';
import { DashboardDrawer } from '@blocks/dashboardDrawer';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DashboardPathLinks } from '@blocks/dashboardPathLinks';
import { DashboardInfo, emptyDashboardInfoItem } from '@data/dict';
import { getAppData, getDashboardPageData } from '@redux/selectors';
import { DashboardAdminsCharts } from '@blocks/dashboardAdminsCharts';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { DashboardSelectBlock } from '@components/dashboardSelectBlock';
import { TDashboardDrawerDataItem, TSimpleStringObj } from '@models/index';
import { setAnalyticsType, setShowAnalyticsDrawer } from '@redux/App/slice';
import {
  ADMINS_CHARTS,
  DASHBOARD_KEYS,
  dashboardAdminsList,
  DASHBOARD_ADMINS_KEYS,
  adminsAllInCallsFilterNames,
  adminsAllOutCallsFilterNames,
} from '@const/dashboard';
import {
  setAdminsDates,
  setSelectedAdminsCategories,
  setSelectedAllInCallsFilterName,
  setSelectedAllOutCallsFilterName,
} from '@redux/Dashboard/slice';

interface IAdminsTemplateProps {
  /**
   * Часть пути для перехода на страницу с табличным представлением данных
   * @param {string}
   */
  pathName: string;
  /**
   * Данные для графиков
   * @param {TDashboardData}
   */
  data: TDashboardData;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Список выбранных чартов
   * @param {Array<keyof typeof dashboardAdminsList>}
   */
  selectedCharts: Array<keyof typeof dashboardAdminsList>;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Опциональный параметр отвечающий за отображение данных телефонии
   * @param {boolean}
   * @default false
   */
  showTelephony?: boolean;
  /**
   * Опциональный параметр отвечающий за отображение данных при наличии интеграции ББ
   * @param {boolean}
   * @default false
   */
  showBBIntegrationData?: boolean;
}

export const AdminsTemplate = ({
  data,
  pathName,
  selectedCharts,
  className = '',
  selectedFilialAccId,
  showTelephony = false,
  showBBIntegrationData = false,
}: IAdminsTemplateProps) => {
  const dispatch = useAppDispatch();
  const {
    adminsEndDate,
    adminsStartDate,
    selectedAllInCallsFilterName,
    selectedAllOutCallsFilterName,
  } = useAppSelector(getDashboardPageData);
  const { showAnalyticsDrawer } = useAppSelector(getAppData);
  const setSelectedChatHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(setSelectedAdminsCategories({ name: name as DASHBOARD_ADMINS_KEYS, checked }));
  };

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setAdminsDates({ startDate, endDate }));
  };

  const selectedAllOutCallsFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedAllOutCallsFilterName(filterName));
    },
    [dispatch],
  );

  const selectedAllInCallsFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedAllInCallsFilterName(filterName as DASHBOARD_KEYS));
    },
    [dispatch],
  );

  const selectedFilterNameHandlers: Record<DASHBOARD_ADMINS_KEYS, (filterName: string) => void> =
    useMemo(
      () => ({
        [DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS]: selectedAllInCallsFilterNameHandler,
        [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: selectedAllOutCallsFilterNameHandler,
        [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: selectedAllInCallsFilterNameHandler,
      }),
      [selectedAllInCallsFilterNameHandler, selectedAllOutCallsFilterNameHandler],
    );

  const selectedAdminsFilterName: Record<DASHBOARD_ADMINS_KEYS, TSimpleStringObj> = useMemo(
    () => ({
      [DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS]: adminsAllInCallsFilterNames,
      [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: adminsAllOutCallsFilterNames,
      [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: adminsAllOutCallsFilterNames,
    }),
    [],
  );

  const selectedFilterName: Record<DASHBOARD_ADMINS_KEYS, string> = useMemo(
    () => ({
      [DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS]: selectedAllInCallsFilterName,
      [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: selectedAllOutCallsFilterName,
      [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: selectedAllInCallsFilterName,
    }),
    [selectedAllOutCallsFilterName, selectedAllInCallsFilterName],
  );

  const changeDisplayDrawer = useCallback(
    (name?: DASHBOARD_KEYS) => {
      let currentDashboardDrawerData: TDashboardDrawerDataItem = emptyDashboardInfoItem;
      if (name) {
        currentDashboardDrawerData = DashboardInfo[name];

        dispatch(setAnalyticsType(currentDashboardDrawerData));
      }

      dispatch(setShowAnalyticsDrawer(!showAnalyticsDrawer));
    },
    [showAnalyticsDrawer, dispatch],
  );

  const getChartContentType = useMemo<Record<DASHBOARD_ADMINS_KEYS, DASHBOARD_KEYS>>(
    () => ({
      [DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS]: selectedAllInCallsFilterName,
      [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: DASHBOARD_KEYS.ALL_OUT_CALLS,
      [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: DASHBOARD_KEYS.WA_AVERAGE_TIME,
    }),
    [selectedAllInCallsFilterName],
  );

  const renderCharts = useMemo(() => {
    return selectedCharts.map(chartName => {
      if (
        !showTelephony &&
        (chartName === DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS ||
          chartName === DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS)
      ) {
        return null;
      }

      if (!showBBIntegrationData && chartName === DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME) {
        return null;
      }

      return (
        <ChartWrapper
          key={chartName}
          isTableView={chartName === DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS}
          isChartSetupModule={chartName !== DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME}
          pathName={pathName}
          selectedFilialAccId={selectedFilialAccId}
          chartName={ADMINS_CHARTS[chartName]}
          chartDataKeyName={ADMINS_CHARTS[chartName]}
          chartFilterNames={selectedAdminsFilterName[chartName]}
          selectedFilterName={selectedFilterName[chartName]}
          callback={selectedFilterNameHandlers[chartName]}
          barChart={
            <DashboardAdminsCharts
              data={data}
              chartName={chartName}
              selectedAllOutCallsFilterName={selectedAllOutCallsFilterName}
              selectedAllInCallsFilterName={selectedAllInCallsFilterName}
            />
          }
          doughnutChart={
            <DashboardAdminsCharts
              data={data}
              chartName={chartName}
              selectedAllOutCallsFilterName={selectedAllOutCallsFilterName}
              selectedAllInCallsFilterName={selectedAllInCallsFilterName}
            />
          }
          chartContentType={getChartContentType[chartName]}
          onHowButtonClick={changeDisplayDrawer}
        />
      );
    });
  }, [
    data,
    pathName,
    showTelephony,
    selectedCharts,
    selectedFilterName,
    selectedFilialAccId,
    getChartContentType,
    changeDisplayDrawer,
    showBBIntegrationData,
    selectedAdminsFilterName,
    selectedFilterNameHandlers,
    selectedAllInCallsFilterName,
    selectedAllOutCallsFilterName,
  ]);

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardDrawer changeDisplayDrawer={changeDisplayDrawer} />
      <DashboardPathLinks
        className='mb-10'
        thirdPageName='Админы'
        selectedFilialAccId={selectedFilialAccId}
      />
      <H1 className='mb-10 tracking-[-0.01em]'>Админы</H1>
      <div className='flex flex-wrap items-center justify-between mb-6'>
        <DateRangeDashboardPicker
          startDate={adminsStartDate}
          endDate={adminsEndDate}
          setSendingDate={setDateRangeHandler}
          className='mb-4 w-full sm:max-w-[20rem]'
        />
        <DashboardSelectBlock
          categories={ADMINS_CHARTS}
          selectedCategories={selectedCharts}
          onChangeHandler={setSelectedChatHandler}
          className='mb-4 sm:max-w-[12.25rem] w-full'
        />
      </div>
      {renderCharts}
    </div>
  );
};
