import React, { memo, useMemo } from 'react';
//
import { DndContainer } from '@atoms/dnd';
import { TTaskInfo } from '@models/Tasks';
import { TDeleteTask } from '@shared/types';
import { useAppDispatch } from '@hooks/index';
import { ORDER_VARIANTS } from '@models/Settings';
import { SortableItem } from '@atoms/dndSortableItem';
import { setOrderThunk } from '@redux/Settings/thunk';
import { setSortedTasksList } from '@redux/Task/slice';

import { TaskCard } from '../taskCard';

interface ITaskCardListProps {
  /**
   * Массив данных для отрисовки карточек задач
   * @param {TTaskInfo[]}
   */
  data: TTaskInfo[];
  /**
   * Если true запрос на удаление обрабатывается
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Callback на удаление задачи
   * @param {TDeleteTask}
   */
  deleteTask: TDeleteTask;
  /**
   * Callback на переключение активности задачи
   * @param {TDeleteTask}
   */
  switchTask: TDeleteTask;
  /**
   * Флаг ошибки при удалении
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаления задачи
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Массив айди задач отсортированный пользователем путем перетаскивания карточек
   * @param {string[]}
   */
  sortedTasksList: string[];
}

export const TaskCardList = memo(
  ({
    data,
    deleteTask,
    switchTask,
    isDeleting,
    isDeletingError,
    isDeletingSuccess,
    sortedTasksList,
    className = '',
  }: ITaskCardListProps) => {
    const dispatch = useAppDispatch();
    // массив карточек с задачами для отображения
    const taskCardsToRender = useMemo(() => {
      if (!sortedTasksList.length) {
        return (
          data
            // .sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1))
            .map(item => (
              <SortableItem id={item.id} key={item.id}>
                <TaskCard
                  data={item}
                  switchTask={switchTask}
                  deleteTask={deleteTask}
                  isDeleting={isDeleting}
                  isDeletingError={isDeletingError}
                  isDeletingSuccess={isDeletingSuccess}
                />
              </SortableItem>
            ))
        );
      }

      return sortedTasksList.map(id => {
        const taskData = data?.find(item => item.id === id);

        if (!taskData) {
          return null;
        }

        return (
          <SortableItem id={id} key={id}>
            <TaskCard
              data={taskData}
              switchTask={switchTask}
              deleteTask={deleteTask}
              isDeleting={isDeleting}
              isDeletingError={isDeletingError}
              isDeletingSuccess={isDeletingSuccess}
            />
          </SortableItem>
        );
      });
    }, [
      data,
      switchTask,
      deleteTask,
      isDeleting,
      isDeletingError,
      sortedTasksList,
      isDeletingSuccess,
    ]);

    const onDragEndHandler = (sortedTasks: string[]) => {
      dispatch(setSortedTasksList(sortedTasks));
      dispatch(setOrderThunk({ target: ORDER_VARIANTS.TASKS, data: sortedTasks }));
    };

    return (
      <div className={`grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1.2rem] ${className}`}>
        <DndContainer items={sortedTasksList} onDragEnd={onDragEndHandler}>
          {taskCardsToRender}
        </DndContainer>
      </div>
    );
  },
);
