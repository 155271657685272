import React, { memo, useCallback, useMemo } from 'react';
//
import { H1 } from '@atoms/h1';
import { ACCESS_RIGHTS } from '@const/access';
import { useAppDispatch } from '@hooks/index';
import { TFilialData } from '@models/Accounts';
import { FilialCard } from '@blocks/filialCard';
import { TSetChosenFilialId } from '@shared/types';
import { FilialListItem } from '@blocks/filialListItem';
import { setSelectedFilial } from '@redux/Accounts/slice';

export interface IAccountsProps {
  /**
   * Массив со списком филиалов
   * @param {Array<TFilialData>}
   */
  data: Array<TFilialData>;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const AccountsTemplate = memo(({ data, className = '' }: IAccountsProps) => {
  const dispatch = useAppDispatch();
  // Записывает id выбранного филиала и тип его аккаунта в LS для последующего использования в запросах
  const setChosenFilialId: TSetChosenFilialId = useCallback(
    ({ accId, branchId, filialName, rights }) =>
      () => {
        dispatch(setSelectedFilial({ accId, branchId, filialName, rights }));
      },
    [dispatch],
  );

  // массив с пунктами меню в таблицу для широких экранов
  const renderDeskTopTableRows = useMemo(
    () =>
      data?.map((filialData, index) => {
        const key = `${filialData.accId}${index}`;
        const disableSettingsButton = !filialData.rights.includes(ACCESS_RIGHTS.SETTINGS);

        return (
          <FilialListItem
            key={key}
            filialData={filialData}
            setChosenFilialId={setChosenFilialId({
              accId: filialData.accId,
              branchId: filialData.branchId,
              filialName: filialData.name,
              rights: filialData.rights,
            })}
            disableSettingsButton={disableSettingsButton}
          />
        );
      }),
    [data, setChosenFilialId],
  );

  // массив с карточками филиалов для мобилок
  const renderMobileFilialCards = useMemo(
    () =>
      data.map((filialData, index) => {
        const key = `${filialData.accId}${index}`;
        const disableSettingsButton = !filialData.rights.includes(ACCESS_RIGHTS.SETTINGS);

        return (
          <FilialCard
            key={key}
            filialData={filialData}
            setChosenFilialId={setChosenFilialId({
              accId: filialData.accId,
              branchId: filialData.branchId,
              filialName: filialData.name,
              rights: filialData.rights,
            })}
            disableSettingsButton={disableSettingsButton}
            className='mb-4'
          />
        );
      }),
    [data, setChosenFilialId],
  );

  return (
    <div className={`px-[1rem] lg:px-[1.5rem] mb-6 ${className}`}>
      <H1 className='mb-6'>Все филиалы</H1>
      <div className='hidden lg:block'>
        <table className='w-full'>
          <thead>
            <tr className='border-b-2 border-stormGray text-left text-stormGray text-[0.875rem] h-10 w-full'>
              <th className='font-medium px-2 w-[30%]'>Филиал</th>
              <th className='font-medium px-2 w-[20%]'>Бьюти Бот</th>
              <th className='font-medium px-2 w-[20%]'>Задачи</th>
              <th className='font-medium px-2 w-[23%]'>Оплата</th>
              <th className='font-medium px-2 w-[7%]'>Настройки</th>
            </tr>
          </thead>
          <tbody>{renderDeskTopTableRows}</tbody>
        </table>
      </div>
      <div className='flex flex-col lg:hidden'>{renderMobileFilialCards}</div>
    </div>
  );
});
