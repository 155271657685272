import React, {
  forwardRef,
  memo,
  useMemo,
  useState,
  useCallback,
  useImperativeHandle,
} from 'react';
import { TSetLinkOnClose } from '@shared/types';
import { useSelected, useSlate } from 'slate-react';
import { Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { AddLinkModal } from '@blocks/addLinkModal';
import { isValidUrl } from '@components/slateEditor/image';
import { TLinkElement } from '@components/slateEditor/custom-types';

import { Button, Icon } from './components';

export const isLinkActive = (editor: Editor) => {
  // eslint-disable-next-line
  // @ts-ignore
  const [link] = Editor.nodes(editor, {
    // eslint-disable-next-line
    // @ts-ignore
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    // eslint-disable-next-line
    // @ts-ignore
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const editLink = (editor: Editor, url: string, text: string) => {
  const link: TLinkElement = {
    type: 'link',
    url,
    children: [{ text: text || url }],
  };

  const firstSelectedNode = Editor.nodes(editor, {
    // eslint-disable-next-line
    // @ts-ignore
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  }).next().value;

  if (firstSelectedNode) {
    const [, path] = firstSelectedNode;
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertNodes(editor, link, { at: path });
  }
};

const deleteLink = (editor: Editor) => {
  const firstSelectedNode = Editor.nodes(editor, {
    // eslint-disable-next-line
    // @ts-ignore
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  }).next().value;

  if (firstSelectedNode) {
    const [, path] = firstSelectedNode;
    Transforms.removeNodes(editor, { at: path });
  }
};

const wrapLink = (editor: Editor, url: string, text: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link: TLinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: text || url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: Editor, url: string, text: string) => {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
};

export const withInlines = (editor: Editor) => {
  // eslint-disable-next-line
  // @ts-ignore
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } = editor;
  // eslint-disable-next-line
  // @ts-ignore
  editor.isInline = element =>
    // eslint-disable-next-line
    // @ts-ignore
    ['link', 'button', 'badge'].includes(element.type) || isInline(element);

  // eslint-disable-next-line
  // @ts-ignore
  editor.isElementReadOnly = element => element.type === 'badge' || isElementReadOnly(element);

  // eslint-disable-next-line
  // @ts-ignore
  editor.isSelectable = element => element.type !== 'badge' && isSelectable(element);

  editor.insertText = (text: string) => {
    if (text && isValidUrl(text)) {
      wrapLink(editor, text, '');
    } else {
      insertText(text);
    }
  };

  // eslint-disable-next-line
  // @ts-ignore
  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isValidUrl(text)) {
      wrapLink(editor, text, '');
    } else {
      insertData(data);
    }
  };

  return editor;
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = memo(() => (
  <span contentEditable={false} className='text-[0px]'>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
));

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];

// eslint-disable-next-line
// @ts-ignore
export const LinkComponent = memo(({ attributes, children, element }) => {
  const selected = useSelected();

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null;
    try {
      parsedUrl = new URL(element.url);
      // eslint-disable-next-line no-empty
    } catch {}
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href;
    }
    return 'about:blank';
  }, [element.url]);

  return (
    <a
      {...attributes}
      href={safeUrl}
      target='_blank'
      rel='noreferrer'
      className={`text-link hover:text-link hover:no-underline ${
        selected ? 'shadow-[0_0_0_3px_#ddd]' : ''
      }`}>
      {children}
    </a>
  );
});

// eslint-disable-next-line
// @ts-ignore
export const EditableButtonComponent = memo(({ attributes, children }) => {
  return (
    /*
      Note that this is not a true button, but a span with button-like CSS.
      True buttons are display:inline-block, but Chrome and Safari
      have a bad bug with display:inline-block inside contenteditable:
      - https://bugs.webkit.org/show_bug.cgi?id=105898
      - https://bugs.chromium.org/p/chromium/issues/detail?id=1088403
      Worse, one cannot override the display property: https://github.com/w3c/csswg-drafts/issues/3226
      The only current workaround is to emulate the appearance of a display:inline button using CSS.
    */
    <span
      {...attributes}
      onClick={ev => ev.preventDefault()}
      // Margin is necessary to clearly show the cursor adjacent to the button
      className='bg-[#efefef] border border-[#767676] rounded-[0.125rem] text-[0.9em] py-0.5 px-[0.375rem] mx-[0.1em]'>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
});

// eslint-disable-next-line
// @ts-ignore
export const BadgeComponent = memo(({ attributes, children }) => {
  const selected = useSelected();

  return (
    <span
      {...attributes}
      contentEditable={false}
      className={`bg-green text-white rounded-[0.125rem] py-0.5 px-[0.375rem] ${
        selected ? 'shadow-[0_0_0_3px_#ddd]' : ''
      }`}
      data-playwright-selected={selected}>
      {children}
    </span>
  );
});

const addLinkToggle = forwardRef((props, ref) => {
  const editor = useSlate();
  const active = isLinkActive(editor);
  const [isShowModal, setIsShowModal] = useState(false);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    enableEdit() {
      setIsShowModal(true);
    },
  }));

  const onModalCloseHandler: TSetLinkOnClose = useCallback(
    ({ newLink, newText }) => {
      const isValidLink = isValidUrl(newLink);
      if (newLink && !isValidLink) {
        // eslint-disable-next-line
        alert('URL не является ссылкой');
        return;
      }
      if (newLink && isValidLink && !active) {
        insertLink(editor, newLink, newText);
      }
      if (newLink && isValidLink && active) {
        editLink(editor, newLink, newText);
      }
      if (!newLink && !newText) {
        deleteLink(editor);
      }
      if (!newLink) {
        unwrapLink(editor);
      }
      setIsShowModal(false);
    },
    [editor, active],
  );

  const deleteLinkHandler = useCallback(() => {
    unwrapLink(editor);
    setIsShowModal(false);
  }, [editor]);

  return (
    <>
      <Button
        reversed={false}
        title='Добавить ссылку'
        active={isLinkActive(editor)}
        className=''
        // eslint-disable-next-line
        // @ts-ignore
        onMouseDown={event => {
          event.preventDefault();
          setIsShowModal(true);
        }}>
        <Icon active={active} className={!active ? 'opacity-40 hover:opacity-100' : ''}>
          {active ? 'link_edit' : 'link'}
        </Icon>
      </Button>
      {isShowModal ? (
        <AddLinkModal
          editor={editor}
          isOpen={isShowModal}
          isInsertImageLink={false}
          deleteLink={deleteLinkHandler}
          onCloseCallback={onModalCloseHandler}
        />
      ) : null}
    </>
  );
});

export const AddLinkToggle = memo(addLinkToggle);

export const RemoveLinkButton = memo(() => {
  const editor = useSlate();

  return (
    <Button
      reversed={false}
      title='Удалить ссылку'
      active={isLinkActive(editor)}
      className=''
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}>
      <Icon className=''>link_off</Icon>
    </Button>
  );
});
