import React, { useEffect, useState } from 'react';
//
import { ButtonGroup } from '@uikit/ButtonGroup';
import { CONNECTION_TYPE, TConnectionType } from '@models/Settings';

type TSettingsConnectionTypeProps = {
  /**
   * Название выбранного подключения
   * @param {TConnectionType}
   */
  connectionType: TConnectionType;
  /**
   * Флаг скрывает/показывает кнопки выбора типа подключения
   * @param {boolean}
   */
  isShowTelephonyConnectionComponent: boolean;
  /**
   * Callback для изменения типа подключения телефонии
   * @param {(type: TConnectionType) => void}
   */
  setConnectionTypeHandler: (type: TConnectionType) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsConnectionType = ({
  connectionType,
  setConnectionTypeHandler,
  isShowTelephonyConnectionComponent,
  className = '',
}: TSettingsConnectionTypeProps) => {
  // Обработчик изменяет тип подключения
  const changeChartTypeHandler = (type: TConnectionType) => () => {
    if (type === CONNECTION_TYPE.MANUAL) {
      setConnectionTypeHandler(CONNECTION_TYPE.MANUAL);
    } else {
      setConnectionTypeHandler(CONNECTION_TYPE.YCLIENTS);
    }
  };

  // Настройка первоначального состояния группы кнопок на основе переданного типа подключения
  const [groupButtonsData, setGroupButtonsData] = useState([
    {
      id: CONNECTION_TYPE.YCLIENTS,
      isActive: connectionType === CONNECTION_TYPE.YCLIENTS,
      text: 'Через YCLIENTS',
      onClick: changeChartTypeHandler(CONNECTION_TYPE.YCLIENTS),
    },
    {
      id: CONNECTION_TYPE.MANUAL,
      isActive: connectionType === CONNECTION_TYPE.MANUAL,
      text: 'Напрямую',
      onClick: changeChartTypeHandler(CONNECTION_TYPE.MANUAL),
    },
  ]);

  // Следит за типом подключения и записывает изменения в локальный state
  useEffect(() => {
    setGroupButtonsData(prevState => {
      return prevState.map(item => {
        if (item.id === connectionType) {
          return { ...item, isActive: true };
        }
        return { ...item, isActive: false };
      });
    });
  }, [connectionType]);

  return (
    <div className={` ${isShowTelephonyConnectionComponent ? '' : ''} ${className}`}>
      <p className='font-inter font-medium text-grayText text-body_text mb-1 leading-6 tracking-[0.0275em]'>
        Подключение
      </p>
      {isShowTelephonyConnectionComponent ? (
        <div className=''>
          <ButtonGroup type='default' view='outlined' color='success' data={groupButtonsData} />
        </div>
      ) : null}
    </div>
  );
};
