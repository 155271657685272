import React, { useEffect } from 'react';
//
import { DASHBOARD_TYPES } from '@api/types';
import { MasterTable } from '@templates/masterTable';
import { getDateStringToGetData } from '@helpers/index';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getDashboardPageData } from '@redux/selectors';
import { getDashboardData, getMainDashboardData } from '@redux/Dashboard/thunks';

const MasterTablePage = () => {
  const dispatch = useAppDispatch();
  const { selectedFilial } = useAppSelector(getAccountsData);
  const selectedFilialAccId = selectedFilial?.accId || '';

  const {
    isError,
    isLoading,
    isRetries,
    isFetchingMeta,
    mastersEndDate,
    mastersStartDate,
    isFetchingMetaSuccess,
  } = useAppSelector(getDashboardPageData);

  useEffect(() => {
    if (isRetries) {
      dispatch(
        getDashboardData({
          force: true,
          type: DASHBOARD_TYPES.FINANCE,
          startDate: getDateStringToGetData(mastersStartDate),
          endDate: getDateStringToGetData(mastersEndDate),
        }),
      );
    }
  }, [dispatch, mastersStartDate, mastersEndDate, isRetries]);

  useEffect(() => {
    if (selectedFilialAccId && !isFetchingMeta && isFetchingMetaSuccess) {
      dispatch(
        getDashboardData({
          force: true,
          type: DASHBOARD_TYPES.FINANCE,
          startDate: getDateStringToGetData(mastersStartDate),
          endDate: getDateStringToGetData(mastersEndDate),
        }),
      );
    }
  }, [
    dispatch,
    isFetchingMeta,
    mastersEndDate,
    mastersStartDate,
    selectedFilialAccId,
    isFetchingMetaSuccess,
  ]);

  useEffect(() => {
    if (isRetries) {
      dispatch(
        getMainDashboardData({
          type: DASHBOARD_TYPES.MAIN,
          startDate: getDateStringToGetData(mastersStartDate),
          endDate: getDateStringToGetData(mastersEndDate),
        }),
      );
    }
  }, [dispatch, mastersStartDate, mastersEndDate, isRetries]);

  useEffect(() => {
    if (selectedFilialAccId && !isFetchingMetaSuccess) {
      dispatch(
        getMainDashboardData({
          type: DASHBOARD_TYPES.MAIN,
          startDate: '2023-05-20 10:00:00',
          endDate: '2023-05-28 10:00:00',
        }),
      );
    }
  }, [dispatch, isFetchingMetaSuccess, selectedFilialAccId]);

  return (
    <div>
      {!isLoading && !isError && !isFetchingMeta && isFetchingMetaSuccess ? (
        <MasterTable selectedFilialAccId={selectedFilialAccId} />
      ) : null}
    </div>
  );
};

export default MasterTablePage;
