import { TASK_TYPE } from '@const/task';
import { ACCESS_RIGHTS } from '@const/access';
import { TFilialData } from '@models/Accounts';
import { OPERATOR_NAMES } from '@const/settings';
import { TReportCardData } from '@models/Report';
import { TBlackListData } from '@models/BlackList';
import { TPayData, TPayResponseData } from '@models/Pay';
import { INPUT_VARIANTS, TReportInfo } from '@models/index';
import { numberOfGiftWeek, numberOfMonth } from '@const/pay';
import { initialWeekDaysData, payerEmptyData } from '@data/index';
import { TTaskCardData, TTaskData, TTaskDataResponse, TTaskInfo } from '@models/Tasks';
import {
  EAdminState,
  TSettingsData,
  TSettingStaff,
  TOperatorsData,
  TStaffListItem,
  TAdminsListItem,
  CONNECTION_TYPE,
  TStaffListResponse,
  TSettingsTelephonyData,
  TGetSettingResponseData,
  TTelephonyTablePageData,
  TGetSmartScheduleResponse,
  TGetAdminsTableDataResponse,
  TSettingsTelephonyResponseData,
} from '@models/Settings';
import {
  TFilialDataResponse,
  TGetResponseWithData,
  IGetResponseWithDataAndAccessToken,
} from '@api/types';
import {
  TDashboardData,
  TAdminsDashboardData,
  TMastersDashboardData,
  TFinancesDashboardData,
} from '@api/dashboard/types';

// Account page
export const getAccountsResponseMockData: IGetResponseWithDataAndAccessToken<
  TFilialDataResponse[]
> = {
  count: 1,
  access_token: '',
  data: [
    {
      acc_id: 'testos',
      branch_id: 367678,
      beauty_bot_integration: true,
      domain: 'borid97',
      name: 'Testos',
      pay_period: '2023-03-05',
      process: true,
      quantity_of_templates: 20,
      role: 'owner',
      permissions: {
        analytics: true,
        settings: true,
        templates: true,
        settings_admins: true,
      },
    },
    {
      acc_id: 'testos2',
      branch_id: 367678,
      beauty_bot_integration: false,
      domain: 'borid97',
      name: 'Testos2',
      pay_period: '2023-01-05',
      process: false,
      quantity_of_templates: 10,
      role: 'owner',
      permissions: {
        analytics: true,
        settings: true,
        templates: true,
        settings_admins: true,
      },
    },
  ],
  banner_data: {
    image_url: '',
    info_link: '',
  },
};

export const accountsMockData: TFilialData[] = [
  {
    process: true,
    name: 'Testos',
    accId: 'testos',
    branchId: 367678,
    payPeriod: '05-03-2023',
    quantityOfTemplates: 20,
    beautyBotIntegration: true,
    payerData: payerEmptyData,
    telephony: null,
    rights: [
      ACCESS_RIGHTS.ANALYTICS,
      ACCESS_RIGHTS.TEMPLATES,
      ACCESS_RIGHTS.SETTINGS,
      ACCESS_RIGHTS.SETTINGS_ADMINS,
    ],
    isOwner: false,
  },
  {
    process: false,
    name: 'Testos2',
    accId: 'testos2',
    branchId: 367678,
    payPeriod: '05-01-2023',
    quantityOfTemplates: 10,
    beautyBotIntegration: false,
    payerData: payerEmptyData,
    telephony: null,
    rights: [],
    isOwner: false,
  },
];

export const selectedFilialMockData: TFilialData = {
  process: true,
  name: 'Testos',
  accId: 'testos',
  branchId: 367678,
  payPeriod: '05-03-2023',
  quantityOfTemplates: 201,
  beautyBotIntegration: true,
  payerData: payerEmptyData,
  telephony: null,
  rights: [
    ACCESS_RIGHTS.ANALYTICS,
    ACCESS_RIGHTS.TEMPLATES,
    ACCESS_RIGHTS.SETTINGS,
    ACCESS_RIGHTS.SETTINGS_ADMINS,
  ],
  isOwner: false,
};

// TaskList page
export const tasksListResponseMockData: TTaskCardData[] = [
  {
    id: 489648,
    state: false,
    title: 'string',
    type: 'client_tasks',
    description: 'Каждый день в 10:40',
    mark: null,
    notes: 1,
  },
  {
    id: 535732,
    state: false,
    title: 'Не записались',
    type: 'client_tasks',
    description: 'Клиент не записан в течении 30 минут',
    mark: null,
    notes: 1,
  },
  {
    id: 156698,
    state: true,
    title: 'Ответить!!!',
    type: 'client_tasks',
    description: 'Клиенту не ответили в течении 10 минут',
    mark: null,
    notes: 1,
  },
  {
    id: 4170003,
    state: false,
    title: 'string',
    type: 'client_tasks',
    description: 'Каждый день в 10:30',
    mark: null,
    notes: 1,
  },
  {
    id: 4268922,
    state: true,
    title: 'string',
    type: 'client_tasks',
    description: 'Каждый день в 10:30',
    mark: {
      id: 4268922,
      type: 'case',
    },
    notes: 1,
  },
  {
    id: 83732,
    state: true,
    title: 'string',
    type: 'client_tasks',
    description: 'Каждый день в 10:30',
    mark: null,
    notes: 1,
  },
  {
    id: 6185405,
    state: true,
    title: 'Для потерявшихся, время постановки 12:30, Клиент не приходит 2 дней',
    type: 'client_tasks',
    description: 'Каждый день в 12:30',
    mark: null,
    notes: 1,
  },
  {
    id: 7180456,
    state: true,
    title: 'Клиенту не ответили, время постановки 12:30, Клиенту не ответили в течении 2 мин',
    type: 'client_tasks',
    description: 'Клиенту не ответили в течении 2 минут',
    mark: null,
    notes: 1,
  },
  {
    id: 7934396,
    state: true,
    title: 'Клиент не записан, время постановки 12:30, Клиент не записан в течении 2 мин',
    type: 'client_tasks',
    description: 'Клиент не записан в течении 2 минут',
    mark: null,
    notes: 1,
  },
  {
    id: 6661589,
    state: false,
    title: 'string',
    type: 'client_tasks',
    description: 'Каждый день в 10:40',
    mark: null,
    notes: 1,
  },
  {
    id: 123456,
    state: true,
    title: 'Перезвонить клиенту!',
    type: 'event_tasks',
    description: 'При пропущ. звонке',
    mark: {
      id: 114302,
      type: 'case',
    },
    notes: 1,
  },
  {
    id: 7488119,
    state: true,
    title: 'Клиента не записали, перезвонить, СРОЧНО!',
    type: 'event_tasks',
    description: 'При пропущ. звонке',
    mark: {
      id: 0,
      type: 'client',
    },
    notes: 1,
  },
  {
    id: 3529886,
    state: false,
    title: 'Перезвонить клиенту',
    type: 'event_tasks',
    description: 'При пропущ. звонке',
    mark: {
      id: 114302,
      type: 'case',
    },
    notes: 1,
  },
  {
    id: 140880,
    state: true,
    title: 'Плохой отзыв',
    type: 'event_tasks',
    description: 'При плохом отзыве',
    mark: {
      id: 114300,
      type: 'client',
    },
    notes: 1,
  },
  {
    id: 321321321,
    state: true,
    title: 'check discount',
    type: 'event_tasks',
    description: 'При ',
    mark: null,
    notes: 1,
  },
  {
    id: 456932,
    state: true,
    title: 'string',
    type: 'regular_tasks',
    description: 'Каждый месяц 12 числа в 12:30',
    mark: {
      id: 456932,
      type: 'case',
    },
    notes: 1,
  },
  {
    id: 711372,
    state: true,
    title: 'Заказать воду',
    type: 'regular_tasks',
    description: 'Каждую неделю в 3 дн. в 15:00',
    mark: {
      id: 711372,
      type: 'case',
    },
    notes: 1,
  },
  {
    id: 8593295,
    state: true,
    title: 'Позвонить поставщику',
    type: 'regular_tasks',
    description: 'Каждый день в 19:00',
    mark: {
      id: 0,
      type: 'client',
    },
    notes: 1,
  },
  {
    id: 9711638,
    state: false,
    title: 'Созвониться с Оксаной',
    type: 'regular_tasks',
    description: 'Каждый день в 10:00',
    mark: {
      id: 9711638,
      type: 'staff',
    },
    notes: 1,
  },
  {
    id: 705746,
    state: false,
    title: 'Заголовок',
    type: 'regular_tasks',
    description: 'Каждую неделю в 1 дн. в 12:00',
    mark: {
      id: 114300,
      type: 'client',
    },
    notes: 1,
  },
];

export const templateMockData: Array<TTaskInfo> = [
  {
    id: '456932',
    active: true,
    name: 'string',
    type: 'case',
    whenSend: 'Каждый месяц 12 числа в 12:30',
    link: '#',
    description: '',
    isChecklist: true,
    notes: 1,
  },
  {
    id: '711372',
    active: true,
    name: 'Заказать воду',
    type: 'case',
    whenSend: 'Каждую неделю в 3 дн. в 15:00',
    link: '#',
    description: '',
    isChecklist: true,
    notes: 1,
  },
  {
    id: '8593295',
    active: true,
    name: 'Позвонить поставщику',
    type: 'client',
    whenSend: 'Каждый день в 10:00',
    link: '#',
    description: '',
    isChecklist: true,
    notes: 1,
  },
  {
    id: '9711638',
    active: false,
    name: 'Созвониться с Оксаной',
    type: 'staff',
    whenSend: 'Каждый день в 10:00',
    link: '#',
    description: '',
    isChecklist: true,
    notes: 1,
  },
  {
    id: '705746',
    active: false,
    name: 'Заголовок',
    type: 'client',
    whenSend: 'Каждую неделю в 1 дн. в 12:00',
    link: '#',
    description: '',
    isChecklist: true,
    notes: 1,
  },
];

export const tasksMockData: Array<TTaskData> = [
  {
    id: '#866305',
    isActive: false,
    taskType: TASK_TYPE.CASE,
    whenSend: 'regular',
    sendingPeriod: 'day',
    sendingTime: '09:15',
    frequency: 1,
    sendWeekDays: initialWeekDaysData,
    monthSendDays: '',
    sendOnLastDayOfMonth: false,
    daysWithoutVisiting: '',
    selectedEventType: '',
    selectedTelephonyType: '',
    selectedYclientsType: '',
    selectedCrmType: '',
    discountAmount: '',
    clientDelayed: '',
    taskName: 'Проверить внешний вид сотрудников',
    taskDescription: 'Проверить внешний вид сотрудников на соответствие правилам',
    isAddChecklist: true,
    checklistItems: ['Верх', 'Низ', 'Цвет', 'Фасон)))'],
    selectedBBIntegrationType: '',
    selectedWorkStatisticsType: '',
    missedCallsPercents: '',
    thresholdWhatsappResponseRate: '',
    thresholdLowEnrolledPercentage: '',
    selectedClientEvent: '',
    notRecord: '',
    lateReply: '',
    daysAfterVisit: '',
    staff: [],
    visits: [],
    services: [],
    report: true,
    notes: [],
    alternativeExecutor: null,
    assignmentRule: null,
    backupRule: null,
    priorityExecutor: null,
    deadline: null,
  },
  {
    id: '#145707',
    isActive: false,
    taskType: TASK_TYPE.STAFF,
    whenSend: 'regular',
    sendingPeriod: 'day',
    sendingTime: '08:45',
    frequency: 1,
    sendWeekDays: initialWeekDaysData,
    monthSendDays: '',
    sendOnLastDayOfMonth: false,
    daysWithoutVisiting: '',
    selectedEventType: '',
    selectedTelephonyType: '',
    selectedYclientsType: '',
    selectedCrmType: '',
    discountAmount: '',
    clientDelayed: '',
    taskName: 'Проверить качество уборки по чек-листу',
    taskDescription: 'Проверить качество уборки помещений.',
    isAddChecklist: true,
    checklistItems: ['Комната1', 'Комната2', 'Комната3', 'Комната4'],
    selectedBBIntegrationType: '',
    selectedWorkStatisticsType: '',
    missedCallsPercents: '',
    thresholdWhatsappResponseRate: '',
    thresholdLowEnrolledPercentage: '',
    selectedClientEvent: '',
    notRecord: '',
    lateReply: '',
    daysAfterVisit: '',
    staff: [],
    visits: [],
    services: [],
    report: true,
    notes: [],
    alternativeExecutor: null,
    assignmentRule: null,
    backupRule: null,
    priorityExecutor: null,
    deadline: null,
  },
  {
    id: '#611816',
    isActive: false,
    taskType: TASK_TYPE.CLIENT,
    whenSend: 'lost',
    sendingPeriod: '',
    sendingTime: '',
    frequency: 0,
    sendWeekDays: initialWeekDaysData,
    monthSendDays: '',
    sendOnLastDayOfMonth: false,
    daysWithoutVisiting: '22',
    selectedEventType: '',
    selectedTelephonyType: '',
    selectedYclientsType: '',
    selectedCrmType: '',
    discountAmount: '',
    clientDelayed: '',
    taskName: 'Позвонить клиенту, предложить записаться на услуги',
    taskDescription: 'тел: 78888888888',
    isAddChecklist: false,
    checklistItems: [],
    selectedBBIntegrationType: '',
    selectedWorkStatisticsType: '',
    missedCallsPercents: '',
    thresholdWhatsappResponseRate: '',
    thresholdLowEnrolledPercentage: '',
    selectedClientEvent: '',
    notRecord: '',
    lateReply: '',
    daysAfterVisit: '',
    staff: [],
    visits: [],
    services: [],
    report: false,
    notes: [],
    alternativeExecutor: null,
    assignmentRule: null,
    backupRule: null,
    priorityExecutor: null,
    deadline: null,
  },
  {
    id: '#611818',
    isActive: false,
    taskType: TASK_TYPE.CLIENT,
    whenSend: 'event',
    sendingPeriod: '',
    sendingTime: '',
    frequency: 0,
    sendWeekDays: initialWeekDaysData,
    monthSendDays: '',
    sendOnLastDayOfMonth: false,
    daysWithoutVisiting: '',
    selectedEventType: 'telephony',
    selectedTelephonyType: 'notRecordedCall',
    selectedYclientsType: '',
    selectedCrmType: '',
    discountAmount: '',
    clientDelayed: '',
    taskName: 'Клиента не записали, перезвонить',
    taskDescription: 'тел: 75555555555',
    isAddChecklist: false,
    checklistItems: [],
    selectedBBIntegrationType: '',
    selectedWorkStatisticsType: '',
    missedCallsPercents: '',
    thresholdWhatsappResponseRate: '',
    thresholdLowEnrolledPercentage: '',
    selectedClientEvent: '',
    notRecord: '',
    lateReply: '',
    daysAfterVisit: '',
    staff: [],
    visits: [],
    services: [],
    report: false,
    notes: [],
    alternativeExecutor: null,
    assignmentRule: null,
    backupRule: null,
    priorityExecutor: null,
    deadline: null,
  },
];

export const tackCardMockData: TTaskInfo = {
  id: '711372',
  active: true,
  type: 'case',
  name: 'Заказать воду',
  whenSend: 'Каждую неделю в 3 дн. в 15:00',
  description: 'Описание задачи',
  link: '#',
  isChecklist: true,
  notes: 1,
};

// Task page
export const saveTaskResponseMockData: TTaskDataResponse = {
  id: 489648,
  active: false,
  title: 'string',
  body: 'Test',
  time: '10:40',
  mark: null,
  checklist: null,
  frequency: null,
  week: null,
  month: null,
  event: null,
  last_visits: 30,
  days_after_record: null,
  not_record: null,
  late_reply: null,
  services: null,
  staff: null,
  visits: null,
  proof_of_work: false,
  notes: [],
  alternative_executor: null,
  assignment_rule: null,
  backup_rule: null,
  priority_executor: null,
  deadline: null,
};
export const staffDropdownList = {
  123: 'Иванов',
  321: 'Иванов2',
  456: 'Сидоров',
  789: 'Петров',
};

export const servicesList = {
  123: 'Стрижа',
  321: 'Укладка',
  456: 'Окрашивание',
  789: 'Мелирование',
};

// ReportList page
export const reportsListMockData: TReportInfo[] = [
  {
    id: '1',
    name: 'Выручка за прошлую неделю',
    active: true,
    whenSend: 'Каждый день в 21:30',
    description:
      'ВЫРУЧКА за неделю **Обращения за день в Whatsapp**\n {pct_clients}\n 🥈Маргарита Т., 43%\n 🥉Алина Ф., 40%\n Азиза К., 35%\n Виктория М., 35%\n Диана Э., 35%\n Жанна Ж., 29%',
    link: '',
  },
  {
    id: '2',
    name: 'Выручка за день',
    active: true,
    whenSend: 'Каждый день в 21:30',
    description:
      '**Обращения за день в Whatsapp**\n {pct_clients}\n 🥈Маргарита Т., 43%\n 🥉Алина Ф., 40%\n Азиза К., 35%\n Виктория М., 35%\n Диана Э., 35%\n Жанна Ж., 29%',
    link: '',
  },
  {
    id: '3',
    name: 'Возвращаемость новых клиентов по мастерам',
    active: true,
    whenSend: 'Каждый день в 21:30',
    description:
      '**Возв ращаемость новых клиентов по мастерам:**\n {pct_clients}\n 🥈Маргарита Т., 43%\n 🥉Алина Ф., 40%\n Азиза К., 35%\n Виктория М., 35%\n Диана Э., 35%\n Жанна Ж., 29%',
    link: '',
  },
  {
    id: '4',
    name: 'Рейтинги матеров',
    active: true,
    whenSend: 'На следующий день в 8:30',
    description:
      'ВЫРУЧКА за неделю **Обращения за день в Whatsapp** \n {pct_clients}\n 🥈Маргарита Т., 43%\n 🥉Алина Ф., 40%\n Азиза К., 35%\n Виктория М., 35%\n Диана Э., 35%\n Жанна Ж., 29%',
    link: '',
  },
  {
    id: '5',
    name: 'Выручка за прошлую неделю',
    active: true,
    whenSend: 'Каждый день в 21:30',
    description:
      '**Обращения за день в Whatsapp**\n {pct_clients}\n 🥈Маргарита Т., 43%\n 🥉Алина Ф., 40%\n Азиза К., 35%\n Виктория М., 35%\n Диана Э., 35%\n Жанна Ж., 29%',
    link: '',
  },
  {
    id: '5',
    name: 'Аналитика по Вотсап',
    active: true,
    whenSend: 'Каждый день в 21:30',
    description: 'Каждую неделю, в ПНД, в 9:00',
    link: '',
  },
];

export const reportListResponseMockData: TGetResponseWithData<TReportCardData[]> = {
  data: [
    {
      id: 129548,
      state: false,
      title: '#129548',
      body: '\n🏬 *{filial_name}*\n\n💰 *Финансы* 💰\n\n💳 *{all_amount}₽*\n\n{amount_service_rate}\n\n🙎‍♀️ *Админы* 🙎‍♀️‍\n\nЗа',
      time: 'За сегодня в 22:00',
    },
  ],
  order: [],
};

// BlackList page
export const blackListMockData: Array<TBlackListData> = [
  { number: '79194110882' },
  { number: '79118418486' },
  { number: '79647720161' },
  { number: '79269140650' },
  { number: '9671172961' },
  { number: '9645124352' },
  { number: '9153116134' },
  { number: '9264384068' },
  { number: '79060886106' },
  { number: '79031072692' },
  { number: '79684217551' },
  { number: '79171549936' },
  { number: '79991845976' },
  { number: '9250017312' },
  { number: '79250017312' },
  { number: '79153116134' },
  { number: '79055124114' },
  { number: '79164773762' },
  { number: '79154958589' },
  { number: '79858252929' },
  { number: '79853543232' },
  { number: '79899977789' },
  { number: '79262570547' },
  { number: '79064545144' },
  { number: '79222221101' },
  { number: '79586373132' },
  { number: '79167926542' },
  { number: '79670926878' },
  { number: '79162150645' },
  { number: '79152683880' },
  { number: '79169349268' },
  { number: '79031601336' },
  { number: '79689645802' },
  { number: '79603114297' },
  { number: '79873063301' },
  { number: '79054241595' },
  { number: '79283292333' },
  { number: '79967870312' },
  { number: '79851998019' },
  { number: '79057665566' },
  { number: '79265615796' },
  { number: '79776138610' },
  { number: '79251995757' },
  { number: '79262070289' },
  { number: '79140899575' },
  { number: '9999746031' },
  { number: '79175669661' },
  { number: '79251585748' },
  { number: '9250020288' },
  { number: '79653226915' },
  { number: '79853588789' },
  { number: '79775221671' },
  { number: '79688383383' },
  { number: '79999746031' },
  { number: '79250020288' },
  { number: '79680304911' },
  { number: '79251957363' },
  { number: '79150916323' },
  { number: '79259104495' },
  { number: '79153667789' },
  { number: '79688956677' },
  { number: '79586364700' },
  { number: '79265584219' },
  { number: '79858652128' },
  { number: '79175288828' },
  { number: '79017978891' },
  { number: '79263043140' },
  { number: '79166203313' },
  { number: '79164937576' },
];

// Settings page
export const settingsPadeMockData: TSettingsData = {
  staffList: {
    123: 'Иванов',
    456: 'Сидоров',
    789: 'Петров',
  },
  employeesToIgnore: [],
  selectedStaff: '',
  missedCallAnswer: false,
  customerChurnTime: '90',
  churnTimeAllowUpdate: '2023-11-29T18:45:25.894966',
  callStats: false,
  paramsCalcStats: {
    endRate: 0,
    startRate: 0,
  },
  useGroups: false,
};

export const settingsResponseMockData: TGetSettingResponseData = {
  call_stats: false,
  missed_call_answer: true,
  churn: {
    churn_days: 1,
    update_allowed_at: '2024-02-20T16:23:19.285000',
  },
  params_calc_stats: {
    start_rate: 1,
    end_rate: 2,
  },
  exception_stats_master: ['1083512'],
  use_groups: false,
};

export const settingsStaffListMockData: TStaffListItem[] = [
  {
    id: 7699257,
    name: 'KPI Аналитика',
  },
  {
    id: 12245725,
    name: 'Админ Бот',
  },
  {
    id: 1859312,
    name: 'Азиза К.',
  },
  {
    id: 12149565,
    name: 'Алина',
  },
  {
    id: 2709727,
    name: 'Алина А.',
  },
  {
    id: 2200160,
    name: 'Алина Администратор',
  },
  {
    id: 2393168,
    name: 'Алина О.',
  },
  {
    id: 2262941,
    name: 'Алина Ф.',
  },
  {
    id: 2610710,
    name: 'Анастасия К. Администратор',
  },
  {
    id: 12115088,
    name: 'Анастасия М. Администратор',
  },
  {
    id: 2123433,
    name: 'Анастасия М. Администратор',
  },
  {
    id: 2673146,
    name: 'Анастасия ММ.',
  },
  {
    id: 2089731,
    name: 'Анна Б.',
  },
  {
    id: 1227537,
    name: 'Ася',
  },
  {
    id: 241613,
    name: 'Бьюти Бот',
  },
  {
    id: 2625530,
    name: 'Валерия Х.',
  },
  {
    id: 2819671,
    name: 'Дарья М.',
  },
  {
    id: 2913034,
    name: 'Диана Э.',
  },
  {
    id: 11935820,
    name: 'Екатерина',
  },
  {
    id: 2794185,
    name: 'Елена К.',
  },
  {
    id: 1475552,
    name: 'Жанна Ж.',
  },
  {
    id: 2201265,
    name: 'Кристина К.',
  },
  {
    id: 2137417,
    name: 'ЛИСТ ОЖИДАНИЯ',
  },
  {
    id: 1992017,
    name: 'Лилия А.',
  },
  {
    id: 176988,
    name: 'Люба Л. (Топ - мастер)',
  },
  {
    id: 345908,
    name: 'Надя С. (Топ - мастер)',
  },
  {
    id: 11877304,
    name: 'Настя',
  },
  {
    id: 2574600,
    name: 'Наталья',
  },
  {
    id: 2687892,
    name: 'Наталья З.',
  },
  {
    id: 1070670,
    name: 'Ника',
  },
  {
    id: 1560240,
    name: 'Нуриза К.',
  },
  {
    id: 2825027,
    name: 'Ольга Т.',
  },
  {
    id: 2623119,
    name: 'Татьяна К.',
  },
  {
    id: 1650205,
    name: 'Татьяна У. (Топ - мастер)',
  },
  {
    id: 12556419,
    name: 'Тест админ 228',
  },
];

export const newSettingsStaffListMockData: TStaffListResponse = {
  data: {
    admins: [
      {
        id: 12364715,
        name: 'Andrey',
      },
      {
        id: 12237310,
        name: 'Ilya',
      },
      {
        id: 12245725,
        name: 'Админ Бот',
      },
      {
        id: 241613,
        name: 'Бьюти Бот',
      },
      {
        id: 12556419,
        name: 'Дмитрий',
      },
      {
        id: 12542597,
        name: 'Никита',
      },
      {
        id: 12547195,
        name: 'Никита АБ',
      },
      {
        id: 12237314,
        name: 'Тест админ бота',
      },
    ],
    masters: [
      {
        id: 1083512,
        name: 'Сотрудник 1',
      },
      {
        id: 2376859,
        name: 'АдминБот',
      },
    ],
  },
};

export const adminsTableData: TAdminsListItem[] = [
  {
    id: 7699257,
    name: 'KPI.bi',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.CLOSE,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_7699257',
  },
  {
    id: 12245725,
    name: 'Админ Бот',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12245725',
  },
  {
    id: 12149565,
    name: 'Алина',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12149565',
  },
  {
    id: 12389144,
    name: 'Анастасия',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12389144',
  },
  {
    id: 12115088,
    name: 'Анастасия М. Администратор',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12115088',
  },
  {
    id: 241613,
    name: 'Бьюти Бот',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_241613',
  },
  {
    id: 12391087,
    name: 'Владислава',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12391087',
  },
  {
    id: 11935820,
    name: 'Екатерина',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_11935820',
  },
  {
    id: 11877304,
    name: 'Настя',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_11877304',
  },
  {
    id: 2574600,
    name: 'Наталья',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_2574600',
  },
  {
    id: 1070670,
    name: 'Ника',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.OPEN,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_1070670',
  },
  {
    id: 12237314,
    name: 'Тест админ бота',
    adminRights: [ACCESS_RIGHTS.ANALYTICS, ACCESS_RIGHTS.TEMPLATES, ACCESS_RIGHTS.SETTINGS],
    state: EAdminState.NOT_ACTIVATED,
    adminLink: 'https://t.me/salon_adminbot?start_crm=86774_12237314',
  },
];

export const adminsTableResponseData: TGetAdminsTableDataResponse[] = [
  {
    id: 12149565,
    name: 'Алина',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_12149565',
    state: null,
    permissions: {
      settings: false,
      analytics: false,
      templates: false,
      settings_admins: false,
    },
  },
  {
    id: 12115088,
    name: 'Анастасия М. Администратор',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_12115088',
    state: null,
    permissions: {
      settings: false,
      analytics: false,
      templates: false,
      settings_admins: false,
    },
  },
  {
    id: 241613,
    name: 'Бьюти Бот',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_241613',
    state: false,
    permissions: {
      settings: true,
      analytics: true,
      templates: true,
      settings_admins: false,
    },
  },
  {
    id: 11935820,
    name: 'Екатерина',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_11935820',
    state: null,
    permissions: {
      settings: true,
      analytics: true,
      templates: true,
      settings_admins: false,
    },
  },
  {
    id: 11877304,
    name: 'Настя',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_11877304',
    state: null,
    permissions: {
      settings: false,
      analytics: false,
      templates: false,
      settings_admins: false,
    },
  },
  {
    id: 2574600,
    name: 'Наталья',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_2574600',
    state: null,
    permissions: {
      settings: true,
      analytics: true,
      templates: true,
      settings_admins: false,
    },
  },
  {
    id: 1070670,
    name: 'Ника',
    role: 'owner',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_1070670',
    state: null,
    permissions: {
      settings: true,
      analytics: true,
      templates: true,
      settings_admins: false,
    },
  },
  {
    id: 12556419,
    name: 'Тест админ 228',
    role: 'worker',
    admin_link: 'https://t.me/salon_adminbot?start=crm_86774_12556419',
    state: true,
    permissions: {
      settings: true,
      analytics: true,
      templates: true,
      settings_admins: true,
    },
  },
];

export const staffsTableData: TSettingStaff[] = [
  {
    id: 1859312,
    name: 'Азиза К.',
    status: 1,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1859312',
  },
  {
    id: 2200160,
    name: 'Алина Администратор',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2200160',
  },
  {
    id: 2393168,
    name: 'Алина О.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2393168',
  },
  {
    id: 2262941,
    name: 'Алина Ф.',
    status: 1,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2262941',
  },
  {
    id: 2610710,
    name: 'Анастасия К. Администратор',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2610710',
  },
  {
    id: 2123433,
    name: 'Анастасия М. Администратор',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2123433',
  },
  {
    id: 2673146,
    name: 'Анастасия ММ.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2673146',
  },
  {
    id: 2089731,
    name: 'Анна Б.',
    status: 1,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2089731',
  },
  {
    id: 2625530,
    name: 'Валерия Х.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2625530',
  },
  {
    id: 2226331,
    name: 'Гуля Б.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2226331',
  },
  {
    id: 1206024,
    name: 'Диана Э.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1206024',
  },
  {
    id: 1475552,
    name: 'Жанна Ж.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1475552',
  },
  {
    id: 2360194,
    name: 'Камилла Х.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2360194',
  },
  {
    id: 2201265,
    name: 'Кристина К.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2201265',
  },
  {
    id: 2137417,
    name: 'ЛИСТ ОЖИДАНИЯ',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2137417',
  },
  {
    id: 1992017,
    name: 'Лилия А.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1992017',
  },
  {
    id: 176988,
    name: 'Люба Л.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_176988',
  },
  {
    id: 345908,
    name: 'Надя С.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_345908',
  },
  {
    id: 1560240,
    name: 'Нуриза К.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1560240',
  },
  {
    id: 2623119,
    name: 'Татьяна К.',
    status: 1,
    staffLink: 'https://t.me/salon_adminbot?start=86774_2623119',
  },
  {
    id: 1650205,
    name: 'Татьяна У.',
    status: 0,
    staffLink: 'https://t.me/salon_adminbot?start=86774_1650205',
  },
];

export const staffTableResponseData: TGetSmartScheduleResponse[] = [
  {
    id: 1859312,
    name: 'Азиза К.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1859312',
    status: 1,
  },
  {
    id: 2709727,
    name: 'Алина А.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2709727',
    status: 0,
  },
  {
    id: 2200160,
    name: 'Алина Администратор',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2200160',
    status: 0,
  },
  {
    id: 2393168,
    name: 'Алина О.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2393168',
    status: 0,
  },
  {
    id: 2262941,
    name: 'Алина Ф.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2262941',
    status: 1,
  },
  {
    id: 2610710,
    name: 'Анастасия К. Администратор',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2610710',
    status: 0,
  },
  {
    id: 2123433,
    name: 'Анастасия М. Администратор',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2123433',
    status: 0,
  },
  {
    id: 2673146,
    name: 'Анастасия ММ.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2673146',
    status: 0,
  },
  {
    id: 2089731,
    name: 'Анна Б.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2089731',
    status: 1,
  },
  {
    id: 1227537,
    name: 'Ася',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1227537',
    status: 0,
  },
  {
    id: 2625530,
    name: 'Валерия Х.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2625530',
    status: 0,
  },
  {
    id: 2819671,
    name: 'Дарья М.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2819671',
    status: 0,
  },
  {
    id: 2913034,
    name: 'Диана Э.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2913034',
    status: 0,
  },
  {
    id: 2794185,
    name: 'Елена К.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2794185',
    status: 0,
  },
  {
    id: 1475552,
    name: 'Жанна Ж.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1475552',
    status: 0,
  },
  {
    id: 2201265,
    name: 'Кристина К.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2201265',
    status: 0,
  },
  {
    id: 2137417,
    name: 'ЛИСТ ОЖИДАНИЯ',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2137417',
    status: 0,
  },
  {
    id: 1992017,
    name: 'Лилия А.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1992017',
    status: 1,
  },
  {
    id: 176988,
    name: 'Люба Л. (Топ - мастер)',
    staff_link: 'https://t.me/salon_adminbot?start=86774_176988',
    status: 0,
  },
  {
    id: 345908,
    name: 'Надя С. (Топ - мастер)',
    staff_link: 'https://t.me/salon_adminbot?start=86774_345908',
    status: 0,
  },
  {
    id: 2687892,
    name: 'Наталья З.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2687892',
    status: 0,
  },
  {
    id: 1560240,
    name: 'Нуриза К.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1560240',
    status: 0,
  },
  {
    id: 2825027,
    name: 'Ольга Т.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2825027',
    status: 0,
  },
  {
    id: 2623119,
    name: 'Татьяна К.',
    staff_link: 'https://t.me/salon_adminbot?start=86774_2623119',
    status: 0,
  },
  {
    id: 1650205,
    name: 'Татьяна У. (Топ - мастер)',
    staff_link: 'https://t.me/salon_adminbot?start=86774_1650205',
    status: 0,
  },
];

// Pay page
export const payMockData: TPayData = {
  // rate: 'Testos, филиал № 86774\n 👩 10 сотрудников\n 💳 2390 руб. в месяц',
  branchEmployees: '10',
  discount: 0,
  branch: '86774',
  amount: '2390',
  isAutoPayEnable: false,
  cardLastNum: '4444',
  payStatus: true,
  payTillDate: '02.12.2025',
  numberOfMonth,
  numberOfGiftWeek,
  numberOfPriceWithBonus: [1791, 1791, 1791, 1791],
  paymentData: [
    {
      date: '24.12.2020',
      period: '26.01.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '25.01.2021',
      period: '26.02.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '25.03.2021',
      period: '26.04.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '26.04.2021',
      period: '26.05.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '26.05.2021',
      period: '26.06.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '26.06.2021',
      period: '26.07.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '25.07.2021',
      period: '26.08.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '29.08.2021',
      period: '29.09.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '30.09.2021',
      period: '30.10.2021',
      sum: 1890,
      type: 'card',
    },
    {
      date: '02.11.2021',
      period: '02.12.2021',
      sum: 1890,
      type: 'card',
    },
  ],
};

export const payPageMockData: TPayData = {
  amount: '100',
  isAutoPayEnable: false,
  discount: 0,
  branch: '123',
  branchEmployees: '1',
  cardLastNum: '4444',
  payTillDate: '01-02-2023',
  payStatus: true,
  numberOfMonth,
  numberOfGiftWeek,
  numberOfPriceWithBonus: [1791, 1791, 1791, 1791],
  paymentData: [
    {
      date: '2023-01-11T10:39:05.680000',
      period: '2023-02-11',
      sum: 1490,
      type: 'card',
    },
  ],
};

export const getPayPageResponseMockData: TPayResponseData = {
  branch_name: 'Testos',
  discount: 0,
  branch: '367678',
  employees: 2,
  bonuses: {
    m3bonus: 1,
    m6bonus: 3,
    m12bonus: 8,
  },
  base_price_with_bonus: {
    m3bonus: 1553,
    m6bonus: 1456,
    m12bonus: 1371,
  },
  payments: [
    {
      payment_date: '2023-01-11T10:39:05.680000',
      paid_till: '2023-02-11',
      amount: 1490,
    },
  ],
  base_price: 1400,
  pay_date: '2023-03-05',
  process: true,
};

const telephonyData = {
  data: [
    {
      date: '08.08.2023 06:20:01',
      type: 'Входящий',
      phone: '79274725571',
      name: 'Рустам',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 06:26:45',
      type: 'Входящий',
      phone: '79377778105',
      name: 'Денис',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 06:28:38',
      type: 'Входящий',
      phone: '79172412725',
      name: 'Данил',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 06:29:06',
      type: 'Входящий',
      phone: '79172412725',
      name: 'Данил',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 06:29:52',
      type: 'Входящий',
      phone: '79274725571',
      name: 'Рустам',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 06:56:58',
      type: 'Входящий',
      phone: '79963360439',
      name: 'Андрей',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/923b8689-36ea-4ebb-8bc3-c4e638f7e394/79506671167_in_2023_08_08-09_56_59_79963360439_yblc.mp3',
      record: null,
    },
    {
      date: '08.08.2023 06:59:39',
      type: 'Входящий',
      phone: '79372838333',
      name: 'Айрат',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/904ae94c-d623-4c54-bdf5-fb6b4fe81103/79506671167_in_2023_08_08-09_59_39_79372838333_ujlv.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:05:18',
      type: 'Входящий',
      phone: '79871862546',
      name: 'Марат',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/75249819-2930-47ac-8338-a02d62dbe512/79506671167_in_2023_08_08-10_05_18_79871862546_gf37.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:06:36',
      type: 'Входящий',
      phone: '79274933666',
      name: 'Михаил',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 07:22:53',
      type: 'Входящий',
      phone: '79033920782',
      name: 'Василий',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/08966e3d-e6fc-473f-93ec-deafd3f14f72/79506671167_in_2023_08_08-10_22_54_79033920782_6hot.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:28:09',
      type: 'Входящий',
      phone: '79656225200',
      name: 'Айрат',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/c00c85ca-19f1-4781-b03b-6ad3b2ee925d/79506671167_in_2023_08_08-10_28_09_79656225200_2ese.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:29:32',
      type: 'Входящий',
      phone: '79651780836',
      name: 'Неизвестно',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 07:30:40',
      type: 'Входящий',
      phone: '79274725571',
      name: 'Рустам',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/43c02061-07bf-44dd-bc1d-8990244780bd/79506671167_in_2023_08_08-10_30_40_79274725571_wxpg.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:39:57',
      type: 'Входящий',
      phone: '79953652778',
      name: 'Неизвестно',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/756c89e9-2e0a-4563-9fdf-1d468b385863/79506671167_in_2023_08_08-10_39_57_79953652778_jhhm.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:40:45',
      type: 'Входящий',
      phone: '79969045078',
      name: 'Олег',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/194caf6b-6639-4c18-ac96-977447627786/79506671167_in_2023_08_08-10_40_46_79969045078_wipy.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:47:13',
      type: 'Входящий',
      phone: '79274725571',
      name: 'Рустам',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/bec0690d-4d94-4fc3-94f2-38441f7092be/79506671167_in_2023_08_08-10_47_13_79274725571_ebzm.mp3',
      record: null,
    },
    {
      date: '08.08.2023 07:55:03',
      type: 'Входящий',
      phone: '79582959192',
      name: 'Неизвестно',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 08:15:30',
      type: 'Входящий',
      phone: '79178565621',
      name: 'Ринат',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 08:16:11',
      type: 'Входящий',
      phone: '79178565621',
      name: 'Ринат',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/ceca6f49-92d8-45f2-8205-3c4b29853a66/79506671167_in_2023_08_08-11_16_11_79178565621_tmbm.mp3',
      record: null,
    },
    {
      date: '08.08.2023 08:52:26',
      type: 'Входящий',
      phone: '79582959192',
      name: 'Неизвестно',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 09:30:50',
      type: 'Входящий',
      phone: '79173942356',
      name: 'Валерий',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 09:32:07',
      type: 'Входящий',
      phone: '79270395555',
      name: 'Ромик',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 09:33:16',
      type: 'Входящий',
      phone: '79173942356',
      name: 'Валерий',
      status: 'Пропущен',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 09:42:52',
      type: 'Исходящий',
      phone: '79270395555',
      name: 'Ромик',
      status: 'Нет ответа',
      link: null,
      record: null,
    },
    {
      date: '08.08.2023 09:43:18',
      type: 'Исходящий',
      phone: '79173942356',
      name: 'Валерий',
      status: 'Состоялся',
      link: 'https://vats495853.megapbx.ru/api/v2/call-records/record/2023-08-08/29605e49-9254-4a89-9124-b5fd51635ddf/tip_top_out_2023_08_08-12_43_35_79173942356_ih5u.mp3',
      record: null,
    },
  ],
};

export const telephonyTablePageEmptyData: TTelephonyTablePageData = {
  paginator: {
    currentPage: 0,
    totalPages: 1,
    count: 0,
    size: 25,
  },
  data: [],
};

export const telephonyTablePageMockData: TTelephonyTablePageData = {
  paginator: {
    currentPage: 0,
    totalPages: 1,
    count: 392,
    size: 25,
  },
  data: telephonyData.data,
};

export const finance: TFinancesDashboardData = {
  all_amount: [
    {
      date: '2023-06-14',
      value: 198060,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-15',
      value: 167600,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-16',
      value: 209830,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-17',
      value: 140620,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-18',
      value: 128550,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-19',
      value: 27052,
      filters: {
        service_category: null,
        masters: null,
      },
    },
    {
      date: '2023-06-20',
      value: 126300,
      filters: {
        service_category: null,
        masters: [
          {
            'Лилия А.': 28700,
          },
          {
            'Анна Б.': 17200,
          },
          {
            'Кристина К.': 13100,
          },
          {
            'Азиза К.': 12000,
          },
          {
            'Валентина  М.': 11600,
          },
          {
            'Диана Э.': 11000,
          },
          {
            'Надя С.': 10700,
          },
          {
            'Жанна Ж.': 9200,
          },
          {
            'Нуриза К.': 6600,
          },
          {
            'Гуля Б.': 6200,
          },
        ],
      },
    },
    {
      date: '2023-06-21',
      value: 40450,
      filters: {
        service_category: [
          {
            'МОМЕНТАЛЬНЫЙ ЗАГАР': 16450,
          },
          {
            ПЕДИКЮР: 13900,
          },
          {
            МАНИКЮР: 6000,
          },
          {
            'БРОВИ|РЕСНИЦЫ': 1400,
          },
          {
            'СНЯТИЕ|ПОКРЫТИЕ|ПОЛИРОВКА': 1200,
          },
          {
            'РЕМОНТ|УКРЕПЛЕНИЕ': 1000,
          },
          {
            ДРУГОЕ: 500,
          },
        ],
        masters: [
          {
            'Анна Б.': 14450,
          },
          {
            'Алина О.': 7200,
          },
          {
            'Камилла Х.': 5400,
          },
          {
            'Надя С.': 5000,
          },
          {
            'Лилия А.': 3400,
          },
          {
            'Гуля Б.': 3000,
          },
          {
            'Татьяна У.': 2000,
          },
        ],
      },
    },
    {
      date: '2023-06-22',
      value: 95830,
      filters: {
        service_category: [
          {
            МАНИКЮР: 37100,
          },
          {
            ПЕДИКЮР: 30300,
          },
          {
            'МОМЕНТАЛЬНЫЙ ЗАГАР': 14000,
          },
          {
            'СНЯТИЕ|ПОКРЫТИЕ|ПОЛИРОВКА': 7300,
          },
          {
            'БРОВИ|РЕСНИЦЫ': 4300,
          },
          {
            'РЕМОНТ|УКРЕПЛЕНИЕ': 1100,
          },
          {
            ДРУГОЕ: 1030,
          },
          {
            ДИЗАЙН: 700,
          },
        ],
        masters: [
          {
            'Анна Б.': 18300,
          },
          {
            'Алина О.': 17500,
          },
          {
            'Гуля Б.': 14400,
          },
          {
            'Камилла Х.': 13110,
          },
          {
            'Татьяна У.': 12900,
          },
          {
            'Алина Ф.': 9920,
          },
          {
            'Люба Л.': 9700,
          },
        ],
      },
    },
    {
      date: '2023-06-23',
      value: 110700,
      filters: {
        service_category: [
          {
            МАНИКЮР: 37258,
          },
          {
            'МОМЕНТАЛЬНЫЙ ЗАГАР': 33000,
          },
          {
            ПЕДИКЮР: 21898,
          },
          {
            'СНЯТИЕ|ПОКРЫТИЕ|ПОЛИРОВКА': 7829,
          },
          {
            'БРОВИ|РЕСНИЦЫ': 5900,
          },
          {
            ДИЗАЙН: 2800,
          },
          {
            ДРУГОЕ: 1314,
          },
          {
            'РЕМОНТ|УКРЕПЛЕНИЕ': 700,
          },
        ],
        masters: [
          {
            'Лилия А.': 38900,
          },
          {
            'Алина О.': 17200,
          },
          {
            'Кристина К.': 15500,
          },
          {
            'Надя С.': 14000,
          },
          {
            'Алина Ф.': 13587.1,
          },
          {
            'Люба Л.': 11512.9,
          },
        ],
      },
    },
    {
      date: '2023-06-24',
      value: 19700,
      filters: {
        service_category: [
          {
            'МОМЕНТАЛЬНЫЙ ЗАГАР': 9800,
          },
          {
            МАНИКЮР: 5700,
          },
          {
            ПЕДИКЮР: 3200,
          },
          {
            'СНЯТИЕ|ПОКРЫТИЕ|ПОЛИРОВКА': 1000,
          },
        ],
        masters: [
          {
            'Лилия А.': 9800,
          },
          {
            'Жанна Ж.': 3700,
          },
          {
            'Алина Ф.': 2500,
          },
          {
            'Люба Л.': 2500,
          },
          {
            'Азиза К.': 1200,
          },
        ],
      },
    },
  ],
  amount_service_rate: [
    {
      'Экспресс моментальный загар': 222950,
    },
    {
      'Снятие+маникюр+выравнивание+гель лак': 219258.06,
    },
    {
      'Педикюр+гель лак': 112300,
    },
    {
      'Снятие+педикюр+гель лак': 106214.61,
    },
    {
      'Снятие+маникюр+выравнивание LUXIO+гель лак LUXIO': 72500,
    },
    {
      'Маникюр+гель лак (входит выравнивание)': 53800,
    },
    {
      'Коррекция бровей + окрашивание': 46800,
    },
    {
      МАНИКЮР: 41076.92,
    },
    {
      Пудрирование: 37950,
    },
    {
      'Гель лак LUXIO': 32314.52,
    },
  ],
  op_records: [
    {
      date: '2023-06-15',
      value: 9,
    },
    {
      date: '2023-06-16',
      value: 32,
    },
    {
      date: '2023-06-17',
      value: 30,
    },
    {
      date: '2023-06-18',
      value: 23,
    },
    {
      date: '2023-06-19',
      value: 44,
    },
  ],
  earned_by_out_calls: new Array(10).fill(null).map((_, idx) => ({
    date: `2023-04-${(idx + 3).toString().padStart(2, '0')}`,
    value: 1000 + idx * 100,
    filters: {
      service_category: [
        { title: `Category ${idx + 1}`, amount: 500 + idx * 50 },
        { title: `Category ${idx + 2}`, amount: 550 + idx * 50 },
      ],
      masters: [
        {
          name: `Master ${idx + 1}`,
          total: 500 + idx * 100,
          services: [
            { id: idx * 4 + 1, title: `Service ${idx * 4 + 1}`, cost: 100 + idx * 10 },
            { id: idx * 4 + 2, title: `Service ${idx * 4 + 2}`, cost: 150 + idx * 10 },
          ],
        },
        {
          name: `Master ${idx + 2}`,
          total: 600 + idx * 100,
          services: [
            { id: idx * 4 + 3, title: `Service ${idx * 4 + 3}`, cost: 200 + idx * 10 },
            { id: idx * 4 + 4, title: `Service ${idx * 4 + 4}`, cost: 120 + idx * 10 },
          ],
        },
      ],
      admin: `Admin ${idx + 1}`,
    },
  })),
};

export const masters: TMastersDashboardData = {
  return_master_rate: [
    {
      date: '2023-06-14',
      masters: [],
      filters: {
        return_new_client_rate: [],
        general_client_rate: [],
      },
    },
    {
      date: '2023-06-15',
      masters: [
        {
          'Азиза К.': 24,
        },
        {
          'Алина Администратор': 0,
        },
        {
          'Алина О.': 14,
        },
        {
          'Алина Ф.': 21,
        },
        {
          'Анна Б.': 21,
        },
        {
          'Валентина  М.': 11,
        },
        {
          'Виктория М.': 17,
        },
        {
          'Гуля Б.': 17,
        },
        {
          'Диана Э.': 27,
        },
        {
          'Жанна Ж.': 39,
        },
        {
          'Камилла Х.': 13,
        },
        {
          'Кристина К.': 20,
        },
        {
          'ЛИСТ ОЖИДАНИЯ': 5,
        },
        {
          'Лилия А.': 19,
        },
        {
          'Люба Л.': 28,
        },
        {
          'Надя С.': 47,
        },
        {
          'Нуриза К.': 31,
        },
        {
          'Татьяна У.': 37,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 29,
          },
          {
            'Алина Администратор': 10,
          },
          {
            'Алина О.': 29,
          },
          {
            'Алина Ф.': 70,
          },
          {
            'Анастасия М. Администратор': 24,
          },
          {
            'Анна Б.': 36,
          },
          {
            'Валентина  М.': 95,
          },
          {
            'Виктория М.': 80,
          },
          {
            'Гуля Б.': 86,
          },
          {
            'Диана Э.': 100,
          },
          {
            'Елена ': 50,
          },
          {
            'Жанна Ж.': 84,
          },
          {
            'Камилла Х.': 75,
          },
          {
            'Кристина К.': 74,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 7,
          },
          {
            'Лилия А.': 32,
          },
          {
            'Люба Л.': 55,
          },
          {
            'Надя С.': 11,
          },
          {
            'Нуриза К.': 53,
          },
          {
            'Татьяна У.': 60,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 29,
          },
          {
            'Алина Администратор': 10,
          },
          {
            'Алина О.': 29,
          },
          {
            'Алина Ф.': 70,
          },
          {
            'Анастасия М. Администратор': 24,
          },
          {
            'Анна Б.': 36,
          },
          {
            'Валентина  М.': 95,
          },
          {
            'Виктория М.': 80,
          },
          {
            'Гуля Б.': 86,
          },
          {
            'Диана Э.': 1,
          },
          {
            'Елена ': 50,
          },
          {
            'Жанна Ж.': 84,
          },
          {
            'Камилла Х.': 75,
          },
          {
            'Кристина К.': 74,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 7,
          },
          {
            'Лилия А.': 32,
          },
          {
            'Люба Л.': 55,
          },
          {
            'Надя С.': 11,
          },
          {
            'Нуриза К.': 53,
          },
          {
            'Татьяна У.': 60,
          },
        ],
      },
    },
    {
      date: '2023-06-16',
      masters: [
        {
          'Азиза К.': 23,
        },
        {
          'Алина О.': 14,
        },
        {
          'Алина Ф.': 21,
        },
        {
          'Анна Б.': 22,
        },
        {
          'Валентина  М.': 13,
        },
        {
          'Виктория М.': 17,
        },
        {
          'Гуля Б.': 17,
        },
        {
          'Диана Э.': 27,
        },
        {
          'Жанна Ж.': 38,
        },
        {
          'Камилла Х.': 12,
        },
        {
          'Кристина К.': 21,
        },
        {
          'ЛИСТ ОЖИДАНИЯ': 4,
        },
        {
          'Лилия А.': 18,
        },
        {
          'Люба Л.': 30,
        },
        {
          'Надя С.': 47,
        },
        {
          'Нуриза К.': 30,
        },
        {
          'Татьяна У.': 36,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 27,
          },
          {
            'Алина О.': 15,
          },
          {
            'Алина Ф.': 22,
          },
          {
            'Анна Б.': 28,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 20,
          },
          {
            'Гуля Б.': 19,
          },
          {
            'Диана Э.': 31,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 13,
          },
          {
            'Кристина К.': 23,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 8,
          },
          {
            'Лилия А.': 25,
          },
          {
            'Люба Л.': 34,
          },
          {
            'Надя С.': 49,
          },
          {
            'Нуриза К.': 34,
          },
          {
            'Татьяна У.': 42,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 27,
          },
          {
            'Алина О.': 15,
          },
          {
            'Алина Ф.': 22,
          },
          {
            'Анна Б.': 28,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 20,
          },
          {
            'Гуля Б.': 19,
          },
          {
            'Диана Э.': 31,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 13,
          },
          {
            'Кристина К.': 23,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 18,
          },
          {
            'Лилия А.': 25,
          },
          {
            'Люба Л.': 34,
          },
          {
            'Надя С.': 49,
          },
          {
            'Нуриза К.': 34,
          },
          {
            'Татьяна У.': 42,
          },
        ],
      },
    },
    {
      date: '2023-06-17',
      masters: [
        {
          'Азиза К.': 24,
        },
        {
          'Алина О.': 15,
        },
        {
          'Алина Ф.': 20,
        },
        {
          'Анна Б.': 21,
        },
        {
          'Валентина  М.': 13,
        },
        {
          'Виктория М.': 14,
        },
        {
          'Гуля Б.': 18,
        },
        {
          'Диана Э.': 27,
        },
        {
          'Жанна Ж.': 38,
        },
        {
          'Камилла Х.': 12,
        },
        {
          'Кристина К.': 20,
        },
        {
          'ЛИСТ ОЖИДАНИЯ': 4,
        },
        {
          'Лилия А.': 21,
        },
        {
          'Люба Л.': 32,
        },
        {
          'Надя С.': 45,
        },
        {
          'Нуриза К.': 30,
        },
        {
          'Татьяна У.': 36,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 28,
          },
          {
            'Алина О.': 15,
          },
          {
            'Алина Ф.': 21,
          },
          {
            'Анна Б.': 27,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 17,
          },
          {
            'Гуля Б.': 20,
          },
          {
            'Диана Э.': 31,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 13,
          },
          {
            'Кристина К.': 22,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 8,
          },
          {
            'Лилия А.': 28,
          },
          {
            'Люба Л.': 36,
          },
          {
            'Надя С.': 48,
          },
          {
            'Нуриза К.': 35,
          },
          {
            'Татьяна У.': 43,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 28,
          },
          {
            'Алина О.': 15,
          },
          {
            'Алина Ф.': 21,
          },
          {
            'Анна Б.': 27,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 17,
          },
          {
            'Гуля Б.': 20,
          },
          {
            'Диана Э.': 1,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 13,
          },
          {
            'Кристина К.': 22,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 8,
          },
          {
            'Лилия А.': 28,
          },
          {
            'Люба Л.': 36,
          },
          {
            'Надя С.': 48,
          },
          {
            'Нуриза К.': 35,
          },
          {
            'Татьяна У.': 43,
          },
        ],
      },
    },
    {
      date: '2023-06-18',
      masters: [
        {
          'Азиза К.': 24,
        },
        {
          'Алина О.': 18,
        },
        {
          'Алина Ф.': 22,
        },
        {
          'Анна Б.': 19,
        },
        {
          'Валентина  М.': 13,
        },
        {
          'Виктория М.': 13,
        },
        {
          'Гуля Б.': 18,
        },
        {
          'Диана Э.': 28,
        },
        {
          'Жанна Ж.': 37,
        },
        {
          'Камилла Х.': 13,
        },
        {
          'Кристина К.': 20,
        },
        {
          'ЛИСТ ОЖИДАНИЯ': 4,
        },
        {
          'Лилия А.': 21,
        },
        {
          'Люба Л.': 32,
        },
        {
          'Надя С.': 44,
        },
        {
          'Нуриза К.': 29,
        },
        {
          'Татьяна У.': 37,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 28,
          },
          {
            'Алина О.': 19,
          },
          {
            'Алина Ф.': 22,
          },
          {
            'Анна Б.': 24,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 15,
          },
          {
            'Гуля Б.': 20,
          },
          {
            'Диана Э.': 31,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 12,
          },
          {
            'Кристина К.': 22,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 8,
          },
          {
            'Лилия А.': 28,
          },
          {
            'Люба Л.': 36,
          },
          {
            'Надя С.': 47,
          },
          {
            'Нуриза К.': 33,
          },
          {
            'Татьяна У.': 43,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 8,
          },
          {
            'Алина О.': 19,
          },
          {
            'Алина Ф.': 22,
          },
          {
            'Анна Б.': 24,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 15,
          },
          {
            'Гуля Б.': 20,
          },
          {
            'Диана Э.': 31,
          },
          {
            'Жанна Ж.': 39,
          },
          {
            'Камилла Х.': 12,
          },
          {
            'Кристина К.': 22,
          },
          {
            'ЛИСТ ОЖИДАНИЯ': 8,
          },
          {
            'Лилия А.': 28,
          },
          {
            'Люба Л.': 36,
          },
          {
            'Надя С.': 47,
          },
          {
            'Нуриза К.': 33,
          },
          {
            'Татьяна У.': 43,
          },
        ],
      },
    },
    {
      date: '2023-06-19',
      masters: [
        {
          Алексей: 19,
        },
        {
          Берадин: 18,
        },
        {
          Григорий: 43,
        },
        {
          Станислав: 50,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            Алексей: 34,
          },
          {
            Берадин: 46,
          },
          {
            Григорий: 87,
          },
          {
            'Максим (работает до 11.04)': 22,
          },
          {
            Станислав: 50,
          },
        ],
        general_client_rate: [
          {
            Алексей: 4,
          },
          {
            Берадин: 46,
          },
          {
            Григорий: 87,
          },
          {
            'Максим (работает до 11.04)': 22,
          },
          {
            Станислав: 50,
          },
        ],
      },
    },
    {
      date: '2023-06-20',
      masters: [
        {
          'Азиза К.': 25,
        },
        {
          'Алина О.': 17,
        },
        {
          'Алина Ф.': 16,
        },
        {
          'Анна Б.': 13,
        },
        {
          'Валентина  М.': 11,
        },
        {
          'Виктория М.': 13,
        },
        {
          'Гуля Б.': 21,
        },
        {
          'Диана Э.': 37,
        },
        {
          'Жанна Ж.': 43,
        },
        {
          'Камилла Х.': 12,
        },
        {
          'Кристина К.': 17,
        },
        {
          'Лилия А.': 22,
        },
        {
          'Люба Л.': 50,
        },
        {
          'Надя С.': 47,
        },
        {
          'Нуриза К.': 26,
        },
        {
          'Татьяна У.': 47,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 27,
          },
          {
            'Алина О.': 17,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 14,
          },
          {
            'Гуля Б.': 22,
          },
          {
            'Диана Э.': 39,
          },
          {
            'Жанна Ж.': 45,
          },
          {
            'Камилла Х.': 16,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 29,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 48,
          },
          {
            'Нуриза К.': 28,
          },
          {
            'Татьяна У.': 47,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 27,
          },
          {
            'Алина О.': 17,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 14,
          },
          {
            'Гуля Б.': 2,
          },
          {
            'Диана Э.': 39,
          },
          {
            'Жанна Ж.': 45,
          },
          {
            'Камилла Х.': 16,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 29,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 48,
          },
          {
            'Нуриза К.': 28,
          },
          {
            'Татьяна У.': 47,
          },
        ],
      },
    },
    {
      date: '2023-06-21',
      masters: [
        {
          'Азиза К.': 25,
        },
        {
          'Алина О.': 18,
        },
        {
          'Алина Ф.': 16,
        },
        {
          'Анна Б.': 13,
        },
        {
          'Валентина  М.': 10,
        },
        {
          'Виктория М.': 13,
        },
        {
          'Гуля Б.': 21,
        },
        {
          'Диана Э.': 41,
        },
        {
          'Жанна Ж.': 44,
        },
        {
          'Камилла Х.': 12,
        },
        {
          'Кристина К.': 17,
        },
        {
          'Лилия А.': 24,
        },
        {
          'Люба Л.': 50,
        },
        {
          'Надя С.': 51,
        },
        {
          'Нуриза К.': 26,
        },
        {
          'Татьяна У.': 47,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 26,
          },
          {
            'Алина О.': 19,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 11,
          },
          {
            'Виктория М.': 15,
          },
          {
            'Гуля Б.': 22,
          },
          {
            'Диана Э.': 43,
          },
          {
            'Жанна Ж.': 46,
          },
          {
            'Камилла Х.': 15,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 30,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 52,
          },
          {
            'Нуриза К.': 28,
          },
          {
            'Татьяна У.': 47,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 26,
          },
          {
            'Алина О.': 19,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 11,
          },
          {
            'Виктория М.': 15,
          },
          {
            'Гуля Б.': 22,
          },
          {
            'Диана Э.': 3,
          },
          {
            'Жанна Ж.': 46,
          },
          {
            'Камилла Х.': 15,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 30,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 52,
          },
          {
            'Нуриза К.': 28,
          },
          {
            'Татьяна У.': 47,
          },
        ],
      },
    },
    {
      date: '2023-06-22',
      masters: [
        {
          'Азиза К.': 25,
        },
        {
          'Алина О.': 20,
        },
        {
          'Алина Ф.': 16,
        },
        {
          'Анна Б.': 13,
        },
        {
          'Валентина  М.': 11,
        },
        {
          'Виктория М.': 12,
        },
        {
          'Гуля Б.': 21,
        },
        {
          'Диана Э.': 41,
        },
        {
          'Жанна Ж.': 45,
        },
        {
          'Камилла Х.': 13,
        },
        {
          'Кристина К.': 18,
        },
        {
          'Лилия А.': 25,
        },
        {
          'Люба Л.': 50,
        },
        {
          'Надя С.': 51,
        },
        {
          'Нуриза К.': 27,
        },
        {
          'Татьяна У.': 52,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 26,
          },
          {
            'Алина О.': 21,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 13,
          },
          {
            'Гуля Б.': 22,
          },
          {
            'Диана Э.': 43,
          },
          {
            'Жанна Ж.': 47,
          },
          {
            'Камилла Х.': 16,
          },
          {
            'Кристина К.': 21,
          },
          {
            'Лилия А.': 32,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 52,
          },
          {
            'Нуриза К.': 30,
          },
          {
            'Татьяна У.': 53,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 26,
          },
          {
            'Алина О.': 21,
          },
          {
            'Алина Ф.': 19,
          },
          {
            'Анна Б.': 16,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 1,
          },
          {
            'Гуля Б.': 22,
          },
          {
            'Диана Э.': 43,
          },
          {
            'Жанна Ж.': 47,
          },
          {
            'Камилла Х.': 16,
          },
          {
            'Кристина К.': 21,
          },
          {
            'Лилия А.': 32,
          },
          {
            'Люба Л.': 56,
          },
          {
            'Надя С.': 52,
          },
          {
            'Нуриза К.': 30,
          },
          {
            'Татьяна У.': 53,
          },
        ],
      },
    },
    {
      date: '2023-06-23',
      masters: [
        {
          'Азиза К.': 24,
        },
        {
          'Алина О.': 17,
        },
        {
          'Алина Ф.': 16,
        },
        {
          'Анна Б.': 13,
        },
        {
          'Валентина  М.': 11,
        },
        {
          'Виктория М.': 9,
        },
        {
          'Гуля Б.': 22,
        },
        {
          'Диана Э.': 40,
        },
        {
          'Жанна Ж.': 45,
        },
        {
          'Камилла Х.': 12,
        },
        {
          'Кристина К.': 16,
        },
        {
          'Лилия А.': 24,
        },
        {
          'Люба Л.': 52,
        },
        {
          'Надя С.': 51,
        },
        {
          'Нуриза К.': 26,
        },
        {
          'Татьяна У.': 53,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 25,
          },
          {
            'Алина О.': 18,
          },
          {
            'Алина Ф.': 18,
          },
          {
            'Анна Б.': 15,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 10,
          },
          {
            'Гуля Б.': 23,
          },
          {
            'Диана Э.': 41,
          },
          {
            'Жанна Ж.': 47,
          },
          {
            'Камилла Х.': 14,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 31,
          },
          {
            'Люба Л.': 59,
          },
          {
            'Надя С.': 53,
          },
          {
            'Нуриза К.': 29,
          },
          {
            'Татьяна У.': 55,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 25,
          },
          {
            'Алина О.': 18,
          },
          {
            'Алина Ф.': 18,
          },
          {
            'Анна Б.': 5,
          },
          {
            'Валентина  М.': 12,
          },
          {
            'Виктория М.': 10,
          },
          {
            'Гуля Б.': 23,
          },
          {
            'Диана Э.': 41,
          },
          {
            'Жанна Ж.': 47,
          },
          {
            'Камилла Х.': 14,
          },
          {
            'Кристина К.': 20,
          },
          {
            'Лилия А.': 31,
          },
          {
            'Люба Л.': 59,
          },
          {
            'Надя С.': 53,
          },
          {
            'Нуриза К.': 29,
          },
          {
            'Татьяна У.': 55,
          },
        ],
      },
    },
    {
      date: '2023-06-24',
      masters: [
        {
          'Азиза К.': 24,
        },
        {
          'Алина О.': 19,
        },
        {
          'Алина Ф.': 23,
        },
        {
          'Анна Б.': 13,
        },
        {
          'Валентина  М.': 12,
        },
        {
          'Виктория М.': 4,
        },
        {
          'Гуля Б.': 17,
        },
        {
          'Диана Э.': 42,
        },
        {
          'Жанна Ж.': 50,
        },
        {
          'Камилла Х.': 13,
        },
        {
          'Кристина К.': 16,
        },
        {
          'Лилия А.': 23,
        },
        {
          'Люба Л.': 45,
        },
        {
          'Надя С.': 53,
        },
        {
          'Нуриза К.': 29,
        },
        {
          'Татьяна У.': 45,
        },
      ],
      filters: {
        return_new_client_rate: [
          {
            'Азиза К.': 25,
          },
          {
            'Алина О.': 22,
          },
          {
            'Алина Ф.': 26,
          },
          {
            'Анна Б.': 15,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 4,
          },
          {
            'Гуля Б.': 17,
          },
          {
            'Диана Э.': 44,
          },
          {
            'Жанна Ж.': 53,
          },
          {
            'Камилла Х.': 14,
          },
          {
            'Кристина К.': 21,
          },
          {
            'Лилия А.': 25,
          },
          {
            'Люба Л.': 51,
          },
          {
            'Надя С.': 56,
          },
          {
            'Нуриза К.': 32,
          },
          {
            'Татьяна У.': 45,
          },
        ],
        general_client_rate: [
          {
            'Азиза К.': 25,
          },
          {
            'Алина О.': 2,
          },
          {
            'Алина Ф.': 26,
          },
          {
            'Анна Б.': 15,
          },
          {
            'Валентина  М.': 14,
          },
          {
            'Виктория М.': 4,
          },
          {
            'Гуля Б.': 17,
          },
          {
            'Диана Э.': 44,
          },
          {
            'Жанна Ж.': 53,
          },
          {
            'Камилла Х.': 14,
          },
          {
            'Кристина К.': 21,
          },
          {
            'Лилия А.': 25,
          },
          {
            'Люба Л.': 51,
          },
          {
            'Надя С.': 56,
          },
          {
            'Нуриза К.': 32,
          },
          {
            'Татьяна У.': 45,
          },
        ],
      },
    },
  ],
  churn_by_master: [
    {
      date: '2023-06-01',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-02',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-03',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-04',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-05',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-06',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-07',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-08',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-09',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-10',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-11',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-12',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-13',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-14',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-15',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-16',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-17',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-18',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-19',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-20',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-21',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-22',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-23',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-24',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-25',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-26',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-27',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-28',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-29',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
    {
      date: '2023-06-30',
      masters: [
        {
          'Нуриза К.': 56.2,
        },
        {
          'Татьяна У.': 54.4,
        },
        {
          'Люба Л.': 46.8,
        },
        {
          'Надя С.': 60.6,
        },
        {
          'Гуля Б.': 59.2,
        },
        {
          'Анастасия ММ.': 100,
        },
        {
          'Диана Э.': 58.9,
        },
        {
          'Анна Б.': 57.9,
        },
        {
          'Наталья З.': 100,
        },
        {
          'Азиза К.': 48.7,
        },
        {
          'Алина Ф.': 49.4,
        },
        {
          'Лилия А.': 55.6,
        },
        {
          'Кристина К.': 44.6,
        },
        {
          'Алина О.': 73.6,
        },
        {
          'Анастасия М. Администратор': 89.1,
        },
        {
          'Татьяна К.': 100,
        },
        {
          'Анастасия К. Администратор': 97.1,
        },
        {
          'Валентина  М.': 30.6,
        },
        {
          'Валерия Х.': 100,
        },
        {
          'Жанна Ж.': 44.4,
        },
        {
          Натали: 100,
        },
        {
          'Камилла Х.': 47.2,
        },
        {
          Анастасия: 100,
        },
        {
          Ирина: 100,
        },
      ],
    },
  ],
  tasks: [
    {
      date: '2023-06-14',
      created: 7,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
    {
      date: '2023-06-15',
      created: 13,
      filters: {
        tasks_completed: 0,
        tasks_missed: 7,
        completion_time: null,
      },
    },
    {
      date: '2023-06-16',
      created: 35,
      filters: {
        tasks_completed: 0,
        tasks_missed: 13,
        completion_time: 14192,
      },
    },
    {
      date: '2023-06-17',
      created: 26,
      filters: {
        tasks_completed: 0,
        tasks_missed: 35,
        completion_time: null,
      },
    },
    {
      date: '2023-06-18',
      created: 30,
      filters: {
        tasks_completed: 0,
        tasks_missed: 27,
        completion_time: null,
      },
    },
    {
      date: '2023-06-19',
      created: 6,
      filters: {
        tasks_completed: 3,
        tasks_missed: 5,
        completion_time: 8649,
      },
    },
    {
      date: '2023-06-20',
      created: 0,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
    {
      date: '2023-06-21',
      created: 0,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
    {
      date: '2023-06-22',
      created: 0,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
    {
      date: '2023-06-23',
      created: 0,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
    {
      date: '2023-06-24',
      created: 0,
      filters: {
        tasks_completed: 0,
        tasks_missed: 0,
        completion_time: null,
      },
    },
  ],
};

export const admins: TAdminsDashboardData = {
  all_out_calls: [
    {
      date: '2023-06-14',
      name: null,
      value: 6,
    },
    {
      date: '2023-06-15',
      name: null,
      value: 41,
    },
    {
      date: '2023-06-16',
      name: null,
      value: 8,
    },
    {
      date: '2023-06-17',
      name: null,
      value: 26,
    },
    {
      date: '2023-06-18',
      name: null,
      value: 11,
    },
    {
      date: '2023-06-19',
      name: null,
      value: 5,
    },
    {
      date: '2023-06-20',
      name: null,
      value: 50,
    },
    {
      date: '2023-06-21',
      name: null,
      value: 20,
    },
    {
      date: '2023-06-22',
      name: null,
      value: 5,
    },
    {
      date: '2023-06-23',
      name: 'Пёс',
      value: 1,
    },
    {
      date: '2023-06-24',
      name: 'Анна',
      value: 4,
    },
  ],
  all_in_calls: [
    {
      date: '2023-06-14',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 56,
      },
    },
    {
      date: '2023-06-15',
      name: null,
      value: 24,
      filters: {
        missed_calls: 0,
        new_clients: 23,
        all_records: 142,
      },
    },
    {
      date: '2023-06-16',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 88,
      },
    },
    {
      date: '2023-06-17',
      name: null,
      value: 32,
      filters: {
        missed_calls: 3,
        new_clients: 27,
        all_records: 76,
      },
    },
    {
      date: '2023-06-18',
      name: null,
      value: 30,
      filters: {
        missed_calls: 6,
        new_clients: 26,
        all_records: 62,
      },
    },
    {
      date: '2023-06-19',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 23,
      },
    },
    {
      date: '2023-06-20',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 0,
      },
    },
    {
      date: '2023-06-21',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 0,
      },
    },
    {
      date: '2023-06-22',
      name: null,
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 0,
      },
    },
    {
      date: '2023-06-23',
      name: 'Пёс',
      value: 0,
      filters: {
        missed_calls: 0,
        new_clients: 0,
        all_records: 0,
      },
    },
    {
      date: '2023-06-24',
      name: 'Анна',
      value: 16,
      filters: {
        missed_calls: 7,
        new_clients: 10,
        all_records: 0,
      },
    },
  ],
  wa_average_time: [
    {
      date: '2023-06-14',
      name: null,
      value: 122,
    },
    {
      date: '2023-06-15',
      name: null,
      value: 240,
    },
    {
      date: '2023-06-16',
      name: null,
      value: 139,
    },
    {
      date: '2023-06-17',
      name: null,
      value: 60,
    },
    {
      date: '2023-06-18',
      name: null,
      value: 90,
    },
    {
      date: '2023-06-19',
      name: null,
      value: 22,
    },
    {
      date: '2023-06-20',
      name: null,
      value: 270,
    },
    {
      date: '2023-06-21',
      name: null,
      value: 50,
    },
    {
      date: '2023-06-22',
      name: null,
      value: 30,
    },
    {
      date: '2023-06-23',
      name: 'Пёс',
      value: 40,
    },
    {
      date: '2023-06-24',
      name: 'Анна',
      value: 60,
    },
  ],
};

export const dashboardData: TDashboardData = {
  admins,
  masters,
  finance,
};

export const dashboardResponseMockData = {
  ok: true,
  data: dashboardData,
  meta: {
    start_date: '2023-06-01T00:00:00',
    end_date: '2023-06-30T00:00:00',
    dashboard_list: {
      service_category: true,
      'all_amount.masters': true,
      amount_service_rate: true,
      op_records: true,
      masters: true,
      return_new_client_rate: true,
      general_client_rate: true,
      churn_by_master: true,
      created: true,
      'filters.masters': true,
      'filters.service_category': true,
      'filters.tasks_completed': true,
      // 'filters.completion_time': true,
      'filters.tasks_missed': true,
      all_out_calls: true,
      'filters.missed_calls': true,
      'filters.new_clients': true,
      'filters.all_records': true,
      wa_average_time: true,
    },
  },
};

export const staff = {
  data: [
    {
      id: 12364715,
      name: 'Andrey',
    },
    {
      id: 12237310,
      name: 'Ilya',
    },
    {
      id: 12245725,
      name: 'Админ Бот',
    },
    {
      id: 241613,
      name: 'Бьюти Бот',
    },
    {
      id: 12556419,
      name: 'Дмитрий',
    },
    {
      id: 12542597,
      name: 'Никита',
    },
    {
      id: 12547195,
      name: 'Никита АБ',
    },
    {
      id: 12237314,
      name: 'Тест админ бота',
    },
    {
      id: 2376859,
      name: 'АдминБот',
    },
    {
      id: 1083512,
      name: 'Сотрудник 1',
    },
  ],
};

export const notSelectedOperator: TOperatorsData = {
  name: OPERATOR_NAMES.NOT_SELECTED,
  yclients: false,
  manual: false,
  webhook: '',
  yclientsDocs: '',
  manualDocs: '',
  fields: [],
};

export const operatorsMocData: TOperatorsData[] = [
  notSelectedOperator,
  {
    name: 'Мегафон',
    webhook: 'https://admin-bot.tech/mfon_hook',
    yclientsDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    manualDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    yclients: true,
    manual: false,
    fields: [
      {
        name: 'token',
        title: 'some text',
        required: true,
        type: INPUT_VARIANTS.TEXT,
      },
    ],
  },
  {
    name: 'Мегафон2',
    webhook: 'https://admin-bot.tech/mfon_hook',
    yclientsDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    manualDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    yclients: true,
    manual: true,
    fields: [
      {
        name: 'token',
        title: 'some text',
        required: true,
        type: INPUT_VARIANTS.TEXT,
      },
    ],
  },
  {
    name: 'Мегафон3',
    webhook: 'https://admin-bot.tech/mfon_hook',
    yclientsDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    manualDocs:
      'https://support.yclients.com/71-77-385--nastrojka-integracii-yclients-i-ip-telefonii-megafon/',
    yclients: false,
    manual: true,
    fields: [
      {
        name: 'token',
        title: 'some text',
        required: true,
        type: INPUT_VARIANTS.TEXT,
      },
    ],
  },
];

export const emptyTelephonyData: TSettingsTelephonyData = {
  connectionStatus: false,
  apiKey: '',
  connectionType: CONNECTION_TYPE.YCLIENTS,
  domain: '',
  operators: operatorsMocData,
  possibleConnectionTypes: {
    yclients: false,
    manual: false,
  },
  phoneNumber: '',
  selectedOperator: OPERATOR_NAMES.NOT_SELECTED,
};

export const getTelephonyResponseData = {
  telephony: {
    name: 'megafon',
    title: 'megafon',
    token: '147858e7-586a-44dd-aad6-a29ba871b589',
    domain: 'vats758751',
    telnum: '79315901119',
  },
  telephony_list: [
    {
      title: 'megafon',
      yclients: true,
      yclients_docs: 'https://yclients.readthedocs.io/en/latest',
      manual: true,
      manual_docs: 'https://yclients.readthedocs.io/en',
      webhook: 'https://yclients.readthedocs.io/en',
    },
    {
      title: 'hotlead',
      yclients: true,
      yclients_docs: 'https://yclients.readthedocs.io/en/latest',
      manual: false,
      manual_docs: 'https://yclients.readthedocs.io/en',
      webhook: 'https://yclients.readthedocs.io/en',
    },
  ],
};

export const emptyTelephony: TSettingsTelephonyResponseData = {
  name: '',
  title: '',
  domain: '',
  telnum: '',
};
