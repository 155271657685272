import { TDashboardMeta } from '@api/dashboard/types';
import { TDashboardAnalyticsData } from '@models/index';
import { dashboardAnalyticsData } from '@const/dashboard';

export const dashboardMappers = {
  responseToStore: (meta: TDashboardMeta): TDashboardMeta => {
    const { start_date, end_date, dashboard_list } = meta;

    const allChartList = Object.keys(dashboardAnalyticsData);

    const newDashboardList = dashboard_list || dashboardAnalyticsData;
    const filteredDashboardList = Object.fromEntries(
      Object.entries(newDashboardList).filter(item => allChartList.includes(item[0])),
    ) as TDashboardAnalyticsData;

    return {
      start_date,
      end_date,
      dashboard_list: filteredDashboardList,
    };
  },
};
