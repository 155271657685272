import { TDashboardData } from '@api/dashboard/types';
import {
  DASHBOARD_KEYS,
  MASTERS_CHARTS,
  DASHBOARD_ADMINS_KEYS,
  DASHBOARD_MASTERS_KEYS,
  DASHBOARD_FINANCES_KEYS,
  ALL_AMOUNT_FILTER_NAMES,
  ALL_OUT_CALLS_FILTER_NAMES,
  EARNED_BY_OUT_CALLS_FILTER_NAMES,
} from '@const/dashboard';

export interface IInitialState {
  isRefresh: boolean;
  isError: boolean;
  isRetries: boolean;
  isLoading: boolean;
  retries: number;
  isAccessDenied: boolean;
  isFetchingMeta: boolean;
  isFetchingMetaSuccess: boolean;
  selectedFinancesDashboard: Array<DASHBOARD_FINANCES_KEYS>;
  selectedMastersDashboard: Array<Partial<DASHBOARD_MASTERS_KEYS>>;
  selectedAdminsDashboard: Array<DASHBOARD_ADMINS_KEYS>;
  selectedAnalyticsDashboard: Array<DASHBOARD_KEYS>;
  selectedAllOutCallsFilterName: string;
  selectedAllInCallsFilterName: DASHBOARD_KEYS;
  allAmountSelectedFilterName: string;
  selectedTaskFilterName: DASHBOARD_KEYS;
  selectedReturnMasterRateFilterName: DASHBOARD_KEYS;
  earnedByOutCallsSelectedFilterName: string;
  financesStartDate: Date;
  financesEndDate: Date;
  mastersStartDate: Date;
  mastersEndDate: Date;
  adminsStartDate: Date;
  adminsEndDate: Date;
  analyticsStartDate: Date;
  analyticsEndDate: Date;
  data: TDashboardData;
}

export const initialState: IInitialState = {
  isRefresh: false,
  isError: false,
  isRetries: false,
  isLoading: false,
  retries: 3,
  isAccessDenied: false,
  isFetchingMeta: false,
  isFetchingMetaSuccess: false,
  selectedFinancesDashboard: Object.values(DASHBOARD_FINANCES_KEYS),
  selectedAdminsDashboard: Object.values(DASHBOARD_ADMINS_KEYS),
  selectedMastersDashboard: Object.keys(MASTERS_CHARTS).map(key => key as DASHBOARD_MASTERS_KEYS),
  selectedAnalyticsDashboard: Object.values(DASHBOARD_KEYS),
  selectedReturnMasterRateFilterName: DASHBOARD_KEYS.MASTERS,
  selectedTaskFilterName: DASHBOARD_KEYS.CREATED,
  selectedAllOutCallsFilterName: ALL_OUT_CALLS_FILTER_NAMES.RECORDED,
  selectedAllInCallsFilterName: DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS,
  allAmountSelectedFilterName: ALL_AMOUNT_FILTER_NAMES.MASTERS,
  earnedByOutCallsSelectedFilterName: EARNED_BY_OUT_CALLS_FILTER_NAMES.MASTERS,
  financesStartDate: new Date('2023-05-20 10:00:00'), // '2023-05-20 10:00:00'
  financesEndDate: new Date('2023-05-28 10:00:00'), // '2023-05-28 10:00:00'
  mastersStartDate: new Date('2023-05-13 10:00:00'), // '2023-05-13 10:00:00'
  mastersEndDate: new Date('2023-05-20 10:00:00'), // '2023-05-20 10:00:00'
  adminsStartDate: new Date('2023-05-20 10:00:00'), // '2023-05-20 10:00:00'
  adminsEndDate: new Date('2023-05-28 10:00:00'), // '2023-05-28 10:00:00'
  analyticsStartDate: new Date('2023-05-28 10:00:00'), // '2023-05-28 10:00:00'
  analyticsEndDate: new Date('2023-05-28 10:00:00'), // '2023-05-28 10:00:00'
  data: {
    finance: {
      all_amount: [],
      op_records: [],
      amount_service_rate: [],
      earned_by_out_calls: [],
    },
    admins: {
      all_out_calls: [],
      all_in_calls: [],
      wa_average_time: [],
    },
    masters: {
      tasks: [],
      churn_by_master: [],
      return_master_rate: [],
    },
  },
};
