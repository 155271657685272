import React, { memo, useMemo } from 'react';
//
import { DndContainer } from '@atoms/dnd';
import { TReportInfo } from '@models/index';
import { TDeleteTask } from '@shared/types';
import { useAppDispatch } from '@hooks/index';
import { ORDER_VARIANTS } from '@models/Settings';
import { setOrderThunk } from '@redux/Settings/thunk';
import { SortableItem } from '@atoms/dndSortableItem';
import { setSortedReportsList } from '@redux/Report/slice';

import { ReportCard } from '../reportCard';

interface IReportCardListProps {
  /**
   * Массив данных для отрисовки карточек задач
   * @param {TReportInfo[]}
   */
  data: TReportInfo[];
  /**
   * Если true запрос на удаление обрабатывается
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Callback на удаление задачи
   * @param {TDeleteTask}
   */
  deleteReport: TDeleteTask;
  /**
   * Callback на переключение активности задачи
   * @param {TDeleteTask}
   */
  switchReport: TDeleteTask;
  /**
   * Флаг ошибки при удалении
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаления задачи
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Отсортированный массив айдишников отчетов
   * @type {string[]}
   */
  sortedReportsData: string[];
}

export const ReportCardList = memo(
  ({
    data,
    deleteReport,
    switchReport,
    isDeleting,
    isDeletingError,
    isDeletingSuccess,
    className = '',
    sortedReportsData,
  }: IReportCardListProps) => {
    const dispatch = useAppDispatch();
    // массив карточек с задачами для отображения
    const ReportCardsToRender = useMemo(() => {
      if (!sortedReportsData.length) {
        return data.map(item => (
          <SortableItem id={item.id} key={item.id}>
            <ReportCard
              data={item}
              switchTask={switchReport}
              deleteTask={deleteReport}
              isDeleting={isDeleting}
              isDeletingError={isDeletingError}
              isDeletingSuccess={isDeletingSuccess}
            />
          </SortableItem>
        ));
      }

      return sortedReportsData.map(id => {
        const foundReport = data.find(item => item.id === id);

        if (!foundReport) {
          return null;
        }

        return (
          <SortableItem id={id} key={id}>
            <ReportCard
              data={foundReport}
              switchTask={switchReport}
              deleteTask={deleteReport}
              isDeleting={isDeleting}
              isDeletingError={isDeletingError}
              isDeletingSuccess={isDeletingSuccess}
            />
          </SortableItem>
        );
      });
    }, [
      data,
      isDeleting,
      switchReport,
      deleteReport,
      isDeletingError,
      isDeletingSuccess,
      sortedReportsData,
    ]);

    const onDragEndHandler = (sortedReports: string[]) => {
      dispatch(setSortedReportsList(sortedReports));
      dispatch(setOrderThunk({ target: ORDER_VARIANTS.STATS, data: sortedReports }));
    };

    return (
      <div className={`grid grid-cols-3temp xxl:grid-cols-3tempXXL gap-[1.2rem] ${className}`}>
        <DndContainer items={sortedReportsData} onDragEnd={onDragEndHandler}>
          {ReportCardsToRender}
        </DndContainer>
      </div>
    );
  },
);
