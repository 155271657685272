import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Pay/state';
import {
  getPayPageData,
  getPayLinkThunk,
  getInvoiceThunk,
  setPayerDataThunk,
  getPayerDataThunk,
} from '@redux/Pay/thunk';
import {
  reducers,
  getInvoiceReducer,
  getPayLinkReducer,
  getPayerDataReducer,
  setPayerDataReducer,
  getPayPageDataReducer,
} from '@redux/Pay/reducers';

export const paySlice = createSlice({
  name: 'pay',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getPayPageData.pending, getPayPageDataReducer.pending);
    builder.addCase(getPayPageData.fulfilled, getPayPageDataReducer.fulfilled);
    builder.addCase(getPayPageData.rejected, getPayPageDataReducer.rejected);
    builder.addCase(getPayerDataThunk.pending, getPayerDataReducer.pending);
    builder.addCase(getPayerDataThunk.fulfilled, getPayerDataReducer.fulfilled);
    builder.addCase(getPayerDataThunk.rejected, getPayerDataReducer.rejected);
    builder.addCase(setPayerDataThunk.pending, setPayerDataReducer.pending);
    builder.addCase(setPayerDataThunk.fulfilled, setPayerDataReducer.fulfilled);
    builder.addCase(setPayerDataThunk.rejected, setPayerDataReducer.rejected);
    builder.addCase(getPayLinkThunk.pending, getPayLinkReducer.pending);
    builder.addCase(getPayLinkThunk.fulfilled, getPayLinkReducer.fulfilled);
    builder.addCase(getPayLinkThunk.rejected, getPayLinkReducer.rejected);
    builder.addCase(getInvoiceThunk.pending, getInvoiceReducer.pending);
    builder.addCase(getInvoiceThunk.fulfilled, getInvoiceReducer.fulfilled);
    builder.addCase(getInvoiceThunk.rejected, getInvoiceReducer.rejected);
  },
});

export const payReducer = paySlice.reducer;
export const {
  clearPayPageErrors,
  showChangeCardModal,
  hideChangeCardModal,
  showConnectAutoPayModal,
  hideConnectAutoPayModal,
  showAutoPayDisableModal,
  hideAutoPayDisableModal,
  setIsAccessOnPayPageDenied,
  setPayerInn,
  setPayerKpp,
  setPayerName,
  clearPayToastMessages,
  setPayErrorToastMessage,
  setPaySuccessToastMessage,
  setSelectedCardNumber,
} = paySlice.actions;
