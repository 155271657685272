import { Fragment, ReactNode, useMemo } from 'react';
//
import { Drawer } from '@uikit/Drawer';
import { getAppData } from '@redux/selectors';
import { useAppSelector } from '@hooks/index';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TAppDrawerProps = {
  /**
   * Параметр отвечающий за отображение информационного окна
   * @param {boolean}
   */
  showAppDrawer: boolean;
  /**
   * Обработчик закрытия информационного окна
   * @param {() => void}
   */
  onCloseHandler: () => void;
  /**
   * Опциональный параметр отображающий данные через переданный элемент
   * @param {ReactNode}
   */
  displayElement?: ReactNode;
};
export const AppDrawer = ({ showAppDrawer, onCloseHandler, displayElement }: TAppDrawerProps) => {
  const width = useWindowWidth();
  const {
    appDrawerData: { title, description },
  } = useAppSelector(getAppData);

  const replaceNewlinesWithBrTags = useMemo(
    () => (text: string) => {
      const lines = text.split('\n');

      return lines.map((line, index) => (
        <Fragment key={line}>
          {line}
          {index !== lines.length - 1 && <br />}
        </Fragment>
      ));
    },
    [],
  );

  const renderTitle = useMemo(() => {
    return <span className='text-h4 font-bold text-black tracking-[0.15px]'>{title}</span>;
  }, [title]);

  return (
    <Drawer
      isCloseButtonShown
      isOpen={showAppDrawer}
      onClose={onCloseHandler}
      type='icon'
      position='right'
      title={renderTitle}
      size={width < 780 ? '100%' : 540}>
      <div className='flex flex-col px-3 py-6'>
        {displayElement || (
          <p className='mb-6 text-black text-body_text font-normal tracking-[0.44px]'>
            {replaceNewlinesWithBrTags(description)}
          </p>
        )}
      </div>
    </Drawer>
  );
};
