import { apiService } from '@api/ApiService';
import { BLACKLIST_ACTION } from '@const/blackList';
import { END_POINTS_URL } from '@const/apiConstants';
import { TRequestStatus, TGetResponseWithData } from '@api/types';
import { TAddDeleteNumberInBlackListQueryData, TBlackListData } from '@models/BlackList';

/**
 * API страницы черный список
 * @module blackListApi
 */

export const blackListApi = {
  /**
   * Метод получает массив номеров в ЧС
   * @method getBlackList
   * @return {Promise<AxiosResponse<TRequestStatus | TGetResponseWithData<TBlackListData[]>, any>>}
   */
  getBlackList() {
    return apiService.get<TGetResponseWithData<TBlackListData[]> | TRequestStatus>(
      END_POINTS_URL.BLACKLIST,
    );
  },
  /**
   * Метод добавляет новый номер в ЧС
   * @method addNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData} номер телефона который нужно добавить в ЧС
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  addNumberToBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.ADD_NUMBER,
      phone: data.phone,
    });
  },
  /**
   * Метод удаляет номер из ЧС
   * @method deleteNumberToBlacklist
   * @param data {TAddDeleteNumberInBlackListQueryData} номер телефона который нужно удалить из ЧС
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  deleteNumberToBlacklist(data: TAddDeleteNumberInBlackListQueryData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.BLACKLIST, {
      action: BLACKLIST_ACTION.DELETE_NUMBER,
      phone: data.phone,
    });
  },
};
