import { z } from 'zod';
import React from 'react';
//
import { RootState } from '@store/store';
//
import { TCommonSearchParams } from '@const/httpConst';
import {
  chartColors,
  DASHBOARD_KEYS,
  DASHBOARD_ADMINS_KEYS,
  DASHBOARD_MASTERS_KEYS,
  DASHBOARD_FINANCES_KEYS,
} from '@const/dashboard';
import {
  TDashboardMeta,
  TDashboardData,
  TEarnedByOutCalls,
  TOneDashboardResponseData,
} from '@api/dashboard/types';
import {
  TRejectResponse,
  TRejectResponse2,
  TDashboardDataItem,
  TReturn_master_rate,
} from '@api/types';
import { TAdminState } from '@models/Settings';
import { TTaskInfo } from '@models/Tasks';

export type TIcon = {
  [key: string]: string;
  delete: string;
  phone: string;
  logout: string;
  emptyList: string;
  telegramCyan: string;
  statusOn: string;
  statusOff: string;
  errorToast: string;
  successToast: string;
  caretDown: string;
  learning: string;
  document: string;
  chevronRight: string;
  taskTracker: string;
  tick: string;
  warningInfo: string;
  darkCross: string;
  calendar: string;
  greenChevronLeft: string;
  qrCodeTelegramLink: string;
  greenChevronRight: string;
};
export type iconKey = keyof TIcon;

export const SimpleStringObj = z.record(z.string(), z.string());

export type TSimpleStringObj = z.infer<typeof SimpleStringObj>;

export interface IWithChildren {
  children: React.ReactNode;
}

export interface IWithOptionalChildren {
  children?: React.ReactNode;
}

export type THelpKeys = keyof THelp;

export type THelp = {
  // first tooltip
  firstTooltipText: string;
  firstTooltipButtonText: string;
  tooltipTitle: string;
  // dashboard
  all_amount: string;
  amount_service_rate: string;
  op_records: string;
  earned_by_out_calls: string;
  all_out_calls: string;
  wa_average_time: string;
  all_in_calls: string;
  return_master_rate: string;
  churn_by_master: string;
  tasks: string;
  // Task
  services: string;
  visits: string;
  visitQuantity: string;
  everyFewDays: string;
  taskDescription: string;
  // reports
  sendToAllStaff: string;
  tgReport: string;
  reportDescription: string;
  whenSendRegularTasks: string;
  whenSendReports: string;
  // settings
  customerChurnTime: string;
  apiKeyTaskTracker: string;
  domainTaskTracker: string;
  autoArchiveTasks: string;
  telephonyOperatorName: string;
  apiKey: string;
  domain: string;
  iSetUpWebhook: string;
  phoneNumber: string;
  taskOpenNotify: string;
  statsNotifyPhoneNumbers: string;
  staff: string;
  selectedStaff: string;
  taskTracker: string;
  yclients: string;
  employeesToIgnore: string;
  missedCallAnswer: string;
  forgottenTaskNotify: string;
  callStats: string;
  tasksNotify: string;
  noStaffStats: string;
  blackList: string;
  masters: string;
  admins: string;
  billData: string;
  owner: string;
  performer: string;
  task_completed: string;
  task_created: string;
  task_overdue: string;
  task_postpone: string;
  useGroups: string;
  // _______________________
  // pay
  tariff: string;
  yourTariff: string;
  staffTableToolTip: string;
  selectAllFilial: string;
};

export type TDashboardAnalyticsData = {
  [key in DASHBOARD_KEYS]: boolean;
};
export type TDashboardAdminsData = {
  [key in DASHBOARD_ADMINS_KEYS]: TDashboardDataItem[];
};
export type TDashboardMastersData = {
  [key in DASHBOARD_MASTERS_KEYS]: TDashboardDataItem[];
};
export type TDashboardFinancesData = {
  [key in DASHBOARD_FINANCES_KEYS]: TDashboardDataItem[];
};

export type AdminsCategoryChangeEvent = React.ChangeEvent<HTMLInputElement> & {
  target: { name: DASHBOARD_ADMINS_KEYS } & EventTarget;
};

export type TRejectValue = { state: RootState; rejectValue: TRejectResponse | TRejectResponse2 };

export type TAccessRight = 'analytics' | 'settings' | 'templates';

export type TStatisticsData = {
  text: string;
  date: string;
  time: string;
  recipients: number;
  answers: number;
  signUp: number;
  sum: number;
};

export type TReportInfo = Omit<TTaskInfo, 'type' | 'isChecklist' | 'notes'>;

export type TStatisticPageData = {
  name: string;
  statisticsDataArray: TStatisticsData[];
};

export type TCustomerChurnTime = { date: string; n: number }[];

export type TNumWord = (value: number, words: string[]) => string;

export type TStatisticsResponse = {
  totalCount: number;
  data: TStatisticsData[];
};

export type TChartColors = keyof typeof chartColors;

export type TStatisticsTableConfig = {
  page: number;
  sortType: string;
  linePerPage: number;
  sortCollumName: string;
};

export type MasterData = {
  [key: string]: number;
};

export type DataItem = {
  date: string;
  masters: MasterData[];
  filters: {
    [key: string]: TSimpleStringObj[];
    return_new_client_rate: TSimpleStringObj[];
  };
};

export type TAllAmountData = {
  date: string;
  value: number;
  filters: {
    [key: string]: TSimpleStringObj[];
    service_category: TSimpleStringObj[];
    masters: TSimpleStringObj[];
  };
};

export type TGetDashboardData = {
  name: keyof TDashboardData | 'main';
  data: TOneDashboardResponseData;
} | void;

export type TGetMainDashboardData = {
  name: string;
  data: TDashboardData;
  meta: TDashboardMeta;
  force: boolean;
} | void;

export type TData = {
  date: string;
  value: number;
  filters: Record<string, TDashboardDataItem[] | null>;
};

export type TTooltipItems = { parsed: { y: number } }[];

export type Dataset = {
  label: string;
  data: number[];
};

export type FormattedData = {
  labels: string[];
  datasets: Dataset[];
};

export type TGetEaredByOutCallsData = ({
  data,
  keyName,
  colors,
}: {
  data: Array<TEarnedByOutCalls>;
  keyName: keyof TEarnedByOutCalls['filters'];
  colors: TSimpleStringObj;
}) => FormattedData;

export type TChatMultiLineData = ({
  data,
  keyName,
  colors,
}: {
  data: TReturn_master_rate[];
  keyName: string;
  colors: TSimpleStringObj;
}) => FormattedData;

export type TGetOneLineChartDataset = ({
  chartData,
  key,
  chartLabel,
}: {
  // eslint-disable-next-line
  chartData: any[];
  key: string;
  chartLabel: string;
  borderColor: TChartColors;
  backgroundColor: TChartColors;
}) => FormattedData;

export type TGetStackedBarChartDataset = ({
  chartData,
  label,
}: {
  // eslint-disable-next-line
  chartData: any[];
  label: string;
}) => FormattedData;

export type TGetAllAmountDataSet = ({
  data,
  keyName,
  colors,
}: {
  data: TData[];
  keyName: string;
  colors: TSimpleStringObj;
}) => FormattedData;

export type TDoughnutChartDataset = {
  datasets: {
    backgroundColor: string[];
    borderColor: string[];
    data: number[];
    rotation: number;
    label: string;
  }[];
  labels: string[][];
};

export type TGetDataForDoughnutChart = ({
  data,
  keyName,
  chartTitle,
}: {
  // eslint-disable-next-line
  data: any[];
  keyName: string;
  chartTitle: string;
}) => TDoughnutChartDataset;

export type TGetDataForDoughnutChartTData = ({
  data,
  keyName,
  chartTitle,
}: {
  data: TData[];
  keyName: string;
  chartTitle: string;
}) => TDoughnutChartDataset;

export type TGetDoughnutChartDataset = ({
  chartData,
  chartTitle,
  getRotationAngeCallback,
}: {
  // eslint-disable-next-line
  chartData: any[];
  chartTitle: string;
  getRotationAngeCallback: (chartData: number[]) => number;
}) => TDoughnutChartDataset;

export type TSaveDashboardData = {
  startDate: string;
  endDate: string;
  selectedAnalyticsDashboard: string[];
};

export type TDatasetWithColors<T> = {
  label: string;
  data: T[];
  backgroundColor: string;
  borderColor: string;
}[];

export type TGetDataset = <T>(
  valuesList: Record<string, T[]>,
  colorsArrayToChart: string[],
) => TDatasetWithColors<T>;

export type TSelectedDashboardList = Record<string, boolean>;

export type TGetRotationAnge = (chartData: number[]) => number;

export type TTemplateKeys = {
  [key: string]: TSimpleStringObj;
  clients: TSimpleStringObj;
  missedCalls: TSimpleStringObj;
  badReviews: TSimpleStringObj;
  crmClientCame: TSimpleStringObj;
  crmRecordCancel: TSimpleStringObj;
  common: TSimpleStringObj;
  recordDone: TSimpleStringObj;
};

export type IStoryBookRequest<T extends TCommonSearchParams> = {
  method: string;
  url: string;
  body: string;
  searchParams: T;
};

export type TSort = <T>(
  unsortedData: Array<T>,
  fieldName: keyof T,
  sortIndex: string,
  sliceStartIndex?: number,
  isTextPeriod?: boolean,
) => T[];

export const SimpleStringObjLevel2Schema = z.record(z.string(), SimpleStringObj);
export type TSimpleStringObjLevel2 = z.infer<typeof SimpleStringObjLevel2Schema>;

export type TValidateValue = ({
  lowLimit,
  upLimit,
  value,
  initialValue,
}: {
  lowLimit: number;
  upLimit?: number;
  value: string;
  initialValue: string;
}) => string;

export type TChangeAdminStatusData = {
  id: number;
  state: TAdminState;
};

export type TDashboardDrawerDataItem = {
  title: string;
  subTitle: string;
  description: {
    how: string;
    business: string;
    more?: string;
  };
};

export enum EVENT_KEY_NAMES {
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
}

export enum INPUT_VARIANTS {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  CHECKBOX = 'checkbox',
}
