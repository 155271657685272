import React, { useCallback, useMemo } from 'react';
//
import { H1 } from '@atoms/h1';
import { ChartWrapper } from '@components/chartWrapper';
import { TDashboardDrawerDataItem } from '@models/index';
import { DashboardDrawer } from '@blocks/dashboardDrawer';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DashboardInfo, emptyDashboardInfoItem } from '@data/dict';
import { saveSelectedDashboardData } from '@redux/Dashboard/thunks';
import { getAppData, getDashboardPageData } from '@redux/selectors';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { DashboardSelectBlock } from '@components/dashboardSelectBlock';
import { getDateString, getDateStringToISOString } from '@helpers/index';
import { AnalyticsChartDoughnut } from '@blocks/dashboardAnalitycsCharts';
import { setAnalyticsType, setShowAnalyticsDrawer } from '@redux/App/slice';
import { setAnalyticsDates, setSelectedAnalyticsCategories } from '@redux/Dashboard/slice';
import {
  ADMINS_CHARTS,
  DASHBOARD_KEYS,
  MASTERS_TASKS_FILTER_NAME,
  ANALYTICS_PAGE_CHART_TITLES,
  ANALYTICS_CHARTS_CATEGORIES,
} from '@const/dashboard';

interface IDashboardTemplateProps {
  /**
   * Id вы бранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const DashboardTemplate = ({
  selectedFilialAccId,
  className = '',
}: IDashboardTemplateProps) => {
  const dispatch = useAppDispatch();
  const { showAnalyticsDrawer } = useAppSelector(getAppData);
  const { data, analyticsStartDate, analyticsEndDate, selectedAnalyticsDashboard } =
    useAppSelector(getDashboardPageData);

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setAnalyticsDates({ startDate, endDate }));
    if (startDate && endDate) {
      dispatch(
        saveSelectedDashboardData({
          startDate: getDateStringToISOString(startDate),
          endDate: getDateStringToISOString(endDate),
          selectedAnalyticsDashboard,
        }),
      );
    }
  };

  const onCloseDropdownHandler = () => {
    if (analyticsStartDate && analyticsEndDate) {
      dispatch(
        saveSelectedDashboardData({
          startDate: getDateStringToISOString(analyticsStartDate),
          endDate: getDateStringToISOString(analyticsEndDate),
          selectedAnalyticsDashboard,
        }),
      );
    }
  };

  const setSelectedChatHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(setSelectedAnalyticsCategories({ name: name as DASHBOARD_KEYS, checked }));
  };

  const doughnutChartLabel = `${getDateString(analyticsStartDate)}-${getDateString(
    analyticsEndDate,
  )}`;

  const changeDisplayDrawer = useCallback(
    (name?: DASHBOARD_KEYS) => {
      let currentDashboardDrawerData: TDashboardDrawerDataItem = emptyDashboardInfoItem;
      if (name) {
        currentDashboardDrawerData = DashboardInfo[name];

        dispatch(setAnalyticsType(currentDashboardDrawerData));
      }

      dispatch(setShowAnalyticsDrawer(!showAnalyticsDrawer));
    },
    [showAnalyticsDrawer, dispatch],
  );

  const getSelectedFilterNames = useMemo(
    () => ({
      [DASHBOARD_KEYS.SERVICE_CATEGORY]: DASHBOARD_KEYS.SERVICE_CATEGORY,
      [DASHBOARD_KEYS.ALL_AMOUNT_MASTERS]: DASHBOARD_KEYS.ALL_AMOUNT_MASTERS,
      [DASHBOARD_KEYS.AMOUNT_SERVICE_RATE]: DASHBOARD_KEYS.AMOUNT_SERVICE_RATE,
      [DASHBOARD_KEYS.OP_RECORDS]: DASHBOARD_KEYS.OP_RECORDS,
      [DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY]: DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY,
      [DASHBOARD_KEYS.FILTERS_MASTERS]: 'earned_by_out_calls.masters',
      // [DASHBOARD_KEYS.FILTERS_ADMINS]: DASHBOARD_KEYS.FILTERS_ADMINS,
      [DASHBOARD_KEYS.MASTERS]: DASHBOARD_KEYS.MASTERS,
      [DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE]: DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE,
      [DASHBOARD_KEYS.GENERAL_CLIENT_RATE]: DASHBOARD_KEYS.GENERAL_CLIENT_RATE,
      [DASHBOARD_KEYS.CHURN_BY_MASTER]: DASHBOARD_KEYS.CHURN_BY_MASTER,
      [DASHBOARD_KEYS.CREATED]: DASHBOARD_KEYS.CREATED,
      [DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED]: DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED,
      [MASTERS_TASKS_FILTER_NAME.COMPLETION_TIME]: MASTERS_TASKS_FILTER_NAME.COMPLETION_TIME,
      [DASHBOARD_KEYS.FILTERS_TASKS_MISSED]: DASHBOARD_KEYS.FILTERS_TASKS_MISSED,
      [DASHBOARD_KEYS.ALL_OUT_CALLS]: '',
      [DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS]: '',
      [DASHBOARD_KEYS.FILTERS_NEW_CLIENTS]: '',
      [DASHBOARD_KEYS.FILTERS_ALL_RECORDS]: '',
      [DASHBOARD_KEYS.WA_AVERAGE_TIME]: '',
    }),
    [],
  );

  const renderCharts = useMemo(() => {
    return selectedAnalyticsDashboard.map(chartName => {
      return (
        <ChartWrapper
          key={chartName}
          pathName=''
          selectedFilialAccId={selectedFilialAccId}
          chartName={
            chartName === DASHBOARD_KEYS.WA_AVERAGE_TIME
              ? ADMINS_CHARTS[chartName]
              : ANALYTICS_PAGE_CHART_TITLES[chartName]
          }
          chartDataKeyName={chartName}
          chartFilterNames={{}}
          selectedFilterName={getSelectedFilterNames[chartName]}
          callback={() => ''}
          barChart={null}
          doughnutChart={
            <AnalyticsChartDoughnut
              chartName={chartName}
              data={data}
              chartTitle={doughnutChartLabel}
            />
          }
          chartContentType={chartName}
          onHowButtonClick={changeDisplayDrawer}
          className={
            selectedAnalyticsDashboard.length > 1
              ? chartName === DASHBOARD_KEYS.CHURN_BY_MASTER ||
                chartName === DASHBOARD_KEYS.MASTERS ||
                chartName === DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE ||
                chartName === DASHBOARD_KEYS.GENERAL_CLIENT_RATE
                ? 'shrink'
                : 'md:max-w-[49%]'
              : ''
          }
        />
      );
    });
  }, [
    data,
    doughnutChartLabel,
    changeDisplayDrawer,
    selectedFilialAccId,
    getSelectedFilterNames,
    selectedAnalyticsDashboard,
  ]);

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardDrawer changeDisplayDrawer={changeDisplayDrawer} />
      <H1 className='mb-10 tracking-[-0.01em]'>Дашборд</H1>
      <div className='flex flex-wrap items-center justify-between mb-6'>
        <DateRangeDashboardPicker
          startDate={analyticsStartDate}
          endDate={analyticsEndDate}
          setSendingDate={setDateRangeHandler}
          className='mb-4 w-full sm:max-w-[20rem]'
        />
        <DashboardSelectBlock
          categories={ANALYTICS_CHARTS_CATEGORIES}
          onChangeHandler={setSelectedChatHandler}
          selectedCategories={selectedAnalyticsDashboard}
          onCloseDropdownCallback={onCloseDropdownHandler}
          className='mb-4 sm:max-w-[13.05rem] w-full'
        />
      </div>
      <div className='flex flex-wrap justify-between'>{renderCharts}</div>
    </div>
  );
};
