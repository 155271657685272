import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Title,
  Colors,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
//
import { noDataChartPlugin } from '@helpers/index';
import { FormattedData, TTooltipItems } from '@models/index';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

const footer = (tooltipItems: TTooltipItems) => {
  let sum = 0;
  tooltipItems.forEach(tooltipItem => {
    sum += tooltipItem.parsed.y;
  });
  return `Сумма: ${sum}`;
};

interface IStackedChartOutCallsProps {
  /**
   * Данные для графика
   * @param {FormattedData}
   */
  data: FormattedData;
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | undefined;
}

export const StackedChartOutCalls = ({
  data,
  chartTitle,
  chartLegendPosition,
}: IStackedChartOutCallsProps) => {
  const options = {
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      tooltip: {
        callbacks: {
          footer,
        },
      },
      title: {
        display: false,
        text: chartTitle,
        position: `${chartLegendPosition || 'bottom'}` as const,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div
      className='chart-container'
      style={{ position: 'relative', height: '420px', width: `clamp(200px, 99%, 1270px)` }}>
      <Bar options={options} data={data} plugins={[noDataChartPlugin]} />
    </div>
  );
};
