import React from 'react';

import Component from './Table';
import PropTypes, {InferProps} from 'prop-types';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `def` `dense`
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(["def", "dense"]),
  /**
   ` The view of the component.
   * Variants: `filled` `flat` `smooth` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "flat", "smooth", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Use or not pagination
   */
  pages: PropTypes.bool,
  /**
   * Use or not checkboxes
   */
  checkboxes: PropTypes.bool,
  position: PropTypes.array,
  currentPage: PropTypes.number,
  onSortCallback: PropTypes.func,
  totalCounter: PropTypes.number,
  onChangePageHandler: PropTypes.func,
  onChangeRowsPerPageHandler: PropTypes.func,
};

type TPropTypes = InferProps<typeof propTypes>;

interface IComponentProps extends TPropTypes {
  [key: string]: any;
}

export const Table: React.FC<IComponentProps> = (props) => <Component {...props} />;