import React from 'react';
import { NavLink } from 'react-router-dom';
//
import { PATH_NAMES } from '@const/httpConst';
import { TFilialData } from '@models/Accounts';
import { QUERY_PARAMS } from '@const/apiConstants';
import { FilialCardItem } from '@blocks/filialCardItem';
import { FilialCardWithStatusItem } from '@blocks/filialCardWithStatusItem';

interface IFilialCardProps {
  /**
   * Отключает кнопку перехода на страницу настроек
   * @param {boolean}
   */
  disableSettingsButton: boolean;
  /**
   * Данные филиала
   * @param {TFilialData}
   */
  filialData: TFilialData;
  /**
   * Callback для установки выбранного филиала в store при клике на NavLink
   * @param {() => void}
   */
  setChosenFilialId: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const FilialCard = ({
  filialData,
  setChosenFilialId,
  disableSettingsButton,
  className = '',
}: IFilialCardProps) => (
  <div className={`border border-quartz p-4 rounded max-w-[61.25rem] w-full ${className}`}>
    <FilialCardItem
      itemName='Филиал'
      itemText={filialData.name}
      callback={setChosenFilialId}
      to={`${PATH_NAMES.TASKS_ALL}?${QUERY_PARAMS.ACC_ID}=${filialData.accId}`}
    />
    <FilialCardWithStatusItem
      itemName='Бьюти Бот'
      callback={setChosenFilialId}
      status={filialData.beautyBotIntegration}
      statusTitle='Статус интеграции Бьюти бота'
      statusTrueText='Подключен'
      statusFalseText='Не подключен'
      to={`${PATH_NAMES.TASKS_ALL}?${QUERY_PARAMS.ACC_ID}=${filialData.accId}`}
    />
    <FilialCardItem
      itemName='Задачи'
      callback={setChosenFilialId}
      itemText={String(filialData.quantityOfTemplates)}
      to={`${PATH_NAMES.TASKS_ALL}?${QUERY_PARAMS.ACC_ID}=${filialData.accId}`}
    />
    <FilialCardWithStatusItem
      itemName='Оплата'
      callback={setChosenFilialId}
      status={!filialData.process}
      statusTitle='Статус оплаты'
      statusTrueText='Оплачен до'
      statusFalseText='Не оплачен'
      date={!filialData.process ? filialData.payPeriod : ''}
      to={`${PATH_NAMES.PAY}?${QUERY_PARAMS.ACC_ID}=${filialData?.accId}`}
    />
    <div className='flex items-center px-2 border-b border-quartz1 min-h-[4rem]'>
      <span className='font-medium px-2 text-grayText text-small_text max-w-[7.125rem] w-full'>
        Настройки
      </span>
      <NavLink
        onClick={setChosenFilialId}
        to={`${PATH_NAMES.SETTINGS}?${QUERY_PARAMS.ACC_ID}=${filialData?.accId}`}
        className={`flex justify-self-start items-center justify-center border border-dGray px-[0.75rem] py-[0.375rem] bg-white font-medium rounded text-[0.875rem] text-blackText h-[1.75rem] hover:no-underline
             ${
               !disableSettingsButton
                 ? 'hover:text-blackText hover:shadow-[0_0_0_1px_rgb(180,180,187,1)] transition-all duration-300 ease-[cubic-bezier(0.25, 0.46, 0.45, 0.94)]'
                 : 'pointer-events-none text-dGray border-dGray'
             }`}>
        Перейти
      </NavLink>
    </div>
  </div>
);
