import React from 'react';
import { NavLink } from 'react-router-dom';

interface ITemplateNavItemProps {
  /**
   * Ссылка в NavLink
   * @param {string}
   */
  to: string;
  /**
   * Текст в NavLink
   * @param {string}
   */
  text: string;
  /**
   * Флаг активности ссылки, добавляет background
   * @param {boolean}
   */
  isActive: boolean;
  /**
   * Опциональный callback обрабатывает click на NavLink
   * @param {() => void}
   * @default
   */
  onClick?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TemplateNavItem = ({
  to,
  text,
  isActive,
  onClick,
  className = '',
}: ITemplateNavItemProps) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={`inline-block text-green1 hover:text-green1 min-h-[2.5rem] leading-[2.5rem] px-[0.75rem] hover:no-underline ${
      isActive ? 'border-b-green1 border-b-4 text-myrtle' : ''
    } tracking-[0.022em] ${className}`}>
    {text}
  </NavLink>
);
