import React, { useEffect, useRef, useState } from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';

interface ILoginPageInputProps {
  /**
   * Параметр отвечающий за размер инпута
   * @param {boolean}
   */
  fill: boolean;
  /**
   * Значение инпута
   * @param {string}
   */
  value: string;
  /**
   * Параметр изменяет внешний вид
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Значение параметра name в инпуте
   * @param {string}
   */
  inputName: string;
  /**
   * Опциональный параметр, если он true в инпуте будут меняться стили при изменении параметра isError
   * @param {boolean}
   */
  isRequired?: boolean;
  /**
   * Placeholder для инпута
   * @param {string}
   */
  inputPlaceholder: string;
  /**
   * Callback для обработки изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeCallback: TOnChangeHandler;
  /**
   * Callback для обработки событий onKeyUp
   * @param {TKeyboardClickEvent}
   */
  onKeyUpCallback: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const LoginPageInput = ({
  fill,
  value,
  isError,
  inputName,
  isRequired,
  onKeyUpCallback,
  inputPlaceholder,
  onChangeCallback,
  className = '',
}: ILoginPageInputProps) => {
  const ref = useRef<HTMLElement>(null);
  const [isLocalError, setIsLocalError] = useState<boolean>(false);

  useEffect(() => {
    if (isError) {
      setIsLocalError(isError);
    }
  }, [isError]);

  useEffect(() => {
    if (ref.current && document.activeElement !== ref.current) {
      ref.current.focus();
    }
  });

  const onChangeCallbackHandler: TOnChangeHandler = event => {
    if (isLocalError) {
      setIsLocalError(false);
    }
    onChangeCallback(event);
  };

  return (
    <div className={className}>
      <TextInput
        ref={ref}
        fill={fill}
        type='text'
        value={value}
        view='outlined'
        name={inputName}
        onKeyUp={onKeyUpCallback}
        placeholder={inputPlaceholder}
        onChange={onChangeCallbackHandler}
        color={isRequired && isError ? 'danger' : 'default'}
      />
    </div>
  );
};
