import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { storageDb } from '@api/storageApi';
import { mappers } from '@redux/Report/mappers';
import { reportApi } from '@api/report/reportApi';
import { getEventData, log } from '@helpers/index';
import { TRejectValue, TReportInfo } from '@models/index';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TEditType, TReportEventData, TTemplateId } from '@helpers/myTracker/type';
import { TASK_RESPONSE_KEYS, REJECT_RESPONSE_KEY, REPORT_RESPONSE_KEYS } from '@api/types';
import { clearReportListDeleteSuccess, clearSuccessRP, setIsRefreshRl } from '@redux/Report/slice';
import { EDIT_TYPE, MY_TRACKER_EVENTS, postEvent, REPORT_TYPE } from '@helpers/myTracker/myTracker';
import {
  TReportData,
  ReportCardDataSchema,
  ReportResponseDataSchema,
  SwitchedReportDataSchema,
} from '@models/Report';

export const getReportById = createAsyncThunk<TReportData | void, { id: string }, TRejectValue>(
  'report/getReportById',
  (data, { rejectWithValue }) => {
    return reportApi
      .getReportById({ id: data.id })
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }
        if (TASK_RESPONSE_KEYS.ID in response.data) {
          const parsedData = ReportResponseDataSchema.safeParse(response.data);

          if (!parsedData.success) {
            log('@@report/getReportById pars error', parsedData.error.format());
            // throw new Error(parsedData.error.toString());
          } else {
            return mappers.responseToStore(parsedData.data);
          }
        }
      })
      .catch(error => rejectWithValue(error.response.data));
  },
);

export const saveReport = createAsyncThunk<TReportData | void, TReportData, TRejectValue>(
  'report/saveReport',
  (data, { dispatch, rejectWithValue }) => {
    const newData = mappers.storeToRequest(data);

    return reportApi
      .saveReport({ data: newData })
      .then(response => {
        setTimeout(() => dispatch(clearSuccessRP()), 1000);

        if (data.id) {
          postEvent<TEditType & TTemplateId>(
            getEventData<TEditType & TTemplateId>({
              eventName: MY_TRACKER_EVENTS.REPORT_TEMPLATE_EDIT,
              eventProperties: {
                edit_type: EDIT_TYPE.CONTENT,
                template_id: +data.id,
              },
            }),
          );
        } else {
          postEvent<TReportEventData>(
            getEventData<TReportEventData>({
              eventName: MY_TRACKER_EVENTS.REPORT_TEMPLATE_CREATE,
              eventProperties: {
                report_type: REPORT_TYPE[data.selectedDataCalculatingPeriod],
                template_id: +data.id,
              },
            }),
          );
        }

        const parsedData = ReportResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@report/saveReport pars error', parsedData.error.format());
          // throw new Error(parsedData.error.toString());
        } else {
          return mappers.responseToStore(parsedData.data);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(saveReport(data)), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const getReportList = createAsyncThunk<
  {
    data: TReportInfo[];
    order: string[];
  } | void,
  void,
  TRejectValue
>('reportList/getReportList', (_, { rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return reportApi
    .getReportListData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (REPORT_RESPONSE_KEYS.DATA in response.data) {
        const parsedData = ReportCardDataSchema.array().safeParse(response.data.data);
        if (!parsedData.success) {
          log('@@reportList/getReportList pars error', parsedData.error.format());
          throw new Error(parsedData.error.toString());
        } else {
          return {
            data: mappers.reportListToStore(parsedData.data, requestData.accId),
            order: response.data.order,
          };
        }
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const switchReport = createAsyncThunk<string | void, { reportId: string }, TRejectValue>(
  'reportList/switchReport',
  (data, { dispatch, rejectWithValue }) => {
    return reportApi
      .switchReportById({ id: data.reportId })
      .then(response => {
        const parsedData = SwitchedReportDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@reportList/switchReport pars error', parsedData.error.format());
          // throw new Error(parsedData.error.toString());
        } else {
          return String(response.data.stats_id);
        }
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(switchReport({ reportId: data.reportId })), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteReport = createAsyncThunk<string, { reportId: string }, TRejectValue>(
  'reportList/deleteReport',
  (data, { dispatch, rejectWithValue }) => {
    return reportApi
      .deleteReportById({ id: data.reportId })
      .then(response => {
        if (!response.data.ok) {
          throw new Error(String(response.data));
        }
        dispatch(setIsRefreshRl(true));
        setTimeout(() => dispatch(clearReportListDeleteSuccess()), 2000);
        return data.reportId;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(deleteReport({ reportId: data.reportId })), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);
