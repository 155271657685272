import React, { memo } from 'react';
//
import { TOnChangeHandler } from '@shared/types';

interface IToggleSwitchProps {
  /**
   * Параметр name в input для использования в onChangeHandler
   * @param {string}
   */
  name: string;
  /**
   * Параметр checked для input
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Callback обрабатывает изменение переключателя
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
}

export const ToggleSwitch = memo(
  ({ name, checked, onChangeHandler, className = '' }: IToggleSwitchProps) => (
    <div className={className}>
      <label className='flex items-center justify-between'>
        <input
          className='peer sr-only hidden h-0 w-0'
          type='checkbox'
          name={name}
          id='switch'
          checked={checked}
          onChange={onChangeHandler}
        />
        <span className='bg-lightGray rounded-full h-6 w-10 flex flex-shrink-0 items-center after:relative after:left-[0px] after:border after:border-dGray after:border-2 after:bg-gray1 after:h-6 after:w-6 after:rounded-full peer-checked:bg-perfume peer-checked:after:translate-x-[17px] peer-checked:after:border-[4px] peer-checked:after:border-green1 peer-checked:after:bg-gray1 ease-in-out duration-300 after:duration-300' />
      </label>
    </div>
  ),
);
