import { useMemo } from 'react';
import { Drawer } from '@uikit/Drawer';
import { DASHBOARD_TEXT } from '@data/dict';
import { getAppData } from '@redux/selectors';
import { useAppSelector } from '@hooks/index';
import { DASHBOARD_KEYS } from '@const/dashboard';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TDashboardDrawerProps = {
  /**
   * Параметр отвечающий за изменение состояния Drawer по клику при закрытии
   * @param {(name?: DASHBOARD_KEYS) => void}
   */
  changeDisplayDrawer: (name?: DASHBOARD_KEYS) => void;
};

export const DashboardDrawer = ({ changeDisplayDrawer }: TDashboardDrawerProps) => {
  const width = useWindowWidth();
  const {
    showAnalyticsDrawer,
    analyticsDrawerData: { subTitle, description },
  } = useAppSelector(getAppData);

  const onCloseDrawerHandler = () => changeDisplayDrawer();

  const renderTitle = useMemo(() => {
    return <span className='text-h4 font-bold text-black tracking-[0.15px]'>{subTitle}</span>;
  }, [subTitle]);
  return (
    <Drawer
      isCloseButtonShown
      isOpen={showAnalyticsDrawer}
      onClose={onCloseDrawerHandler}
      title={renderTitle}
      type='icon'
      position='right'
      size={width < 780 ? '100%' : 540}>
      <div className='flex flex-col px-3 py-6'>
        {/* <h1 className='mb-6 text-blackText text-h2Mobile'>{analyticsdrawerData.title}</h1> */}
        <h3>{DASHBOARD_TEXT.HOW_TEXT}</h3>
        <p className='mb-6 text-black text-body_text font-normal tracking-[0.44px]'>
          {description.how}
        </p>
        <h3>{DASHBOARD_TEXT.BUSINESS_TEXT}</h3>
        <p className='mb-6 text-black text-body_text font-normal tracking-[0.44px]'>
          {description.business}
        </p>
        {description.more ? (
          <>
            <h3>{DASHBOARD_TEXT.MORE_TEXT}</h3>
            <p className='mb-6 text-black text-body_text font-normal tracking-[0.44px]'>
              {description.more}
            </p>
          </>
        ) : null}
      </div>
    </Drawer>
  );
};
