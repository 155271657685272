import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Dashboard/state';
import {
  reducers,
  getDashboardDataReducers,
  getMainDashboardDataReducers,
  saveSelectedDashboardDataReducers,
} from '@redux/Dashboard/reducers';
import {
  getDashboardData,
  getMainDashboardData,
  saveSelectedDashboardData,
} from '@redux/Dashboard/thunks';

// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxODkwMzU3NDk2LCJleHBpcmVzIjoxNjg3NDE2Nzg5LjM3MTQ4Mzh9._lVyQLM3hwAD_bJZ6dpmdVkV9QvXneJa0nQtW58WmmM

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getDashboardData.pending, getDashboardDataReducers.pending);
    builder.addCase(getDashboardData.fulfilled, getDashboardDataReducers.fulfilled);
    builder.addCase(getDashboardData.rejected, getDashboardDataReducers.rejected);
    builder.addCase(getMainDashboardData.pending, getMainDashboardDataReducers.pending);
    builder.addCase(getMainDashboardData.fulfilled, getMainDashboardDataReducers.fulfilled);
    builder.addCase(getMainDashboardData.rejected, getMainDashboardDataReducers.rejected);
    builder.addCase(saveSelectedDashboardData.pending, saveSelectedDashboardDataReducers.pending);
    // builder.addCase(
    //   saveSelectedDashboardData.fulfilled,
    //   saveSelectedDashboardDataReducers.fulfilled,
    // );
    builder.addCase(saveSelectedDashboardData.rejected, saveSelectedDashboardDataReducers.rejected);
  },
});

export const dashboardReducer = dashboardSlice.reducer;
export const {
  setAdminsDates,
  setMastersDates,
  setFinancesDates,
  setAnalyticsDates,
  setSelectedTaskFilterName,
  setSelectedAdminsCategories,
  setSelectedMastersCategories,
  setSelectedFinancesCategories,
  setSelectedAnalyticsCategories,
  setSelectedAllAmountFilterName,
  setSelectedAllInCallsFilterName,
  setSelectedAllOutCallsFilterName,
  setSelectedEarnedByOutCallsFilterName,
  setSelectedReturnMasterRateFilterName,
} = dashboardSlice.actions;
