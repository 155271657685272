import { getEventData, log } from '@helpers/index';
import { noteApi } from '@api/note/noteApi';
import { TRejectValue } from '@models/index';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TFolderCreate } from '@helpers/myTracker/type';
import { noteListMappers } from '@redux/noteList/mappers';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TFolderItem, TNoteItem, TNoteTag } from '@redux/noteList/zod';
import { MY_TRACKER_EVENTS, postEvent } from '@helpers/myTracker/myTracker';
import { TNoteListPageDataToRedux, TRenameFolderData } from '@redux/noteList/types';
import {
  NoteTagResponseSchema,
  NoteResponseDataSchema,
  NoteListPageDataSchema,
  NoteFolderResponseSchema,
} from '@models/Note';

export const getNoteListPageData = createAsyncThunk<
  TNoteListPageDataToRedux | void,
  void,
  TRejectValue
>('noteList/getNoteListPageData', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .getNotesPageData()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data) {
        throw new Error('');
      }
      const parsedData = NoteListPageDataSchema.safeParse(response.data);

      if (!parsedData.success) {
        log('@@noteList/getNoteListPageData pars error', parsedData.error.format());
      } else {
        return noteListMappers.mapNoteListPageDataToRedux(response.data);
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getNoteListPageData()), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const getAllNotesThunk = createAsyncThunk<TNoteItem[] | void, void, TRejectValue>(
  'noteList/getAllNotesThunk',
  (_, { dispatch, rejectWithValue }) => {
    return noteApi
      .getAllNotes()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data) {
          throw new Error('');
        }
        const parsedData = NoteResponseDataSchema.array().safeParse(response.data);

        if (!parsedData.success) {
          log('@@noteList/getAllNotesThunk pars error', parsedData.error.format());
        } else {
          return noteListMappers.mapNotesListToRedux(response.data);
        }
      })
      .catch(error => {
        if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getAllNotesThunk()), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAllTagsThunk = createAsyncThunk<TNoteTag[] | void, void, TRejectValue>(
  'noteList/getAllTagsThunk',
  (data, { dispatch, rejectWithValue }) => {
    return noteApi
      .getAllTags()
      .then(response => {
        if (response.data && REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error('');
        }
        if (Array.isArray(response.data)) {
          const parsedData = NoteTagResponseSchema.array().safeParse(response.data);

          if (!parsedData.success) {
            log('@@noteList/getAllTagsThunk pars error', parsedData.error.format());
          } else {
            return noteListMappers.mapTagsToRedux(response.data);
          }
        }
      })
      .catch(error => {
        if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getAllTagsThunk()), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAllFoldersThunk = createAsyncThunk<TFolderItem[] | void, void, TRejectValue>(
  'noteList/getAllFoldersThunk',
  (data, { dispatch, rejectWithValue }) => {
    return noteApi
      .getAllFolders()
      .then(response => {
        if (response.data && REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error('');
        }
        if (Array.isArray(response.data)) {
          const parsedData = NoteFolderResponseSchema.array().safeParse(response.data);

          if (!parsedData.success) {
            log('@@noteList/getAllFoldersThunk pars error', parsedData.error.format());
          } else {
            return noteListMappers.mapFoldersToRedux(response.data);
          }
        }
      })
      .catch(error => {
        if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(getAllFoldersThunk()), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const createFolderThunk = createAsyncThunk<
  TFolderItem | void,
  { folderName: string; modalCloseCallback: () => void },
  TRejectValue
>('noteList/createFolderThunk', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .createFolder(data.folderName)
    .then(response => {
      if (response.data && REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error('');
      }
      if ('id' in response.data) {
        postEvent<TFolderCreate>(
          getEventData<TFolderCreate>({
            eventName: MY_TRACKER_EVENTS.FOLDER_CREATE,
            eventProperties: {
              folder_id: response.data.id,
            },
          }),
        );

        const parsedData = NoteFolderResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@noteList/createFolderThunk pars error', parsedData.error.format());
        } else {
          if (data.modalCloseCallback) {
            data.modalCloseCallback();
          }
          return noteListMappers.mapFolderToRedux(response.data);
        }
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(createFolderThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const renameFolderThunk = createAsyncThunk<
  TRenameFolderData | void,
  TRenameFolderData,
  TRejectValue
>('noteList/renameFolderThunk', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .renameFolder(data)
    .then(() => {
      if (data.modalCloseCallback) {
        data.modalCloseCallback();
      }
      return data;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(renameFolderThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const deleteFolderThunk = createAsyncThunk<
  string | void,
  { folderId: string; modalCloseCallback: () => void },
  TRejectValue
>('noteList/deleteFolderThunk', (data, { dispatch, rejectWithValue }) => {
  return noteApi
    .deleteFolder(data.folderId)
    .then(response => {
      if (typeof response.data === 'string') {
        if (data.modalCloseCallback) {
          data.modalCloseCallback();
        }
        return data.folderId;
      }
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error('');
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteFolderThunk(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

export const deleteTagThunk = createAsyncThunk<
  string | void,
  { tagId: string; modalCloseCallback: () => void },
  TRejectValue
>('note/deleteTagThunk', ({ tagId, modalCloseCallback }, { dispatch, rejectWithValue }) => {
  return noteApi
    .deleteTag(tagId)
    .then(response => {
      if (response.data && REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error('');
      }

      if (modalCloseCallback) {
        modalCloseCallback();
      }

      return tagId;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteTagThunk({ tagId, modalCloseCallback })), 100);
      }
      return rejectWithValue(error.response.data);
    });
});
