import { TDashboardData } from '@api/dashboard/types';
import { BarChart } from '@components/barChart';
import { LineChart } from '@components/lineChart';
import { ChurnByMasterChart } from '@components/churnByMasterChart';
import { chartColors, DASHBOARD_MASTERS_KEYS } from '@const/dashboard';
import { chatMultiLineData, getOneLineChartDataset } from '@helpers/index';

type TDashboardMastersChartsProps = {
  chartName: DASHBOARD_MASTERS_KEYS;
  data: TDashboardData;
  keyName: string;
  chartLabel: string;
};
export const DashboardMastersCharts = ({
  data,
  keyName,
  chartName,
  chartLabel,
}: TDashboardMastersChartsProps) => {
  if (!data || !data.masters || !data.masters[chartName]) return null;

  const isReturnMasterRate = chartName === DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE;
  const isChurnByMaster = chartName === DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER;

  if (isReturnMasterRate) {
    return (
      <LineChart
        chartTitle=''
        data={chatMultiLineData({
          data: data.masters[chartName],
          keyName,
          colors: chartColors,
        })}
      />
    );
  }
  if (isChurnByMaster) {
    return (
      <ChurnByMasterChart
        isMultiLineChart
        data={data.masters[chartName]}
        eventData={[]}
        keyName={keyName}
        chartTitle=''
      />
    );
  }

  return (
    <BarChart
      chartTitle=''
      data={getOneLineChartDataset({
        chartData: data.masters[chartName].map(dataItem => ({
          ...dataItem,
          value: dataItem.created,
        })),
        key: keyName,
        chartLabel,
        borderColor: chartColors['green-30'],
        backgroundColor: chartColors['green-30'],
      })}
    />
  );
};
