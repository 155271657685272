import React, { memo, useCallback, useMemo, useState } from 'react';
//
import { Drawer } from '@uikit/Drawer';
import { Button } from '@uikit/Button';
import { Navbar } from '@uikit/Navbar';
import { SideNav } from '@blocks/sideNav';
import { Support } from '@blocks/support';
import { HomeLink } from '@blocks/homeLink';
import { Hamburger } from '@uikit/Hamburger';
import { PATH_NAMES } from '@const/httpConst';
import { TTelegramAuthData } from '@models/Auth';
import { LinkButton } from '@components/linkButton';

interface IHeaderProps {
  /**
   * Состояние компонента Drawer открыт/закрыт
   * @param {boolean}
   */
  isOpen?: boolean;
  /**
   * Опциональный параметр, га странице аккаунтов отключается меню
   * @param {boolean}
   * @default
   */
  isAccountPage?: boolean;
  /**
   * Callback вызывается при нажатии на копку выход
   * @param {() => void}
   */
  logoutHandler: () => void;
  /**
   * Опциональный callback для обработки нажатий на гамбургер
   * @param {() => void}
   */
  onClickHandler?: () => void;
  /**
   * Линк на канал поддержки в telegram
   * @param {string}
   */
  supportTelegramLink: string;
  /**
   * Телефонный номер поддержки, передается без форматирования в параметр href
   * @param {string}
   */
  supportTelegramPhoneToHref: string;
  /**
   * Телефонный номер поддержки с форматированием для показа на странице
   * @param {string}
   */
  supportTelegramPhoneToShow: string;
  /**
   * Данные авторизованного пользователь для отображения имени, фамилии, аватарки
   * @param {TTelegramAuthData}
   */
  authUserData: TTelegramAuthData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Header = memo(
  ({
    isOpen = false,
    authUserData,
    isAccountPage = false,
    logoutHandler,
    onClickHandler = () => '',
    supportTelegramLink,
    supportTelegramPhoneToHref,
    supportTelegramPhoneToShow,
    className = '',
  }: IHeaderProps) => {
    const [isShowLogoutButton, setIsShowLogoutButton] = useState(false);

    const showLogoutButtonToggle = useCallback(() => {
      setIsShowLogoutButton(prevState => !prevState);
    }, []);

    const memoizedDrawer = useMemo(
      () => (
        <Drawer
          size='20.5rem'
          position='left'
          isOpen={isOpen}
          onClose={onClickHandler}
          className='overflow-auto'>
          <div className='flex items-center ml-4 mt-2 mb-10 h-[4.5rem]'>
            <Button
              dense
              fill={false}
              type='action'
              view='smooth'
              color='default'
              icon='arrow-left'
              onClick={onClickHandler}
              className='mr-3 !h-[2.75rem] w-[2.75rem]'
            />
            <HomeLink href={PATH_NAMES.ACCOUNTS} isDrawer />
          </div>
          <SideNav onClickHandler={onClickHandler} isMobile />
        </Drawer>
      ),
      [isOpen, onClickHandler],
    );

    const memoizedHamburger = useMemo(
      () => (
        <Hamburger
          rounded
          size={12}
          type='circle'
          view='smooth'
          variant='spin'
          color='default'
          isOpen={false}
          setOpen={onClickHandler}
          className='self-start lg:hidden'
        />
      ),
      [onClickHandler],
    );

    const initial = `${(authUserData.firstName || '').slice(0, 1)}${(
      authUserData.lastName || ''
    ).slice(0, 1)}`;

    return (
      <header>
        {isShowLogoutButton ? (
          <div
            onClick={showLogoutButtonToggle}
            className='absolute top-0 right-0 bottom-0 left-0 z-[1]'
          />
        ) : null}
        <div className={`lg:mx-[1.5rem] ${className}`}>
          <Navbar
            view='raised'
            color='default'
            className='flex items-center justify-between flex-wrap p-4 mx-auto max-w-[101.5rem]'>
            <div className='flex items-center justify-self-start'>
              {isAccountPage ? null : memoizedHamburger}
              <HomeLink href={PATH_NAMES.ACCOUNTS} className={isAccountPage ? '' : 'ml-2.5'} />
            </div>
            <Support
              supportTelegramLink={supportTelegramLink}
              supportPhoneToHref={supportTelegramPhoneToHref}
              supportPhoneToShow={supportTelegramPhoneToShow}
              className='hidden sm:flex'
            />
            <div className='flex items-center'>
              <span className='hidden text-darkGray lg:inline tracking-[0.0275em] mr-5'>{`${
                authUserData.firstName || ''
              } ${authUserData.lastName || ''}`}</span>
              <div className='relative'>
                {authUserData.photoUrl ? (
                  <img
                    alt='Аватар пользователя'
                    src={authUserData.photoUrl}
                    onClick={showLogoutButtonToggle}
                    className='rounded-full h-[2.5rem] w-[2.5rem] lg:hidden sm:mr-5'
                  />
                ) : (
                  <div
                    onClick={showLogoutButtonToggle}
                    className='rounded-full bg-gray4 flex flex-wrap items-center justify-center lg:hidden sm:mr-5 h-10 w-10'>
                    <span className='font-medium leading-6'>{initial}</span>
                  </div>
                )}
                {isShowLogoutButton ? (
                  <div className='sm:hidden absolute top-16 bg-white rounded-lg p-2 translate-x-[-60%] shadow-dropdownShadow z-[2]'>
                    <LinkButton
                      href={PATH_NAMES.START_PAGE}
                      icon='logout'
                      callback={logoutHandler}
                      className='!min-w-[7rem]'>
                      Выйти
                    </LinkButton>
                  </div>
                ) : null}
              </div>
              <div className='hidden sm:flex'>
                <LinkButton href={PATH_NAMES.START_PAGE} icon='logout' callback={logoutHandler}>
                  Выйти
                </LinkButton>
              </div>
            </div>
          </Navbar>
          {memoizedDrawer}
        </div>
      </header>
    );
  },
);
