import React, { forwardRef } from 'react';
//
import { Label } from '@atoms/label';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, IWithChildren } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface ISettingInputWithLabel extends IWithChildren {
  /**
   * Значение инпута
   * @param {string}
   */
  value: string;
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр tailwind класс для изменения цвета текста label
   * @param {string}
   * @default
   */
  textColor?: string;
  /**
   * Опциональный параметр добавляет к тексту label знак *
   * @param {boolean}
   * @default
   */
  isRequire?: boolean;
  /**
   * Опциональный параметр, значение placeholder в input
   * @param {string}
   */
  placeHolder?: string;
  /**
   * Параметр для отключения инпута
   * @param {boolean}
   */
  disabled?: boolean;
  /**
   * Опциональный параметр, если передан ключ, из словаря берется текст и подставляется в label
   * можно передать и ключ и children
   * @param {THelpKeys}
   */
  variant?: THelpKeys;
  /**
   * Callback для обработки изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback для очистки значения в инпуте
   * @param {TOnChangeHandler}
   */
  clearSearchString: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Опциональный параметр отвечающий за отображение ошибки
   * @param {boolean}
   */
  error?: boolean;
  /**
   * Опциональный параметр отвечающий за отображаемый текст ошибки
   * @param {string}
   */
  errorText?: string;
}

type TRef = HTMLInputElement;

export const SettingInputWithLabel = forwardRef<TRef, ISettingInputWithLabel>(
  (
    {
      value,
      htmlFor,
      children,
      className = '',
      isRequire,
      onChangeHandler,
      placeHolder = '',
      disabled,
      textColor = '',
      clearSearchString,
      variant,
      errorText,
      error,
    },
    ref,
  ) => (
    <div className={`flex flex-col ${className}`}>
      <Label
        htmlFor={htmlFor}
        className={`font-inter text-body_text ${textColor || 'text-grayText'} font-medium mb-1`}>
        {children}
        {isRequire ? '*' : ''}
        {variant ? <IconWithTooltips tooltips={variant} /> : null}
      </Label>
      <TextInput
        ref={ref}
        type='text'
        view='outlined'
        color='default'
        clearButton={!disabled && !error && !!value}
        id={htmlFor}
        value={value}
        name={htmlFor}
        disabled={disabled}
        required={isRequire}
        onChange={onChangeHandler}
        placeholder={placeHolder}
        className='!w-full'
        clearSearchString={clearSearchString}
        error={error}
      />
      {error && errorText ? (
        <p className='text-small_text text-statusRed mt-1'>{errorText}</p>
      ) : null}
    </div>
  ),
);
