import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { TRequestStatus, TAuthErrorResponse } from '@api/types';
import {
  TAuthResponse,
  TLoginUserByPin,
  TTelegramPinAuthResponse,
  TTelegramAuthResponseData,
} from '@models/Auth';

/**
 * API страницы авторизации
 * @module authApi
 */

export const authApi = {
  /**
   * Метод выполняет запрос для авторизации пользователя
   * @method loginUser
   * @param data данные пользователя для авторизации передает виджет телеграма
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  loginUser(data: TTelegramAuthResponseData) {
    return apiService.post<TAuthResponse | TAuthErrorResponse>(END_POINTS_URL.GET_TOKEN, data);
  },
  /**
   * Метод выполняет запрос для получения пина для авторизации через бота
   * @method getPin
   * @param user_id 'telegram ID пользователя'
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  getPin(user_id: string) {
    return apiService.get<TRequestStatus>(END_POINTS_URL.GET_PIN, {
      user_id,
    });
  },
  /**
   * Метод выполняет запрос для проверки пина и последующей авторизации пользователя в приложении
   * @method loginUserByPin
   * @param data telegram ID пользователя и pin для авторизации
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  loginUserByPin(data: TLoginUserByPin) {
    return apiService.post<TTelegramPinAuthResponse | TRequestStatus>(END_POINTS_URL.CHECK_PIN, {
      user_id: data.userId,
      pin: data.pin,
    });
  },
  /**
   * Обновляет токен.
   * @method updateToken
   * @param token - токен  для обновления
   * @return {Promise<AxiosResponse<TAuthResponse | TAuthErrorResponse, any>>}
   */
  updateToken(token: string) {
    return apiService.get<TAuthResponse | TAuthErrorResponse>(END_POINTS_URL.UPDATE_TOKEN, {
      token,
    });
  },
};
