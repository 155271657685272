import React, { memo } from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { selectTextContentOnFocus } from '@helpers/index';

interface IEventInputBlockProps {
  /**
   * Значение input
   * @param {string}
   */
  inputValue: string;
  /**
   * Callback для обработки изменения значения в input
   * @param {TOnChangeHandler}
   */
  InputOnChangeCallback: TOnChangeHandler;
  /**
   * Текст перед input
   * @param {string}
   */
  textBeforeInput: string;
  /**
   * Опциональный параметр текст после input
   * @param {string}
   * @default
   */
  textAfterInput?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const EventInputBlock = memo(
  ({
    inputValue,
    InputOnChangeCallback,
    textBeforeInput,
    textAfterInput,
    className = '',
  }: IEventInputBlockProps) => (
    <div className={`flex items-center ${className}`}>
      <p className='text-black tracking-[0.0275em] mb-0'>{textBeforeInput}</p>
      <TextInput
        min={1}
        color='default'
        view='outlined'
        value={inputValue}
        onChange={InputOnChangeCallback}
        onClick={selectTextContentOnFocus}
        className='mx-2 !h-10 !w-12'
      />
      {textAfterInput ? <p className='mb-0'>{textAfterInput}</p> : null}
    </div>
  ),
);
