import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//
import { login } from '@redux/Auth/thunk';
import { PATH_NAMES } from '@const/httpConst';
import { getAuthData } from '@redux/selectors';
import { LoginTemplate } from '@templates/login';
import { TTelegramAuthResponseData } from '@models/Auth';
import { useAppDispatch, useAppSelector } from '@hooks/index';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const {
    pin,
    auth,
    userId,
    isError,
    isLoading,
    telegramId,
    isFetching,
    isInputError,
    isGetPinError,
    isChekPinError,
    isGetPinSuccess,
  } = useAppSelector(getAuthData);

  // Из хука получаем объект navigate для организации редиректа
  const navigate = useNavigate();

  // Функция вызывается в LoginTemplate в нее передаются данные пользователя
  // затем они отправляются на бэк для авторизации
  const loginUser = (data: TTelegramAuthResponseData) => {
    dispatch(login(data));
  };

  // В случае успешной авторизации переходим на страницу аккаунтов
  useEffect(() => {
    if (auth && !isError && !isLoading) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [auth, userId, isError, isLoading, navigate]);

  return (
    <LoginTemplate
      pinValue={pin}
      isError={isError}
      loginUser={loginUser}
      isFetching={isFetching}
      isInputError={isInputError}
      telegramIdValue={telegramId}
      isGetPinError={isGetPinError}
      isChekPinError={isChekPinError}
      isGetPinSuccess={isGetPinSuccess}
    />
  );
};

export default LoginPage;
