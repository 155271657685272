/**
 * Константы и энамы которые используются по всему приложению
 * @module Common
 */

/**
 * Литерал объекта со списком регулярных выражений
 * @const
 */
export const REG_EXP = {
  onlyNumbers: /\D/g,
  removeAllSpace: /\s/g,
};

/**
 * Энам DOM событий
 * @enum EVENT_TYPE_NAMES
 */
export enum EVENT_TYPE_NAMES {
  BLUR = 'blur',
  KEYUP = 'keyup',
}

/**
 * Энам имен клавиш для события keyUp
 * @enum EVENT_KEY_NAMES
 */
export enum EVENT_KEY_NAMES {
  ENTER = 'Enter',
  BACKSPACE = 'Backspace',
}

/**
 * Энам сообщений для toast
 * @enum COMMON_TOAST_MESSAGES
 */
export enum COMMON_TOAST_MESSAGES {
  SAVE_SUCCESS = 'Сохранено',
  COMMON_ERROR_MESSAGE = 'Ошибка\n перезагрузите страницу \n и попробуйте еще раз',
  COMMON_SAVE_ERROR_MESSAGE = 'Ошибка сохранения, \n перезагрузите страницу \n и попробуйте еще раз',
}

export enum PROMISE_STATUS {
  REJECTED = 'rejected',
}

export type TInstructionData = {
  headerText: string;
  videoLink: string;
  startTime: string;
};

export const VIDEO_INSTRUCTIONS: Record<string, TInstructionData[]> = {
  login: [
    {
      headerText: 'Как войти в личный кабинет',
      videoLink: 'https://www.youtube.com/embed/rTmWOxqtNWs?si=hZ280vDFt28MRviN',
      startTime: '1',
    },
  ],
  connection: [
    {
      headerText: 'Подключение Бьюти Бота к рабочему номеру салона красоты',
      videoLink: 'https://www.youtube.com/embed/PnjYbTxSN_g?si=iQwnp1U-DNvwoL5I',
      startTime: '1',
    },
  ],
  templates: [
    {
      headerText: 'Типы шаблонов',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '1',
    },
    {
      headerText: 'Включение и отключение рассылки шаблона',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '72',
    },
    {
      headerText: 'Удаление шаблона',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '84',
    },
    {
      headerText: 'Создание и редактирование шаблона',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '91',
    },
    {
      headerText: 'Отправка рассылки потерявшимся клиентам',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '554',
    },
    {
      headerText: 'Цепочка отзывов',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '663',
    },
    {
      headerText: 'Статистика по шаблонам',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '769',
    },
    {
      headerText: 'Копирование шаблонов и их настроек',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '785',
    },
    {
      headerText: 'Редактирование шаблонов из тг бота',
      videoLink: 'https://www.youtube.com/embed/ZB_HHlOaXQg?si=h14hm74soCpPZpwM&amp',
      startTime: '814',
    },
  ],
};
