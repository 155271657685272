import React, { memo, useMemo } from 'react';
//
import { numWord } from '@helpers/index';
import { Counter } from '@uikit/Counter';
import { TWeekDays } from '@models/Tasks';
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { SENDING_PERIODS, sendingPeriods, weekDays } from '@const/task';

import { WhenTime } from '../whenTime';

interface IRegularBlockProps {
  /**
   * Создавать задачу через каждые И дней
   * @param {string}
   */
  frequency: number;
  /**
   * Callback для установки параметра frequency
   * @param {(frequency: number) => void}
   */
  setFrequency: (frequency: number) => void;
  /**
   * В какой день недели отправлять
   * @param {string}
   */
  sendWeekDays: TWeekDays;
  /**
   * Callback для установки дня недели для отправки
   * @param {(weekDay: string) => () => void}
   */
  setWhenSendDay: (weekDay: string) => () => void;
  /**
   * Значение периода отправки (каждый день, каждую неделю, раз в месяц)
   * @param {string}
   */
  selectedSendingPeriod: string;
  /**
   * Callback для изменения периода отправки
   * @param {(period: string) => () => void}
   */
  selectSendingPeriod: (period: string) => () => void;
  /**
   * Время постановки
   * @param {string}
   */
  sendingTime: string;
  /**
   * Callback для изменения времени постановки
   * @param {(value: string) => () => void}
   */
  setWhenTime: (value: string) => void;
  /**
   * В какой день месяца отправлять
   * @param {string}
   */
  monthSendDays: string;
  /**
   * Callback для изменения дня отправления в месяце
   * @param {TOnChangeHandler}
   */
  setMonthSendDayHandler: TOnChangeHandler;
  /**
   * Отправлять в последний день месяца
   * @param {boolean}
   */
  sendOnLastDayOfMonth: boolean;
  /**
   * Callback для изменения параметра отправлять в последний день месяца
   * @param {(checked: boolean) => void}
   */
  setSendOnLastDayOfMonth: TOnChangeHandler;
  /**
   * Callback вызывается при событии Blur и происходит сортировка дней месяца по порядку
   * @param {() => void}
   */
  sortMonthSendDays: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const RegularBlock = memo(
  ({
    frequency,
    sendingTime,
    setWhenTime,
    setFrequency,
    sendWeekDays,
    monthSendDays,
    setWhenSendDay,
    sortMonthSendDays,
    selectSendingPeriod,
    sendOnLastDayOfMonth,
    selectedSendingPeriod,
    setMonthSendDayHandler,
    setSendOnLastDayOfMonth,
    className = '',
  }: IRegularBlockProps) => {
    // Массив чекбоксов с днями недели для отображения
    const weekDaysList = useMemo(
      () =>
        Object.entries(weekDays).map(item => (
          <Checkbox
            key={item[0]}
            color='success'
            name={item[0]}
            checked={sendWeekDays[item[0] as unknown as keyof TWeekDays]}
            className=''
            onChange={setWhenSendDay(item[0])}
            label={item[1]}
          />
        )),
      [sendWeekDays, setWhenSendDay],
    );

    const memoizedTexInput = useMemo(
      () => (
        <TextInput
          color='default'
          view='outlined'
          value={monthSendDays}
          onBlur={sortMonthSendDays}
          onChange={setMonthSendDayHandler}
          className='mx-2 mb-4 h-10 !max-w-[7.5rem]'
        />
      ),
      [monthSendDays, sortMonthSendDays, setMonthSendDayHandler],
    );

    const memoizedCounter = useMemo(
      () => (
        <Counter
          view='outlined'
          leftColor='success'
          rightColor='success'
          value={frequency}
          leftIcon='plus'
          rightIcon='minus'
          onChange={setFrequency}
        />
      ),
      [frequency, setFrequency],
    );

    const memoizedCheckbox = useMemo(
      () => (
        <Checkbox
          color='success'
          checked={sendOnLastDayOfMonth}
          onChange={setSendOnLastDayOfMonth}
        />
      ),
      [sendOnLastDayOfMonth, setSendOnLastDayOfMonth],
    );

    return (
      <div className={`flex flex-col ${className}`}>
        <div className='flex flex-wrap flex-col sm:flex-row sm:items-center mb-8'>
          <div className='sm:mr-6 w-full sm:max-w-[15rem] mb-4 sm:mb-0'>
            <DropdownWithIcon
              items={sendingPeriods}
              callBack={selectSendingPeriod}
              selectedItem={selectedSendingPeriod}
              className='w-full sm:max-w-[15rem]'
            />
          </div>
          <div className='flex items-center'>
            <span className='tracking-[0.0275em] mr-6'>в</span>
            <WhenTime whenTime={sendingTime} onChangeHandler={setWhenTime} />
          </div>
        </div>
        {selectedSendingPeriod === SENDING_PERIODS.EVERY_FEW_DAYS ? (
          <div className='flex items-center mb-8'>
            <span className='mr-2'>Создавать через каждые</span>
            {memoizedCounter}
            <span className='ml-2'>{numWord(frequency, ['день', 'дня', 'дней'])}</span>
          </div>
        ) : null}
        {selectedSendingPeriod === SENDING_PERIODS.WEEK ? (
          <div className='flex flex-wrap items-center'>
            <p className='mr-2 tracking-[0.0275em] mb-4 min-w-[6.5rem]'>В какие дни:</p>
            <div className='grid grid-cols-weekDays gap-[0.5rem] mb-4 w-full md:max-w-[39rem]'>
              {weekDaysList}
            </div>
          </div>
        ) : null}
        {selectedSendingPeriod === SENDING_PERIODS.MONTH ? (
          <div className='flex flex-col align-middle'>
            <div className='flex flex-wrap items-center'>
              <p className='tracking-[0.0275em] mb-4'>Укажите числа месяца через запятую:</p>
              {memoizedTexInput}
            </div>
            <div className='flex flex-wrap items-center'>
              <p className='mr-4 mb-0 tracking-[0.0275em]'>и/или</p>
              {memoizedCheckbox}
              <p className='mb-0 tracking-[0.0275em]'>В последний день месяца</p>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);
