import React, { memo } from 'react';
//
import { LABEL_TEXT } from '@data/dict';
import { THelpKeys } from '@models/index';
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface ISettingsCheckboxProps {
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Состояние инпута
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Вариант текста для метки, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Флаг, показывает/скрывает иконку с подсказкой
   * @param {boolean}
   */
  isShowInfoIcon: boolean;
  /**
   * Callback для обработки изменения значения в инпуте
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const SettingsCheckbox = memo(
  ({
    htmlFor,
    checked,
    variant,
    onChangeHandler,
    isShowInfoIcon,
    className = '',
  }: ISettingsCheckboxProps) => (
    <div className={`flex items-center rounded min-h-7 ${checked ? 'bg-[#ECF7ED]' : ''} w-fit`}>
      <Checkbox
        color='success'
        name={htmlFor}
        checked={checked}
        className={className}
        onChange={onChangeHandler}
        label={LABEL_TEXT[variant]}
      />
      {isShowInfoIcon ? (
        <IconWithTooltips tooltips={variant} className='flex items-center mr-2' />
      ) : null}
    </div>
  ),
);
