import {
  EAdminState,
  TAdminState,
  TSettingsData,
  TSettingStaff,
  TPostAdminsData,
  TAdminsListItem,
  TAdminsPermission,
  TGetSettingResponseData,
  TGetSmartScheduleResponse,
  TGetAdminsTableDataResponse,
  TSettingsTelephonyDataResponse,
  TSettingsTelephonyData,
  TSettingsTelephonyDataTest,
  TSettingsTelephonyResponseData,
  CONNECTION_TYPE,
  TTelephonyResponse,
  TTelephonyTablePageData,
} from '@models/Settings';
import { OPERATOR_NAMES } from '@const/settings';
import { emptyTelephony, notSelectedOperator } from '@data/mockData';

export const settingsMappers = {
  smartScheduleResponseToStore: (data: TGetSmartScheduleResponse[]): TSettingStaff[] => {
    return data.map(staffItem => ({
      id: staffItem.id,
      name: staffItem.name,
      status: staffItem.status,
      staffLink: staffItem.staff_link,
    }));
  },
  settingsResponseToStore: (data: TGetSettingResponseData): TSettingsData => {
    const { churn, missed_call_answer, exception_stats_master, params_calc_stats, call_stats } =
      data;
    return {
      selectedStaff: '',
      staffList: {},
      employeesToIgnore: exception_stats_master.map(item => String(item)),
      missedCallAnswer: missed_call_answer,
      customerChurnTime: String(churn.churn_days || ''),
      churnTimeAllowUpdate: churn.update_allowed_at || '',
      callStats: call_stats,
      paramsCalcStats: {
        startRate: params_calc_stats?.end_rate || 0, // Соответствует полю Текущий период
        endRate: params_calc_stats?.start_rate || 1, // Соответствует полю Прошлый период
      },
      useGroups: data.use_groups,
    };
  },
  mapSettingsDataToBack: (data: TSettingsData): TGetSettingResponseData => ({
    missed_call_answer: data.missedCallAnswer,
    exception_stats_master: data.employeesToIgnore,
    churn: {
      churn_days: Number(data.customerChurnTime),
      update_allowed_at: String(data.churnTimeAllowUpdate),
    },
    call_stats: data.callStats,
    params_calc_stats: {
      start_rate: data.paramsCalcStats.endRate, // Прошлый период - это start_rate на бэке
      end_rate: data.paramsCalcStats.startRate, // Текущий период - это end_rate на бэке
    },
    use_groups: data.useGroups,
  }),
  adminsResponseToStore: (data: TGetAdminsTableDataResponse[]): TAdminsListItem[] => {
    return data.map(staffItem => {
      const adminRights = staffItem.permissions
        ? Object.entries(staffItem.permissions)
            .map(permission => {
              return permission[1] ? permission[0] : '';
            })
            .filter(item => !!item)
        : [];

      const state: TAdminState =
        staffItem.state === null
          ? EAdminState.NOT_ACTIVATED
          : staffItem.state
          ? EAdminState.OPEN
          : EAdminState.CLOSE;

      return {
        adminRights,
        id: staffItem.id,
        name: staffItem.name,
        state,
        adminLink: staffItem.admin_link,
      };
    });
  },
  adminsDataToBack: (data: TAdminsListItem[]): TPostAdminsData[] => {
    return data.map(adminItem => {
      const permissions: Record<string, boolean> = {
        analytics: false,
        settings: false,
        templates: false,
      };

      adminItem.adminRights.forEach(right => {
        permissions[right] = true;
      });

      const state =
        adminItem.state === EAdminState.NOT_ACTIVATED ? null : adminItem.state === EAdminState.OPEN;

      return {
        permissions: permissions as TAdminsPermission,
        id: adminItem.id,
        state,
      };
    });
  },
};

export const settingsTelephonyMappers = {
  responseToStore(data: TSettingsTelephonyDataResponse): TSettingsTelephonyData {
    const { telephony_list } = data;
    const possibleOperatorTypes = { yclients: false, manual: false };

    const { name, title, telnum, ...rest } = data.telephony || emptyTelephony;

    if (name) {
      const { yclients, manual } = telephony_list.filter(
        operator => operator.title === title.toLowerCase(),
      )[0];

      possibleOperatorTypes.yclients = yclients;
      possibleOperatorTypes.manual = manual;
    }

    const connectionType = name
      ? name === CONNECTION_TYPE.YCLIENTS
        ? CONNECTION_TYPE.YCLIENTS
        : CONNECTION_TYPE.MANUAL
      : CONNECTION_TYPE.YCLIENTS;

    return {
      ...rest,
      connectionType,
      selectedOperator: name && title ? title : OPERATOR_NAMES.NOT_SELECTED,
      phoneNumber: telnum,
      operators: [
        notSelectedOperator,
        ...telephony_list.map(operator => ({
          name: operator.title,
          manual: operator.manual,
          webhook: operator.webhook,
          yclients: operator.yclients,
          manualDocs: operator.manual_docs,
          yclientsDocs: operator.yclients_docs,
          fields: operator.fields,
        })),
      ],
      connectionStatus: !!name && !!telnum,
      possibleConnectionTypes: possibleOperatorTypes,
    };
  },
  storeToRequest({
    domain,
    branchId,
    phoneNumber,
    connectionType,
    selectedOperator,
    ...rest
  }: TSettingsTelephonyDataTest): TSettingsTelephonyResponseData {
    const isManual = connectionType === CONNECTION_TYPE.MANUAL;

    // Нужно для того, чтобы в запрос ушли только нужные данные
    // eslint-disable-next-line
    const { operators, possibleConnectionTypes, connectionStatus, ...restData } = rest;

    if (isManual) {
      return {
        ...restData,
        title: selectedOperator,
        name: selectedOperator,
        telnum: phoneNumber,
        domain,
      };
    }

    return {
      title: selectedOperator,
      name: CONNECTION_TYPE.YCLIENTS,
      telnum: phoneNumber,
      domain: String(branchId),
      token: '',
    };
  },
};

export const telephonyMappers = {
  responseToStore: (data: TTelephonyResponse): TTelephonyTablePageData => {
    return {
      paginator: {
        size: data.paginator.size,
        currentPage: data.paginator.current_page,
        totalPages: data.paginator.total_pages,
        count: data.paginator.count,
      },
      data: data.data,
    };
  },
};
