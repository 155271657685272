import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Editor, Element as SlateElement } from 'slate';
import { Dialog } from '@uikit/Dialog';
import { Icon } from '@blueprintjs/core';
import { EVENT_KEY_NAMES } from '@const/common';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import {
  TSetLinkOnClose,
  TOnChangeHandler,
  TKeyboardClickEvent,
  TSetLinkOnCloseHandler,
} from '@shared/types';

type TProps = {
  /**
   * Флае показа модалки
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Срабатывает при закрытии модалки
   * @param {TSetLinkOnClose | null}
   */
  onCloseCallback: TSetLinkOnClose | null;
  /**
   * Отключает поле ввода названия ссылки
   * @param {boolean}
   */
  isInsertImageLink: boolean;
  /**
   * Удаляет ссылку,оставляя только текст
   * @param {() => void}
   */
  deleteLink?: () => void;
  /**
   * Редактор для доступа к данным
   * @param {Editor}
   */
  editor: Editor;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const AddLinkModal = memo(
  ({ editor, isOpen, deleteLink, onCloseCallback, isInsertImageLink, className = '' }: TProps) => {
    const ref = useRef<boolean[]>([false, false]);
    const [values, setValues] = useState({
      link: '',
      text: '',
    });

    const selectedText = editor.selection ? Editor.string(editor, editor.selection) : '';

    const firstSelectedNode = Editor.nodes(editor, {
      // eslint-disable-next-line
      // @ts-ignore
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    }).next().value;

    // eslint-disable-next-line
    // @ts-ignore
    const activeNodeLink = firstSelectedNode?.[0]?.url || '';

    // eslint-disable-next-line
    // @ts-ignore
    const activeNodeText = firstSelectedNode?.[0]?.children?.[0]?.text || '';

    useEffect(() => {
      if (activeNodeLink && !values.link && isOpen && !ref.current[0]) {
        setValues(prevState => ({ ...prevState, link: activeNodeLink }));
        ref.current = [true, ref.current[1]];
      }
      if ((activeNodeText || selectedText) && !values.text && isOpen && !ref.current[1]) {
        setValues(prevState => ({ ...prevState, text: activeNodeText || selectedText }));
        ref.current = [ref.current[0], true];
      }
      return () => {
        if ((values.link || values.text) && !isOpen) {
          setValues({ link: '', text: '' });
          ref.current = [false, false];
        }
      };
    }, [values, activeNodeLink, activeNodeText, selectedText, isOpen]);

    const onChangeHandler: TOnChangeHandler = useCallback(event => {
      const { name, value } = event.currentTarget;

      setValues(prevState => ({ ...prevState, [name]: value }));
    }, []);

    const closeModalHandler: TSetLinkOnCloseHandler = useCallback(
      ({ newLink, newText }) =>
        () => {
          if (onCloseCallback) {
            ref.current = [];
            setValues({ link: '', text: '' });
            onCloseCallback({ newLink, newText });
          }
        },
      [onCloseCallback],
    );

    const onKeyUpHandler: TKeyboardClickEvent = useCallback(
      event => {
        const { key } = event;

        if (key === EVENT_KEY_NAMES.ENTER) {
          closeModalHandler({ newLink: values.link, newText: values.text })();
        }
      },
      [closeModalHandler, values],
    );

    const deleteLinkHandler = useCallback(() => {
      if (deleteLink) {
        deleteLink();
        ref.current = [];
        setValues({ link: '', text: '' });
      }
    }, [deleteLink]);

    const modalContent = (
      <div className='flex flex-col'>
        {activeNodeLink && deleteLink ? (
          <div className='flex items-center justify-end mb-2'>
            <button
              aria-label='delete link'
              onClick={deleteLinkHandler}
              className='opacity-40 hover:opacity-100'>
              <Icon icon='trash' size={20} color='black' />
            </button>
          </div>
        ) : null}
        <div className='flex flex-col mb-2'>
          <span className='text-stormGray leading-6 tracking-[0.0275em] mb-0.5'>
            URL адрес ссылки
          </span>
          <TextInput
            fill
            type='text'
            name='link'
            clearButton
            view='outlined'
            color='default'
            value={values.link}
            onKeyUp={onKeyUpHandler}
            onChange={onChangeHandler}
            clearSearchString={() => setValues(prevState => ({ ...prevState, link: '' }))}
          />
        </div>
        {!isInsertImageLink ? (
          <div className='flex flex-col'>
            <span className='text-stormGray leading-6 tracking-[0.0275em] mb-0.5'>
              Название ссылки
            </span>
            <TextInput
              fill
              type='text'
              name='text'
              clearButton
              view='outlined'
              color='default'
              value={values.text}
              onKeyUp={onKeyUpHandler}
              onChange={onChangeHandler}
              clearSearchString={() => setValues(prevState => ({ ...prevState, text: '' }))}
            />
          </div>
        ) : null}
      </div>
    );
    return (
      <div className={className}>
        <Dialog
          type='default'
          view='raised'
          color='default'
          text={modalContent}
          backdropOpacity={40}
          isCloseButtonShown={false}
          isOpen={isOpen && !!onCloseCallback}
          onClose={closeModalHandler({ newLink: values.link, newText: values.text })}
        />
      </div>
    );
  },
);

AddLinkModal.displayName = 'AddLinkModal';
