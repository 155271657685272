import { TMeta, TAnalyticsAdmin, TAnalyticsResponseData, TGeneral } from '@models/Analytics';

export const mapAnalyticsResponseToStore = ({
  data,
  meta,
}: TAnalyticsResponseData): {
  adminsList: TAnalyticsAdmin[];
  generalData: TGeneral;
  selectedCharts: TMeta;
} => {
  const { admins, generals } = data;

  return {
    adminsList: admins,
    generalData: generals,
    selectedCharts: meta,
  };
};
