import React, { memo, useEffect, useMemo, useState } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { useAppDispatch } from '@hooks/index';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { THelpKeys, TSimpleStringObj } from '@models/index';
import { getSecondDropdownListTitle } from '@helpers/index';
import { getSettingsPageStaffList } from '@redux/Settings/thunk';
import { TDeleteBalloonSet, TOnChangeHandler } from '@shared/types';

interface ISettingsInputWithDropdownProps {
  /**
   * Массив данных для отображения Chips components
   * @param {string[]}
   */
  addedValues: string[];
  /**
   * Id на input для ввода нового значения
   * @param {string}
   */
  inputId: string;
  /**
   * Параметр передает данные по успешном завершении получения данных страницы,
   * используется для отправки запроса к ЕР для получения списка для dropdown.
   * Запрос за списком уходит только если этот параметр true.
   * @param {boolean}
   */
  isFetchingSuccess: boolean;
  /**
   * Вариант текста в label, из словаря берется текст и подставляется в label
   * @param {THelpKeys}
   */
  labelTextVariant: THelpKeys;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Объект с данными для отображения выпадающего списка
   * @param {TSimpleStringObj}
   */
  staffDropdownData: TSimpleStringObj;
  /**
   * Опциональный параметр включает или отключает валидацию нового значения, если параметр true
   * в input нельзя ввести текст, только число.
   * @param {boolean}
   * @default
   */
  typeOfValueIsNumber?: boolean;
  /**
   * Callback для удаления Chips component
   * @param {TDeleteBalloonSet}
   */
  deleteBalloon: TDeleteBalloonSet;
  /**
   * Callback для добавления Chips component при событии blur
   * @param {TAddBalloonOnBlur}
   */
  addBalloonOnBlur: (name: string, value: string) => void;
  /**
   * Флаг по которому изменяется цвет чипса на красный и добавляется иконка
   * @param {boolean}
   * @default
   */
  isDanger?: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const SettingsInputWithDropdown = memo(
  ({
    inputId,
    addedValues,
    deleteBalloon,
    addBalloonOnBlur,
    labelTextVariant,
    isFetchingSuccess,
    staffDropdownData,
    typeOfValueIsNumber,
    selectedFilialAccId,
    isDanger = false,
    className = '',
  }: ISettingsInputWithDropdownProps) => {
    const dispatch = useAppDispatch();
    const [filterValue, seFilterValue] = useState('');
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [filteredList, setFilteredList] = useState<TSimpleStringObj>({});
    const [filteredBySearchStringList, setFilteredBySearchStringList] = useState<TSimpleStringObj>(
      {},
    );

    const dropdownList = useMemo(() => staffDropdownData, [staffDropdownData]);

    const isGetCategoryList = useMemo(
      () => !Object.entries(dropdownList || {}).length,
      [dropdownList],
    );

    useEffect(() => {
      if (inputId && isFetchingSuccess && selectedFilialAccId && isGetCategoryList) {
        dispatch(getSettingsPageStaffList());
      }
    }, [dispatch, inputId, isFetchingSuccess, selectedFilialAccId, isGetCategoryList]);

    useEffect(() => {
      const moduloValues = addedValues.map(item => item.replace(/^-*/gm, ''));
      const newList = Object.fromEntries(
        Object.entries(dropdownList || []).filter(item => !moduloValues.includes(item[0])),
      );
      setFilteredList(newList || []);
    }, [dropdownList, inputId, addedValues]);

    // Валидирует введенное значение, валидация происходит если передан опциональный проп "typeOfValueIsNumber"
    const isBalloonValueValid = (valueToValidate: string) => {
      const valueWithOutMinus = valueToValidate.replace(/^-*/gm, '');
      if (!valueWithOutMinus) return false;
      if (typeOfValueIsNumber) {
        return !Number.isNaN(Number(valueToValidate));
      }
      return true;
    };

    // Добавляет новый "шарик", если введенные данные валидны, в противном случае очищает поле ввода
    const addBalloons = (balloonValue: string) => () => {
      if (isBalloonValueValid(balloonValue)) {
        const newList = Object.fromEntries(
          Object.entries(filteredList).filter(item => {
            return !balloonValue.includes(item[0]);
          }),
        );
        setFilteredList(newList);

        addBalloonOnBlur(inputId, balloonValue);

        if (isShowDropdown) {
          setIsShowDropdown(false);
          seFilterValue('');
        }
      }
    };

    const deleteBalloonHandler: TDeleteBalloonSet = (item, inputName) => () => {
      const deletedItem = Object.fromEntries(
        Object.entries(dropdownList).filter(listItem => listItem[1] === item.replace(/-/gm, '')),
      );
      const newSortedList = Object.assign(filteredList, deletedItem);

      setFilteredList(newSortedList);

      deleteBalloon(item, inputName)();
    };

    // Подготовка "шариков" для рендеринга
    const balloons = addedValues.map((item, index) => {
      const key = `${index}${item}`;

      const findBalloonData = Object.entries(staffDropdownData).filter(
        balloonItem => balloonItem[0] === item,
      );

      const chipsText = `${findBalloonData.length ? findBalloonData[0][1] : ''} (${item})`;
      return (
        <Chips
          key={key}
          removable
          type='def'
          tag={chipsText}
          color={isDanger ? 'danger' : 'default'}
          onRemove={deleteBalloonHandler(item, inputId)}
          className='tracking-wide mr-2.5 mb-2.5'
        />
      );
    });

    const dropdownTitle = getSecondDropdownListTitle(labelTextVariant);

    const arrayToRenderDropdownMenu = Object.entries(
      filterValue ? filteredBySearchStringList : filteredList,
    );

    const itemsToRenderInSecondDropdown = arrayToRenderDropdownMenu.map(item => {
      if (item[0] === 'masters' || item[0] === 'admins') {
        return (
          <span key={item[0]} className='inline-block pt-1 pl-5 text-small_text text-dGray'>
            {item[1]}
          </span>
        );
      }
      if (item[0].includes('divider')) {
        return <span key={item[0]} className='border-b border-quartz1 block my-2 w-full' />;
      }

      return (
        <MenuItem
          key={item[0]}
          onClick={item[0] !== 'Ничего не найдено' ? addBalloons(item[0]) : undefined}
          text={<span>{`${item[1]} ${item[0] ? `(${item[0]})` : ''}`}</span>}
        />
      );
    });

    const menuToRender = (
      <Menu
        view='raised'
        className={`dropdown-list-scrollbar ${
          Object.entries(itemsToRenderInSecondDropdown).length === 1 ? `one_item_ul` : ''
        } max-h-[25vh] overflow-y-auto`}>
        {itemsToRenderInSecondDropdown}
      </Menu>
    );

    const fetchStaffList = () => {
      if (isGetCategoryList) {
        dispatch(getSettingsPageStaffList());
      }
    };

    const dropdownVisibleHandler = () => {
      setIsShowDropdown(true);
    };

    const filterInputHandler: TOnChangeHandler = event => {
      const { value } = event.currentTarget;

      if (value) {
        const newArray = Object.entries(filteredList).filter(
          item =>
            item[0]?.toLowerCase().includes(value.toLowerCase()) ||
            item[1]?.toLowerCase().includes(value.toLowerCase()),
        );
        const newList = Object.fromEntries(
          newArray.length ? newArray : [['Ничего не найдено', '']],
        );
        seFilterValue(value);
        setFilteredBySearchStringList(newList);
      } else {
        seFilterValue(value);
        setIsShowDropdown(true);
        setFilteredList(filteredList);
      }
    };

    const dropdownCloseHandler = () => {
      seFilterValue('');
      setIsShowDropdown(false);
      setFilteredList(filteredList);
    };

    return (
      <div className={`flex flex-col ${className}`}>
        <div className='flex flex-wrap sm:flex-nowrap items-center'>
          <div className={`relative mb-2 w-full 'sm:!max-w-[29rem]'`}>
            <TextInput
              fill
              type='text'
              clearButton
              error={false}
              view='outlined'
              color='default'
              success={false}
              value={filterValue}
              onClick={fetchStaffList}
              placeholder={dropdownTitle}
              onChange={filterInputHandler}
              onFocus={dropdownVisibleHandler}
              clearSearchString={() => seFilterValue('')}
              className={`${isShowDropdown ? 'z-[60]' : ''} ${
                filterValue ? '' : 'chevron_in_after'
              }`}
            />
            <div className={`${isShowDropdown ? 'block' : 'hidden'} absolute top-[3rem] z-[70]`}>
              {menuToRender}
            </div>
          </div>
        </div>
        <div className='flex items-center flex-wrap w-full'>{balloons}</div>
        {isShowDropdown
          ? createPortal(<Overlay onClickCallback={dropdownCloseHandler} />, document.body)
          : null}
      </div>
    );
  },
);
