import React from 'react';
//
import { Chips } from '@uikit/Chips';
import { TASK_TYPE } from '@const/task';

type TTaskTypeChipsProps = {
  /**
   * Тип задачи
   * @param {string}
   */
  type: string;
  /**
   * Флаг показывает скрывает чипс индикации наличия чек листа
   * @param {boolean}
   */
  isChecklist: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TaskTypeChips = ({ type, isChecklist, className = '' }: TTaskTypeChipsProps) => {
  return (
    <div className={`flex items-center ${className}`}>
      {type === TASK_TYPE.CLIENT ? (
        <Chips round type='dense' noClick tag='👩🏻 Клиент' color='success' />
      ) : null}
      {type === TASK_TYPE.CASE ? (
        <Chips round type='dense' noClick tag='💼 Дело' color='primary' />
      ) : null}
      {type === TASK_TYPE.STAFF ? (
        <Chips round type='dense' noClick tag='👩‍⚕️ Сотрудник' color='warning' />
      ) : null}
      {isChecklist ? (
        <Chips
          round
          noClick
          type='dense'
          icon='small-tick'
          tag='Чек-лист'
          color='default'
          className='ml-2'
        />
      ) : null}
    </div>
  );
};
