import { z } from 'zod';

export const BlackListData = z.object({
  number: z.string(),
});

export const AddDeleteNumberInBlackListQueryDataSchema = z.object({
  phone: z.string(),
});

export type TBlackListData = z.infer<typeof BlackListData>;

export type TAddDeleteNumberInBlackListQueryData = z.infer<
  typeof AddDeleteNumberInBlackListQueryDataSchema
>;
