import { z } from 'zod';
import { DASHBOARD_KEYS } from '@const/dashboard';

const DashboardDataItemSchema = z.record(z.union([z.string(), z.number()]));

const DateSchema = z.string().refine(
  value => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(value)) return false;

    const date = new Date(value);
    return date.toISOString().slice(0, 10) === value;
  },
  {
    message: 'The date must be in the format YYYY-MM-DD and be a valid date',
  },
);

const DateTimeSchema = z.string().refine(
  value => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (!dateRegex.test(value)) return false;

    const date = new Date(value);
    return date.toISOString() === value;
  },
  {
    message: 'The date must be in the format YYYY-MM-DD and be a valid date',
  },
);

export const AllInCallsSchema = z.object({
  date: DateSchema,
  value: z.number(),
  name: z.string().nullable(),
  filters: z.object({
    all_records: z.number(),
    missed_calls: z.number(),
    new_clients: z.number(),
  }),
});

export const AllOutCallsSchema = z.object({
  date: DateSchema,
  value: z.number().nullable(),
  name: z.string().nullable(),
});

export const WaAverageTimeSchema = AllOutCallsSchema.extend({});

export const AllAmountSchema = z.object({
  date: DateSchema,
  value: z.number(),
  filters: z.object({
    service_category: DashboardDataItemSchema.array().nullable(),
    masters: DashboardDataItemSchema.array().nullable(),
  }),
});

export const OpRecordsSchema = z.object({
  date: DateSchema,
  value: z.number(),
});

export const ServiceCategorySchema = DashboardDataItemSchema;

export const MastersSchema = z.object({
  name: z.string(),
  total: z.number(),
  services: z
    .object({
      id: z.number(),
      title: z.string(),
      cost: z.number(),
    })
    .array(),
});

export const EarnedByOutCallsSchema = z.object({
  date: DateSchema,
  value: z.number(),
  filters: z.object({
    service_category: ServiceCategorySchema.array().nullable(),
    masters: MastersSchema.array().nullable(),
    admin: z.string().nullable(),
  }),
});

export const ChurnByMasterSchema = z.object({
  date: DateSchema,
  masters: DashboardDataItemSchema.array(),
});

export const ReturnMasterRateSchema = z.object({
  date: DateSchema,
  masters: DashboardDataItemSchema.array(),
  filters: z.object({
    return_new_client_rate: DashboardDataItemSchema.array().nullable(),
    general_client_rate: DashboardDataItemSchema.array().nullable(),
  }),
});

export const TaskSchema = z.object({
  date: DateSchema,
  created: z.number(),
  filters: z.object({
    completion_time: z.number().nullable(),
    tasks_completed: z.number().nullable(),
    tasks_missed: z.number().nullable(),
  }),
});

export const AdminsDashboardDataSchema = z.object({
  all_in_calls: AllInCallsSchema.array(),
  all_out_calls: AllOutCallsSchema.array(),
  wa_average_time: WaAverageTimeSchema.array(),
});

export const FinancesDashboardDataSchema = z.object({
  all_amount: AllAmountSchema.array(),
  amount_service_rate: DashboardDataItemSchema.array(),
  op_records: OpRecordsSchema.array(),
  earned_by_out_calls: EarnedByOutCallsSchema.array(),
});

export const MastersDashboardDataSchema = z.object({
  churn_by_master: ChurnByMasterSchema.array(),
  return_master_rate: ReturnMasterRateSchema.array(),
  tasks: TaskSchema.array(),
});

export const DashboardDataSchema = z.object({
  admins: AdminsDashboardDataSchema,
  finance: FinancesDashboardDataSchema,
  masters: MastersDashboardDataSchema,
});

export const DashboardAnalyticsDataSchema = z.object({
  [DASHBOARD_KEYS.CREATED]: z.boolean(),
  [DASHBOARD_KEYS.MASTERS]: z.boolean(),
  [DASHBOARD_KEYS.OP_RECORDS]: z.boolean(),
  [DASHBOARD_KEYS.ALL_OUT_CALLS]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_MASTERS]: z.boolean(),
  [DASHBOARD_KEYS.WA_AVERAGE_TIME]: z.boolean(),
  [DASHBOARD_KEYS.CHURN_BY_MASTER]: z.boolean(),
  [DASHBOARD_KEYS.SERVICE_CATEGORY]: z.boolean(),
  [DASHBOARD_KEYS.ALL_AMOUNT_MASTERS]: z.boolean(),
  [DASHBOARD_KEYS.AMOUNT_SERVICE_RATE]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_ALL_RECORDS]: z.boolean(),
  [DASHBOARD_KEYS.GENERAL_CLIENT_RATE]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_NEW_CLIENTS]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_TASKS_MISSED]: z.boolean(),
  [DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE]: z.boolean(),
  [DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY]: z.boolean(),
  [DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED]: z.boolean(),
});

export const DashboardMetaSchema = z.object({
  start_date: DateTimeSchema,
  end_date: DateTimeSchema,
  dashboard_list: DashboardAnalyticsDataSchema,
});

export const DashboardsResponseDataSchema = z.object({
  ok: z.boolean(),
  meta: DashboardMetaSchema,
  data: DashboardDataSchema,
});

export const OneDashboardResponseDataSchema = z.object({
  ok: z.boolean(),
  meta: DashboardMetaSchema,
  data: z.union([
    AdminsDashboardDataSchema,
    FinancesDashboardDataSchema,
    MastersDashboardDataSchema,
  ]),
});
