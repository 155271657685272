import moment from 'moment';
import { TASK_TYPE } from '@const/task';
import { storageDb } from '@api/storageApi';
import { EAnalyticsType } from '@models/Analytics';
import { TAnalyticsAdminsInitialState } from '@redux/Analytics/types';

export const AnalyticsInitialState: TAnalyticsAdminsInitialState = {
  isAdminsLoading: false,
  isErrorLoadingAdmins: false,
  isSuccessLoadingAdmins: false,
  type: EAnalyticsType.ADMIN,
  adminsList: [],
  generalData: null,
  selectedTaskType: TASK_TYPE.CASE,
  selectedCharts: [],
  selectedTaskId: 0,
  selectedAdminId: 0,
  start_date: storageDb.getAnalyticsStart() || moment().subtract(30, 'd').toISOString(),
  end_date: storageDb.getAnalyticsEnd() || new Date().toISOString(),
  analyticsStartDate: moment().subtract(7, 'd').toISOString(),
  analyticsEndDate: new Date().toISOString(),
};
