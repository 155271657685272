import React, { useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { NavLink } from 'react-router-dom';
import { TSimpleStringObj } from '@models/index';
import { ButtonGroup } from '@uikit/ButtonGroup';
import { CHART_TYPE, DASHBOARD_KEYS } from '@const/dashboard';
import { DashboardHowButton } from '@blocks/dashboardHowButton';
import { ChartFilterComponent } from '@components/chartFilterComponent';

interface IChartWrapperProps {
  /**
   * Часть пути для перехода на страницу с табличным представлением данных
   * @param {string}
   */
  pathName: string;
  /**
   * Имя графика
   * @param {string}
   */
  chartName: string;
  /**
   * Флаг показывает/скрывает линк на страницу с таблицей
   * @param {boolean}
   */
  isTableView?: boolean;
  /**
   * Ключ в объекте с данными для страницы
   * @param {string}
   */
  chartDataKeyName: string;
  /**
   * Компонент графика
   * @param {React.ReactNode}
   */
  barChart: React.ReactNode;
  /**
   * Имя выбранного фильтра дынных
   * @param {string}
   */
  selectedFilterName: string;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр, флаг показывает/скрывает модуль переключения фильтра для данных
   * @param {boolean}
   */
  isChartSetupModule?: boolean;
  /**
   * Опциональный параметр, компонент пончиковой диаграммы
   * @param {React.ReactNode}
   */
  doughnutChart?: React.ReactNode;
  /**
   * Объект для формирования выпадающего списка
   * @param {TSimpleStringObj}
   */
  chartFilterNames: TSimpleStringObj;
  /**
   * Опциональный параметр, флаг показывает/скрывает модуль переключения между столбчатой и пончиковой диаграммами
   * @param {boolean}
   */
  isChartTypeSwitcherModule?: boolean;
  /**
   * Callback для переключения фильтра графиков
   * @param filterName
   */
  callback: (filterName: string) => void;
  /**
   * Callback обрабатывает клики по кнопке информации
   * @param {(name: DASHBOARD_KEYS) => void}
   */
  onHowButtonClick?: (name: DASHBOARD_KEYS) => void;
  /**
   * Тип графика для формирования нужного текста подсказки
   * @param {DASHBOARD_KEYS}
   */
  chartContentType?: DASHBOARD_KEYS;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ChartWrapper = ({
  barChart,
  callback,
  pathName,
  chartName,
  isTableView,
  doughnutChart,
  chartContentType,
  chartDataKeyName,
  chartFilterNames,
  onHowButtonClick,
  isChartSetupModule,
  selectedFilterName,
  selectedFilialAccId,
  isChartTypeSwitcherModule,
  className = '',
}: IChartWrapperProps) => {
  const [chartType, setChartType] = useState<string>('bar');

  const changeChartTypeHandler = (id: number) => () => {
    // eslint-disable-next-line
    setGroupButtonsData(prevState => {
      return prevState.map(item => {
        if (item.id === id) {
          return { ...item, isActive: true };
        }
        return { ...item, isActive: false };
      });
    });
    if (id === 1) {
      setChartType(CHART_TYPE.DOUGHNUT);
    } else {
      setChartType(CHART_TYPE.BAR);
    }
  };

  const [groupButtonsData, setGroupButtonsData] = useState([
    {
      id: 0,
      isActive: true,
      icon: 'timeline-bar-chart',
      onClick: changeChartTypeHandler(0),
    },
    {
      id: 1,
      isActive: false,
      icon: 'doughnut-chart',
      onClick: changeChartTypeHandler(1),
    },
  ]);

  const onHowButtonClickHandler = () => {
    if (onHowButtonClick && chartContentType) {
      onHowButtonClick(chartContentType);
    }
  };

  return (
    <div className={`border border-quartz rounded-lg flex flex-col p-6 mb-10 w-full ${className}`}>
      <div className='flex flex-col sm:flex-row flex-wrap sm:justify-between sm:mb-4'>
        <div className=''>
          <p className='font-medium flex flex-col leading-8 m-0 mb-4 mr-5 text-h3Mobile tracking-[0.004em] h-16'>
            {chartName}
            {/* <span className='text-small_text text-dGray'>{chartDataKeyName}</span> */}
          </p>
          <DashboardHowButton onClick={onHowButtonClickHandler} className='mb-4' />
        </div>
        <div className='flex flex-col sm:flex-row flex-wrap sm:flex-nowrap'>
          {isChartSetupModule ? (
            <div
              className={`flex flex-wrap flex-col sm:flex-row sm:items-center w-fit md:w-full md:max-w-[23rem] ${
                isChartTypeSwitcherModule ? '' : 'justify-end'
              }`}>
              <ChartFilterComponent
                callback={callback}
                filterNamesObj={chartFilterNames}
                selectedFilterName={selectedFilterName}
                className='chart-dropdown mb-4 w-fit sm:min-w-[15rem]'
              />
              {isChartTypeSwitcherModule ? (
                <div className='mb-4 ml-0 sm:ml-4'>
                  <ButtonGroup
                    type='default'
                    view='outlined'
                    color='success'
                    data={groupButtonsData}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {isTableView ? (
            <NavLink
              to={`${pathName}/table?acc_id=${selectedFilialAccId}&table_name=${chartDataKeyName}`}
              className='flex items-center font-bold text-body_text text-green1 hover:no-underline hover:text-gray2 tracking-[0.0275em] sm:ml-8 mb-4 min-w-[11.5rem]'>
              Детализация
              <Icon variant='greenChevronRight' className='ml-[1.125rem]' />
            </NavLink>
          ) : null}
        </div>
      </div>
      {chartType === CHART_TYPE.BAR && isChartSetupModule ? barChart : doughnutChart}
    </div>
  );
};
