import { IInitialState } from '@redux/App/state';
import { PayloadAction } from '@reduxjs/toolkit';
import { TDashboardDrawerDataItem } from '@models/index';

export const reducers = {
  setTaskTypeToPath(state: IInitialState, action: PayloadAction<{ taskType: string }>) {
    state.taskTypeToPath = action.payload.taskType;
  },
  setShowAnalyticsDrawer(state: IInitialState, action: PayloadAction<boolean>) {
    state.showAnalyticsDrawer = action.payload;
  },
  setAnalyticsType(state: IInitialState, action: PayloadAction<TDashboardDrawerDataItem>) {
    state.analyticsDrawerData = action.payload;
  },
  setShowAppDrawer(state: IInitialState, action: PayloadAction<boolean>) {
    state.showAppDrawer = action.payload;
  },
  setAppDrawerData(
    state: IInitialState,
    action: PayloadAction<{ title: string; description: string }>,
  ) {
    state.appDrawerData = action.payload;
  },
  setIsDragging(state: IInitialState, action: PayloadAction<boolean>) {
    state.isDragging = action.payload;
  },
};
