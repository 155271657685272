import { APP_LANGUAGES } from '@const/app';
import { PATH_NAMES } from '@const/httpConst';
import { TSimpleStringObj } from '@models/index';

export const settingsPageNameDict: TSimpleStringObj = {
  [PATH_NAMES.SETTINGS]: 'Общие',
  [PATH_NAMES.TELEPHONY]: 'Телефония',
  [PATH_NAMES.SETTINGS_ADMINS]: 'Админы',
  [PATH_NAMES.SETTINGS_NOTIFICATIONS]: 'Уведомления',
  // TODO: Скрыто по задаче AB-283
  // [PATH_NAMES.SETTINGS_MASTERS]: 'Мастера',
  [PATH_NAMES.BLACKLIST]: 'Чёрный список',
  [PATH_NAMES.SETTINGS_PAY]: 'Данные счёта',
};

export const RIGHT_MENU_ITEMS: TSimpleStringObj = {
  analytics: 'Аналитика',
  templates: 'Отчеты на телефон',
  settings: 'Настройки',
};

export const RIGHT_MENU_ITEMS_OWNER: TSimpleStringObj = {
  settings_admins: 'Админы',
};

export enum OPERATOR_NAMES {
  NOT_SELECTED = 'Не выбрано',
  MEGAFON = 'megafon',
}

export enum SETTINGS_DATA_KEY_NAMES {
  TASK_TRACKER_NAME = 'taskTrackerName',
  API_KEY_TASK_TRACKER = 'apiKeyTaskTracker',
  DOMAIN_TASK_TRACKER = 'domainTaskTracker',
  AUTO_ARCHIVE_TASK = 'autoArchiveTasks',
  SPACE_ID = 'spaceId',
  TELEPHONY_OPERATOR_NAME = 'telephonyOperatorName',
  TELEPHONE_DOMAIN = 'telephonyDomain',
  APY_KEY_TELEPHONY = 'apiKeyTelephony',
  PHONE_NUMBER = 'phoneNumber',
  TASK_OPEN_NOTIFY = 'taskOpenNotify',
  STAFF_LIST = 'staffList',
  SELECTED_STAFF = 'selectedStaff',
  EMPLOYEES_TO_IGNORE = 'employeesToIgnore',
  SEND_TIME = 'sendTime',
  TASK_TRACKER = 'taskTracker',
  YCLIENTS = 'yclients',
  MISSED_CALL_ANSWER = 'missedCallAnswer',
  FORGOTTEN_TASK_NOTIFY = 'forgottenTaskNotify',
  CUSTOMER_CHURN_TIME = 'customerChurnTime',
  CALL_STATS = 'callStats',
  START_RATE = 'startRate',
  END_RATE = 'endRate',
  NAME = 'name',
  INN = 'inn',
  KPP = 'kpp',
  USE_GROUPS = 'useGroups',
}

export const INPUT_WITH_DROPDOWN_LABEL_TEXT_VARIANT = ['staff', 'employeesToIgnore'];

export enum TIME_OFFSET {
  MOSCOW = 180,
}

export enum TIME_PERIOD {
  MINUTES_IN_HOUR = 60,
  MILLISECONDS_IN_SECOND = 1000,
}

export const OPERATOR_DATA = {
  link: 'https://ya.ru',
  name: '«Звонки и копыта»',
  promoCode: 'ABZVONI',
};

export const settingPageText: Record<string, Record<string, string>> = {
  [APP_LANGUAGES.RUSSIAN]: {
    periodsOfReturnStatisticsHeader: 'Периоды для расчёта возвращаемости',
    startRateLabel: 'Прошлый период (период потери)',
    startRatePlaceholder: 'Прошлый период',
    endRateLabel: 'Текущий период',
    endRatePlaceholder: 'Текущий период',
    today: 'Сегодня',
    returned: 'Вернувшиеся',
  },
};

export const PASSED_PERIOD_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8];

export const CURRENT_PERIOD_MONTH = [1, 2, 3, 4, 5, 6];

export const churnByMasterNumWords: Record<string, string[]> = {
  [APP_LANGUAGES.RUSSIAN]: ['месяц', 'месяца', 'месяцев'],
  [APP_LANGUAGES.ENGLISH]: ['month', 'month', 'month'],
};

export const CURRENT_PERIOD_ENABLED_MONTHS: Record<number, number[]> = {
  1: [1],
  2: [1, 2],
  3: [2, 3],
  4: [2, 3, 4],
  5: [3, 4, 5],
  6: [3, 4, 5, 6],
  7: [4, 5],
  8: [4],
};

export const PASSED_PERIOD_ENABLED_MONTHS: Record<number, number[]> = {
  1: PASSED_PERIOD_MONTHS,
  2: PASSED_PERIOD_MONTHS,
  3: PASSED_PERIOD_MONTHS,
  4: PASSED_PERIOD_MONTHS,
  5: PASSED_PERIOD_MONTHS,
  6: PASSED_PERIOD_MONTHS,
  7: PASSED_PERIOD_MONTHS,
  8: PASSED_PERIOD_MONTHS,
};

export enum NOTIFICATIONS_VARIANTS {
  TASK_CREATED = 'task_created',
  TASK_COMPLETED = 'task_completed',
  TASK_OVERDUE = 'task_overdue',
  TASK_POSTPONE = 'task_postpone',
}

export const NOTIFICATIONS_VARIANTS_TEXT: Record<string, string> = {
  [NOTIFICATIONS_VARIANTS.TASK_CREATED]: 'Задача создана',
  [NOTIFICATIONS_VARIANTS.TASK_COMPLETED]: 'Задача выполнена',
  // [NOTIFICATIONS_VARIANTS.TASK_OVERDUE]: 'Задача просрочена',
  [NOTIFICATIONS_VARIANTS.TASK_POSTPONE]: 'Задача отложена',
};

export const OPERATORS_NAME_TO_ADD_DESCRIPTION: Record<string, string> = {
  zadarma: 'Novofon',
};
