import React from 'react';
import { NavLink } from 'react-router-dom';
//
import { Icon } from '@atoms/icon';
import { PATH_NAMES } from '@const/httpConst';

interface IDashboardPathLinksProps {
  /**
   * Опциональный параметр, название второй ссылки
   * @param {string}
   */
  secondPageName?: string;
  /**
   * Опциональный параметр, URL второй ссылки
   * @param {string}
   */
  secondPageLink?: string;
  /**
   * Название последней "ссылки"
   * @param {string}
   */
  thirdPageName: string;
  /**
   * ID выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const DashboardPathLinks = ({
  secondPageName,
  secondPageLink,
  thirdPageName,
  selectedFilialAccId,
  className = '',
}: IDashboardPathLinksProps) => {
  return (
    <div className={`flex items-center ${className}`}>
      <NavLink
        to={`${PATH_NAMES.DASHBOARD}?acc_id=${selectedFilialAccId}`}
        className='flex items-center text-body_text text-gray2 hover:no-underline hover:text-gray2 mr-[1.125rem] tracking-[0.0275em]'>
        Дашборд
        <Icon variant='chevronRight' className='ml-[1.125rem]' />
      </NavLink>
      {secondPageLink && secondPageName ? (
        <NavLink
          to={`${secondPageLink}?acc_id=${selectedFilialAccId}`}
          className='flex items-center text-body_text text-gray2 hover:no-underline hover:text-gray2 mr-[1.125rem] tracking-[0.0275em]'>
          {secondPageName}
          <Icon variant='chevronRight' className='ml-[1.125rem]' />
        </NavLink>
      ) : null}
      <p className='font-medium flex items-center m-0 text-body_text text-blackText tracking-[0.022em]'>
        {thirdPageName}
      </p>
    </div>
  );
};
