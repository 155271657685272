import React, { memo } from 'react';

import { Icon } from '../icon';

interface IStatusProps {
  /**
   * Опциональный параметр title в img
   * @param {string}
   */
  title?: string;
  /**
   * Статус индикатора
   * @param {boolean}
   */
  status: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Status = memo(({ title, status, className = '' }: IStatusProps) => {
  const variant = status ? 'statusOn' : 'statusOff';

  return <Icon title={title} variant={variant} className={className} />;
});
