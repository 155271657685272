import { z } from 'zod';

export const ReportCardDataSchema = z.object({
  id: z.number(),
  state: z.boolean(),
  title: z.string(),
  body: z.string(),
  time: z.string(),
});

export const SwitchedReportDataSchema = z.object({
  acc_id: z.string(),
  state: z.boolean(),
  stats_id: z.number(),
});

export const ReportResponseDataSchema = z.object({
  id: z.number(),
  active: z.boolean(),
  title: z.string().nullable(),
  body: z.string(),
  stats_type: z.string(),
  period: z.number(),
  time: z.string(),
  wa_notify: z.string().array(),
  tg_notify: z.number().array(),
  send_to_staff: z.boolean(),
});

export const ReportSaveRequestDataSchema = z.object({
  data: ReportResponseDataSchema,
});

export const ReportOperationRequestDataSchema = z.object({
  id: z.string(),
});

export const ReportDataSchema = z.object({
  id: z.string(),
  isActive: z.boolean(),
  reportName: z.string(),
  reportDescription: z.string(),
  selectedReportSendPeriod: z.string(),
  sendingTime: z.string(),
  sendingDate: z.string(),
  selectedDataCalculatingPeriod: z.string(),
  selectedEveryWeekSendingPeriod: z.string(),
  selectedEveryMonthSendingPeriod: z.string(),
  whatsappRecipients: z.string().array(),
  telegramRecipients: z.string().array(),
  sendToAllStaff: z.boolean(),
});

export type TReportCardData = z.infer<typeof ReportCardDataSchema>;

export type TSwitchedReportData = z.infer<typeof SwitchedReportDataSchema>;

export type TReportResponseData = z.infer<typeof ReportResponseDataSchema>;

export type TReportSaveRequestData = z.infer<typeof ReportSaveRequestDataSchema>;

export type TReportOperationRequestData = z.infer<typeof ReportOperationRequestDataSchema>;

export type TReportData = z.infer<typeof ReportDataSchema>;
