import { BarChart } from '@components/barChart';
import { LineChart } from '@components/lineChart';
import { TDashboardData, TEarnedByOutCalls } from '@api/dashboard/types';
import { DoughnutChart } from '@components/doughnutChart';
import { StackedBarChart } from '@components/stackedBarChart';
import { ChurnByMasterChart } from '@components/churnByMasterChart';
import { StackedChartOutCalls } from '@components/stackedChartOutCalls';
import {
  chartColors,
  ADMINS_CHARTS,
  DASHBOARD_KEYS,
  mastersTasksFilterName,
  adminsAllInCallsFilterNames,
  ANALYTICS_PAGE_CHART_TITLES,
} from '@const/dashboard';
import {
  getRotationAnge,
  chatMultiLineData,
  getAllAmountDataSet,
  getEaredByOutCallsData,
  getOneLineChartDataset,
  getDoughnutChartDataset,
  getStackedBarChartDataset,
} from '@helpers/index';

type TDoughnutChartProps = {
  chartName: DASHBOARD_KEYS;
  data: TDashboardData;
  chartTitle: string;
};
export const AnalyticsChartDoughnut = ({ data, chartName, chartTitle }: TDoughnutChartProps) => {
  switch (chartName) {
    case DASHBOARD_KEYS.SERVICE_CATEGORY:
      return (
        <StackedBarChart
          isUseFooter
          chartTitle=''
          data={getAllAmountDataSet({
            data: data.finance.all_amount,
            keyName: chartName,
            colors: chartColors,
          })}
        />
      );
    case DASHBOARD_KEYS.ALL_AMOUNT_MASTERS:
      return (
        <StackedBarChart
          isUseFooter
          chartTitle=''
          data={getAllAmountDataSet({
            data: data.finance.all_amount,
            keyName: DASHBOARD_KEYS.MASTERS,
            colors: chartColors,
          })}
        />
      );
    case DASHBOARD_KEYS.AMOUNT_SERVICE_RATE:
      return (
        <DoughnutChart
          chartLegendPosition='top'
          data={getDoughnutChartDataset({
            chartData: data.finance.amount_service_rate,
            chartTitle,
            getRotationAngeCallback: getRotationAnge,
          })}
        />
      );

    case DASHBOARD_KEYS.OP_RECORDS:
      return (
        <LineChart
          data={getOneLineChartDataset({
            chartData: data.finance.op_records,
            key: chartName,
            chartLabel: '',
            borderColor: chartColors['green-30'],
            backgroundColor: chartColors['green-30'],
          })}
          chartTitle=''
        />
      );

    case DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY:
    case DASHBOARD_KEYS.FILTERS_MASTERS:
      // case DASHBOARD_KEYS.FILTERS_ADMINS:
      return (
        <StackedChartOutCalls
          chartTitle=''
          data={getEaredByOutCallsData({
            data: data.finance.earned_by_out_calls,
            keyName: chartName.split('.')[1] as keyof TEarnedByOutCalls['filters'],
            colors: chartColors,
          })}
        />
      );

    case DASHBOARD_KEYS.MASTERS:
    case DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE:
    case DASHBOARD_KEYS.GENERAL_CLIENT_RATE:
      return (
        <LineChart
          chartTitle=''
          data={chatMultiLineData({
            data: data.masters.return_master_rate,
            keyName: chartName,
            colors: chartColors,
          })}
        />
      );

    case DASHBOARD_KEYS.CHURN_BY_MASTER:
      return (
        <ChurnByMasterChart
          isMultiLineChart
          data={data.masters.churn_by_master}
          eventData={[]}
          // TODO разобраться почему не работает с фильтром 'churn_by_master'
          keyName='masters'
          chartTitle=''
        />
      );

    case DASHBOARD_KEYS.CREATED:
    case DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED:
    case DASHBOARD_KEYS.FILTERS_TASKS_MISSED:
      return (
        <BarChart
          chartTitle=''
          data={getOneLineChartDataset({
            chartData: data.masters.tasks.map(dataItem => ({
              ...dataItem,
              value: dataItem.created,
            })),
            key: chartName,
            chartLabel: mastersTasksFilterName[chartName],
            borderColor: chartColors['green-30'],
            backgroundColor: chartColors['green-30'],
          })}
        />
      );

    case DASHBOARD_KEYS.ALL_OUT_CALLS:
      return (
        <StackedBarChart
          chartTitle=''
          data={getStackedBarChartDataset({
            chartData: data.admins.all_out_calls,
            label: ANALYTICS_PAGE_CHART_TITLES[chartName],
          })}
        />
      );

    case DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS:
    case DASHBOARD_KEYS.FILTERS_NEW_CLIENTS:
    case DASHBOARD_KEYS.FILTERS_ALL_RECORDS:
      return (
        <BarChart
          chartTitle=''
          data={getOneLineChartDataset({
            chartData: data.admins.all_in_calls,
            key: chartName,
            chartLabel: adminsAllInCallsFilterNames[chartName],
            borderColor: chartColors['green-30'],
            backgroundColor: chartColors['green-30'],
          })}
        />
      );

    case DASHBOARD_KEYS.WA_AVERAGE_TIME:
      return (
        <StackedBarChart
          chartTitle=''
          data={getStackedBarChartDataset({
            chartData: data.admins.wa_average_time,
            label: ADMINS_CHARTS[chartName],
          })}
        />
      );

    default:
      return null;
  }
};
