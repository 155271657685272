import React, { memo } from 'react';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { TInstructionData } from '@const/common';
import { VideoInstructionWithDropdown } from '@components/videoInstructionWithDropdown';

interface IHeaderWithButtonProps {
  /**
   * Название домена для формирования ссылки на таск-трекер
   * если не передать имя домена, ссылка в правом углу сменится
   * на "подключить интеграцию" ссылка переключит на страницу настроек
   * @param {string}
   */
  domain: string;
  /**
   * Текст заголовка
   * @param {status}
   */
  headerText: string;
  /**
   * Опциональный параметр заменяет ссылку на таск-трекер кнопкой новый шаблон
   * @param {boolean}
   * @default
   */
  isReportsPage?: boolean;
  /**
   * Опциональный параметр callback на кнопку новый шаблон
   * @param {() => void}
   */
  callBack?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Данные для отображения инструкций
   * @param {TInstructionData[]}
   */
  instructionData?: TInstructionData[];
}

export const HeaderWithButton = memo(
  ({
    domain,
    headerText,
    isReportsPage = false,
    callBack,
    className = '',
    instructionData,
  }: IHeaderWithButtonProps) => (
    <div className={`flex flex-wrap items-center justify-between ${className}`}>
      <H1 className={`${isReportsPage || domain ? 'mr-4' : ''} mb-4 sm:mb-0`}>{headerText}</H1>
      {isReportsPage ? (
        <Button
          dense
          icon='plus'
          type='action'
          view='filled'
          color='success'
          text='Новый отчет'
          onClick={callBack}
          className='mb-0 m:mb-4 sm:mb-0 w-full sm:w-auto'
        />
      ) : null}
      {instructionData ? <VideoInstructionWithDropdown instructionData={instructionData} /> : null}
    </div>
  ),
);
