import React, { memo } from 'react';
//
import { Spinner } from '@uikit/Spinner';

interface ILoaderProps {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Loader = memo(({ className = '' }: ILoaderProps) => (
  <div className={`flex items-center justify-center ${className}`}>
    <Spinner color='default' size={40} />
    <p className='m-0 ml-4'>Загрузка...</p>
  </div>
));
