import { z } from 'zod';

export const PayerDataSchema = z.object({
  name: z.string().nullable().optional(),
  inn: z.string().nullable().optional(),
  kpp: z.string().nullable().optional(),
});

export const PayLinkDataSchema = z.object({
  link: z.string(),
  telegramStatus: z.boolean().nullable(),
});

export const PaymentsBonusSchema = z.object({
  m3bonus: z.number(),
  m6bonus: z.number(),
  m12bonus: z.number(),
});

export const PaymentsItemSchema = z.object({
  payment_date: z.string(),
  paid_till: z.string(),
  amount: z.number(),
});

export const PayResponseDataSchema = z.object({
  branch_name: z.string(),
  branch: z.string(),
  employees: z.number(),
  bonuses: PaymentsBonusSchema,
  payments: PaymentsItemSchema.array(),
  base_price: z.number(),
  base_price_with_bonus: PaymentsBonusSchema,
  pay_date: z.string(),
  process: z.boolean(),
  discount: z.number(),
});

export const PaymentDataSchema = z.object({
  date: z.string(),
  period: z.string(),
  sum: z.number(),
  type: z.string(),
});

export const PayDataSchema = z.object({
  branchEmployees: z.string(),
  branch: z.string(),
  amount: z.string(),
  isAutoPayEnable: z.boolean(),
  cardLastNum: z.string(),
  payStatus: z.boolean(),
  payTillDate: z.string(),
  numberOfMonth: z.string().array(),
  numberOfGiftWeek: z.string().array(),
  numberOfPriceWithBonus: z.number().array(),
  paymentData: PaymentDataSchema.array(),
  discount: z.number(),
});

export const PayLinkResponseSchema = z.object({
  link: z.string(),
  telegram_status: z.boolean().nullable(),
});

export type TPayerData = z.infer<typeof PayerDataSchema>;
export type TPayLinkData = z.infer<typeof PayLinkDataSchema>;
export type TPayResponseData = z.infer<typeof PayResponseDataSchema>;
export type TPaymentData = z.infer<typeof PaymentDataSchema>;
export type TPayData = z.infer<typeof PayDataSchema>;
export type TPayLinkResponse = z.infer<typeof PayLinkResponseSchema>;
