import { numberOfMonth } from '@const/pay';
import { getEUDateFromString } from '@helpers/index';
import {
  TPayData,
  TPayerData,
  TPayLinkData,
  TPayResponseData,
  TPayLinkResponse,
} from '@models/Pay';

export const payMappers = {
  responseToStore: (data: TPayResponseData): TPayData => {
    const {
      bonuses,
      branch,
      pay_date,
      payments,
      employees,
      process,
      base_price,
      base_price_with_bonus,
      discount,
    } = data;

    const paymentData = payments.map(item => {
      const period = getEUDateFromString(item.paid_till, '.');
      const date = getEUDateFromString(item.payment_date, '.');

      return {
        date,
        period,
        sum: item.amount,
      };
    });

    return {
      branch,
      branchEmployees: String(employees),
      amount: String(base_price),
      isAutoPayEnable: false,
      cardLastNum: '',
      payStatus: process,
      payTillDate: getEUDateFromString(pay_date),
      numberOfMonth,
      numberOfGiftWeek: Object.values(bonuses).map(item => String(item)),
      numberOfPriceWithBonus: [
        base_price,
        ...Object.values(base_price_with_bonus).map(item => item),
      ],
      paymentData: paymentData.map(pay => ({
        ...pay,
        type: 'card',
      })),
      discount,
    };
  },
  payerDataResponseToStore: ({ name, inn, kpp }: TPayerData): TPayerData => ({
    name: name || '',
    inn: inn || '',
    kpp: kpp || '',
  }),
  payerStoreDataToRequest: ({ name, inn, kpp }: TPayerData): TPayerData => ({
    name: name || '',
    inn: String(inn),
    kpp: kpp ? String(kpp) : null,
  }),
  payLinkResponseToStore: ({ link, telegram_status }: TPayLinkResponse): TPayLinkData => ({
    link,
    telegramStatus: telegram_status,
  }),
};
