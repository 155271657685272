import { ReactNode, useCallback, useMemo } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { setIsDragging } from '@redux/App/slice';
import { useDetectPlatform } from '@hooks/useDetectPlatform';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  useSensor,
  DndContext,
  useSensors,
  TouchSensor,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  KeyboardSensor,
} from '@dnd-kit/core';

type TDndContainerProps = {
  /**
   * Массив айди задач отсортированный пользователем путем перетаскивания карточек
   * @type {string[]}
   */
  items: string[];
  /**
   * Массив айдишников карточек для перетаскивания
   * @type {ReactNode}
   */
  children: ReactNode;
  /**
   * Слушатель события окончания перетаскивания карточки
   * @param elements {string[]}
   */
  onDragEnd?: (elements: string[]) => void;
};

export const DndContainer = ({ items, children, onDragEnd }: TDndContainerProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useDetectPlatform();

  const detectSensor = useMemo(() => {
    return {
      sensor: isMobile ? TouchSensor : PointerSensor,
      options: isMobile
        ? { activationConstraint: { delay: 250, tolerance: 5 } }
        : { activationConstraint: { distance: 5 } },
    };
  }, [isMobile]);

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(detectSensor.sensor, detectSensor.options),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      dispatch(setIsDragging(false));
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = items.findIndex(taskId => taskId === active.id);
        const newIndex = items.findIndex(taskId => taskId === over.id);
        const sortedTasks = arrayMove(items, oldIndex, newIndex);
        if (onDragEnd) onDragEnd(sortedTasks);
      }
    },
    [items, dispatch, onDragEnd],
  );

  const handleDragStart = useCallback(() => {
    dispatch(setIsDragging(true));
  }, [dispatch]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}>
      <SortableContext items={items}>{children}</SortableContext>
    </DndContext>
  );
};
