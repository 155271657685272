import React, { memo, useCallback } from 'react';
//
import { TimePicker } from '@uikit/DateTime/TimePicker';
import { getRoundedTime, getTime, selectTextContentOnFocus } from '@helpers/index';

interface IWhenTimeProps {
  /**
   * Время в формате HH:MM
   * @param {string}
   */
  whenTime: string;
  /**
   * Опциональный параметр при передаче этого флага можно изменять только часы
   * @param {string}
   */
  isSetOnlyHour?: boolean;
  /**
   * Callback обрабатывает изменение данных в input
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  stepMinutes?: number;
}

export const WhenTime = memo(
  ({
    whenTime,
    isSetOnlyHour,
    onChangeHandler,
    className = '',
    stepMinutes = 15,
  }: IWhenTimeProps) => {
    // срабатывает при вводе нового времени, округляет минуты до четверти часа, формирует строку времени
    // и записывает в редакс вызовом callback
    const setTimeHandler = useCallback(
      (date: Date) => {
        onChangeHandler(getRoundedTime(date, !!isSetOnlyHour, stepMinutes));
      },
      [onChangeHandler, isSetOnlyHour, stepMinutes],
    );

    return (
      <TimePicker
        color='primary'
        useAmPm={false}
        precision='MINUTE'
        showArrowButtons={false}
        onChange={setTimeHandler}
        value={getTime(whenTime)}
        onFocus={selectTextContentOnFocus}
        className={`border border-dGray rounded ${className}`}
      />
    );
  },
);
