import React from 'react';
//
import { IWithChildren } from '@models/index';

interface IH2Props extends IWithChildren {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const H2 = ({ children, className = '' }: IH2Props) => (
  <h2 className={`font-inter text-h2_header font-medium ${className}`}>{children}</h2>
);
