import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
//
import { Loader } from '@blocks/loader';
import { TReportInfo } from '@models/index';
import { TDeleteTask } from '@shared/types';
import { PATH_NAMES } from '@const/httpConst';
import { useAppDispatch } from '@hooks/index';
import { ORDER_VARIANTS } from '@models/Settings';
import { ErrorMessage } from '@blocks/errorMessage';
import { setOrderThunk } from '@redux/Settings/thunk';
import { ToastsMessages } from '@components/toastsMessages';
import { ReportCardList } from '@components/reportCardList';
import { HeaderWithButton } from '@components/headerWithButton';
import { deleteReport, switchReport } from '@redux/Report/thunk';
import { EmptyCardsListTemplate } from '@components/emptyCardsListTemplate';
import {
  clearErrorsRP,
  clearReportsErrors,
  setReportIdInProgress,
  setSortedReportsList,
} from '@redux/Report/slice';

interface IReportsTemplateProps {
  /**
   * Данные страницы
   * @param {TReportInfo[]}
   */
  data: TReportInfo[];
  /**
   * Флаг ошибки при запросе данных для страницы
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает что запрос данных для страницы в процессе
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Флаг показывает что запрос на удаление задачи в процессе
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Флаг повторного запроса данных для страницы
   * @param {boolean}
   */
  isFetching: boolean;
  /**
   * Флаг ошибки при сохранении удаление задачи
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаление задачи
   * @param {string}
   */
  isDeletingSuccess: boolean;
  /**
   * Флаг ошибки при переключении статуса задачи
   * @param {boolean}
   */
  isReportSwitchingError: boolean;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Отсортированный массив айдишников отчетов
   * @type {string[]}
   */
  sortedReportsData: string[];
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
}

export const ReportsTemplate = memo(
  ({
    data,
    isError,
    isLoading,
    isDeleting,
    isFetching,
    isDeletingError,
    isDeletingSuccess,
    sortedReportsData,
    errorToastMessage,
    successToastMessage,
    isReportSwitchingError,
    selectedFilialAccId,
  }: IReportsTemplateProps) => {
    // Получаем функцию navigate для редиректа на страницу новой задачи
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    // переходит на страницу новой задачи
    const addNewTemplate = useCallback(() => {
      dispatch(clearErrorsRP());
      navigate(PATH_NAMES.NEW_REPORT_TEMPLATE);
    }, [navigate, dispatch]);

    // Записывает в state id отчета над который удаляют или переключают активность
    const setReportIdInProgressHandler = useCallback(
      (reportId: string) => {
        dispatch(setReportIdInProgress({ reportId }));
      },
      [dispatch],
    );

    // тогл, переключает активность шаблона
    const switchReportHandler: TDeleteTask = useCallback(
      reportId => {
        setReportIdInProgressHandler(reportId);
        dispatch(switchReport({ reportId }));
      },
      [setReportIdInProgressHandler, dispatch],
    );

    // удаляет шаблон по ID
    const deleteReportHandler: TDeleteTask = useCallback(
      reportId => {
        const sortedReportsDataWithoutDeletedOne = sortedReportsData.filter(id => id !== reportId);
        setReportIdInProgressHandler(reportId);
        dispatch(deleteReport({ reportId }));
        dispatch(setSortedReportsList(sortedReportsDataWithoutDeletedOne));
        dispatch(
          setOrderThunk({ target: ORDER_VARIANTS.STATS, data: sortedReportsDataWithoutDeletedOne }),
        );
      },
      [setReportIdInProgressHandler, dispatch, sortedReportsData],
    );

    const hideErrorToastHandler = useCallback(() => {
      dispatch(clearReportsErrors());
    }, [dispatch]);

    return (
      <div className='h-full'>
        <HeaderWithButton
          domain=''
          isReportsPage
          callBack={addNewTemplate}
          headerText='Отчеты на телефон'
          className='mb-10 mt-0'
        />
        {isError ? <ErrorMessage /> : null}
        {isLoading ? <Loader className='h-[50Vh]' /> : null}
        {isFetching ? <div className='absolute top-0 right-0 bottom-0 left-0 z-[100]' /> : null}
        {!isLoading && !isError && selectedFilialAccId ? (
          data.length ? (
            <ReportCardList
              data={data}
              isDeleting={isDeleting}
              isDeletingError={isDeletingError}
              switchReport={switchReportHandler}
              deleteReport={deleteReportHandler}
              sortedReportsData={sortedReportsData}
              isDeletingSuccess={isDeletingSuccess}
            />
          ) : (
            <EmptyCardsListTemplate description='У вас нет ни одного отчёта' className='h-[60vh]' />
          )
        ) : null}
        <ToastsMessages
          errorMessage={errorToastMessage}
          successMessage={successToastMessage}
          isShowSuccessToast={isDeletingSuccess}
          clearErrorCallback={hideErrorToastHandler}
          isShowErrorToast={isDeletingError || isReportSwitchingError}
        />
      </div>
    );
  },
);
