import React, { memo } from 'react';
//
import { H1 } from '@atoms/h1';

interface IReportPageHeaderProps {
  /**
   * Id отчета, если id нет заголовок изменится на "Новый шаблон отчёта"
   * @param {string}
   */
  id: string;
  /**
   * Флаг состояния загрузки, если идет загрузка заголовок меняется на "Шаблон..."
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportPageHeader = memo(
  ({ id, isLoading, className = '' }: IReportPageHeaderProps) => (
    <div className={className}>
      <p className='font-medium text-h3_body text-darkGray tracking-[0.004em] leading-8 mb-1 min-h-[2rem]'>
        {id ? `#${id}` : ''}
      </p>
      <H1 className='tracking-[-0.01em]'>
        {!isLoading ? (id ? `Шаблон` : 'Новый шаблон отчёта') : 'Шаблон...'}
      </H1>
    </div>
  ),
);
