import React, { useEffect, useRef, useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { EVENT_KEY_NAMES } from '@const/common';

interface ICheckListItem {
  /**
   * Текст элемента
   * @param {string}
   */
  itemValue: string;
  /**
   * Обработчик для изменения значения существующего элемента чеклиста
   * @param {(newValue: string) => void;}
   */
  itemValueEditCallback: (newValue: string) => void;
  /**
   * Callback для удаления элемента чеклиста
   * @param {(item: string) => () => void}
   */
  deleteChecklistItemHandler: (item: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const CheckListItem = ({ ...props }: ICheckListItem) => {
  // Ширина инпута
  const [width, setWidth] = useState<number>(0);
  // Значение в инпуте
  const [value, setValue] = useState<string>('');
  // ref на спан
  const spanElement = useRef<HTMLInputElement>(null);
  // ref на инпут
  const inputElement = useRef<HTMLInputElement>(null);
  // Флаг включения режима редактирования
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // Показывает/скрывает спан элемент для предотвращения удаления пункта чеклиста
  // т.к. кнопки сохранения изменений и удаления находятся на одном уровне
  // при сохранении изменения нажатием на кнопку сохранения
  // кнопка сохранения скрывается и показывается обычный пункт чеклиста
  // и происходит нажатие на кнопку удаления
  const [isShow, setIsShow] = useState<boolean>(false);
  // Размер всего блока с чеклистом
  const [checklistWidth, setChecklistWidth] = useState<number>(0);

  const { itemValue, itemValueEditCallback, deleteChecklistItemHandler, className = '' } = props;

  // устанавливает новое значение в локальный state
  useEffect(() => {
    if (!isEdit) {
      setValue(itemValue);
    }
  }, [itemValue, isEdit]);

  // записывает размеры спана и всего чеклиста в локальный state
  const setWidthOfElements = () => {
    if (spanElement.current) {
      const newWidth = spanElement.current.scrollWidth - 44;
      const newChecklistWidth = spanElement.current.parentElement?.parentElement?.offsetWidth || 0;
      setWidth(newWidth);
      setChecklistWidth(newChecklistWidth);
    }
  };

  // устанавливает размер элементов при каждом ререндере компонента
  useEffect(() => {
    if (spanElement.current) {
      setWidthOfElements();
    }
  }, []);

  // устанавливает размер элементов при изменении размера окна
  useEffect(() => {
    window.addEventListener('resize', setWidthOfElements);

    return () => window.removeEventListener('resize', setWidthOfElements);
  });

  // устанавливает стили на элементы при изменении значения или размеров элементов
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.setAttribute('style', 'width: 1px');
      const { scrollWidth } = inputElement.current;
      if (scrollWidth > checklistWidth - 90) {
        const newWidth = checklistWidth - 90;
        setWidth(newWidth);
        inputElement.current.setAttribute('style', `width: ${newWidth}px`);
      } else {
        const newWidth = inputElement.current.scrollWidth;
        setWidth(newWidth);
        inputElement.current.setAttribute('style', `width: ${newWidth}px`);
      }
    }
  }, [value, checklistWidth, width]);

  // при выключении режима редактирования устанавливает таймер который скроет "защитный" спан
  useEffect(() => {
    if (!isEdit) setTimeout(() => setIsShow(false), 500);
  }, [isEdit]);

  // включает режим редактирования и ставит фокус на инпут
  const setEditHandler = (): void => {
    setIsEdit(true);
    setTimeout(() => {
      if (inputElement.current) inputElement.current.focus();
    });
  };

  // обрабатывает изменения значения в инпуте
  const localOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
  };

  // вызывается при отключении режима редактирования
  // вызывает callback для записи нового значения в пункт чеклиста
  // выводит защитный спан, чтобы не произошло удаление элемента чеклиста
  // если выход из режима редактирование осуществлен нажатием на кнопку "сохранить"
  const setNewValue = () => {
    itemValueEditCallback(value);
    setIsShow(true);
    setIsEdit(false);
  };

  // вызывает функцию setNewValue при нажатии на Enter в инпуте
  const setNewValueByEnterKeyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === EVENT_KEY_NAMES.ENTER) {
      setNewValue();
    }
  };

  return (
    <li
      onBlur={setNewValue}
      onDoubleClick={setEditHandler}
      className={`relative group bg-darkDot bg-left-1 bg-no-repeat pl-[2.4rem] list-none ${className}`}>
      {isShow ? <span className='absolute top-0 right-0 bottom-0 left-0 z-[50]' /> : null}
      <span
        ref={spanElement}
        className='flex items-center justify-start relative pr-11 tracking-[0.0275em] h-5 w-fit'>
        {isEdit ? (
          <>
            <input
              value={value}
              ref={inputElement}
              style={{ width: `${width}px` }}
              onChange={localOnChangeHandler}
              onKeyUp={setNewValueByEnterKeyPressHandler}
              className='bg-transparent inline tracking-[0.0275em]'
            />
            <button
              onClick={setNewValue}
              className='inline-block absolute right-0 bg-none border-none text-center h-8 w-6'>
              <Icon variant='tick' className='!inline-block mb-[2px] h-[0.9rem] w-[0.9rem]' />{' '}
            </button>
          </>
        ) : (
          <>
            {itemValue}
            <button
              onClick={deleteChecklistItemHandler(itemValue)}
              className='hidden group-hover:inline-block absolute right-0 bg-none border-none text-center h-8 w-6'>
              <Icon variant='delete' className='!inline-block mb-[2px] h-[0.9rem] w-[0.9rem]' />{' '}
            </button>
          </>
        )}
      </span>
    </li>
  );
};
