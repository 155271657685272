import { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
//
import { RootState } from '@store/store';
import { authApi } from '@api/auth/authApi';
import { storageDb } from '@api/storageApi';
import { TRejectValue } from '@models/index';
import { tasksApi } from '@api/tasks/tasksApi';
import { TFilialData } from '@models/Accounts';
import { getEventData, log } from '@helpers/index';
import { setTaskTypeToPath } from '@redux/App/slice';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { mappers, taskListMappers } from '@redux/Task/mappers';
import { TTaskCreate, TTaskEdit } from '@helpers/myTracker/type';
import { StaffListItemSchema, TStaffListItem } from '@models/Settings';
import { EDIT_TYPE, MY_TRACKER_EVENTS, postEvent } from '@helpers/myTracker/myTracker';
import { clearSuccessTP, clearTaskListDeleteSuccess, setIsRefreshTl } from '@redux/Task/slice';
import {
  TTaskInfo,
  TTaskData,
  TTaskDataResponse,
  TaskCardDataSchema,
  TaskDataResponseSchema,
  SwitchedTaskDataSchema,
} from '@models/Tasks';
import {
  TRequestStatus,
  TASK_RESPONSE_KEYS,
  REJECT_RESPONSE_KEY,
  TASKS_RESPONSE_KEYS,
  T_STAFF_LIST_RESPONSE_KEYS,
} from '@api/types';

export const getTaskData = createAsyncThunk<
  TTaskData | void,
  { id: string; accId: string },
  TRejectValue
>('task/getTaskData', ({ id }, { dispatch, rejectWithValue }) => {
  return tasksApi
    .getTaskById({ id })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (TASK_RESPONSE_KEYS.ID in response.data) {
        const parsedData = TaskDataResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@getTaskData: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }
        const taskData = mappers.responseToStore(parsedData.data);
        dispatch(setTaskTypeToPath({ taskType: taskData.whenSend }));
        return taskData;
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const saveTask = createAsyncThunk<TTaskData, TTaskData, TRejectValue>(
  'task/saveTask',
  (data: TTaskData, { dispatch, getState, rejectWithValue }) => {
    const { userId, accId } = storageDb.getRequestData();
    const { oldData } = getState().task;

    return tasksApi
      .saveTask({ data: mappers.storeToRequest(data), options: { userId, accId } })
      .then(response => {
        setTimeout(() => dispatch(clearSuccessTP()), 1000);
        if (!data.id) {
          postEvent<TTaskCreate>(
            getEventData<TTaskCreate>({
              eventName: MY_TRACKER_EVENTS.TASK_TEMPLATE_CREATE,
              eventProperties: { template_type: data.taskType, template_id: response.data.id },
            }),
          );
        } else {
          postEvent<TTaskEdit>(
            getEventData<TTaskEdit>({
              eventName: MY_TRACKER_EVENTS.TASK_TEMPLATE_UPDATE,
              eventProperties: {
                template_id: Number(data.id),
                template_type: data.taskType,
                is_active: data.isActive,
                edit_type: EDIT_TYPE.CONTENT,
              },
            }),
          );
        }

        const parsedData = TaskDataResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@saveTask: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return mappers.responseToStore(parsedData.data);
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(saveTask(data)), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const saveTasksCopy = createAsyncThunk<
  PromiseSettledResult<AxiosResponse<TTaskDataResponse | TRequestStatus> | PromiseRejectedResult>[],
  { data: TTaskData; filials: TFilialData[] },
  { state: RootState }
>('task/saveTasksCopy', async (data, { getState }) => {
  let requestData = storageDb.getRequestData();
  const {
    task: { isCopyTasksError, rejectedFilialsToCopy },
  } = getState();

  if (isCopyTasksError) {
    data.filials = data.filials.filter(filial => rejectedFilialsToCopy.includes(filial.accId));
  }

  try {
    const { data: authData } = await authApi.updateToken(requestData.accessToken);
    if ('access_token' in authData) {
      storageDb.setToken(authData.access_token);
      requestData = {
        ...requestData,
        ...authData,
      };
    }
  } catch (error) {
    throw new Error('Update Token Error');
  }

  const filialsReq = data.filials.map(filial => {
    const saveData = mappers.storeToRequest(data.data);

    return tasksApi.saveTask({
      options: {
        userId: requestData.userId,
        accId: filial.accId,
      },
      data: {
        ...saveData,
        id: 0,
      },
    });
  });

  return Promise.allSettled(filialsReq);
});

/**
 * Thunk-экшен для получения списка категорий.
 *
 * @param {string} categoryName - Название категории.
 * @returns {{ data: TStaffListItem[]; categoryName: string } | void} - Объект, содержащим данные списка сотрудников и название категории, или void в случае неудачи.
 * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
 */
export const getListOfServicesTS = createAsyncThunk<
  { data: TStaffListItem[]; categoryName: string } | void,
  { categoryName: string },
  TRejectValue
>('task/getListOfServicesTS', (data, { dispatch, rejectWithValue }) => {
  return tasksApi
    .getCategoryList(data.categoryName)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data.status));
      }
      if (T_STAFF_LIST_RESPONSE_KEYS.DATA in response.data) {
        const parsedData = StaffListItemSchema.array().safeParse(response.data.data);

        if (!parsedData.success) {
          log('@@getListOfServicesTS: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return { data: parsedData.data, categoryName: data.categoryName };
      }
    })
    .catch(error => {
      if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getListOfServicesTS(data)), 100);
      }
      return rejectWithValue(error.response.data);
    });
});

// /**
//  * Thunk-экшен для получения списка категорий.
//  *
//  * @param {string} categoryName - Название категории.
//  * @returns {{ data: TStaffListItem[]; categoryName: string } | void} - Объект, содержащим данные списка сотрудников и название категории, или void в случае неудачи.
//  * @rejects {TRejectResponse | TRejectResponse2} - Объект, представляющий информацию об ошибке в случае неудачного запроса.
//  */
// export const getListOfStaffTS = createAsyncThunk<
//   { data: TStaffData; categoryName: string } | void,
//   { categoryName: string },
//   TRejectValue
// >('task/getListOfStaffTS', (data, { dispatch, rejectWithValue }) => {
//   return tasksApi
//     .getStaffList()
//     .then(response => {
//       if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
//         throw new Error(String(response.data.status));
//       }
//       if (T_STAFF_LIST_RESPONSE_KEYS.DATA in response.data) {
//         return { data: response.data.data, categoryName: data.categoryName };
//       }
//     })
//     .catch(error => {
//       if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
//         setTimeout(() => dispatch(getListOfStaffTS(data)), 100);
//       }
//       return rejectWithValue(error.response.data);
//     });
// });

export const getTasksList = createAsyncThunk<
  {
    data: TTaskInfo[];
    order: string[];
  } | void,
  { rt: boolean; et: boolean; ct: boolean },
  TRejectValue
>('taskList/getTasksList', (data, { rejectWithValue }) => {
  const requestData = storageDb.getRequestData();
  return tasksApi
    .getTasksListData({ ct: data.ct, et: data.et, rt: data.rt })
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (TASKS_RESPONSE_KEYS.DATA in response.data) {
        const parsedData = TaskCardDataSchema.array().safeParse(response.data.data);

        if (!parsedData.success) {
          log('@@getTasksList: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return taskListMappers.responseToStore(response.data, requestData.accId);
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const deleteTask = createAsyncThunk<string, { taskId: string }, TRejectValue>(
  'taskList/deleteTask',
  (data, { dispatch, rejectWithValue }) => {
    return tasksApi
      .deleteTaskById({ id: data.taskId })
      .then(response => {
        if (!response.data.ok) {
          throw new Error(String(response.data));
        }
        dispatch(setIsRefreshTl(true));
        setTimeout(() => dispatch(clearTaskListDeleteSuccess()), 2000);
        return data.taskId;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(deleteTask({ taskId: data.taskId })), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);

export const switchTask = createAsyncThunk<string, { taskId: string }, TRejectValue>(
  'taskList/switchTask',
  (data, { dispatch, rejectWithValue }) => {
    return tasksApi
      .switchTaskById({ id: data.taskId })
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data));
        }

        const parsedData = SwitchedTaskDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@switchTask: ', parsedData.error?.format());
          throw new Error(String(parsedData.error));
        }

        return data.taskId;
      })
      .catch(error => {
        if (error.response.data.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
          setTimeout(() => dispatch(switchTask({ taskId: data.taskId })), 100);
        }
        return rejectWithValue(error.response.data);
      });
  },
);
