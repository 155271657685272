import React, { memo } from 'react';
//
import { Button } from '@uikit/Button';
import bbIcon from '@img/baseTemplate/bbLogo.png';

interface IBbAdsSideNav {
  /**
   * Callback для открытия страницы при клике на кнопку
   * @param {() => void}
   */
  changePageOnClick: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BBAdsSideNav = memo(({ changePageOnClick, className = '' }: IBbAdsSideNav) => (
  <div
    className={`border border-lightGreen flex items-start justify-start gap-8 rounded-lg p-4 max-w-[17.5rem] ${className}`}>
    <div className='border border-lightGray bg-white rounded-2xl flex items-center justify-center h-16 min-w-[4rem]'>
      <img src={bbIcon} className='h-10' alt='Бьюти бот' />
    </div>
    <div>
      <p className='font-bold leading-8 text-blackText text-h6Mobile mb-2.5'>Бьюти Бот</p>
      <p className='text-grayText leading-6 mb-2 w-[10em]'>
        Бьюти Бот повышает оборот салона красоты с помощью авто-рассылок и уведомлений WhatsApp!
      </p>
      <Button
        dense
        type='action'
        view='filled'
        color='success'
        text='Подключить'
        className='!min-w-[8.875rem] transition-all duration-300 ease-out'
        onClick={changePageOnClick}
      />
    </div>
  </div>
));
