import { apiService } from '@api/ApiService';
import { END_POINTS_URL } from '@const/apiConstants';
import { ANALYTICS_CHARTS_LIST } from '@const/dashboard';
import { TSaveDashboardData, TSelectedDashboardList } from '@models/index';
import { DASHBOARD_TYPES, TRejectResponse, IDashboardRequestData } from '@api/types';
import { TDashboardsResponseData, TOneDashboardResponseData } from '@api/dashboard/types';

/**
 * Функция получает объект с выбранными дашбордами выделяет из них названия шаблонов со значением true,
 * создает новый объект из этих данных, затем объединяет полученные данные и объект с 'выключенными' графиками
 * возвращает полученный после объединения объект
 * @param dataSheetList объект с выбранными графиками
 * @param dataSheetObj объект со всеми дашбордами в выключенном состоянии
 */
const getSelectedDashboardList = (
  dataSheetList: string[],
  dataSheetObj: TSelectedDashboardList,
): TSelectedDashboardList => {
  const selectedDataSheetObj: TSelectedDashboardList = dataSheetList.reduce(
    (acc, item) => ({ ...acc, [item]: true }),
    {},
  );
  return { ...dataSheetObj, ...selectedDataSheetObj };
};

/**
 * API страниц дашбордов
 * @module dashboardApi
 */

export const dashboardApi = {
  /**
   * Метод запрашивает данные определенного типа(страницы) графиков, тип запрашиваемого графика указывается в параметре type
   * так же передается диапазон дат в котором нужно получить данные,
   * если поле force: false, указанный диапазон дат игнорируется, берется сохраненный на бэке
   * если поле force: true, с бэка приходят данные в указанном диапазоне
   * этот метод НЕ СОХРАНЯЕТ! выбранный диапазон дат
   * @method getDashboard
   * @param data {IDashboardRequestData} параметры запроса данных графиков
   */
  getDashboard(data: IDashboardRequestData) {
    return apiService.get<TOneDashboardResponseData | TRejectResponse>(END_POINTS_URL.DASHBOARD, {
      type: data.type,
      force: !!data.force,
      start_date: data.startDate,
      end_date: data.endDate,
    });
  },
  /**
   * Метод запрашивает данные типа main, получаем все данные, список выбранных шаблонов и диапазон дат.
   * @method getMainDashboard
   * @param data {IDashboardRequestData} параметры запроса данных графиков
   */
  getMainDashboard(data: IDashboardRequestData) {
    return apiService.get<TDashboardsResponseData | TRejectResponse>(END_POINTS_URL.DASHBOARD, {
      type: DASHBOARD_TYPES.MAIN,
      force: !!data.force,
      start_date: data.startDate,
      end_date: data.endDate,
    });
  },
  /**
   * Метод сохраняет список выбранных графиков и диапазон дат
   * @method saveDashboard
   * @param data {TSaveDashboardData} объект с выбранными шаблонами и временным диапазоном
   */
  saveDashboard(data: TSaveDashboardData) {
    return apiService.post<TRejectResponse>(END_POINTS_URL.DASHBOARD_SAVE, {
      start_date: data.startDate,
      end_date: data.endDate,
      dashboard_list: getSelectedDashboardList(
        data.selectedAnalyticsDashboard,
        ANALYTICS_CHARTS_LIST,
      ),
    });
  },
};
