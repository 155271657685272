import React, { useState } from 'react';
import { EmojiClickData } from 'emoji-picker-react';
//
import { useAppDispatch } from '@hooks/index';
import { setHideEmojiPicker } from '@redux/emoji/emojiSlice';
import { addEmojiInTextarea, setAbandonedTextareaData } from '@redux/Report/slice';
import { addKeyInTextareaTP, setAbandonedTextareaDataTP } from '@redux/Task/slice';
import { TEPNameList, TOnEmojiViewChange, TSetViewEmojiPicker } from '@shared/types';

type TUseEmoji = {
  onEmojiViewChange: TOnEmojiViewChange;
  setViewEmojiPicker: TSetViewEmojiPicker;
  setEmojiInTextarea: (emoji: EmojiClickData) => void;
  setTextAreaOnBlur: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
};

type TUseEmojiProps = {
  type: TEPNameList;
};
export const useEmoji = ({ type }: TUseEmojiProps): TUseEmoji => {
  const dispatch = useAppDispatch();
  const [textarea, setTextarea] = useState<EventTarget & HTMLTextAreaElement>();

  const setEmojiInTextarea = (emoji: EmojiClickData) => {
    const action = type === 'report' ? addEmojiInTextarea : addKeyInTextareaTP;

    dispatch(action({ innerText: emoji.emoji }));
    if (textarea) {
      textarea.focus();
    }
  };

  const setTextAreaOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setTextarea(event.currentTarget);
  };

  const onEmojiViewChange: TOnEmojiViewChange =
    ({ textAreaName, selectionStart, selectionEnd }) =>
    () => {
      const action = type === 'report' ? setAbandonedTextareaData : setAbandonedTextareaDataTP;
      dispatch(
        action({
          name: textAreaName,
          selectionStart,
          selectionEnd,
        }),
      );
    };

  const setViewEmojiPicker: TSetViewEmojiPicker = ({ name, isOpenEmoji }) => {
    dispatch(setHideEmojiPicker({ isOpen: isOpenEmoji, name }));
  };

  return { setEmojiInTextarea, setTextAreaOnBlur, onEmojiViewChange, setViewEmojiPicker };
};
