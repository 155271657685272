import { storageDb } from '@api/storageApi';
import { TInitialState } from '@redux/Auth/zod';
import { PayloadAction } from '@reduxjs/toolkit';
import { TLoginData, TTelegramAuthData } from '@models/Auth';

export const reducers = {
  setAuthUserData(state: TInitialState, action: PayloadAction<TTelegramAuthData>) {
    state.authUserData = action.payload;
  },
  setAccessToken(state: TInitialState, action: PayloadAction<string>) {
    state.accessToken = action.payload;
  },
  logout(state: TInitialState) {
    storageDb.setAuth(false);
    state.auth = false;
  },
  setTelegramId(state: TInitialState, action: PayloadAction<string>) {
    state.telegramId = action.payload;
    state.isInputError = false;
    state.isGetPinError = false;
    state.isChekPinError = false;
  },
  setPin(state: TInitialState, action: PayloadAction<string>) {
    const newPin = action.payload;
    if (newPin.length <= 4) {
      state.pin = action.payload;
      state.isInputError = false;
      state.isGetPinError = false;
      state.isChekPinError = false;
    }
  },
  setGetPinSuccess(state: TInitialState, action: PayloadAction<boolean>) {
    state.isGetPinSuccess = action.payload;
    state.pin = '';
    state.isInputError = false;
    state.isGetPinError = false;
    state.isChekPinError = false;
  },
  setInputError(state: TInitialState, action: PayloadAction<boolean>) {
    state.isInputError = action.payload;
  },
};

export const loginReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isLoading = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TLoginData | void>) => {
    if (action.payload) {
      const { auth, userId, accessToken, authUserData } = action.payload;

      state.auth = auth;
      state.userId = userId;
      state.accessToken = accessToken;
      state.authUserData = authUserData;
    }
    state.isLoading = false;
  },
  rejected: (state: TInitialState) => {
    state.isError = true;
    state.isLoading = false;
  },
};

export const getPinDataReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isFetching = true;
    state.isInputError = false;
    state.isGetPinError = false;
    state.isChekPinError = false;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<boolean | void>) => {
    if (action.payload) {
      state.isGetPinSuccess = action.payload;
    }
    state.isFetching = false;
  },
  rejected: (state: TInitialState) => {
    state.isFetching = false;
    state.isGetPinError = true;
  },
};

export const loginUserByPinReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isFetching = true;
    state.isInputError = false;
    state.isGetPinError = false;
    state.isChekPinError = false;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TLoginData | void>) => {
    if (action.payload) {
      const { auth, userId, accessToken, authUserData } = action.payload;

      state.auth = auth;
      state.userId = userId;
      state.accessToken = accessToken;
      state.authUserData = authUserData;
    }
    state.pin = '';
    state.auth = true;
    state.telegramId = '';
    state.isFetching = false;
    state.isGetPinSuccess = false;
  },
  rejected: (state: TInitialState) => {
    state.pin = '';
    state.isFetching = false;
    state.isChekPinError = true;
  },
};

export const updateTokenReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isLoading = true;
    state.isUpdTokenPending = true;
  },
  fulfilled: (
    state: TInitialState,
    action: PayloadAction<Omit<TLoginData, 'authUserData'> | void>,
  ) => {
    if (action.payload) {
      const { auth, userId, accessToken } = action.payload;
      state.auth = auth;
      state.userId = userId;
      state.accessToken = accessToken;
    }
    state.isLoading = false;
    state.isUpdTokenSuccess = true;
    state.isUpdTokenPending = false;
  },
  rejected: (state: TInitialState) => {
    state.isError = true;
    state.isLoading = false;
    state.isUpdTokenError = true;
    state.isUpdTokenPending = false;
    state.auth = false;
    storageDb.setAuth(false);
  },
};
