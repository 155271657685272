import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Auth/state';
import { getPinData, login, loginUserByPinThunk, updateToken } from '@redux/Auth/thunk';
import {
  reducers,
  loginReducer,
  getPinDataReducer,
  updateTokenReducer,
  loginUserByPinReducer,
} from '@redux/Auth/reducers';

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(login.pending, loginReducer.pending);
    builder.addCase(login.fulfilled, loginReducer.fulfilled);
    builder.addCase(login.rejected, loginReducer.rejected);
    builder.addCase(getPinData.pending, getPinDataReducer.pending);
    builder.addCase(getPinData.fulfilled, getPinDataReducer.fulfilled);
    builder.addCase(getPinData.rejected, getPinDataReducer.rejected);
    builder.addCase(loginUserByPinThunk.pending, loginUserByPinReducer.pending);
    builder.addCase(loginUserByPinThunk.fulfilled, loginUserByPinReducer.fulfilled);
    builder.addCase(loginUserByPinThunk.rejected, loginUserByPinReducer.rejected);
    builder.addCase(updateToken.pending, updateTokenReducer.pending);
    builder.addCase(updateToken.fulfilled, updateTokenReducer.fulfilled);
    builder.addCase(updateToken.rejected, updateTokenReducer.rejected);
  },
});

export const authReducer = authSlice.reducer;
export const {
  logout,
  setPin,
  setInputError,
  setTelegramId,
  setAccessToken,
  setAuthUserData,
  setGetPinSuccess,
} = authSlice.actions;
