import React from 'react';
//
import { EVENT_KEY_NAMES } from '@const/common';
import { TOnChangeHandler } from '@shared/types';
import { LoginPageInput } from '@components/loginPageInput';

interface ITextInputWithErrorTextProps {
  /**
   * Параметр отвечающий за размер инпута
   * @param {boolean}
   */
  fill?: boolean;
  /**
   * Значение параметра name в инпуте
   * @param {string}
   */
  inputName: string;
  /**
   * Значение пина
   * @param {string}
   */
  inputValue: string;
  /**
   * Показывает ошибку №1
   * @param {boolean}
   */
  isShowErrorText: boolean;
  /**
   * Показывает ошибку №2
   * @param {boolean}
   */
  isInputError: boolean;
  /**
   * Опциональный параметр, если он true в инпуте будут меняться стили при изменении параметра isError
   * @param {boolean}
   */
  isRequired?: boolean;
  /**
   * Заголовок инпута
   * @param {string}
   */
  inputHeaderText: string;
  /**
   * Текст placeholder в input
   * @param {string}
   */
  inputPlaceholder: string;
  /**
   * Текст ошибки №1
   * @param {string}
   */
  inputDataErrorText: string;
  /**
   * Текст ошибки №2
   * @param {string}
   */
  inputDataErrorText2: string;
  /**
   * Callback для обработки нажатия на клавишу Enter
   * @param {() => void}
   */
  onClickCallback: () => void;
  /**
   * Кнопка расположенная справа от заголовка
   * @param {React.ReactNode}
   */
  rightButtonInHeader: React.ReactNode;
  /**
   * Callback для обработки изменений в инпуте
   * @param {(value: string) => void}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TextInputWithErrorText = ({
  inputName,
  isRequired,
  inputValue,
  fill = false,
  isInputError,
  onClickCallback,
  onChangeHandler,
  isShowErrorText,
  inputHeaderText,
  inputPlaceholder,
  inputDataErrorText,
  inputDataErrorText2,
  rightButtonInHeader,
  className = '',
}: ITextInputWithErrorTextProps) => {
  const onKeyDownCallbackHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (EVENT_KEY_NAMES.ENTER === key) {
      onClickCallback();
    }
  };

  return (
    <div className={className}>
      <div className=''>
        <div className='flex items-center justify-between mb-2 w-full'>
          <p className='leading-5 text-gray6 text-inner_text tracking-[0.018em]'>
            {inputHeaderText}
          </p>
          {rightButtonInHeader}
        </div>
        <LoginPageInput
          fill={fill}
          value={inputValue}
          inputName={inputName}
          isRequired={isRequired}
          onChangeCallback={onChangeHandler}
          inputPlaceholder={inputPlaceholder}
          isError={isInputError || isShowErrorText}
          onKeyUpCallback={onKeyDownCallbackHandler}
          className={isShowErrorText || isInputError ? '' : 'mb-10'}
        />
      </div>
      {isInputError ? (
        <p className='text-small_text text-auChico mt-0.5 mb-6 tracking-[0.033em]'>
          {inputDataErrorText}
        </p>
      ) : null}
      {isShowErrorText ? (
        <p className='text-small_text text-auChico mt-0.5 mb-6 tracking-[0.033em]'>
          {inputDataErrorText2}
        </p>
      ) : null}
    </div>
  );
};
