import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './DefaultInput';

const propTypes = {
  /**
   ` The view of the component.
   * Variants: `outlined`
   * Default value (if undefined): `smooth` `
   */
  view: PropTypes.oneOf(["smooth", "outlined", "raised", "filled"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Placeholder text in the absence of any value.
   */
  placeholder: PropTypes.string,
  /**
   * Label text
   */
  label: PropTypes.string,
  /**
   * Form value of the input
   */
  value: PropTypes.string,
  /**
   * If `true`, the component will take up the full width of its container.
   */
  fill: PropTypes.bool,
  /**
   * Under input helper text
   */
  helperText: PropTypes.string,
  /**
   * Set Clear button
   */
  clearButton: PropTypes.bool,
  /**
   * Set Success style for input
   */
  success: PropTypes.bool,
  /**
   * Set Error style for input
   */
  error: PropTypes.bool,
  /**
   * Set Fixed label for input
   */
  fixed: PropTypes.bool,
  /**
   * Element to render on right side of input.
   */
  rightElement: PropTypes.element,
  /**
   * Custom right icon for Success style
   */
  successIcon: PropTypes.string,
  /**
   * Custom right icon for Error style
   */
  errorIcon: PropTypes.string,
  /**
   * Set custom icon for "Clear button" element
   */
  clearIcon: PropTypes.string,
  /**
   * Dense size
   */
  dense: PropTypes.bool,
  /**
   * Change event handler.
   */
  onChange: PropTypes.func,
  /**
   * Errors helper text
   */
  errorHelperText: PropTypes.string,
  clearSearchString: PropTypes.func,
  onKeyUp: PropTypes.func,
  /**
   * Input mode
   * Change keyboard type
   */
  inputMode: PropTypes.string
};

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const TextInput: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);