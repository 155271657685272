import React, { memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { newTaskData } from '@data/index';
import { getEventData } from '@helpers/index';
import { TaskTemplate } from '@templates/task';
import { NoAccess } from '@components/noAccess';
import { setTaskData } from '@redux/Task/slice';
import { getTaskData } from '@redux/Task/thunks';
import { QUERY_PARAMS } from '@const/apiConstants';
import { TPageView } from '@helpers/myTracker/type';
import { setTaskTypeToPath } from '@redux/App/slice';
import { getNoteListPageData } from '@redux/noteList/thunk';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getAppData, getTaskPageData } from '@redux/selectors';
import { MY_TRACKER_EVENTS, PAGE_NAMES, postEvent } from '@helpers/myTracker/myTracker';

const TaskPage = memo(() => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // Получаем id задачи из searchParams
  const taskId = searchParams.get(QUERY_PARAMS.TASK_ID);

  // Получаем id филиала из searchParams
  const accId = searchParams.get(QUERY_PARAMS.ACC_ID);

  // Получаем строку с типом задачи формирования ссылки
  const { taskTypeToPath } = useAppSelector(getAppData);

  // Получаем объект с данными выбранного филиала
  const { selectedFilial } = useAppSelector(getAccountsData);

  const {
    isError,
    isSaving,
    isLoading,
    isRetries,
    isSuccess,
    isSavingError,
    staffNewValue,
    isAccessDenied,
    isSavingSuccess,
    servicesNewValue,
    errorToastMessage,
    categoriesDataList,
    successToastMessage,
    newChecklistItemName,
    data,
  } = useAppSelector(getTaskPageData);

  // получаем данные для отображения модалки добавления заметок в задачу
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getNoteListPageData());
    }
  }, [selectedFilial?.accId, dispatch]);

  // Эффект делает запрос за данными задачи если из URL удалось взять id задачи
  // в противном случае, в state загружается пустая/новая задача
  useEffect(() => {
    if (selectedFilial?.accId) {
      if (taskId && accId) {
        dispatch(setTaskTypeToPath({ taskType: '' }));
        dispatch(getTaskData({ id: taskId, accId }));
        postEvent<TPageView>(
          getEventData<TPageView>({
            eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
            eventProperties: { name_page: PAGE_NAMES.EDIT_TASK },
          }),
        );
      } else {
        dispatch(setTaskTypeToPath({ taskType: '' }));
        dispatch(setTaskData({ data: newTaskData }));
        postEvent<TPageView>(
          getEventData<TPageView>({
            eventName: MY_TRACKER_EVENTS.LK_PAGE_VIEW,
            eventProperties: { name_page: PAGE_NAMES.NEW_TASK },
          }),
        );
      }
    }
  }, [taskId, accId, selectedFilial?.accId, dispatch]);

  // Эффект делает повторный запрос за данными задачи при ошибке токена
  useEffect(() => {
    if (isRetries) {
      if (taskId && accId) {
        setTimeout(() => {
          dispatch(setTaskTypeToPath({ taskType: '' }));
          dispatch(getTaskData({ id: taskId, accId }));
        }, 100);
      } else {
        setTimeout(() => {
          dispatch(setTaskTypeToPath({ taskType: '' }));
          dispatch(setTaskData({ data: newTaskData }));
        }, 100);
      }
    }
  }, [isRetries, taskId, accId, dispatch]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <TaskTemplate
          data={data}
          isError={isError}
          isSaving={isSaving}
          isLoading={isLoading}
          isSuccess={isSuccess}
          newStaffValue={staffNewValue}
          isSavingError={isSavingError}
          selectedFilial={selectedFilial}
          taskTypeToPath={taskTypeToPath}
          isSavingSuccess={isSavingSuccess}
          newServicesValue={servicesNewValue}
          errorToastMessage={errorToastMessage}
          categoriesDataList={categoriesDataList}
          successToastMessage={successToastMessage}
          newChecklistItemName={newChecklistItemName}
          isBBIntegrated={selectedFilial?.beautyBotIntegration || false}
        />
      ) : null}
    </>
  );
});

export default TaskPage;
