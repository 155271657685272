import { z } from 'zod';
import { TASK_TYPE } from '@const/task';

export enum EAnalyticsType {
  ADMIN = 'admins',
}

export enum EAnalyticsChartsType {
  TASKS_ALL = 'plan_actual_all',
  TASKS_BY_ID = 'plan_actual_id',
  TASKS_BY_TYPE = 'plan_actual_type',
  AVERAGE_CHECK_BY_CLIENTS = 'average_check_by_client',
  LEADS_TO_RECORDS = 'leads_to_records',
  MISSED_CALLS = 'percent_missed_calls_callback',
  WA_RESPONSE_TIME = 'response_time_call_and_wa',
  EARNED_BY_OUT_CALLS = 'earned_by_out_calls',
  PERCENT_CUSTOMERS_BOUGHT_PRODUCT = 'percent_customers_bought_product',
  AMOUNT_GOODS_SOLD = 'amount_goods_sold',
  PERCENT_CUSTOMERS_BOUGHT_CERTIFICATES = 'percent_customers_bought_certificates',
  AMOUNT_CERTIFICATES_SUBSCRIPTIONS_SOLD = 'amount_certificates_subscriptions_sold',
}

export const chartDataSchema = z.object({
  name: z.string(),
  bar: z.number().optional(),
  line: z.number().optional(),
  trend: z.number().optional(),
});

export const chartDataListSchema = z.record(
  z.nativeEnum(EAnalyticsChartsType),
  chartDataSchema.array(),
);

const AnalyticsDateSchema = z.string().transform(str => new Date(str).toLocaleDateString('en-CA'));

export const TaskBaseValueSchema = z.object({
  tasks_active: z.number(),
  tasks_done: z.number(),
  percentage_completed: z.number(),
});

export const TaskActualIdValueSchema = TaskBaseValueSchema.extend({
  template_id: z.number(),
  template_title: z.string(),
}).array();

export const trendChartDataSchema = z.object({
  name: z.string(),
  trend: z.number(),
});

export const PlanActualAllSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: TaskBaseValueSchema.nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const PlanActualIdSchema = z.object({
  template_title: z.string(),
  template_id: z.number(),
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const PlanActualTypeSchema = z.object({
  task_type: z.nativeEnum(TASK_TYPE),
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const AverageCheckByClientSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const LeadsToRecordsSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const PercentMissedCallsSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z
        .object({
          missed_and_callback_percent: z.number(),
          missed_calls_percent: z.number(),
        })
        .nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const ResponseTimeCallAndWaSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const EarnedByOutCallsSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const PercentCustomersBoughtProductSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const AmountGoodsSoldSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const PercentCustomersBoughtCertificatesSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const AmountCertificatesSubscriptionsSoldSchema = z.object({
  graph: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
  trend: z
    .object({
      date: AnalyticsDateSchema,
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralPlanActualAllSchema = PlanActualAllSchema.extend({
  top_three: z
    .object({
      name: z.string(),
      place: z.number(),
      user_id: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .array(),
});

export const GeneralPlanActualIdSchema = PlanActualIdSchema.extend({
  top_three: z
    .object({
      name: z.string(),
      place: z.number(),
      user_id: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .array(),
});

export const GeneralPlanActualTypeSchema = PlanActualTypeSchema.extend({
  top_three: z
    .object({
      name: z.string(),
      place: z.number(),
      user_id: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .array(),
});

export const GeneralAverageCheckByClientSchema = AverageCheckByClientSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralLeadsToRecordsSchema = LeadsToRecordsSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralPercentMissedCallsSchema = PercentMissedCallsSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z
        .object({
          missed_and_callback_percent: z.number(),
          missed_calls_percent: z.number(),
        })
        .nullable(),
    })
    .array(),
});

export const GeneralResponseTimeCallAndWaSchema = ResponseTimeCallAndWaSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralEarnedByOutCallsSchema = EarnedByOutCallsSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralPercentCustomersBoughtProductSchema =
  PercentCustomersBoughtProductSchema.extend({
    top_three: z
      .object({
        user_id: z.number(),
        name: z.string(),
        place: z.number(),
        value: z.number().nullable(),
      })
      .array(),
  });

export const GeneralAmountGoodsSoldSchema = AmountGoodsSoldSchema.extend({
  top_three: z
    .object({
      user_id: z.number(),
      name: z.string(),
      place: z.number(),
      value: z.number().nullable(),
    })
    .array(),
});

export const GeneralPercentCustomersBoughtCertificatesSchema =
  PercentCustomersBoughtCertificatesSchema.extend({
    top_three: z
      .object({
        user_id: z.number(),
        name: z.string(),
        place: z.number(),
        value: z.number(),
      })
      .array(),
  });

export const GeneralAmountCertificatesSubscriptionsSoldSchema =
  AmountCertificatesSubscriptionsSoldSchema.extend({
    top_three: z
      .object({
        user_id: z.number(),
        name: z.string(),
        place: z.number(),
        value: z.number().nullable(),
      })
      .array(),
  });

export const GeneralSchema = z.object({
  plan_actual_all: GeneralPlanActualAllSchema,
  plan_actual_type: GeneralPlanActualTypeSchema.array(),
  plan_actual_id: GeneralPlanActualIdSchema.array(),
  average_check_by_client: GeneralAverageCheckByClientSchema,
  leads_to_records: GeneralLeadsToRecordsSchema,
  percent_missed_calls_callback: GeneralPercentMissedCallsSchema,
  response_time_call_and_wa: GeneralResponseTimeCallAndWaSchema,
  earned_by_out_calls: GeneralEarnedByOutCallsSchema,
  percent_customers_bought_product: GeneralPercentCustomersBoughtProductSchema,
  amount_goods_sold: GeneralAmountGoodsSoldSchema,
  percent_customers_bought_certificates: GeneralPercentCustomersBoughtCertificatesSchema,
  amount_certificates_subscriptions_sold: GeneralAmountCertificatesSubscriptionsSoldSchema,
});

// Admins
export const AdminPlanActualAllSchema = PlanActualAllSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .nullable(),
});

export const AdminPlanActualTypeSchema = PlanActualTypeSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .nullable(),
});

export const AdminPlanActualIdSchema = PlanActualIdSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z
        .object({
          tasks_active: z.number(),
          tasks_done: z.number(),
          percentage_completed: z.number(),
        })
        .nullable(),
    })
    .nullable(),
});

export const AdminAverageCheckByClientSchema = AverageCheckByClientSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminsLeadsToRecordsSchema = LeadsToRecordsSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminPercentMissedCallsSchema = PercentMissedCallsSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z
        .object({
          missed_and_callback_percent: z.number(),
          missed_calls_percent: z.number(),
        })
        .nullable(),
    })
    .nullable(),
});

export const AdminResponseTimeCallAndWaSchema = ResponseTimeCallAndWaSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminEarnedByOutCallsSchema = EarnedByOutCallsSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminPercentCustomersBoughtProductSchema = PercentCustomersBoughtProductSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminAmountGoodsSoldSchema = AmountGoodsSoldSchema.extend({
  rating: z
    .object({
      place: z.number(),
      value: z.number().nullable(),
    })
    .nullable(),
});

export const AdminPercentCustomersBoughtCertificatesSchema =
  PercentCustomersBoughtCertificatesSchema.extend({
    rating: z
      .object({
        place: z.number(),
        value: z.number().nullable(),
      })
      .nullable(),
  });

export const AdminAmountCertificatesSubscriptionsSoldSchema =
  AmountCertificatesSubscriptionsSoldSchema.extend({
    rating: z
      .object({
        place: z.number(),
        value: z.number().nullable(),
      })
      .nullable(),
  });

export const AdminsSchema = z.object({
  plan_actual_all: AdminPlanActualAllSchema,
  plan_actual_type: AdminPlanActualTypeSchema.array(),
  plan_actual_id: AdminPlanActualIdSchema.array(),
  average_check_by_client: AdminAverageCheckByClientSchema,
  leads_to_records: AdminsLeadsToRecordsSchema,
  percent_missed_calls_callback: AdminPercentMissedCallsSchema,
  response_time_call_and_wa: AdminResponseTimeCallAndWaSchema,
  earned_by_out_calls: AdminEarnedByOutCallsSchema,
  percent_customers_bought_product: AdminPercentCustomersBoughtProductSchema,
  amount_goods_sold: AdminAmountGoodsSoldSchema,
  percent_customers_bought_certificates: AdminPercentCustomersBoughtCertificatesSchema,
  amount_certificates_subscriptions_sold: AdminAmountCertificatesSubscriptionsSoldSchema,
});

// Схема для admins
export const AdminSchema = AdminsSchema.extend({
  user_id: z.number(),
  name: z.string(),
  avatar: z.string().nullable(),
});

export const MetaSchema = z.object({
  dashboard_list: z.record(z.nativeEnum(EAnalyticsChartsType), z.boolean()),
});

export const analyticsResponseSchema = z.object({
  ok: z.boolean(), //
  data: z.object({
    generals: GeneralSchema,
    admins: AdminSchema.array(),
  }),
  meta: MetaSchema,
});

export const AdminsRatingSchema = z
  .object({
    name: z.string(),
    userId: z.number(),
    place: z.number(),
    completed: z.number().optional(),
    total: z.number().optional(),
    sum: z.number().optional(),
    percent: z.number().optional(),
    seconds: z.number().optional(),
  })
  .nullable();

export const getAnalyticsAdminRequestSchema = z.object({
  type: z.nativeEnum(EAnalyticsType),
  start_date: z.string(),
  end_date: z.string(),
});

export const saveAnalyticsMetaRequestSchema = z.object({
  dashboard_list: z.record(z.nativeEnum(EAnalyticsChartsType), z.boolean()),
});

export type TGetAnalyticsAdminRequest = z.infer<typeof getAnalyticsAdminRequestSchema>;

export type TSaveAnalyticsMetaRequest = z.infer<typeof saveAnalyticsMetaRequestSchema>;

// Тип для объекта

export type TAnalyticsResponseData = z.infer<typeof analyticsResponseSchema>;

export type TPlanActualId = z.infer<typeof PlanActualIdSchema>;
export type TAnalyticsAdmin = z.infer<typeof AdminSchema>;

export type TGeneral = z.infer<typeof GeneralSchema>;

export type TChartData = z.infer<typeof chartDataSchema>;

export type TAdminsRating = z.infer<typeof AdminsRatingSchema>;

export type TMeta = z.infer<typeof MetaSchema>;
