import { PayloadAction } from '@reduxjs/toolkit';
import { IInitialState } from '@redux/Dashboard/state';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { getDashboardList, getDateFromString } from '@helpers/index';
import { TGetDashboardData, TGetMainDashboardData } from '@models/index';
import {
  DASHBOARD_KEYS,
  DASHBOARD_ADMINS_KEYS,
  DASHBOARD_MASTERS_KEYS,
  DASHBOARD_FINANCES_KEYS,
} from '@const/dashboard';
import {
  REJECT_RESPONSE_KEY,
  TRejectActionPayload,
  REJECT_RESPONSE_2_KEY,
  TSaveDashboardResponse,
  DASHBOARD_TYPES,
} from '@api/types';
import {
  TAdminsDashboardData,
  TMastersDashboardData,
  TFinancesDashboardData,
} from '@api/dashboard/types';

export const reducers = {
  setSelectedFinancesCategories(
    state: IInitialState,
    action: PayloadAction<{ name: DASHBOARD_FINANCES_KEYS; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    if (checked) {
      state.selectedFinancesDashboard.push(name);
    } else {
      const newDashboardList = state.selectedFinancesDashboard.filter(item => name !== item);

      if (newDashboardList.length) {
        state.selectedFinancesDashboard = newDashboardList;
      } else {
        state.selectedFinancesDashboard = [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT];
      }
    }
  },
  setSelectedMastersCategories(
    state: IInitialState,
    action: PayloadAction<{ name: DASHBOARD_MASTERS_KEYS; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    if (checked) {
      state.selectedMastersDashboard.push(name);
    } else {
      const newDashboardList = state.selectedMastersDashboard.filter(item => name !== item);

      if (newDashboardList.length) {
        state.selectedMastersDashboard = newDashboardList;
      } else {
        state.selectedMastersDashboard = [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE];
      }
    }
  },
  setSelectedAdminsCategories(
    state: IInitialState,
    action: PayloadAction<{ name: DASHBOARD_ADMINS_KEYS; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    if (checked) {
      state.selectedAdminsDashboard.push(name);
    } else {
      const newDashboardList = state.selectedAdminsDashboard.filter(item => name !== item);

      if (newDashboardList.length) {
        state.selectedAdminsDashboard = newDashboardList;
      } else {
        state.selectedAdminsDashboard = [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS];
      }
    }
  },
  setSelectedAnalyticsCategories(
    state: IInitialState,
    action: PayloadAction<{ name: DASHBOARD_KEYS; checked: boolean }>,
  ) {
    const { name, checked } = action.payload;
    if (checked) {
      state.selectedAnalyticsDashboard.push(name);
    } else {
      const newDashboardList = state.selectedAnalyticsDashboard.filter(item => name !== item);

      if (newDashboardList.length) {
        state.selectedAnalyticsDashboard = newDashboardList;
      } else {
        state.selectedAnalyticsDashboard = [DASHBOARD_KEYS.SERVICE_CATEGORY];
      }
    }
  },
  setFinancesDates(
    state: IInitialState,
    action: PayloadAction<{ startDate: Date; endDate: Date }>,
  ) {
    const { startDate, endDate } = action.payload;
    if (startDate && endDate) {
      state.financesStartDate = startDate;
      state.financesEndDate = endDate;
    }
  },
  setMastersDates(state: IInitialState, action: PayloadAction<{ startDate: Date; endDate: Date }>) {
    const { startDate, endDate } = action.payload;
    if (startDate && endDate) {
      state.mastersStartDate = startDate;
      state.mastersEndDate = endDate;
    }
  },
  setAdminsDates(state: IInitialState, action: PayloadAction<{ startDate: Date; endDate: Date }>) {
    const { startDate, endDate } = action.payload;
    if (startDate && endDate) {
      state.adminsStartDate = startDate;
      state.adminsEndDate = endDate;
    }
  },
  setAnalyticsDates(
    state: IInitialState,
    action: PayloadAction<{ startDate: Date; endDate: Date }>,
  ) {
    const { startDate, endDate } = action.payload;
    if (startDate && endDate) {
      state.analyticsStartDate = startDate;
      state.analyticsEndDate = endDate;
    }
  },
  setSelectedAllAmountFilterName(state: IInitialState, action: PayloadAction<string>) {
    state.allAmountSelectedFilterName = action.payload;
  },
  setSelectedEarnedByOutCallsFilterName(state: IInitialState, action: PayloadAction<string>) {
    state.earnedByOutCallsSelectedFilterName = action.payload;
  },
  setSelectedAllOutCallsFilterName(state: IInitialState, action: PayloadAction<string>) {
    state.selectedAllOutCallsFilterName = action.payload;
  },
  setSelectedAllInCallsFilterName(state: IInitialState, action: PayloadAction<DASHBOARD_KEYS>) {
    state.selectedAllInCallsFilterName = action.payload;
  },
  setSelectedReturnMasterRateFilterName(
    state: IInitialState,
    action: PayloadAction<DASHBOARD_KEYS>,
  ) {
    state.selectedReturnMasterRateFilterName = action.payload;
  },
  setSelectedTaskFilterName(state: IInitialState, action: PayloadAction<DASHBOARD_KEYS>) {
    state.selectedTaskFilterName = action.payload;
  },
};

const rejected = (state: IInitialState, action: PayloadAction<TRejectActionPayload>) => {
  if (action.payload) {
    if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
      const { detail } = action.payload;
      if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
        state.isRetries = true;
        state.retries -= 1;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.isRefresh = false;
      }
    }
    if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
      const { status } = action.payload;
      if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else {
        state.isError = true;
      }
      state.isLoading = false;
      state.isRefresh = false;
    }
  } else {
    state.isError = true;
    state.isLoading = false;
    state.isRefresh = false;
  }
};

export const getDashboardDataReducers = {
  pending: (state: IInitialState) => {
    state.isError = false;
    state.isLoading = true;
  },
  fulfilled: (state: IInitialState, action: PayloadAction<TGetDashboardData>) => {
    if (action.payload) {
      if (state.isRetries) {
        state.retries = 1;
        state.isRetries = false;
      }
      const { name, data } = action.payload;

      switch (name) {
        case DASHBOARD_TYPES.ADMINS: {
          state.data = {
            ...state.data,
            admins: data.data as TAdminsDashboardData,
          };
          break;
        }
        case DASHBOARD_TYPES.FINANCE: {
          state.data = {
            ...state.data,
            finance: data.data as TFinancesDashboardData,
          };
          break;
        }
        case DASHBOARD_TYPES.MASTERS: {
          state.data = {
            ...state.data,
            masters: data.data as TMastersDashboardData,
          };
          break;
        }
        default:
          break;
      }
      state.isLoading = false;
    }
  },
  rejected,
};

export const getMainDashboardDataReducers = {
  pending: (state: IInitialState) => {
    state.isFetchingMeta = true;
  },
  fulfilled: (state: IInitialState, action: PayloadAction<TGetMainDashboardData>) => {
    if (action.payload) {
      if (state.isRetries) {
        state.retries = 1;
        state.isRetries = false;
      }
      const { data, meta } = action.payload;
      const { start_date, end_date, dashboard_list } = meta;

      const startDate = new Date(getDateFromString(start_date));
      const endDate = new Date(getDateFromString(end_date));

      state.data = data;
      state.financesStartDate = startDate;
      state.financesEndDate = endDate;
      state.mastersStartDate = startDate;
      state.mastersEndDate = endDate;
      state.adminsStartDate = startDate;
      state.adminsEndDate = endDate;
      state.analyticsStartDate = startDate;
      state.analyticsEndDate = endDate;
      if (dashboard_list) {
        state.selectedAnalyticsDashboard = getDashboardList(dashboard_list);
      }
      state.isFetchingMetaSuccess = true;
    }
    state.isLoading = false;
    state.isFetchingMeta = false;
  },
  rejected: (state: IInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
          state.isRefresh = false;
          state.isFetchingMeta = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
        state.isRefresh = false;
        state.isFetchingMeta = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
      state.isRefresh = false;
      state.isFetchingMeta = false;
    }
  },
};

export const saveSelectedDashboardDataReducers = {
  pending: (state: IInitialState) => {
    state.isError = false;
    state.isLoading = false;
  },
  fulfilled: (state: IInitialState, action: PayloadAction<TSaveDashboardResponse | void>) => {
    if (!action.payload) return;
    const { startDate: start_date, endDate: end_date, dashboard_list } = action.payload;

    const startDate = new Date(getDateFromString(start_date));
    const endDate = new Date(getDateFromString(end_date));

    state.analyticsStartDate = startDate;
    state.analyticsEndDate = endDate;
    state.selectedAnalyticsDashboard = getDashboardList(dashboard_list);
  },
  rejected,
};
