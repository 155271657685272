import React from 'react';
//
import { PinInput } from '@components/pinInput';

interface IPinInputWithErrorTextProps {
  /**
   * Значение пина
   * @param {string}
   */
  inputValue: string;
  /**
   * Показывает ошибку №1
   * @param {boolean}
   */
  isShowErrorText: boolean;
  /**
   * Показывает ошибку №2
   * @param {boolean}
   */
  isInputError: boolean;
  /**
   * Заголовок инпута
   * @param {string}
   */
  inputHeaderText: string;
  /**
   * Текст ошибки №1
   * @param {string}
   */
  inputDataErrorText: string;
  /**
   * Текст ошибки №2
   * @param {string}
   */
  inputDataErrorText2: string;
  /**
   * Кнопка расположенная справа от заголовка
   * @param {React.ReactNode}
   */
  rightButtonInHeader: React.ReactNode;
  /**
   * Callback для обработки изменений в инпуте
   * @param {(value: string) => void}
   */
  onChangeHandler: (value: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PinInputWithErrorText = ({
  inputValue,
  isInputError,
  onChangeHandler,
  isShowErrorText,
  inputHeaderText,
  inputDataErrorText,
  inputDataErrorText2,
  rightButtonInHeader,
  className = '',
}: IPinInputWithErrorTextProps) => {
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className=''>
        <div className='flex items-center justify-between mb-2 w-full'>
          <p className='leading-5 text-gray6 text-inner_text tracking-[0.018em] mr-1'>
            {inputHeaderText}
          </p>
          {rightButtonInHeader}
        </div>
        <PinInput pin={inputValue} onChangeCallback={onChangeHandler} className='mb-1' />
      </div>
      {isInputError ? (
        <p className='text-small_text text-auChico mb-[0.375rem] tracking-[0.033em] w-fit'>
          {inputDataErrorText}
        </p>
      ) : null}
      {isShowErrorText ? (
        <p className='text-small_text text-auChico mb-[0.375rem] tracking-[0.033em] w-fit'>
          {inputDataErrorText2}
        </p>
      ) : null}
    </div>
  );
};
