import React, { Ref, PropsWithChildren, forwardRef } from 'react';
import { Transforms, Element as SlateElement } from 'slate';
import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';
import codeIcon from '@img/notes/richTextEditor/code.svg';
import linkIcon from '@img/notes/richTextEditor/link.svg';
import linkOffIcon from '@img/notes/richTextEditor/link_off.svg';
import linkEditIcon from '@img/notes/richTextEditor/link_edit.svg';
import looksOneIcon from '@img/notes/richTextEditor/looks_one.svg';
import looksTwoIcon from '@img/notes/richTextEditor/looks_two.svg';
import checkListIcon from '@img/notes/richTextEditor/checklist.svg';
import formatBoldIcon from '@img/notes/richTextEditor/format_bold.svg';
import formatQuoteIcon from '@img/notes/richTextEditor/format_quote.svg';
import smartButtonIcon from '@img/notes/richTextEditor/smart_button.svg';
import formatItalicIcon from '@img/notes/richTextEditor/format_italic.svg';
import formatAlignLeftIcon from '@img/notes/richTextEditor/format_align_left.svg';
import formatUnderlinedIcon from '@img/notes/richTextEditor/format_underlined.svg';
import formatAlignRightIcon from '@img/notes/richTextEditor/format_align_right.svg';
import formatAlignCenterIcon from '@img/notes/richTextEditor/format_align_center.svg';
import formatListNumberedIcon from '@img/notes/richTextEditor/format_list_numbered.svg';
import formatListBulletedIcon from '@img/notes/richTextEditor/format_list_bulleted.svg';
import formatAlignJustifyIcon from '@img/notes/richTextEditor/format_align_justify.svg';

interface IBaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

const buttonsIcons: Record<string, string> = {
  code: codeIcon,
  link: linkIcon,
  link_off: linkOffIcon,
  looks_one: looksOneIcon,
  looks_two: looksTwoIcon,
  link_edit: linkEditIcon,
  checklist: checkListIcon,
  format_bold: formatBoldIcon,
  format_quote: formatQuoteIcon,
  smart_button: smartButtonIcon,
  format_italic: formatItalicIcon,
  format_align_left: formatAlignLeftIcon,
  format_underlined: formatUnderlinedIcon,
  format_align_right: formatAlignRightIcon,
  format_align_center: formatAlignCenterIcon,
  format_list_numbered: formatListNumberedIcon,
  format_list_bulleted: formatListBulletedIcon,
  format_align_justify: formatAlignJustifyIcon,
};

export const Button = forwardRef(
  // eslint-disable-next-line
  // @ts-ignore
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & IBaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => (
    <span
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={`cursor-pointer text-${
        reversed ? (active ? 'white' : 'gray3') : active ? 'black' : 'gray3'
      } ${className}`}
    />
  ),
);

export const Icon = forwardRef(
  // eslint-disable-next-line
  // @ts-ignore
  (
    { className, title, active, ...props }: PropsWithChildren<IBaseProps>,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => {
    return (
      <img
        // eslint-disable-next-line
        // @ts-ignore
        ref={ref}
        alt={String(title)}
        src={buttonsIcons[String(props.children)]}
        className={`text-[1.125rem] align-bottom text-inherit mr-2 ${active ? '' : 'opacity-40'} ${
          className || ''
        }`}
      />
    );
  },
);

export const Menu = forwardRef(
  // eslint-disable-next-line
  // @ts-ignore
  ({ className, ...props }: PropsWithChildren<IBaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div
      {...props}
      data-test-id='menu'
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={className}
    />
  ),
);

export const Toolbar = forwardRef(
  // eslint-disable-next-line
  // @ts-ignore
  ({ className, ...props }: PropsWithChildren<IBaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      ref={ref}
      className={`relative border border-gray2 rounded-lg flex items-center px-4 py-2 ${className}`}
    />
  ),
);

// eslint-disable-next-line
// @ts-ignore
export const CheckListItemElement = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const { checked } = element;
  return (
    <div {...attributes} className='flex items-center'>
      <span contentEditable={false} className='mr-3'>
        <input
          type='checkbox'
          checked={checked}
          disabled={readOnly}
          onChange={event => {
            // eslint-disable-next-line
            // @ts-ignore
            const path = ReactEditor.findPath(editor, element);
            // eslint-disable-next-line
            // @ts-ignore
            const newProperties: Partial<SlateElement> = { checked: event.target.checked };
            Transforms.setNodes(editor, newProperties, { at: path });
          }}
          className='cursor-pointer'
        />
      </span>
      <span
        contentEditable={!readOnly}
        suppressContentEditableWarning
        className={`focus:outline-none ${checked ? 'opacity-70 line-through' : ''}`}>
        {children}
      </span>
    </div>
  );
};
