import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { noDataChartPlugin } from '@helpers/index';
import { TDoughnutChartDataset } from '@models/index';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

interface IDoughnutChartProps {
  /**
   * Данные для графика
   * @param {TDoughnutChartDataset}
   */
  data: TDoughnutChartDataset;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | 'center' | undefined;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const DoughnutChart = ({
  data,
  chartLegendPosition,
  className = '',
}: IDoughnutChartProps) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: `${chartLegendPosition || 'bottom'}` as const,
      },
      title: {
        display: false,
        text: '',
      },
    },
  };

  return (
    <div
      className={`chart-container ${className}`}
      style={{ position: 'relative', height: '460px', width: `clamp(200px, 99%, 1270px)` }}>
      <Doughnut options={options} data={data} plugins={[noDataChartPlugin]} />
    </div>
  );
};
