import React, {forwardRef} from 'react';

import Component from'./Chips';
import PropTypes, {InferProps} from 'prop-types';

const propTypes = {
  /**
   `The type of the component.
   * Variants: `dense`
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(["def", "dense"]),
  /**
   ` The view of the component.
   * Variants: `outlined`
   * Default value (if undefined): `smooth` `
   */
  view: PropTypes.oneOf(["smooth", "outlined"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  /**
   * Text
   */
  tag: PropTypes.string,
  /**
   * Whether this tag should have rounded ends.
   */
  round: PropTypes.bool,
  /**
   * Show remove button
   */
  removable: PropTypes.bool,
  /**
   * Click handler for remove button.
   */
  onRemove: PropTypes.func,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render before the text.
   */
  icon: PropTypes.string,
  /**
   * Name of a Blueprint UI icon (or an icon element) to render after the text.
   */
  rightIcon: PropTypes.string,
  /**
   * Render Tick animation
   */
  withTick: PropTypes.bool,
  /**
   * Render image before the text
   */
  withAvatar: PropTypes.string,
  /**
   * Render number before the text
   */
  withNumber: PropTypes.string,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  noClick: PropTypes.bool
};

type TComponentProps = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentProps {
  [key: string]: any;
}

export const Chips: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);