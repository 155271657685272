import React from 'react';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { useNavigate } from 'react-router-dom';

/**
 * Тип для объекта ссылки
 * @typedef {Object} LinkType
 * @property {string} href - Ссылка для навигации.
 * @property {string} title - Текст кнопки.
 */
type LinkType = {
  href: string;
  title: string;
};

/**
 * Типы пропсов для компонента NoAccessWithCustom
 * @typedef {Object} TNoAccessWithCustomProps
 * @property {string} [header] - Заголовок (опционально).
 * @property {string} img - Ссылка на изображение.
 * @property {string} description - Описание страницы/компонента.
 * @property {LinkType} [link] - Объект с данными для кнопки и ссылки (опционально).
 */
type TNoAccessWithCustomProps = {
  header?: string;
  img: string;
  description: string;
  link?: LinkType;
};

/**
 * Компонент отображает страницу с отсутствующим доступом и позволяет перейти по ссылке при наличии.
 *
 * @param {TNoAccessWithCustomProps} props - Свойства компонента.
 * @returns {JSX.Element} React-компонент для отображения страницы с сообщением об отсутствии доступа.
 */
export const NoAccessWithCustom = ({
  img,
  description,
  link,
  header,
}: TNoAccessWithCustomProps): JSX.Element => {
  const navigate = useNavigate();

  /**
   * Обработчик клика по кнопке, использует `react-router` для навигации по переданной ссылке.
   * @returns {void}
   */
  const onClickHandler = (): void => {
    if (link) {
      navigate(link.href);
    }
  };

  return (
    <div className='h-full'>
      {header && <H1 className='mb-6 tracking-[-0.01em]'>{header}</H1>}
      <div className='h-full flex items-center justify-center'>
        <div className='flex flex-col items-center max-w-[390px]'>
          <img src={img} alt={description} className='mb-2' />
          <p className='text-stormGray font-normal text-second_nav_text text-center hyphens-auto mb-6'>
            {description}
          </p>
          {link && (
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              text={link.title}
              onClick={onClickHandler}
              className='transition-all duration-300 ease-out flex'
            />
          )}
        </div>
      </div>
    </div>
  );
};
