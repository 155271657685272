import { Button } from '@uikit/Button';
import { DASHBOARD_TEXT } from '@data/dict';

type TDashboardHowButtonProps = {
  /**
   * Опциональный параметр нажатия на кнопку
   * @param {() => void}
   */
  onClick?: () => void;
  /**
   * Опциональный параметр для добавления класса
   * @param {string}
   */
  className?: string;
};

export const DashboardHowButton = ({ onClick, className }: TDashboardHowButtonProps) => {
  return (
    <Button
      icon='info-sign'
      dense
      view='flat'
      className={`!text-green1 !shadow-none !p-0 ${className || ''}`}
      onClick={onClick}>
      {DASHBOARD_TEXT.HOW_BUTTON}
    </Button>
  );
};
