import React, { memo } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { setTaskType } from '@redux/Task/slice';
import { TASK_TYPE, taskTypes } from '@const/task';
import { DropdownWithIcon } from '@components/dropdownWithIcon';

interface ITaskBlockProps {
  /**
   * Тип выбранной задачи
   * @param {string}
   */
  selectedTaskType: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TaskType = memo(({ selectedTaskType, className = '' }: ITaskBlockProps) => {
  const dispatch = useAppDispatch();

  // устанавливает тип задачи
  const setTaskTypeHandler = (taskType: TASK_TYPE) => () => {
    dispatch(setTaskType({ taskType }));
  };

  return (
    <div className={className}>
      <p className='text-black text-h3Mobile mb-4'>Тип Задачи</p>
      <DropdownWithIcon
        items={taskTypes}
        callBack={type => setTaskTypeHandler(type as TASK_TYPE)}
        selectedItem={selectedTaskType}
        className='w-full max-w-[47rem]'
      />
    </div>
  );
});
