import { REG_EXP } from '@const/common';
import { PayloadAction } from '@reduxjs/toolkit';
import { TBlackListData } from '@models/BlackList';
import { TInitialState } from '@redux/BlackList/zod';
import { validatePhoneNumber } from '@helpers/index';
import { HTTP_STATUS_CODE } from '@const/apiConstants';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { BLACK_LIST_TOAST_MESSAGES } from '@const/blackList';

export const reducers = {
  setNewNumber(state: TInitialState, action: PayloadAction<{ value: string }>) {
    const newNumber = action.payload.value.replace(REG_EXP.onlyNumbers, '');

    if (validatePhoneNumber(newNumber).length < 14) {
      state.newNumber = newNumber;
    }
  },
  setNumberToDelete(state: TInitialState, action: PayloadAction<{ number: string }>) {
    state.numberToDelete = action.payload.number;
  },
  setSearchString(state: TInitialState, action: PayloadAction<{ value: string }>) {
    const searchString = action.payload.value;
    state.searchString = searchString.replace(REG_EXP.onlyNumbers, '');
    if (!searchString) {
      state.filteredState = state.data;
    } else {
      state.filteredState = state.data.filter(item => item.number.includes(state.searchString));
    }
  },
  setFilteredList(state: TInitialState) {
    state.filteredState = state.data.filter(item => item.number.includes(state.searchString));
  },
  setFullBlackList(state: TInitialState) {
    state.filteredState = state.data;
  },
  hideSuccessMessages(state: TInitialState) {
    state.isAddSuccess = false;
    state.isDeleteSuccess = false;
  },
};

export const getBlackListReducer = {
  pending: (state: TInitialState) => {
    if (!state.isRefresh) {
      state.isLoading = true;
    }
    state.isAddSuccess = false;
    state.isAccessDenied = false;
    state.isAddingNumber = false;
    state.isDeleteSuccess = false;
    state.isDeleteNumberError = false;
    state.isAddingNumberError = false;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TBlackListData[] | void>) => {
    const data = action.payload;
    if (state.isRefresh) {
      state.isRefresh = false;
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
    state.data = data || [];
    state.filteredState = data || [];
  },
  rejected: (state: TInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;
      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        // state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.isRefresh = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
      state.isRefresh = false;
    }
  },
};

export const addNumberToBlacklistReducer = {
  pending: (state: TInitialState) => {
    state.isLoading = false;
    state.isAddSuccess = false;
    state.isAddingNumber = true;
    state.isDeleteSuccess = false;
    state.isDeleteNumberError = false;
    state.isAddingNumberError = false;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<number | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      state.newNumber = '';
      state.isRefresh = true;
      state.isAddSuccess = true;
      state.isAddingNumber = false;

      if (status === 204) {
        state.successToastMessage = BLACK_LIST_TOAST_MESSAGES.ADD_SUCCESS;
      } else {
        state.successToastMessage = BLACK_LIST_TOAST_MESSAGES.NUMBER_ALREADY_IN_LIST;
      }
    }
  },
  rejected: (state: TInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isAddingNumber = false;
      } else {
        state.errorToastMessage = BLACK_LIST_TOAST_MESSAGES.ADD_ERROR;
      }
    }
    state.isAddingNumber = false;
  },
};

export const deleteNumberFromBlacklistReducer = {
  pending: (state: TInitialState) => {
    state.isLoading = false;
    state.isAddSuccess = false;
    state.isAddingNumber = false;
    state.isDeleteSuccess = false;
    state.isDeleteNumberError = false;
    state.isAddingNumberError = false;
  },
  fulfilled: (state: TInitialState) => {
    state.numberToDelete = '';
    state.isDeleteSuccess = true;
    state.isRefresh = true;
    state.successToastMessage = BLACK_LIST_TOAST_MESSAGES.DELETE_SUCCESS;
  },
  rejected: (state: TInitialState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      const status = action.payload;

      if (status === HTTP_STATUS_CODE.ACCESS_DENIED) {
        state.isAccessDenied = true;
      } else if (status === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        state.isAddingNumber = false;
      } else {
        state.isDeleteNumberError = true;
        state.errorToastMessage = BLACK_LIST_TOAST_MESSAGES.DELETE_ERROR;
      }
    }
  },
};
