import React, { memo, useMemo } from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { selectTextContentOnFocus } from '@helpers/index';

import { WhenTime } from '../whenTime';

interface IClientEventBlockProps {
  /**
   * опциональный параметр показывает или скрывает блок с временем постановки задачи
   * @param {boolean}
   * @default
   */
  isNoTime?: boolean;
  /**
   * Значение блока время постановки задачи
   * @param {string}
   */
  sendingTime: string;
  /**
   * Callback для изменения времени постановки задачи
   * @param {(value: string) => void}
   */
  setWhenTime: (value: string) => void;
  /**
   * Значение input
   * @param {string}
   */
  inputValue: string;
  /**
   * Callback для изменения значения input
   * @param {TOnChangeHandler}
   */
  changeInputValueCallback: TOnChangeHandler;
  /**
   * Текст перед input
   * @param {string}
   */
  textBeforeInput: string;
  /**
   * Опциональный параметр текст после input
   * @param {string}
   * @default
   */
  textAfterInput?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ClientEventBlock = memo(
  ({
    isNoTime = false,
    inputValue,
    sendingTime,
    setWhenTime,
    textBeforeInput,
    textAfterInput = '',
    changeInputValueCallback,
    className = '',
  }: IClientEventBlockProps) => {
    const memoizedTextInput = useMemo(
      () => (
        <TextInput
          min={1}
          color='default'
          view='outlined'
          value={inputValue}
          name='noVisitsDays'
          onClick={selectTextContentOnFocus}
          onChange={changeInputValueCallback}
          className='mx-2 h-10 !w-12'
        />
      ),
      [inputValue, changeInputValueCallback],
    );

    return (
      <div className={className}>
        {!isNoTime ? (
          <div className='flex items-center mb-4'>
            <span className='tracking-[0.0275em] mr-2'>Время постановки</span>
            <WhenTime whenTime={sendingTime} onChangeHandler={setWhenTime} />
          </div>
        ) : null}
        <div className='flex items-center'>
          <p className='mb-0'>{textBeforeInput}</p>
          {memoizedTextInput}
          {textAfterInput ? <p className='mb-0'>{textAfterInput}</p> : null}
        </div>
      </div>
    );
  },
);
