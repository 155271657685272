import React, { memo, useMemo } from 'react';
//
import { LABEL_TEXT } from '@data/dict';
import { THelpKeys, IWithOptionalChildren } from '@models/index';

interface ILabelProps extends IWithOptionalChildren {
  /**
   * Параметр htmlFor для label
   * @param {string}
   */
  htmlFor: string;
  /**
   * Опциональный параметр, если передан ключ, из словаря берется текст и подставляется в label
   * можно передать и ключ и children
   * @param {THelpKeys}
   */
  variant?: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const Label = memo(({ htmlFor, variant, className = '', children }: ILabelProps) => {
  // из словаря выбирается вариант текста для метки, если, опциональный параметр вариант не передан, устанавливается пустая строка
  const labelInnerText = useMemo(() => (variant ? LABEL_TEXT[variant] : ''), [variant]);

  const memoizedChildren = useMemo(() => [children], [children]);

  return (
    <label
      htmlFor={htmlFor}
      className={`flex items-center font-inter font-medium text-blackText cursor-pointer tracking-[0.0275em] w-fit ${className}`}>
      {labelInnerText ? <p className={children ? 'mr-[0.3125rem]' : ''}>{labelInnerText}</p> : null}
      {memoizedChildren}
    </label>
  );
});
