import React, { memo } from 'react';
//
import { THelpKeys } from '@models/index';
import { ReportSendTime } from '@blocks/reportSendTime';
import { IconWithTooltips } from '@components/iconWithTooltips';
import {
  dayStatisticsSend,
  REPORT_SEND_PERIOD,
  everyWeekSendPeriod,
  everyMonthSendPeriod,
} from '@const/report';

import { DropdownWithIcon } from '../dropdownWithIcon';

interface IWhenSendReportProps {
  /**
   * Вариант тултипа для инпута
   * @type {THelpKeys}
   */
  tooltipVariant?: THelpKeys;
  /**
   * Время отправки отчета в формате HH:MM
   * @param {string}
   */
  sendingTime: string;
  /**
   * Дата отправки отчета в формате DD.MM.YYYY
   * @param {string}
   */
  sendingDate: string;
  /**
   * Выбранный период отправки отчета
   * @param {string}
   */
  selectedReportSendPeriod: string;
  /**
   * Выбранный период подсчета данных
   * @param {string}
   */
  selectedDataCalculatingPeriod: string;
  /**
   * Когда отправлять недельный отчет
   * @param {string}
   */
  selectedEveryWeekSendingPeriod: string;
  /**
   * Когда отправлять отчет за месяц
   * @param {string}
   */
  selectedEveryMonthSendingPeriod: string;
  /**
   * Обработчик устанавливает выбранное время
   * @param {(time: string) => void}
   */
  setSendingTime: (time: string) => void;
  /**
   * Обработчик устанавливает выбранную дату
   * @param {(dateString: string) => void}
   */
  setSendingDate: (dateString: string) => void;
  /**
   * Обработчик устанавливает период отправки отчета
   * @param {(sendPeriod: string) => () => void}
   */
  setSendPeriod: (sendPeriod: string) => () => void;
  /**
   * Обработчик устанавливает когда отправлять недельный отчет
   * @param {(sendPeriod: string) => () => void}
   */
  setEveryWeekSendingPeriodHandler: (sendPeriod: string) => () => void;
  /**
   * Обработчик устанавливает когда отправлять отчет за месяц
   * @param {(sendPeriod: string) => () => void}
   */
  setEveryMonthSendingPeriodHandler: (sendPeriod: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const WhenSendReport = memo(
  ({
    sendingTime,
    // eslint-disable-next-line
    sendingDate,
    setSendPeriod,
    // eslint-disable-next-line
    setSendingDate,
    setSendingTime,
    selectedReportSendPeriod,
    selectedDataCalculatingPeriod,
    // eslint-disable-next-line
    selectedEveryWeekSendingPeriod, // eslint-disable-next-line
    selectedEveryMonthSendingPeriod, // eslint-disable-next-line
    setEveryWeekSendingPeriodHandler, // eslint-disable-next-line
    setEveryMonthSendingPeriodHandler,
    className = '',
    tooltipVariant,
  }: IWhenSendReportProps) => {
    const dict =
      selectedDataCalculatingPeriod === REPORT_SEND_PERIOD.EVERY_DAY
        ? dayStatisticsSend
        : selectedDataCalculatingPeriod === REPORT_SEND_PERIOD.EVERY_WEEK
        ? everyWeekSendPeriod
        : selectedDataCalculatingPeriod === REPORT_SEND_PERIOD.EVERY_MONTH
        ? everyMonthSendPeriod
        : dayStatisticsSend;

    return (
      <div className={className}>
        <div className='flex'>
          <p className='font-medium text-h3_body text-black mb-4 mr-2'>Когда отправлять отчет?</p>
          {tooltipVariant ? (
            <IconWithTooltips tooltips={tooltipVariant} className='flex items-center' />
          ) : null}
        </div>
        <div className='flex flex-wrap items-center mb-4 mr-2'>
          <div className='w-full max-w-[15rem]'>
            <DropdownWithIcon
              items={dict}
              callBack={setSendPeriod}
              selectedItem={selectedReportSendPeriod}
              className='w-full max-w-[15rem] when_send_dropdown'
            />
          </div>
          {/* {selectedReportSendPeriod === REPORT_SEND_PERIOD.EVERY_WEEK ? (
            <div className='w-full max-w-[15rem]'>
              <DropdownWithIcon
                items={everyWeekSendPeriod}
                callBack={setEveryWeekSendingPeriodHandler}
                selectedItem={selectedEveryWeekSendingPeriod}
                className='max-w-[15rem] when_send_dropdown'
              />
            </div>
          ) : null}
          {selectedReportSendPeriod === REPORT_SEND_PERIOD.EVERY_MONTH ? (
            <div className='w-full max-w-[15rem]'>
              <DropdownWithIcon
                items={everyMonthSendPeriod}
                callBack={setEveryMonthSendingPeriodHandler}
                selectedItem={selectedEveryMonthSendingPeriod}
                className='max-w-[15rem] when_send_dropdown'
              />
            </div>
          ) : null} */}
          {/* {selectedReportSendPeriod === REPORT_SEND_PERIOD.EVERY_QUARTER ? (
            <QuarterDatePicker dateString={sendingDate} setSendingDate={setSendingDate} />
          ) : null} */}
          {selectedReportSendPeriod ? (
            <ReportSendTime
              isSetOnlyHour
              sendingTime={sendingTime}
              setSendingTime={setSendingTime}
              className='ml-[0.8rem] h-10'
            />
          ) : null}
        </div>
      </div>
    );
  },
);
