import React, { memo } from 'react';
//
import { Button } from '@uikit/Button';

interface IBBAdsToastProps {
  /**
   * Ссылка на страницу которая будет открыта при нажатии на кнопку
   * @param {string}
   */
  href: string;
  /**
   * Опциональный параметр значение target для открытия страницы
   * @param {'_blank' | '_self'}
   * @default
   */
  target?: '_blank' | '_self';
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const BBAdsToast = memo(({ href, target = '_blank', className = '' }: IBBAdsToastProps) => {
  const changePageOnClick = () => {
    const newWindow = window.open(href, target, 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div
      className={`bg-varden flex flex-wrap items-center justify-between rounded-lg px-4 pt-3 ${className}`}>
      <p className='font-medium text-goldenBrown mb-0 mb-3'>
        Данный функционал доступен только пользователям Бьюти Бота
      </p>
      <Button
        dense
        type='action'
        view='filled'
        color='success'
        text='Подключить'
        className='mb-3 !min-w-[8.875rem] transition-all duration-300 ease-out'
        onClick={changePageOnClick}
      />
    </div>
  );
});
