import { TInitialState } from '@redux/Auth/zod';

export const initialState: TInitialState = {
  auth: false,
  userId: 0,
  accessToken: '',
  isTimerSet: false,
  newAccessToken: null,
  isError: false,
  isLoading: false,
  telegramId: '',
  pin: '',
  isFetching: false,
  isChekPinError: false,
  isGetPinError: false,
  isGetPinSuccess: false,
  isInputError: false,
  isUpdTokenPending: false,
  isUpdTokenSuccess: false,
  isUpdTokenError: false,
  authUserData: {
    id: 0,
    crmUserId: 0,
    firstName: 'Александр',
    lastName: 'Григорьев',
    username: '',
    photoUrl: 'https://t.me/i/userpic/320/qvZ6YsJBVe_5r_q67i3RRkY7RUgfSth7wZ9LWvoppQg.jpg',
  },
};

// stringifyUserData: { "id": 0, "firstName": "Александр", "lastName": "Григорьев", "username": "", "photoUrl": "https://t.me/i/userpic/320/qvZ6YsJBVe_5r_q67i3RRkY7RUgfSth7wZ9LWvoppQg.jpg" }
