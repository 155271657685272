import React, { useCallback, useMemo } from 'react';
//
import { H1 } from '@atoms/h1';
import { TDashboardData } from '@api/dashboard/types';
import { ChartWrapper } from '@components/chartWrapper';
import { DashboardDrawer } from '@blocks/dashboardDrawer';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getDateString, getRotationAnge } from '@helpers/index';
import { DashboardPathLinks } from '@blocks/dashboardPathLinks';
import { DashboardInfo, emptyDashboardInfoItem } from '@data/dict';
import { getAppData, getDashboardPageData } from '@redux/selectors';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { DashboardSelectBlock } from '@components/dashboardSelectBlock';
import { TDashboardDrawerDataItem, TSimpleStringObj } from '@models/index';
import { BarCharts, DoughnutChart } from '@blocks/dashboardFinancesCharts';
import { setAnalyticsType, setShowAnalyticsDrawer } from '@redux/App/slice';
import {
  setFinancesDates,
  setSelectedFinancesCategories,
  setSelectedAllAmountFilterName,
  setSelectedEarnedByOutCallsFilterName,
} from '@redux/Dashboard/slice';
import {
  DASHBOARD_KEYS,
  FINANCES_CHARTS,
  FINANCES_CHARTS_NAMES,
  FINANCES_CHART_TITLES,
  DASHBOARD_FINANCES_KEYS,
  financesAllAmountFilterName,
  financesEarnedByOutCallsFilterName,
} from '@const/dashboard';

interface IFinancesProps {
  /**
   * Часть пути для перехода на страницу с табличным представлением данных
   * @param {string}
   */
  pathName: string;
  /**
   * Данные для графиков
   * @param {TDashboardData}
   */
  data: TDashboardData;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Массив выбранных категорий/графиков
   * @param {string[]}
   */
  selectedCategories: string[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const FinancesTemplate = ({
  data,
  pathName,
  selectedCategories,
  selectedFilialAccId,
  className = '',
}: IFinancesProps) => {
  const dispatch = useAppDispatch();
  const {
    financesEndDate,
    financesStartDate,
    selectedFinancesDashboard,
    allAmountSelectedFilterName,
    earnedByOutCallsSelectedFilterName,
  } = useAppSelector(getDashboardPageData);
  const { showAnalyticsDrawer } = useAppSelector(getAppData);

  const doughnutChartLabel = `${getDateString(financesStartDate)}-${getDateString(
    financesEndDate,
  )}`;

  const setSelectedCategoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(setSelectedFinancesCategories({ name: name as DASHBOARD_FINANCES_KEYS, checked }));
  };

  const setSelectedAllAmountFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedAllAmountFilterName(filterName));
    },
    [dispatch],
  );

  const setSelectedEarnedByOutCallsFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedEarnedByOutCallsFilterName(filterName));
    },
    [dispatch],
  );

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setFinancesDates({ startDate, endDate }));
  };

  const changeDisplayDrawer = useCallback(
    (name?: DASHBOARD_KEYS) => {
      let currentDashboardDrawerData: TDashboardDrawerDataItem = emptyDashboardInfoItem;
      if (name) {
        currentDashboardDrawerData = DashboardInfo[name];

        dispatch(setAnalyticsType(currentDashboardDrawerData));
      }

      dispatch(setShowAnalyticsDrawer(!showAnalyticsDrawer));
    },
    [showAnalyticsDrawer, dispatch],
  );

  const getChartFilterNames: Record<
    FINANCES_CHARTS_NAMES,
    TSimpleStringObj | Record<string, never>
  > = useMemo(
    () => ({
      [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT]: financesAllAmountFilterName,
      [DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE]: {},
      [DASHBOARD_FINANCES_KEYS.OP_RECORDS]: {},
      [DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS]: financesEarnedByOutCallsFilterName,
    }),
    [],
  );

  const getSelectedFilterName: Record<FINANCES_CHARTS_NAMES, string> = useMemo(
    () => ({
      [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT]: allAmountSelectedFilterName,
      [DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE]: '',
      [DASHBOARD_FINANCES_KEYS.OP_RECORDS]: '',
      [DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS]: earnedByOutCallsSelectedFilterName,
    }),
    [allAmountSelectedFilterName, earnedByOutCallsSelectedFilterName],
  );

  const getCallback: Record<
    FINANCES_CHARTS_NAMES,
    ((filterName: string) => void) | (() => string)
  > = useMemo(
    () => ({
      [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT]: setSelectedAllAmountFilterNameHandler,
      [DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE]: () => '',
      [DASHBOARD_FINANCES_KEYS.OP_RECORDS]: () => '',
      [DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS]: setSelectedEarnedByOutCallsFilterNameHandler,
    }),
    [setSelectedAllAmountFilterNameHandler, setSelectedEarnedByOutCallsFilterNameHandler],
  );

  const getChartContentType = useMemo(
    () => ({
      [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT]:
        allAmountSelectedFilterName === DASHBOARD_KEYS.MASTERS
          ? DASHBOARD_KEYS.ALL_AMOUNT_MASTERS
          : DASHBOARD_KEYS.AMOUNT_SERVICE_RATE,
      [DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE]: DASHBOARD_KEYS.AMOUNT_SERVICE_RATE,
      [DASHBOARD_FINANCES_KEYS.OP_RECORDS]: DASHBOARD_KEYS.OP_RECORDS,
      [DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS]:
        earnedByOutCallsSelectedFilterName === DASHBOARD_KEYS.MASTERS
          ? DASHBOARD_KEYS.FILTERS_MASTERS
          : DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY,
    }),
    [earnedByOutCallsSelectedFilterName, allAmountSelectedFilterName],
  );

  const renderCharts = useMemo(() => {
    return selectedFinancesDashboard.map(chartName => {
      return (
        <ChartWrapper
          key={chartName}
          isChartSetupModule={
            chartName === DASHBOARD_FINANCES_KEYS.ALL_AMOUNT ||
            chartName === DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS
          }
          pathName={pathName}
          isChartTypeSwitcherModule={
            chartName === DASHBOARD_FINANCES_KEYS.ALL_AMOUNT ||
            chartName === DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS
          }
          selectedFilialAccId={selectedFilialAccId}
          chartName={FINANCES_CHART_TITLES[chartName]}
          chartDataKeyName={chartName}
          chartFilterNames={getChartFilterNames[chartName]}
          selectedFilterName={getSelectedFilterName[chartName]}
          callback={getCallback[chartName]}
          barChart={
            <BarCharts
              data={data}
              chartName={chartName}
              keyName={getSelectedFilterName[chartName]}
            />
          }
          doughnutChart={
            <DoughnutChart
              data={data}
              chartName={chartName}
              keyName={getSelectedFilterName[chartName]}
              getRotationAngeCallback={getRotationAnge}
              chartTitle={doughnutChartLabel}
            />
          }
          chartContentType={getChartContentType[chartName]}
          onHowButtonClick={changeDisplayDrawer}
        />
      );
    });
  }, [
    data,
    pathName,
    getCallback,
    doughnutChartLabel,
    selectedFilialAccId,
    changeDisplayDrawer,
    getChartContentType,
    getChartFilterNames,
    getSelectedFilterName,
    selectedFinancesDashboard,
  ]);

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardDrawer changeDisplayDrawer={changeDisplayDrawer} />
      <DashboardPathLinks
        className='mb-10'
        thirdPageName='Салон'
        selectedFilialAccId={selectedFilialAccId}
      />
      <H1 className='mb-10 tracking-[-0.01em]'>Салон</H1>
      <div className='flex flex-wrap items-center justify-between mb-6'>
        <DateRangeDashboardPicker
          startDate={financesStartDate}
          endDate={financesEndDate}
          setSendingDate={setDateRangeHandler}
          className='mb-4 w-full sm:max-w-[20rem]'
        />
        <DashboardSelectBlock
          categories={FINANCES_CHARTS}
          selectedCategories={selectedCategories}
          onChangeHandler={setSelectedCategoryHandler}
          className='mb-4 sm:max-w-[12.25rem] w-full'
        />
      </div>
      {renderCharts}
    </div>
  );
};
