import { TDashboardNames } from '@api/types';
import { PATH_NAMES } from '@const/httpConst';
import {
  TSimpleStringObj,
  TDashboardAdminsData,
  TDashboardMastersData,
  TDashboardFinancesData,
  TSelectedDashboardList,
  TDashboardAnalyticsData,
} from '@models/index';

export const ONE_WEEK_IN_MS = 604400000;

export enum CHART_TYPE {
  DOUGHNUT = 'doughnut',
  BAR = 'bar',
}

export const FINANCES_CHARTS: TSimpleStringObj = {
  all_amount: 'Сумма дохода',
  amount_service_rate: 'Доход по услугам',
  op_records: 'Оперативная запись, %',
  earned_by_out_calls: 'Сумма записей после исходящих',
};

export const FINANCES_CHART_TITLES: TSimpleStringObj = {
  all_amount: 'Сумма дохода, руб.',
  amount_service_rate: 'Доход по услугам, руб.',
  op_records: 'Оперативная запись, %',
  earned_by_out_calls: 'Сумма записей после исходящих, руб.',
};

export const ANALYTICS_PAGE_CHART_TITLES = {
  'all_amount.masters': 'Сумма дохода (по мастерам), руб.',
  service_category: 'Сумма дохода (по категориям услуг), руб.',
  amount_service_rate: 'Доход по услугам, руб.',
  op_records: 'Оперативная запись, %',
  earned_by_out_calls: 'Сумма записей после исходящих, руб.',
  'filters.service_category': 'Сумма записей после исходящих (по категориям услуг), руб.',
  'filters.masters': 'Сумма записей после исходящих (по мастерам), руб.',
  'filters.admins': 'Сумма записей после исходящих (по админам), руб.',
  masters: 'Возвращаемость по мастерам, %',
  return_new_client_rate: 'Возвращаемость (по новым клиентам), %',
  general_client_rate: 'Возвращаемость (по общей возвращаемости в салон), % ',
  churn_by_master: 'Отток клиентов у каждого мастера',
  created: 'Задачи (созданные задачи)',
  'filters.tasks_completed': 'Задачи (выполненные задачи)',
  'filters.completion_time': 'Задачи (время выполнения)',
  'filters.tasks_missed': 'Задачи (просроченные задачи)',
  all_out_calls: 'Исходящие звонки (доля записанных), кол-во',
  'filters.missed_calls': 'Входящие звонки (пропущенные), кол-во',
  'filters.new_clients': 'Входящие звонки (новые клиенты), кол-во',
  'filters.all_records': 'Входящие звонки (Количество созданных записей), кол-во',
};

export enum FINANCES_CHARTS_NAMES {
  ALL_AMOUNT = 'all_amount',
  AMOUNT_SERVICE_RATE = 'amount_service_rate',
  OP_RECORDS = 'op_records',
  EARNED_BY_OUT_CALS = 'earned_by_out_calls',
}

export const financesAllAmountFilterName: TSimpleStringObj = {
  masters: 'По мастерам',
  service_category: 'По категории услуг',
};

export enum ALL_AMOUNT_FILTER_NAMES {
  MASTERS = 'masters',
  SERVICE_CATEGORY = 'service_category',
}

export const financesEarnedByOutCallsFilterName: TSimpleStringObj = {
  masters: 'По мастерам',
  service_category: 'По категории услуг',
  // admins: 'По админам',
};

export enum EARNED_BY_OUT_CALLS_FILTER_NAMES {
  MASTERS = 'masters',
  SERVICE_CATEGORY = 'service_category',
  ADMINS = 'admins',
}

export const ADMINS_CHARTS: TSimpleStringObj = {
  all_out_calls: 'Исходящие звонки, кол-во',
  all_in_calls: 'Входящие звонки, кол-во',
  wa_average_time: 'Среднее время ответа на сообщения в Whatsapp, сек',
};

export enum ADMINS_CHARTS_NAMES {
  ALL_OUT_CALLS = 'all_out_calls',
  ALL_IN_CALLS = 'all_in_calls',
  WA_AVERAGE_TIME = 'wa_average_time',
}

export const adminsAllOutCallsFilterNames: TSimpleStringObj = {
  recorded: 'Доля записанных',
};

export enum ALL_OUT_CALLS_FILTER_NAMES {
  RECORDED = 'recorded',
}

export const adminsAllInCallsFilterNames: TSimpleStringObj = {
  'filters.missed_calls': 'Пропущенные',
  'filters.new_clients': 'Новые клиенты',
  'filters.all_records': 'Количество созданных записей',
};

export enum ALL_IN_CALLS_FILTER_NAMES {
  FILTERS_MISSED_CALLS = 'filters.missed_calls',
  FILTERS_NEW_CLIENTS = 'filters.new_clients',
  FILTERS_ALL_RECORDS = 'filters.all_records',
}

export const MASTERS_CHARTS: TSimpleStringObj = {
  return_master_rate: 'Возвращаемость',
  tasks: 'Задачи',
};

export const MASTERS_CHART_TITLES: TSimpleStringObj = {
  return_master_rate: 'Возвращаемость, %',
  churn_by_master: 'Отток, %',
  tasks: 'Задачи',
};

export enum MASTERS_CHART_NAMES {
  RETURN_MASTER_RATE = 'return_master_rate',
  CHURN_BY_MASTER = 'churn_by_master',
  TASKS = 'tasks',
}

export const mastersReturnMasterRateFilterNames: TSimpleStringObj = {
  masters: 'По мастерам',
  return_new_client_rate: 'По новым клиентам',
  general_client_rate: 'По общей возвращаемости в салон',
  churn_by_master: 'По оттоку клиентов у каждого мастера',
};

export enum RETURN_MASTER_RATE_FILTERS_NAME {
  RETURN_CLIENT_TO_MASTER_RATE = 'masters',
  RETURN_NEW_CLIENT_RATE = 'return_new_client_rate',
  GENERAL_CLIENT_RATE = 'general_client_rate',
  CHURN_BY_MASTER = 'churn_by_master',
}

export const mastersTasksFilterName: TSimpleStringObj = {
  created: 'Созданные задачи',
  'filters.tasks_completed': 'Выполненные задачи',
  // 'filters.completion_time': 'Время выполнения',
  'filters.tasks_missed': 'Просроченные задачи',
};

export enum MASTERS_TASKS_FILTER_NAME {
  CREATED = 'created',
  COMPLETED = 'filters.tasks_completed',
  COMPLETION_TIME = 'filters.completion_time',
  TASK_MISSED = 'filters.tasks_missed',
}

export const PATH_TO_CHART_NAME: { [key: string]: TDashboardNames } = {
  [PATH_NAMES.FINANCES]: 'finance',
  [PATH_NAMES.ADMINS]: 'admins',
  [PATH_NAMES.MASTERS]: 'masters',
  [PATH_NAMES.DASHBOARD]: 'main',
};

export const ANALYTICS_CHARTS_CATEGORIES: TSimpleStringObj = {
  finances: 'ФИНАНСЫ',
  service_category: 'Сумма дохода (по категориям услуг)',
  'all_amount.masters': 'Сумма дохода (по мастерам)',
  amount_service_rate: 'Доход по услугам',
  op_records: 'Оперативная запись',
  'filters.service_category': 'Сумма записей после исходящих (по категориям услуг)',
  'filters.masters': 'Сумма записей после исходящих (по мастерам)',
  // 'filters.admins': 'Сумма записей после исходящих (по админам)',
  divider: '',
  mastersLabel: 'МАСТЕРА',
  masters: 'Возвращаемость по мастерам',
  return_new_client_rate: 'Возвращаемость (по новым клиентам)',
  general_client_rate: 'Возвращаемость (по общей возвращаемости в салон)',
  churn_by_master: 'Отток клиентов у каждого мастера',
  // '': 'Отток',
  created: 'Задачи (созданные задачи)',
  'filters.tasks_completed': 'Задачи (выполненные задачи)',
  // 'filters.completion_time': 'Задачи (время выполнения)',
  'filters.tasks_missed': 'Задачи (просроченные задачи)',
  divider1: '',
  admins: 'АДМИНЫ',
  all_out_calls: 'Исходящие звонки (доля записанных)',
  'filters.missed_calls': 'Входящие звонки (пропущенные)',
  'filters.new_clients': 'Входящие звонки (новые клиенты)',
  'filters.all_records': 'Входящие звонки (Количество созданных записей)',
  wa_average_time: 'Среднее время ответа',
};

export const ANALYTICS_CHARTS_LIST: TSelectedDashboardList = {
  service_category: false,
  'all_amount.masters': false,
  amount_service_rate: false,
  op_records: false,
  masters: false,
  return_new_client_rate: false,
  general_client_rate: false,
  churn_by_master: false,
  created: false,
  'filters.masters': false,
  'filters.service_category': false,
  'filters.tasks_completed': false,
  // 'filters.completion_time': false,
  'filters.tasks_missed': false,
  all_out_calls: false,
  'filters.missed_calls': false,
  'filters.new_clients': false,
  'filters.all_records': false,
  wa_average_time: false,
};

export const TURN_ON_ALL_ANALYTICS_CHARTS: TSelectedDashboardList = {
  service_category: true,
  'all_amount.masters': true,
  amount_service_rate: true,
  op_records: true,
  masters: true,
  return_new_client_rate: true,
  general_client_rate: true,
  churn_by_master: true,
  created: true,
  'filters.masters': true,
  'filters.service_category': true,
  'filters.tasks_completed': true,
  // 'filters.completion_time': true,
  'filters.tasks_missed': true,
  all_out_calls: true,
  'filters.missed_calls': true,
  'filters.new_clients': true,
  'filters.all_records': true,
  wa_average_time: true,
};

export const TELEPHONY_CALL_TYPE_FILTER: TSimpleStringObj = {
  all: 'Все звонки',
  incoming: 'Входящие звонки',
  outgoing: 'Исходящие звонки',
};

export enum TELEPHONY_CALL_TYPE_FILTER_NAME {
  ALL = 'all',
  INBOX = 'inbox',
  OUTGOING = 'outgoing',
  NEW_INBOX = 'new_inbox',
  NEW_OUTGOING = 'new_outgoing',
}

export const TELEPHONY_CLIENT_TYPE: TSimpleStringObj = {
  all: 'Все клиенты',
  new_inbox: 'Новые клиенты',
  new_outgoing: 'Старые клиенты',
};

export const chartColors: Record<string, string> = {
  'green-30': '#87C289',
  'orange-50': '#FB982E',
  'indigo-40': '#97A0D6',
  'pink-60': '#EC407A',
  'blue-40': '#76A9FF',
  'yellow-40': '#EBBF67',
  'teal-40': '#60BCBC',
  'braon-drk': '#9D3C4D',
  'green-50': '#65B168',
  'orange-60': '#FB8200',
  'indigo-60': '#5C6BC0',
  'pink-50': '#EF6292',
  'blue-60': '#2979FF',
  'yellow-60': '#D6981B',
  'teal-60': '#069697',
  'red-40': '#F8877F',
  'green-70': '#37833B',
  'orange-40': '#FCAF5C',
  'indigo-70': '#4C589E',
  'pink-40': '#F285AA',
  'blue-50': '#4F91FF',
  'yellow-50': '#E5AE40',
  'teal-70': '#057B7C',
  'red-50': '#F6655A',
  'green-60': '#43A047',
  'teal-sea': '#009688',
  'braon-grey': '#795548',
  'pink-new': '#FF6384',
  'wet-sky': '#3F729B',
  'light-blue': '#9DC2FF',
  'pink-deep': '#DA4771',
  'blue-mint': '#8DCFCF',
  'gray-asphalt': '#607D8B',
  'yellow-vivid': '#FFD700',
  'blue-flo': '#76A9FF',
  'burgund-dark': '#581845',
  violet: '#976BFC',
  'green-grass': '#8BC34A',
  'bright-pink': '#FF5252',
  'yellow-bright': '#FFCC5D',
};

export const emptyDataset = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
  ],
};

/**
 * Plugins
 * Plugin рисует вертикальную линию при наведении на точку/point графика
 * Работает только на тех точках значение которых равно 0,0001
 * для правильной работы необходимо включить в опциях графика
 * interaction: {
 *  mode: 'point' as const,
 *  // intersect: false,
 *  },
 *  это позволит выводить tooltip только при наведении на одну точку в tooltip вводятся данные только выбранной точки
 */
export const hoverLine = {
  id: 'hoverLine',
  // eslint-disable-next-line
  afterDraw: (chart: any) => {
    // в этом условии проверяется наличие точки и что ее значение равно 0.00001,
    // если убрать это условие, вертикальные линии будут рисоваться от любой точки/point
    if (chart.tooltip?._active?.length) {
      const { x } = chart.tooltip._active[0].element;
      const yAxis = chart.scales.y;
      const { ctx } = chart;

      // Draw vertical line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      // цвет линии берется из настроек цвета label
      ctx.strokeStyle = chart?.tooltip?.labelColors[0]?.backgroundColor;
      ctx.stroke();
      ctx.restore();
    }
  },
};

/**
 * Callback для строк в tooltip, изменяет строку информации о точке, выводит только название, скрывает значение
 * @param tooltipItem
 */
// eslint-disable-next-line
export const tooltipFilter = (tooltipItem: any) => {
  const label = tooltipItem.dataset.label || '';

  if (tooltipItem.raw > 0 && label === 'Изменение N') {
    return ` ${label}: ${Math.round(tooltipItem.raw * 100000)}`;
  }
};

export enum DASHBOARD_KEYS {
  SERVICE_CATEGORY = 'service_category',
  ALL_AMOUNT_MASTERS = 'all_amount.masters',
  AMOUNT_SERVICE_RATE = 'amount_service_rate',
  OP_RECORDS = 'op_records',
  MASTERS = 'masters',
  RETURN_NEW_CLIENT_RATE = 'return_new_client_rate',
  GENERAL_CLIENT_RATE = 'general_client_rate',
  CHURN_BY_MASTER = 'churn_by_master',
  CREATED = 'created',
  FILTERS_MASTERS = 'filters.masters',
  FILTERS_SERVICE_CATEGORY = 'filters.service_category',
  FILTERS_TASKS_COMPLETED = 'filters.tasks_completed',
  FILTERS_TASKS_MISSED = 'filters.tasks_missed',
  ALL_OUT_CALLS = 'all_out_calls',
  ALL_FILTERS_MISSED_CALLS = 'filters.missed_calls',
  FILTERS_NEW_CLIENTS = 'filters.new_clients',
  FILTERS_ALL_RECORDS = 'filters.all_records',
  // FILTERS_ADMINS = 'filters.admins',
  WA_AVERAGE_TIME = 'wa_average_time',
  // FILTERS_COMPLETION_TIME = 'filters.completion_time',
}

export enum DASHBOARD_ADMINS_KEYS {
  ALL_OUT_CALLS = 'all_out_calls',
  ALL_IN_CALLS = 'all_in_calls',
  WA_AVERAGE_TIME = 'wa_average_time',
}

export enum DASHBOARD_MASTERS_KEYS {
  RERUN_MASTER_RATE = 'return_master_rate',
  CHURN_BY_MASTER = 'churn_by_master',
  TASKS = 'tasks',
}

export enum DASHBOARD_FINANCES_KEYS {
  ALL_AMOUNT = 'all_amount',
  AMOUNT_SERVICE_RATE = 'amount_service_rate',
  OP_RECORDS = 'op_records',
  EARNED_BY_OUT_CALLS = 'earned_by_out_calls',
}

export const dashboardAnalyticsData: TDashboardAnalyticsData = {
  [DASHBOARD_KEYS.SERVICE_CATEGORY]: true,
  [DASHBOARD_KEYS.ALL_AMOUNT_MASTERS]: true,
  [DASHBOARD_KEYS.AMOUNT_SERVICE_RATE]: true,
  [DASHBOARD_KEYS.OP_RECORDS]: true,
  [DASHBOARD_KEYS.MASTERS]: true,
  [DASHBOARD_KEYS.RETURN_NEW_CLIENT_RATE]: true,
  [DASHBOARD_KEYS.GENERAL_CLIENT_RATE]: true,
  [DASHBOARD_KEYS.CHURN_BY_MASTER]: true,
  [DASHBOARD_KEYS.CREATED]: true,
  [DASHBOARD_KEYS.FILTERS_MASTERS]: true,
  [DASHBOARD_KEYS.FILTERS_SERVICE_CATEGORY]: true,
  [DASHBOARD_KEYS.FILTERS_TASKS_COMPLETED]: true,
  [DASHBOARD_KEYS.FILTERS_TASKS_MISSED]: true,
  [DASHBOARD_KEYS.ALL_OUT_CALLS]: true,
  [DASHBOARD_KEYS.ALL_FILTERS_MISSED_CALLS]: true,
  [DASHBOARD_KEYS.FILTERS_NEW_CLIENTS]: true,
  [DASHBOARD_KEYS.FILTERS_ALL_RECORDS]: true,
  [DASHBOARD_KEYS.WA_AVERAGE_TIME]: true,
  // [DASHBOARD_KEYS.FILTERS_ADMINS]: true,
  // [DASHBOARD_KEYS.FILTERS_COMPLETION_TIME]: true,
};

export const dashboardAdminsList: TDashboardAdminsData = {
  [DASHBOARD_ADMINS_KEYS.ALL_OUT_CALLS]: [],
  [DASHBOARD_ADMINS_KEYS.ALL_IN_CALLS]: [],
  [DASHBOARD_ADMINS_KEYS.WA_AVERAGE_TIME]: [],
};

export const dashboardMastersList: TDashboardMastersData = {
  [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: [],
  [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: [],
  [DASHBOARD_MASTERS_KEYS.TASKS]: [],
};

export const dashboardFinancesList: TDashboardFinancesData = {
  [DASHBOARD_FINANCES_KEYS.ALL_AMOUNT]: [],
  [DASHBOARD_FINANCES_KEYS.AMOUNT_SERVICE_RATE]: [],
  [DASHBOARD_FINANCES_KEYS.OP_RECORDS]: [],
  [DASHBOARD_FINANCES_KEYS.EARNED_BY_OUT_CALLS]: [],
};

export const NO_CHART_DATA = 'Нет данных';
