import React from 'react';
//
import { useAppSelector } from '@hooks/index';
import { TOnChangeHandler } from '@shared/types';
import { TSimpleStringObj } from '@models/index';
import { getSettingsData } from '@redux/selectors';
import { TelephonyFilter } from '@components/telephonyFilter';
import { ChartFilterComponent } from '@components/chartFilterComponent';
import { getCallDescription, validatePhoneNumber } from '@helpers/index';
import { SearchInputWithButton } from '@components/searchInputWithButton';
import {
  TAdminsDashboardData,
  TMastersDashboardData,
  TFinancesDashboardData,
} from '@api/dashboard/types';

interface ITableWrapperProps {
  /**
   * Опциональный параметр, можно заменить стандартную таблицу
   * @param {React.ReactNode}
   */
  table?: React.ReactNode;
  /**
   *
   */
  isLoading?: boolean;
  /**
   * Ключ, который будет использоваться для извлечения значений из массива данных
   * @param {string}
   */
  chartDataKeyName: string;
  /**
   * Имя выбранного фильтра дынных
   * @param {string}
   */
  selectedFilterName: string;
  /**
   * Опциональный параметр, флаг показывает/скрывает модуль переключения фильтра для данных
   * @param {boolean}
   */
  isChartSetupModule?: boolean;
  /**
   * Объект для формирования выпадающего списка
   * @param {TSimpleStringObj}
   */
  chartFilterNames: TSimpleStringObj;
  /**
   * Опциональный параметр, флаг показывает/скрывает модуль переключения между столбчатой и пончиковой диаграммами
   * @param {boolean}
   */
  isChartTypeSwitcherModule?: boolean;
  /**
   * Callback для изменения фильтра данных
   * @param {(filterName: string) => void}
   */
  callback: (filterName: string) => void;
  /**
   * Строка поиска в инпут
   * @param {string}
   */
  searchString: string;
  /**
   * Callback запуска поиска
   * @param {() => void}
   */
  startSearchHandler: () => void;
  /**
   * Callback для очистки поисковой строки
   * @param {() => void}
   */
  clearSearchStringHandler: () => void;
  /**
   * Callback для обработки изменения поисковой строки
   * @param {TOnChangeHandler}
   */
  setSearchStringHandler: TOnChangeHandler;
  /**
   * Данные для таблицы
   * @param {string}
   */
  data: TAdminsDashboardData | TFinancesDashboardData | TMastersDashboardData;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TableWrapper = ({
  // eslint-disable-next-line
  data,
  table,
  callback,
  searchString,
  // eslint-disable-next-line
  chartDataKeyName,
  chartFilterNames,
  isLoading = false,
  isChartSetupModule,
  selectedFilterName,
  startSearchHandler,
  setSearchStringHandler,
  clearSearchStringHandler,
  isChartTypeSwitcherModule,
  className = '',
}: ITableWrapperProps) => {
  const { telephonyTableData: TelephonyData, firstFilterValue } = useAppSelector(getSettingsData);

  const callsCount = TelephonyData.paginator.count;

  const callDescription = getCallDescription(firstFilterValue, callsCount);

  const disableSearchButton = isLoading || validatePhoneNumber(searchString).length < 11;

  return (
    <div className={`flex flex-col ${className}`}>
      <div className='flex flex-col sm:flex-row flex-wrap sm:justify-between mb-4'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-col max-w-[16rem] w-full'>
            {!isLoading ? (
              <p className='font-medium flex flex-col leading-8 m-0 mb-3 text-h3Mobile tracking-[0.004em]'>
                {`${callsCount} ${callDescription}`}
              </p>
            ) : (
              <div className='h-8 mb-3' />
            )}
            <TelephonyFilter />
          </div>
          <SearchInputWithButton
            buttonText='Найти'
            searchString={searchString}
            startSearchHandler={startSearchHandler}
            disableSearchButton={disableSearchButton}
            placeholder='Поиск клиента по номеру телефона'
            setSearchStringHandler={setSearchStringHandler}
            clearSearchStringHandler={clearSearchStringHandler}
            className='h-fit max-w-[32rem]'
          />
        </div>
        <div className='flex flex-wrap sm:flex-nowrap'>
          {isChartSetupModule ? (
            <div
              className={`flex flex-wrap items-center w-fit md:w-full md:max-w-[23rem] ${
                isChartTypeSwitcherModule ? '' : 'justify-end'
              }`}>
              <ChartFilterComponent
                callback={callback}
                filterNamesObj={chartFilterNames}
                selectedFilterName={selectedFilterName}
                className='chart-dropdown w-fit min-w-[15rem]'
              />
            </div>
          ) : null}
        </div>
      </div>
      {table}
    </div>
  );
};
