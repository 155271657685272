import React, { useCallback, useMemo } from 'react';
//
import { H1 } from '@atoms/h1';
import { TDashboardData } from '@api/dashboard/types';
import { ChartWrapper } from '@components/chartWrapper';
import { TDashboardDrawerDataItem } from '@models/index';
import { DashboardDrawer } from '@blocks/dashboardDrawer';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DashboardPathLinks } from '@blocks/dashboardPathLinks';
import { DashboardInfo, emptyDashboardInfoItem } from '@data/dict';
import { getAppData, getDashboardPageData } from '@redux/selectors';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import { DashboardMastersCharts } from '@blocks/dashboardMastersCharts';
import { DashboardSelectBlock } from '@components/dashboardSelectBlock';
import { setAnalyticsType, setShowAnalyticsDrawer } from '@redux/App/slice';
import {
  DASHBOARD_KEYS,
  MASTERS_CHARTS,
  dashboardMastersList,
  MASTERS_CHART_TITLES,
  DASHBOARD_MASTERS_KEYS,
  mastersTasksFilterName,
  mastersReturnMasterRateFilterNames,
} from '@const/dashboard';
import {
  setMastersDates,
  setSelectedTaskFilterName,
  setSelectedMastersCategories,
  setSelectedReturnMasterRateFilterName,
} from '@redux/Dashboard/slice';

interface IMastersTemplateProps {
  /**
   * Часть пути для перехода на страницу с табличным представлением данных
   * @param {string}
   */
  pathName: string;
  /**
   * Данные для графиков
   * @param {TDashboardData}
   */
  data: TDashboardData;
  /**
   * Массив выбранных категорий/графиков
   * @param {Array<keyof typeof dashboardMastersList>}
   */
  selectedCategories: Array<keyof typeof dashboardMastersList>;
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const MastersTemplate = ({
  data,
  pathName,
  selectedCategories,
  selectedFilialAccId,
  className = '',
}: IMastersTemplateProps) => {
  const dispatch = useAppDispatch();
  const {
    mastersEndDate,
    mastersStartDate,
    selectedTaskFilterName,
    selectedReturnMasterRateFilterName,
  } = useAppSelector(getDashboardPageData);
  const { showAnalyticsDrawer } = useAppSelector(getAppData);

  const setSelectedCategoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch(setSelectedMastersCategories({ name: name as DASHBOARD_MASTERS_KEYS, checked }));
  };

  const setSelectedReturnMasterRateFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedReturnMasterRateFilterName(filterName as DASHBOARD_KEYS));
    },
    [dispatch],
  );

  const setSelectedTaskFilterNameHandler = useCallback(
    (filterName: string) => {
      dispatch(setSelectedTaskFilterName(filterName as DASHBOARD_KEYS));
    },
    [dispatch],
  );

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setMastersDates({ startDate, endDate }));
  };

  const changeDisplayDrawer = useCallback(
    (name?: DASHBOARD_KEYS) => {
      let currentDashboardDrawerData: TDashboardDrawerDataItem = emptyDashboardInfoItem;
      if (name) {
        currentDashboardDrawerData = DashboardInfo[name];

        dispatch(setAnalyticsType(currentDashboardDrawerData));
      }

      dispatch(setShowAnalyticsDrawer(!showAnalyticsDrawer));
    },
    [showAnalyticsDrawer, dispatch],
  );

  const getChartFilterNames = useMemo(
    () => ({
      [DASHBOARD_MASTERS_KEYS.TASKS]: mastersTasksFilterName,
      [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: mastersReturnMasterRateFilterNames,
      [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: mastersReturnMasterRateFilterNames,
    }),
    [],
  );

  const getSelectedFilterName = useMemo(
    () => ({
      [DASHBOARD_MASTERS_KEYS.TASKS]: selectedTaskFilterName,
      [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: selectedReturnMasterRateFilterName,
      [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: selectedReturnMasterRateFilterName,
    }),
    [selectedTaskFilterName, selectedReturnMasterRateFilterName],
  );

  const getCallback = useMemo(
    () => ({
      [DASHBOARD_MASTERS_KEYS.TASKS]: setSelectedTaskFilterNameHandler,
      [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: setSelectedReturnMasterRateFilterNameHandler,
      [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: setSelectedReturnMasterRateFilterNameHandler,
    }),
    [setSelectedTaskFilterNameHandler, setSelectedReturnMasterRateFilterNameHandler],
  );

  const getChartContentType = useMemo(
    () => ({
      [DASHBOARD_MASTERS_KEYS.TASKS]: selectedTaskFilterName,
      [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: DASHBOARD_KEYS.CHURN_BY_MASTER,
      [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: selectedReturnMasterRateFilterName,
    }),
    [selectedTaskFilterName, selectedReturnMasterRateFilterName],
  );

  const getKeyName = useMemo(
    () => ({
      [DASHBOARD_MASTERS_KEYS.TASKS]: selectedTaskFilterName,
      [DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER]: DASHBOARD_KEYS.MASTERS,
      [DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE]: selectedReturnMasterRateFilterName,
    }),
    [selectedTaskFilterName, selectedReturnMasterRateFilterName],
  );

  const renderCharts = useMemo(() => {
    return selectedCategories.map(chartName => {
      let currentChartName = chartName;

      if (
        chartName === DASHBOARD_MASTERS_KEYS.RERUN_MASTER_RATE &&
        selectedReturnMasterRateFilterName === DASHBOARD_KEYS.CHURN_BY_MASTER
      ) {
        currentChartName = DASHBOARD_MASTERS_KEYS.CHURN_BY_MASTER;
      }

      return (
        <ChartWrapper
          key={currentChartName}
          isChartSetupModule
          pathName={pathName}
          selectedFilialAccId={selectedFilialAccId}
          chartName={MASTERS_CHART_TITLES[currentChartName]}
          chartDataKeyName={MASTERS_CHART_TITLES[currentChartName]}
          chartFilterNames={getChartFilterNames[currentChartName]}
          selectedFilterName={getSelectedFilterName[currentChartName]}
          callback={getCallback[currentChartName]}
          barChart={
            <DashboardMastersCharts
              chartName={currentChartName}
              data={data}
              keyName={getKeyName[currentChartName]}
              chartLabel={mastersTasksFilterName[selectedTaskFilterName]}
            />
          }
          doughnutChart={null}
          chartContentType={getChartContentType[chartName]}
          onHowButtonClick={changeDisplayDrawer}
        />
      );
    });
  }, [
    data,
    pathName,
    getKeyName,
    getCallback,
    selectedCategories,
    selectedFilialAccId,
    changeDisplayDrawer,
    getChartFilterNames,
    getChartContentType,
    getSelectedFilterName,
    selectedTaskFilterName,
    selectedReturnMasterRateFilterName,
  ]);

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardDrawer changeDisplayDrawer={changeDisplayDrawer} />
      <DashboardPathLinks
        className='mb-10'
        thirdPageName='Мастера'
        selectedFilialAccId={selectedFilialAccId}
      />
      <H1 className='mb-10 tracking-[-0.01em]'>Мастера</H1>
      <div className='flex flex-wrap items-center justify-between mb-6'>
        <DateRangeDashboardPicker
          startDate={mastersStartDate}
          endDate={mastersEndDate}
          setSendingDate={setDateRangeHandler}
          className='mb-4 w-full sm:max-w-[20rem]'
        />
        <DashboardSelectBlock
          categories={MASTERS_CHARTS}
          selectedCategories={selectedCategories}
          onChangeHandler={setSelectedCategoryHandler}
          className='mb-4 sm:max-w-[12.25rem] w-full'
        />
      </div>
      {renderCharts}
    </div>
  );
};
