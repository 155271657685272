import React from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { ACCESS_RIGHTS } from '@const/access';
import { TSimpleStringObj } from '@models/index';
import { TSelectAdminRightHandler } from '@shared/types';
import { CheckboxToDropdown } from '@blocks/checkboxToDropdown';

type TRightDropdownProps = {
  /**
   * Тмя администратора, используется при вызове callback
   * @param {string}
   */
  adminName: string;
  /**
   * Массив выбранных значений
   * @param {string[]}
   */
  selectedRights: string[];
  /**
   * Объект для формирования выпадающего списка
   * @param {TSimpleStringObj}
   */
  rightToDropdown: TSimpleStringObj;
  /**
   * Callback для выбора пункта меню
   * @param {TSelectAdminRightHandler}
   */
  selectAdminRightHandler: TSelectAdminRightHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const RightDropdown = ({
  adminName,
  selectedRights,
  rightToDropdown,
  selectAdminRightHandler,
  className = '',
}: TRightDropdownProps) => {
  // Фильтруем массив прав, удаляем неиспользуемое право settings_admins
  // const filteredSelectedRights = selectedRights.filter(
  //   rightItem => rightItem !== ACCESS_RIGHTS.SETTINGS_ADMINS,
  // );

  // формируется заголовок в зависимости от количества выбранных пунктов в выпадающем списке
  const label = selectedRights.length
    ? selectedRights.length === 1
      ? `${rightToDropdown[selectedRights[0]]}`
      : `Выбрано: ${selectedRights.length}`
    : 'Выберите из списка';

  const itemsToDropdown = Object.entries(rightToDropdown).map(rightItem => (
    <CheckboxToDropdown
      key={rightItem[0]}
      text={rightItem[1]}
      name={rightItem[0]}
      checked={selectedRights.includes(rightItem[0])}
      onChangeHandler={selectAdminRightHandler(rightItem[0], adminName)}
    />
  ));

  return (
    <div className={className}>
      <Dropdown
        view='outlined'
        color='default'
        position='bottom-left'
        text={<span>{label}</span>}
        content={
          <Menu view='raised' className='!p-2'>
            {itemsToDropdown}
          </Menu>
        }
        className='w-full nav_dropdown'
      />
    </div>
  );
};
