import React, { useState } from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { numWord } from '@helpers/index';
import { MONTH_WORDS, PAY_PAGE_TEXT } from '@const/pay';
import { CheckboxWithLabel } from '@blocks/checkboxWithLabel';

type TGetBillModal = {
  totalAmount: number;
  filialName: string;
  monthAmount: string;
  branchEmployees: string;
  closeModalCallback: () => void;
  downloadBillCallback: (payAll: boolean) => void;
  sendBillInTgCallback: (payAll: boolean) => void;
  /**
   * Флаг показывает состояние запроса для отправки счета
   * @param {boolean}
   */
  isLoading: boolean;
};

export const GetBillModal = ({
  isLoading,
  filialName,
  totalAmount,
  monthAmount,
  branchEmployees,
  closeModalCallback,
  downloadBillCallback,
  sendBillInTgCallback,
}: TGetBillModal) => {
  const [selectAllFilial, setSelectAllFilial] = useState<boolean>(false);
  const [pressedButton, setPressedButton] = useState<'tg' | 'download'>('tg');

  const sendBillInTGHandler = () => {
    setPressedButton('tg');
    sendBillInTgCallback(selectAllFilial);
  };

  const downloadBillHandler = () => {
    setPressedButton('download');
    downloadBillCallback(selectAllFilial);
  };

  const paymentPeriod = totalAmount / +monthAmount;

  // Формируем строку описания филиала
  const rate = `${PAY_PAGE_TEXT.yourTariff} 👩 ${branchEmployees} ${PAY_PAGE_TEXT.employers}, 💳 ${paymentPeriod} ${PAY_PAGE_TEXT.rubl} ${PAY_PAGE_TEXT.month}`;

  // Формируем строку описания сформированного счета
  const billDescription = `${PAY_PAGE_TEXT.billCreated2} ${monthAmount} ${numWord(
    Number(monthAmount),
    MONTH_WORDS,
  )} ${PAY_PAGE_TEXT.billSum} ${totalAmount} ${PAY_PAGE_TEXT.rubl}`;

  return (
    <Dialog
      type='default'
      view='raised'
      color='default'
      title={`${PAY_PAGE_TEXT.billCreated} (${totalAmount}  ${PAY_PAGE_TEXT.rublSign})`}
      text={
        <div className='flex flex-col'>
          <span className='tracking-[0.0275em]'>{`${PAY_PAGE_TEXT.filial} ${filialName}`}</span>
          <span className='tracking-[0.0275em]'>{rate}</span>
          <span className='font-medium tracking-[0.022em] mt-6'>{billDescription}</span>
          <div className='mt-4'>
            <CheckboxWithLabel
              onChangeHandler={() => setSelectAllFilial(prev => !prev)}
              checked={selectAllFilial}
              name='selectAllFilial'
              variant='selectAllFilial'
            />
          </div>
        </div>
      }
      backdropOpacity={40}
      rightButton={
        <div className='flex flex-wrap'>
          <Button
            dense
            type='action'
            view='outlined'
            onClick={sendBillInTGHandler}
            loading={pressedButton === 'tg' && isLoading}
            icon={<Icon variant='smallTG' />}
            text={PAY_PAGE_TEXT.sendInTg}
            className='mt-2 mr-6 w-[12rem]'
          />
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            onClick={downloadBillHandler}
            loading={pressedButton === 'download' && isLoading}
            text={PAY_PAGE_TEXT.downloadPdf}
            icon={<Icon variant='downloadWhite' />}
            className='mt-2 w-[12rem]'
          />
        </div>
      }
      isOpen
      onClose={closeModalCallback}
      style={{ maxWidth: '42.5rem', width: '100%' }}
    />
  );
};
