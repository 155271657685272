import React, { memo, useCallback, useState } from 'react';
//
import { THelpKeys } from '@models/index';
import { Tooltip } from '@uikit/Tooltip';
import { InfoIcon } from '@atoms/infoIcon';
import { TOOLTIPS_TEXT } from '@data/dict';

interface IIconWithTooltipsProps {
  /**
   * Параметр для выбора из словаря подсказок текста подсказки
   * @param {THelpKeys}
   */
  tooltips: THelpKeys;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const IconWithTooltips = memo(({ tooltips, className = '' }: IIconWithTooltipsProps) => {
  // текст подсказки передаваемый в компонент <Tooltip />
  const [tooltipsText, setTooltipsText] = useState<string>('');

  // подсказка показывается если есть текст в tooltipsText,
  // для отображения подсказки записываем текст в tooltipsText
  const showTooltips = useCallback(() => {
    // текст для подсказок берется из словаря data/dict
    const text = TOOLTIPS_TEXT[tooltips];

    if (text) setTooltipsText(text);
  }, [tooltips]);

  // очищаем tooltipsText для скрытия подсказки
  const hideTooltips = useCallback(() => {
    setTooltipsText('');
  }, []);

  return (
    <Tooltip
      view='smooth'
      content={<span className='whitespace-pre-line'>{tooltipsText}</span> || <span>no text</span>}
      color='default'
      isOpen={!!tooltipsText}
      position='bottom'
      className='max-w-[25rem] w-[95vw] z-[50]'>
      <InfoIcon
        className={`cursor-pointer ${className}`}
        onMouseOut={hideTooltips}
        onMouseOver={showTooltips}
        iconColor={tooltipsText ? '#43A047' : '#9696A0'}
      />
    </Tooltip>
  );
});
