import React from 'react';
import { Line } from 'react-chartjs-2';
import { TChurn_by_master, TIndicator_changes_days } from '@api/types';
import { chartColors, hoverLine, tooltipFilter } from '@const/dashboard';
import {
  itemSort,
  noDataChartPlugin,
  chatMultiLineData,
  getEUDateFromString,
} from '@helpers/index';

type TTestChartProps = {
  /**
   * Данные для графика
   * @param {any[]}
   */
  // eslint-disable-next-line
  data: TChurn_by_master[];
  /**
   * Данные для графика событий
   * @param {any[]}
   */
  // eslint-disable-next-line
  eventData: TIndicator_changes_days[];
  /**
   * Ключ, который будет использоваться для извлечения значений из массива данных.
   * @param {string}
   * @default
   */
  keyName: string;
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Опциональный параметр - высота графика
   * @param {string}
   */
  chartHeight?: string;
  /**
   * Название данных
   * @param {string}
   */
  datasetLabel?: string;
  /**
   * Опциональный параметр - изменяет вывод информации с линейной диаграммы на конкурентную
   * @param {boolean}
   */
  isMultiLineChart?: boolean;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | 'center' | undefined;
};

type TDatasets = {
  type: 'line';
  order: number;
  label: string;
  data: number[];
  borderColor?: string;
  backgroundColor?: string | string[];
  // eslint-disable-next-line
  pointBackgroundColor?: any;
  pointStyle?: string;
  pointRadius?: number;
  pointHoverRadius?: number;
  spanGaps?: boolean;
};

type TDataset = {
  labels: string[];
  datasets: TDatasets[];
};

const emptyDataset: TDataset = {
  labels: [],
  datasets: [],
};

export const ChurnByMasterChart = ({
  data,
  keyName = 'value',
  eventData,
  chartTitle,
  chartHeight,
  chartLegendPosition,
  isMultiLineChart = false,
  datasetLabel = 'Событие',
}: TTestChartProps) => {
  // eslint-disable-next-line
  const createOneLineChartDataSet = (chartData: any[], key: string): TDataset => {
    const values: number[] = [];
    const chatLabels = chartData.map(item => {
      const keysArray = key?.split('.');

      if (keysArray.length > 1) {
        values.push(item[key?.split('.')[0]][key?.split('.')[1]]);
      } else {
        values.push(item.value + 10 || 0);
      }
      return getEUDateFromString(item.date, '.');
    });
    return {
      labels: chatLabels,
      datasets: [
        {
          type: 'line',
          order: 2,
          label: datasetLabel,
          data: values,
          borderColor: '#87C289',
          backgroundColor: '#87C289',
        },
      ],
    };
  };

  const createEventsArray = (
    chartData: TChurn_by_master[],
    indicatorChangesDays: TIndicator_changes_days[],
  ) => {
    const eventLabels: string[] = (indicatorChangesDays || []).map(item => item.date);

    return (chartData || []).map(item => {
      const indexOfDate = eventLabels.indexOf(item.date);
      if (indexOfDate >= 0) {
        return indicatorChangesDays?.[indexOfDate];
      }
      return {};
    });
  };

  // eslint-disable-next-line
  const createEventDataset = (chartData: any[]): { labels: string[]; dataset: TDatasets } => {
    const values: number[] = [];
    const hidden: string[] = [];
    const chatLabels = (chartData || []).map(item => {
      const keysArray = keyName?.split('.');

      if (keysArray.length > 1) {
        const value = item[keyName?.split('.')[0]][keyName?.split('.')[1]];

        values.push(value);

        hidden.push(!value ? 'rgba(255,255,255,0)' : '#a47777');
      } else {
        const value = item.n * 0.00001 || 0;
        values.push(value);
        hidden.push(!value ? 'rgba(255,255,255,0)' : '#a47777');
      }
      return getEUDateFromString(item.date || '', '.');
    });

    return {
      labels: chatLabels,
      dataset: {
        type: 'line',
        order: 1,
        label: 'Изменение N',
        data: values,
        borderColor: 'rgba(255,255,255,0)',
        backgroundColor: '#a47777',
        pointBackgroundColor: hidden,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
        spanGaps: true,
      },
    };
  };

  const getMultilineChartData = (
    // eslint-disable-next-line
    multilineData: any[],
    key: string,
    colorArray: Record<string, string>,
  ) => {
    const multiLineData = chatMultiLineData({
      data: multilineData,
      keyName: key,
      colors: colorArray,
    });
    const mappedMultiLineData = multiLineData.datasets.map(item => ({
      ...item,
      type: 'line',
      order: 2,
    })) as TDatasets[];

    return { labels: multiLineData.labels, mappedMultiLineData };
  };

  const multilineData = isMultiLineChart
    ? getMultilineChartData(data, keyName, chartColors)
    : { labels: [], mappedMultiLineData: [] };

  const datasetsObj = !isMultiLineChart
    ? { ...createOneLineChartDataSet(data, keyName) }
    : { ...emptyDataset };

  // eventData.forEach(item => {
  //   const { dataset } = createEventDataset(item);
  //   const numArray: TDatasets[] = [];
  //   numArray.push(dataset);
  //   datasetsObj.datasets = [...datasetsObj.datasets, ...numArray];
  // });

  const { dataset } = createEventDataset(createEventsArray(data, eventData));
  const numArray: TDatasets[] = [];
  numArray.push(dataset);
  datasetsObj.datasets = [...datasetsObj.datasets, ...numArray];

  datasetsObj.labels = isMultiLineChart
    ? [...(multilineData?.labels || [])]
    : [...datasetsObj.labels];
  datasetsObj.datasets = [...datasetsObj.datasets, ...multilineData.mappedMultiLineData];

  const height = ((datasetsObj?.datasets?.length || 0) + 1) * 18;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      // intersect: false,
    },
    plugins: {
      tooltip: {
        position: 'nearest' as const,
        itemSort,
        enabled: true,
        usePointStyle: true,
        callbacks: {
          label: tooltipFilter,
        },
        // eslint-disable-next-line
        filter(e: any) {
          return !!+e.raw;
        },
      },
      legend: {
        position: `${chartLegendPosition || 'bottom'}` as const,
        labels: {
          // фильтрует строки легенды
          // eslint-disable-next-line
          filter(context: any): boolean {
            return !context.text.includes(datasetLabel);
          },
        },
      },
      title: {
        display: false,
        text: chartTitle,
        position: 'top' as const,
      },
    },
  };

  return (
    <div
      className='chart-container'
      style={{
        position: 'relative',
        height: `${chartHeight || `clamp(420px, ${height}px, 700px)`}`,
        width: `clamp(200px, 99%, 1270px)`,
      }}>
      <Line plugins={[hoverLine, noDataChartPlugin]} options={options} data={datasetsObj} />
    </div>
  );
};
