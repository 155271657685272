import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Title,
  Legend,
  Colors,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
//
import { FormattedData } from '@models/index';
import { noDataChartPlugin } from '@helpers/index';

interface IBarChartProps {
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | 'center' | undefined;
  /**
   * Опциональный параметр, изменяет гранулярность шкалы Y и добавляет "мин."
   */
  isTimeChart?: boolean;
  /**
   * Данные для отображения графика
   * @param {any[]}
   */
  data: FormattedData;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Colors);

export const BarChart = ({
  chartTitle,
  isTimeChart,
  data: chartData,
  chartLegendPosition,
}: IBarChartProps) => {
  const height = (chartData?.datasets?.length || 0) * 18;

  const scalesOptionToTimeChart = {
    y: {
      ticks: {
        stepSize: 60,
        callback: (value: number) => {
          return `${value / 60} мин.`;
        },
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: isTimeChart ? scalesOptionToTimeChart : {},
    plugins: {
      legend: {
        position: `${chartLegendPosition || 'bottom'}` as const,
      },
      title: {
        display: false,
        text: chartTitle,
      },
    },
  };

  return (
    <div
      className='chart-container'
      style={{
        position: 'relative',
        height: `clamp(420px, ${height}px, 1000px)`,
        width: `clamp(200px, 99%, 1270px)`,
      }}>
      <Bar options={options} data={chartData} plugins={[noDataChartPlugin]} />
    </div>
  );
};
