import React from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TSimpleStringObj } from '@models/index';

interface IChartFilterComponentProps {
  /**
   * Имя выбранного фильтра
   * @param {string}
   */
  selectedFilterName: string;
  /**
   *  Объект с данными для формирования выпадающего списка
   * @param {TSimpleStringObj}
   */
  filterNamesObj: TSimpleStringObj;
  /**
   * Callback для установки выбранного фильтра
   * @param (filterName: string) => void
   */
  callback: (filterName: string) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const ChartFilterComponent = ({
  callback,
  filterNamesObj,
  selectedFilterName,
  className = '',
}: IChartFilterComponentProps) => {
  const setSelectedAllAmountFilterNameHandler = (filterName: string) => () => {
    callback(filterName);
  };

  const filterItemRoRender = Object.entries(filterNamesObj).map(item => (
    <MenuItem
      key={item[0]}
      text={<span>{item[1]}</span>}
      onClick={setSelectedAllAmountFilterNameHandler(item[0])}
    />
  ));

  const label = filterNamesObj[selectedFilterName] || '';

  return (
    <div className={className}>
      <Dropdown
        disabled={filterItemRoRender.length < 2}
        view='outlined'
        color='default'
        position='bottom-left'
        text={<span>{label}</span>}
        content={<Menu view='raised'>{filterItemRoRender}</Menu>}
        className={`nav_dropdown w-full ${filterItemRoRender.length < 2 ? '!bg-lightGray' : ''}`}
      />
    </div>
  );
};
