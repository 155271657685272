import { log } from '@helpers/index';
import { TRejectValue } from '@models/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { accountsApi } from '@api/accounts/accountsApi';
import { accountsMappers } from '@redux/Accounts/mappers';
import { REJECT_RESPONSE_KEY, ACCOUNT_RESPONSE_KEY } from '@api/types';
import { TAccountsData, AccountsResponseSchema } from '@models/Accounts';

export const getAccounts = createAsyncThunk<TAccountsData | void, void, TRejectValue>(
  'accounts/getAccounts',
  (_, { rejectWithValue }) => {
    return accountsApi
      .getAccounts()
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(String(response.data.status));
        }
        if (ACCOUNT_RESPONSE_KEY.COUNT in response.data) {
          const parsedData = AccountsResponseSchema.safeParse(response.data);

          if (!parsedData.success) {
            log('@@accounts/getAccounts pars error', parsedData.error.format());
            // throw new Error('Something went wrong');
          } else if (ACCOUNT_RESPONSE_KEY.COUNT in response.data) {
            return accountsMappers.responseToStore(parsedData.data);
          }
        }
      })
      .catch(error => rejectWithValue(error.response.data));
  },
);
