import { TInitialState } from '@redux/Accounts/zod';

export const initialState: TInitialState = {
  isLoading: false,
  isError: false,
  isRetries: false,
  retries: 1,
  isAccessDenied: false,
  isNoFilialError: false,
  selectedFilial: null,
  data: [],
  bannerData: {
    imageUrl: '',
    infoUrl: '',
  },
};
