import React from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { TOnChangeHandler } from '@shared/types';
import { TSimpleStringObj } from '@models/index';
import { CheckboxToDropdown } from '@blocks/checkboxToDropdown';

interface IDashboardSelectBlockProps {
  /**
   * Объект с категориями лоя формирования списка в дропдаун
   * @param {TSimpleStringObj}
   */
  categories: TSimpleStringObj;
  /**
   * Массив выбранных категорий/графиков
   * @param {string[]}
   */
  selectedCategories: string[];
  /**
   * Callback для изменения списка
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Callback которая вызывается при закрытии дропдауна
   * @param {() => void}
   */
  onCloseDropdownCallback?: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Опциональный параметр текста лэйбла
   * @param {string}
   * @default "''"
   */
  labelText?: string;
  /**
   * Опциональный параметр строка классов лэйбла
   * @param {string}
   * @default "''"
   */
  labelClass?: string;
}

export const DashboardSelectBlock = ({
  categories,
  onChangeHandler,
  selectedCategories,
  onCloseDropdownCallback = () => {
    ('');
  },
  className = '',
  labelText = '',
  labelClass = '',
}: IDashboardSelectBlockProps) => {
  // формируется заголовок в зависимости от количества выбранных пунктов в выпадающем списке
  const label = selectedCategories.length
    ? `Показатели: ${selectedCategories.length}`
    : 'Выберите из списка';

  const checkBoxElementsList = Object.entries(categories).map(item => {
    if (item[0] === 'finances' || item[0] === 'mastersLabel' || item[0] === 'admins') {
      return (
        <span key={item[0]} className='pl-[0.6rem] text-small_text text-dGray'>
          {item[1]}
        </span>
      );
    }
    if (item[0].includes('divider')) {
      return <span key={item[0]} className='border-b border-quartz1 block my-2 w-full' />;
    }
    return (
      <CheckboxToDropdown
        key={item[0]}
        text={item[1]}
        name={item[0]}
        onChangeHandler={onChangeHandler}
        checked={selectedCategories.includes(item[0])}
        className='w-fit'
      />
    );
  });

  return (
    <div className={className}>
      {labelText ? (
        <span className={`text-grayText inline-block mb-2 ${labelClass}`}>{labelText}</span>
      ) : null}
      <Dropdown
        onClose={onCloseDropdownCallback}
        view='outlined'
        color='default'
        position='bottom-right'
        text={<span>{label}</span>}
        content={
          <Menu view='raised' className='!p-2'>
            {checkBoxElementsList}
          </Menu>
        }
        className='w-full nav_dropdown chart-dropdown'
      />
    </div>
  );
};
