import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
//
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { TReportInfo } from '@models/index';
import { useAppSelector } from '@hooks/index';
import { getAppData } from '@redux/selectors';
import { DeleteButton } from '@blocks/deleteButton';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { TDeleteTask, TOnChangeHandler, TOnclickHandler } from '@shared/types';

interface IReportCardProps {
  /**
   * Данные для карточки задачи
   * @param {TReportInfo}
   */
  data: TReportInfo;
  /**
   * Если true запрос на удаление обрабатывается
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Callback на удаление задачи
   * @param {TDeleteTask}
   */
  deleteTask: TDeleteTask;
  /**
   * Callback на переключение активности задачи
   * @param {TDeleteTask}
   */
  switchTask: TDeleteTask;
  /**
   * Флаг ошибки при удалении
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаления задачи
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const ReportCard = memo(
  ({
    data,
    className = '',
    deleteTask,
    switchTask,
    isDeleting,
    isDeletingError,
    isDeletingSuccess,
  }: IReportCardProps) => {
    const { isDragging } = useAppSelector(getAppData);
    const { id, name: taskName, description, active, link, whenSend } = data;

    // состояние модалки подтверждения удаления шаблона
    const [isOpen, setIsOpen] = useState<boolean>(() => false);

    // Закрывает окно подтверждения удаления шаблона при успешном удалении
    // или при возникновении ошибки
    useEffect(() => {
      if (isDeletingSuccess || isDeletingError) {
        setIsOpen(false);
      }
    }, [isDeletingSuccess, isDeletingError]);

    // открывает модалку на подтверждение удаления шаблона из списка
    const modalToggle: TOnclickHandler = useCallback(event => {
      event.preventDefault();
      setIsOpen(prevState => !prevState);
    }, []);

    // вызывает коллбак удаления шаблона и передает в него его ID шаблона
    const deleteTemplate: TOnclickHandler = useCallback(
      event => {
        event.preventDefault();
        deleteTask(id);
      },
      [id, deleteTask],
    );

    // включает или отключает задачу по клику на переключатель
    const toggleTaskActive: TOnChangeHandler = useCallback(
      event => {
        event.preventDefault();
        switchTask(id);
      },
      [id, switchTask],
    );

    const memoizedDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          title={`Вы хотите удалить\n шаблон ${id}?`}
          text={<span>{`${taskName} \n\n ${description}`}</span>}
          className='whitespace-pre-line break-words'
          backdropOpacity={40}
          leftButton={
            <Button
              dense
              type='action'
              text='Отмена'
              view='outlined'
              color='default'
              onClick={modalToggle}
              className='!min-w-[6rem]'
            />
          }
          rightButton={
            <Button
              dense
              type='action'
              view='filled'
              color='danger'
              text='Удалить'
              onClick={deleteTemplate}
              loading={isDeleting}
              disabled={isDeleting}
              className='!min-w-[6rem]'
            />
          }
          isOpen={isOpen}
          onClose={modalToggle}
        />
      ),
      [deleteTemplate, description, id, isDeleting, isOpen, modalToggle, taskName],
    );

    return (
      <div
        className={`relative bg-gray1 flex flex-col justify-between font-inter text-blackText p-[0.8rem] pb-[1.625rem] rounded-lg h-[12.75rem] max-h-[12.75rem] w-full hover:shadow-btnHoverShadow transition-all ${className}`}>
        <Link
          to={link}
          aria-label={`Шаблон отчетов ${id}`}
          className={`${isDragging ? 'hidden' : 'absolute'} top-0 right-0 bottom-0 left-0`}
        />
        <div className='flex flex-col m-0.5'>
          <div className='flex justify-between mb-2'>
            <p className='break-words font-medium text-black text-h3Mobile tracking-[0.004em] leading-[1.5rem] overflow-hidden mb-0 mr-2 h-[3rem]'>
              {taskName}
            </p>
            <ToggleSwitch
              name={id}
              checked={active}
              onChangeHandler={toggleTaskActive}
              className='cursor-pointer items-start z-[1]'
            />
          </div>
          <p className='break-words mb-2 text-tooltips text-grayText overflow-hidden tracking-[0.018em] leading-[1.25rem] h-[2.5rem]'>
            {description}
          </p>
        </div>
        <p className='mt-[0.875rem] mb-0 text-tooltips text-grayText overflow-hidden max-w-[93%]'>
          {whenSend}
        </p>
        <DeleteButton
          callback={modalToggle}
          className='absolute bottom-[1.525rem] right-[0.975rem]'
        />
        {memoizedDialog}
      </div>
    );
  },
);
