import { TSimpleStringObj } from '@models/index';

export enum REPORTS_ERROR_MESSAGE {
  COMMON_ERROR = 'Ошибка \n Перезагрузите страницу \n и попробуйте ещё раз',
}

export enum REPORT_SEND_PERIOD {
  EVERY_DAY = 'day',
  EVERY_WEEK = 'week',
  EVERY_MONTH = 'month',
  EVERY_QUARTER = 'everyQuarter',
  EVERY_YEAR = 'everyYear',
}

export const reportSendPeriod: TSimpleStringObj = {
  day: 'Каждый день',
  week: 'Каждую неделю',
  month: 'Каждый месяц',
  // everyQuarter: 'Каждый квартал',
  // everyYear: 'Каждый год',
};

export const everyWeekSendPeriod: TSimpleStringObj = {
  1: 'В понедельник',
  0: 'В воскресенье',
};

export enum EVERY_WEEK_SEND_PERIOD_VALUES {
  MONDAY = '1',
  SUNDAY = '0',
}

export const everyMonthSendPeriod: TSimpleStringObj = {
  1: 'Первый день месяца',
  0: 'Последний день месяца',
};

export enum EVERY_MONTH_SENDING_PERIOD_VALUES {
  FIRST_DAY = '0',
  LAST_DAY = '1',
}

export enum DATA_CALCULATION_PERIOD {
  TODAY = '0',
  YESTERDAY = '1',
  MONDAY = '1',
  SUNDAY = '0',
  LAST_DAY_OF_MOTH = '0',
  FIRST_DAY_OF_MONTH = '1',
}

export const statisticCalculatingPeriod: TSimpleStringObj = {
  day: 'День',
  week: 'Неделю',
  month: 'Месяц',
};

export const dayStatisticsSend: TSimpleStringObj = {
  0: 'Сегодня',
  1: 'Вчера',
};

export const textKeys: TSimpleStringObj = {
  '*': '*текст*',
  _: '_текст_',
  '~': '~текст~',
  '«': '«текст«',
};

export const keyButtons: TSimpleStringObj = {
  finances: '💰 Салон',
  // clients: '👩🏻 Клиенты',
  staff: '👩‍⚕️ Мастера',
  admins: '💻 Админы',
  // task: '📌 Задачи',
};

export const financesKey: TSimpleStringObj = {
  filial_name: 'Название филиала',
  report_date: 'Дата отчета',
  all_amount: 'Заработано с записей за день на момент подсчета+',
  amount_service_rate: 'Топ 3 услуг по выручке+',
  all_paid: 'Оплаченных записей за день расчета',
  all_records: 'Новых записей за день расчета',
  new_clients: 'Кол-во тех, кого нет в базе салона среди всех входящих звонков за день подсчета',
};

export const clientsKey: TSimpleStringObj = {
  filial_name: 'Название филиала',
};

export const adminsKey: TSimpleStringObj = {
  filial_name: 'Название филиала',
  report_date: 'Дата отчета',
  all_out_calls: 'Кол-во исходящих звонков за день подсчета',
  success_out_calls: 'Кол-во успешных звонков за день подсчета',
  all_in_calls: 'Кол-во входящих звонков за день подсчета',
  missed_calls: 'Кол-во пропущенных звонков за день подсчета',
  dont_recall: 'Кол-во тех, кому не перезвонили за день подсчета',
  op_records: 'Процент записанных на будущее среди тех, кто был в день подсчета',
  tasks_missed: 'Просрочено с предыдущего дня',
  completion_time: 'Среднее время выполнения',
  task_plan_actual: 'Выполненные задачи, кол-во',
  task_plan_actual_type: 'Выполненные задачи (по типу “Клиент”, “Сотрудник”, “Дело”), кол-во',
  task_plan_actual_id: 'Выполненные задачи (по номеру шаблона), кол-во',
  client_average_check: 'Средний чек (по клиентам), ₽',
  lead_to_record: 'Конверсия из входящих в запись, %',
  missed_calls_and_callbacks: 'Доля пропущенных, %',
  call_and_wa_response_time: 'Время ответа на звонки и Whatsapp, сек.',
  outbound_call_sales: 'Продажи по исходящим звонкам, ₽',
  product_conversion_rate: 'Доля клиентов, кому продали товары, %',
  total_product_sales: 'Сумма проданных товаров, ₽',
  membership_conversion_rate: 'Доля клиентов кому продали сертификат или абонемент, %',
  total_membership_sales: 'Сумма проданных сертификатов и абонементов, ₽',
};

export const staffKey: TSimpleStringObj = {
  filial_name: 'Название филиала',
  report_date: 'Дата отчета',
  return_master_rate: 'Рейтинг возвращаемости клиентов по каждому мастеру',
  return_new_master_rate: 'Рейтинг возвращаемости новых клиентов по каждому мастеру',
  return_master_rate_work:
    'Рейтинг возвращаемости клиентов по каждому мастеру которые работают сегодня',
  churn_by_master: 'Доля ушедших из салона клиентов в общем количестве клиентов',
};

export const taskKey: TSimpleStringObj = {
  filial_name: 'Название филиала',
};

export enum TEXT_STYLE_SYMBOLS {
  BOLD = '*',
  ITALIC = '_',
  UNDERLINED_TEXT = '~',
  MONOSPACE_TEXT = '«',
}

export const HTML_TEXT_STYLE_TAG: TSimpleStringObj = {
  '*': 'b',
  // _: 'i',
  // '~': 's',
  // '«': 'pre',
};

export enum KEY_WRAPPER_SYMBOLS {
  OPEN_SYMBOL = '{',
  CLOSE_SYMBOL = '}',
}

export const reportPreviewDict: TSimpleStringObj = {
  filial_name: 'Название филиала',
  all_amount: '123 540',
  amount_service_rate:
    '\n 🥇31 730₽ - Снятие+маникюр+выравнивание+гель лак\n 🥈13 000₽ - Снятие+педикюр+гель лак\n 🥉9 700₽ - Экспресс моментальный загар',
  all_records: '49',
  all_paid: '36',
  all_out_calls: '23',
  success_out_calls: '4',
  all_in_calls: '10',
  new_clients: '7',
  missed_calls: '2',
  dont_recall: '0',
  wa_average_time: '1 мин 29 сек',
  return_master_rate: '\n 🥇Татьяна У., 37%\n 🥈Жанна Ж., 35%\n 🥉Надя С., 33%\n ...',
  return_new_master_rate: '\n 🥇Татьяна У., 37%\n 🥈Жанна Ж., 35%\n 🥉Надя С., 33%\n ...',
  return_master_rate_work: `\n 🥇 Мария 50%\n 🥈 Анна 34%\n 🥉 Екатерина 23%\n \xa0\xa0\xa0\xa0\xa0\xa0\xa0${String.fromCharCode(
    0xb7,
  )} Настя 22%\n  \xa0\xa0\xa0\xa0\xa0\xa0\xa0${String.fromCharCode(0xb7)} Оля  20%`,
  tasks_created: '29',
  tasks_completed: '29',
  tasks_missed: '0',
  completion_time: '369 мин 19 сек',
  op_records: '90%',
  report_date: '10.03.2023 19:00',
  earned_by_out_calls: '100000',
  client_name: 'Имя клиента',
  date: '3.10',
  start_time: '10:00',
  master_name: 'Имя мастера',
  price: '1500',
  client_phone: '79111111111',
  services: '▫️ Услуга 1',
  client_history: 'История посещений (гиперлинк)',
  client_visits: '5',
  review_text: 'Текст жалобы клиента',
  churn_by_master: '\n 🥇Татьяна У., 21%\n 🥈Жанна Ж., 8%\n 🥉Надя С., 1%\n ...',
  general_client_rate: '\n 🥇Татьяна У., 60%\n 🥈Жанна Ж., 39%\n 🥉Надя С., 19%\n ...',
  cancelled_date: '27.09 15:30',
};

export const statisticReportMockDescriptionData =
  '🏬 *{filial_name}*\n' +
  '=========================\n' +
  '\n' +
  '💳 Выручка: *{all_amount}₽*\n' +
  'Создано новых записей: {all_records}\n' +
  'Записей реализовано: {all_paid}\n' +
  '\n' +
  '📞 *Данные о звонках*\n' +
  'Исходящих звонков: {all_out_calls}\n' +
  'Успешных исходящих: {success_out_calls}\n' +
  '\n' +
  '🤳 *Звонки входящие*\n' +
  'Входящих звонков: {all_in_calls}\n' +
  'Из них новых клиентов: {new_clients}\n' +
  'Не дозвонились: {missed_calls}\n' +
  'Не перезвонили: {dont_recall}\n' +
  '\n' +
  '🟢 *Вотсап*\n' +
  'Скорость ответов: {wa_average_time}\n' +
  '\n' +
  '*Данные о задачах*\n' +
  'Задач поставлено: *{tasks_created}*\n' +
  'Из них выполнено: *{tasks_completed}*\n' +
  'Просрочено с предыдущего дня: *{tasks_missed}*\n' +
  'Среднее время выполнения: *{completion_time}*';

export enum REPORT_DATA_KEY_NAMES {
  ID = 'id',
  IS_ACTIVE = 'isActive',
  REPORT_NAME = 'reportName',
  REPORT_DESCRIPTION = 'reportDescription',
  SELECTED_REPORT_SEND_PERIOD = 'selectedReportSendPeriod',
  SENDING_TIME = 'sendingTime',
  SENDING_DATE = 'sendingDate',
  SELECTED_DATA_CALCULATING_PERIOD = 'selectedDataCalculatingPeriod',
  SELECTED_EVERY_WEEK_SENDING_PERIOD = 'selectedEveryWeekSendingPeriod',
  WHATSAPP_RECIPIENTS = 'whatsappRecipients',
  TELEGRAM_RECIPIENTS = 'telegramRecipients',
  SEND_TO_ALL_STAFF = 'sendToAllStaff',
}
