import { END_POINTS_URL } from '@const/apiConstants';
//
import { apiService } from '@api/ApiService';
import { TRequestStatus, TGetResponseWithData } from '@api/types';
import {
  TStaffListItem,
  ORDER_VARIANTS,
  TPostAdminsData,
  TTelephonyResponse,
  TTelephonyRequestData,
  TGetSettingResponseData,
  TGetSmartScheduleResponse,
  TGetAdminsTableDataResponse,
  TSettingsTelephonyDataResponse,
  TSettingsTelephonyResponseData,
  TSettingsNotificationResponseData,
} from '@models/Settings';

/**
 * API страницы настроек
 * @module settingsApi
 */

export const settings = {
  /**
   * Метод выполняет запрос за данными страницы
   * @method getSettings
   * @return {Promise<AxiosResponse<TGetSettingResponseData | TRequestStatus, any>>}
   */
  getSettings() {
    return apiService.get<TGetSettingResponseData | TRequestStatus>(
      END_POINTS_URL.SETTINGS_GENERAL,
    );
  },
  /**
   * Метод выполняет запрос для сохранения данных страницы
   * @method postSettings
   * @param data {TPostSettingsRequestData} данные страницы настроек
   * @return {Promise<AxiosResponse<TGetSettingResponseData | TRequestStatus, any>>}
   */
  postSettings(data: TGetSettingResponseData) {
    return apiService.post<TGetSettingResponseData | TRequestStatus>(
      END_POINTS_URL.SETTINGS_GENERAL,
      data,
    );
  },
  // /**
  //  * Метод выполняет запрос за списком сотрудников филиала
  //  * @method getExceptionStatsMaster
  //  * @return {Promise<AxiosResponse<TRequestStatus | TStaffListResponse, any>>}
  //  */
  // getExceptionStatsMaster() {
  //   return apiService.get<TStaffListResponse | TRequestStatus>(END_POINTS_URL.STAFF);
  // },
  /**
   * Метод выполняет запрос за списком сотрудников филиала
   * @method getExceptionStatsMaster
   * @return {Promise<AxiosResponse<TRequestStatus | TStaffListResponse, any>>}
   */
  getExceptionStatsMaster() {
    return apiService.get<TGetResponseWithData<TStaffListItem[]> | TRequestStatus>(
      END_POINTS_URL.STAFF,
    );
  },
  /**
   * Метод запрашивает данные для отображения таблиц мастеров
   * @method getSmartSchedule
   * @return {Promise<AxiosResponse<TRequestStatus | TGetSmartScheduleResponse[], any>>}
   */
  getSmartSchedule() {
    return apiService.get<TGetSmartScheduleResponse[] | TRequestStatus>(
      END_POINTS_URL.SETTINGS_MASTERS,
    );
  },
  /**
   * Метод запрашивает данные для отображения таблицы Админов
   * @method getAdmins
   * @return {Promise<AxiosResponse<TRequestStatus | TGetSmartScheduleResponse[], any>>}
   */
  getAdmins() {
    return apiService.get<TGetAdminsTableDataResponse[] | TRequestStatus>(END_POINTS_URL.CRM_TG);
  },
  postAdmins(data: TPostAdminsData[]) {
    return apiService.post<TGetAdminsTableDataResponse[]>(END_POINTS_URL.CRM_TG, data);
  },
  /**
   * Метод получает данные о телефонии
   * @method getTelephonyData
   * @return {Promise<AxiosResponse<TRequestStatus | TSettingsTelephonyDataResponse, any>>}
   */
  getTelephonyData() {
    return apiService.get<TSettingsTelephonyDataResponse | TRequestStatus>(
      END_POINTS_URL.SETTINGS_TELEPHONY,
    );
  },
  /**
   * Метод передает данные подключения для проверки возможности подключить телефонию с этими данными
   * @method testTelephonyData
   * @param data {TSettingsTelephonyData} данные для проверки
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  testTelephonyData(data: TSettingsTelephonyResponseData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SETTINGS_TELEPHONY_TEST, data);
  },
  /**
   * Метод подключает телефонию, передавая данные введенные пользователем
   * @param data {TSettingsTelephonyData} настройки телефонии для подключения
   * @return {Promise<AxiosResponse<TRequestStatus, any>>}
   */
  saveTelephonyData(data: TSettingsTelephonyResponseData) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SETTINGS_TELEPHONY, data);
  },
  getNotifications() {
    return apiService.get<TSettingsNotificationResponseData>(END_POINTS_URL.SETTINGS_NOTIFICATIONS);
  },
  saveNotifications(data: TSettingsNotificationResponseData) {
    return apiService.post<TSettingsNotificationResponseData>(
      END_POINTS_URL.SETTINGS_NOTIFICATIONS,
      data,
    );
  },
  /**
   * Метод получает данные для таблицы телефонии
   * @method getTelephonyTableData
   * @param data
   */
  getTelephonyTableData(data: TTelephonyRequestData) {
    const params = Object.fromEntries(Object.entries(data).filter(param => !!param[1]));
    return apiService.get<TTelephonyResponse | TRequestStatus>(END_POINTS_URL.TELEPHONY_TABLE, {
      ...params,
    });
  },
  /**
   * Метод получает данные о телефонии
   * @method getTelephonyData
   * @return {Promise<AxiosResponse<TRequestStatus | TSettingsTelephonyDataResponse, any>>}
   */
  // getTelephonyData() {
  //   return apiService.get<TSettingsTelephonyDataResponse | TRequestStatus>(
  //       END_POINTS_URL.SETTINGS_TELEPHONY,
  //   );
  // },
  // /**
  //  * Метод передает данные подключения для проверки возможности подключить телефонию с этими данными
  //  * @method testTelephonyData
  //  * @param data {TSettingsTelephonyData} данные для проверки
  //  * @return {Promise<AxiosResponse<TRequestStatus, any>>}
  //  */
  // testTelephonyData(data: TSettingsTelephonyResponseData) {
  //   return apiService.post<TRequestStatus>(END_POINTS_URL.SETTINGS_TELEPHONY_TEST, data);
  // },
  // /**
  //  * Метод подключает телефонию, передавая данные введенные пользователем
  //  * @param data {TSettingsTelephonyData} настройки телефонии для подключения
  //  * @return {Promise<AxiosResponse<TRequestStatus, any>>}
  //  */
  // saveTelephonyData(data: TSettingsTelephonyResponseData) {
  //   return apiService.post<TRequestStatus>(END_POINTS_URL.SETTINGS_TELEPHONY, data);
  // },
  saveOrderOnDnD({ target, data }: { target: ORDER_VARIANTS; data: string[] }) {
    return apiService.post<TRequestStatus>(END_POINTS_URL.SETTINGS_SET_ORDER, {
      [target]: data,
    });
  },
};
