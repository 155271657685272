import { reducers } from '@redux/App/reducers';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '@redux/App/state';

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers,
});

export const appReducer = slice.reducer;
export const {
  setTaskTypeToPath,
  setShowAnalyticsDrawer,
  setAnalyticsType,
  setShowAppDrawer,
  setAppDrawerData,
  setIsDragging,
} = slice.actions;
