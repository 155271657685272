import { createSlice } from '@reduxjs/toolkit';
//
import { initialState } from '@redux/Accounts/state';
import { getAccounts } from '@redux/Accounts/thunks';
import { getAccountsReducer, reducers } from '@redux/Accounts/reducers';

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getAccounts.pending, getAccountsReducer.pending);
    builder.addCase(getAccounts.fulfilled, getAccountsReducer.fulfilled);
    builder.addCase(getAccounts.rejected, getAccountsReducer.rejected);
  },
});

// state.isAccessDenied = true;
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxODkwMzU3NDk2LCJleHBpcmVzIjoxNjc0ODM0NzgxLjI1NjI3fQ.veXJQvyiQOxTG5ZFNP-PZpLBbgr9f-hbUjOg3JzR50w

export const { setSelectedFilial, setIsAccessDenied, showNoFilialError, hideNoFilialError } =
  accountsSlice.actions;
export const accountsReducer = accountsSlice.reducer;
