import { z } from 'zod';
import { PayerDataSchema } from '@models/Pay';

const PermissionsSchema = z.object({
  settings: z.boolean(),
  analytics: z.boolean(),
  templates: z.boolean(),
  settings_admins: z.boolean(),
});

const BannerResponseSchema = z.object({
  image_url: z.string().optional(),
  info_link: z.string().optional(),
});

const AccountSchema = z.object({
  name: z.string(),
  quantity_of_templates: z.number(),
  acc_id: z.string(),
  branch_id: z.number(),
  process: z.boolean(),
  payer_data: PayerDataSchema,
  pay_period: z.string(),
  beauty_bot_integration: z.boolean(),
  role: z.string(),
  permissions: PermissionsSchema,
  is_owner: z.boolean(),
  telephony: z.string().nullable(),
});

export const AccountsResponseSchema = z.object({
  count: z.number(),
  banner_data: BannerResponseSchema,
  data: AccountSchema.array(),
});

export const BannerDataTypeSchema = z.object({
  imageUrl: z.string(),
  infoUrl: z.string(),
});

export const FilialDataSchema = z.object({
  name: z.string(),
  accId: z.string(),
  rights: z.array(z.string()),
  process: z.boolean(),
  branchId: z.number(),
  payPeriod: z.string(),
  payerData: PayerDataSchema,
  quantityOfTemplates: z.number(),
  telephony: z.string().nullable(),
  beautyBotIntegration: z.boolean(),
  isOwner: z.boolean(),
});

export const AccountsSchema = z.object({
  count: z.number(),
  data: FilialDataSchema.array(),
  bannerData: BannerDataTypeSchema,
});

export type TAccountResponseData = z.infer<typeof AccountsResponseSchema>;

export type TFilialData = z.infer<typeof FilialDataSchema>;

export type TAccountsData = z.infer<typeof AccountsSchema>;
