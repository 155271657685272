import { TSimpleStringObj } from '@models/index';
import {
  reportPreviewDict,
  TEXT_STYLE_SYMBOLS,
  HTML_TEXT_STYLE_TAG,
  KEY_WRAPPER_SYMBOLS,
} from '@const/report';

interface IPreviewMaker {
  getPreviewMarkUp: (description: string) => string;
}

class PreviewMaker implements IPreviewMaker {
  private readonly keyWrapperSymbols;

  private readonly htmlTextStyleTag;

  private readonly reportPreviewDictionary;

  private readonly textStyleKeys;

  private description: string;

  constructor(
    keyWrapperSymbol: typeof KEY_WRAPPER_SYMBOLS,
    reportPreviewDictionary: TSimpleStringObj,
    htmlTextStyleTag: TSimpleStringObj,
    textStileKeys: string[],
  ) {
    this.keyWrapperSymbols = keyWrapperSymbol;
    this.htmlTextStyleTag = htmlTextStyleTag;
    this.reportPreviewDictionary = reportPreviewDictionary;
    this.textStyleKeys = textStileKeys;
    this.description = '';
  }

  private getDividedText(
    startIndex: number,
    endIndex: number,
  ): {
    textBeforeKey: string;
    textInKey: string;
    textAfterKey: string;
  } {
    const textBeforeKey = this.description.slice(0, startIndex);
    const textInKey = this.description.slice(startIndex + 1, endIndex);
    const textAfterKey = this.description.slice(endIndex + 1);
    return {
      textAfterKey,
      textBeforeKey,
      textInKey,
    };
  }

  private replaceStatisticKeyByMockData() {
    let openSymbolIndex = 0;
    let isOpenIndexFound = false;
    let closedSymbolIndex = 0;
    let isCloseIndexFound = false;

    for (let i = 0; i < this.description.length; i++) {
      if (this.description[i] === this.keyWrapperSymbols.OPEN_SYMBOL) {
        openSymbolIndex = i;
        isOpenIndexFound = true;
      }

      if (this.description[i] === this.keyWrapperSymbols.CLOSE_SYMBOL) {
        closedSymbolIndex = i;
        isCloseIndexFound = true;
      }

      if (isOpenIndexFound && isCloseIndexFound) {
        const { textBeforeKey, textInKey, textAfterKey } = this.getDividedText(
          openSymbolIndex,
          closedSymbolIndex,
        );

        const replacedKeyLength = closedSymbolIndex - openSymbolIndex;

        const mockData = this.reportPreviewDictionary[textInKey];

        if (mockData) {
          const mockDataLength = mockData.length;

          const iCorrectValue = mockDataLength - replacedKeyLength;

          this.description = `${textBeforeKey}${mockData}${textAfterKey}`;

          i += iCorrectValue - 2;
        }

        openSymbolIndex = 0;
        isOpenIndexFound = false;
        closedSymbolIndex = 0;
        isCloseIndexFound = false;
      }
    }
  }

  private replaceTextFormattingKeysByHTLMTag() {
    let startIndexOfStyledText = 0;
    let isStartIndexFound = false;
    let endIndexOfStyledText = 0;
    let isEndIndexFound = false;

    this.textStyleKeys.forEach(key => {
      for (let i = 0; i < this.description.length; i++) {
        if (this.description[i] === key && !isStartIndexFound) {
          startIndexOfStyledText = i;
          isStartIndexFound = true;
          i++;
        }

        if (this.description[i] === key && !isEndIndexFound) {
          endIndexOfStyledText = i;
          isEndIndexFound = true;
        }

        if (isStartIndexFound && isEndIndexFound) {
          const { textBeforeKey, textInKey, textAfterKey } = this.getDividedText(
            startIndexOfStyledText,
            endIndexOfStyledText,
          );

          const htmlTagText = this.htmlTextStyleTag[key];

          const htmlMarkUp = `<${htmlTagText}>${textInKey}</${htmlTagText}>`;

          this.description = `${textBeforeKey}${htmlMarkUp}${textAfterKey}`;

          startIndexOfStyledText = 0;
          isStartIndexFound = false;
          endIndexOfStyledText = 0;
          isEndIndexFound = false;
        }
      }
    });
  }

  getPreviewMarkUp(description: string): string {
    this.description = description;
    this.replaceStatisticKeyByMockData();
    this.replaceTextFormattingKeysByHTLMTag();
    return this.description;
  }
}

export const preview = new PreviewMaker(
  KEY_WRAPPER_SYMBOLS,
  reportPreviewDict,
  HTML_TEXT_STYLE_TAG,
  Object.values(TEXT_STYLE_SYMBOLS),
);

Object.seal<IPreviewMaker>(preview);
