import React from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { ToggleSwitch } from '@blocks/toggleSwitch';

type TReportRequireProps = {
  /**
   * Параметр отвечающий за состояние чекбокса
   * @type {boolean}
   */
  checked: boolean;
  /**
   * Хэндлер для изменения состояния чекбокса
   * @type {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Параметр отвечающий за название чекбокса
   * @type {string}
   */
  name: string;
  /**
   * Опциональный параметр отвечающий за строку классов
   * @type {string}
   * @default ''
   */
  className?: string;
};
export const ReportRequire = ({ className = '', ...props }: TReportRequireProps) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <h2 className='mb-4 text-blackText text-h4 font-medium'>Отчет о выполнении</h2>
      <div className='flex items-center mb-8'>
        <ToggleSwitch {...props} className='mr-4' />
        <div>
          <p className='text-blackText text-body_text font-medium'>Отчёт обязателен</p>
          <p className='text-textareaBlack text-inner_text'>
            Для завершения задачи требуется комментарий или файл — включите чтобы админ не смог
            закрыть задачу без отчета о выполнении.
          </p>
        </div>
      </div>
    </div>
  );
};
