import React, { useEffect, useState } from 'react';
//
import { Button } from '@uikit/Button';
import { Icon } from '@blueprintjs/core';
import { WhenTime } from '@blocks/whenTime';
import { Icon as AtomIcon } from '@atoms/icon';

type TRegularTaskDeadlineProps = {
  /**
   * Время когда задача должна быть выполнена в формате HH:MM
   * @type {string | null}
   */
  deadlineTime: string | null;
  /**
   * Обработчик изменения поля ввода времени выполнения задачи
   * @param value
   * @type {(value: string | null) => void}
   */
  onTimeChange: (value: string | null) => void;
  /**
   * Опциональный параметр строки классов
   * @type {string}
   * @default ""
   */
  className?: string;
};
export const RegularTaskDeadline = ({
  deadlineTime,
  onTimeChange,
  className = '',
}: TRegularTaskDeadlineProps) => {
  const [isShowDeadline, setShowDeadline] = useState<boolean>(false);

  useEffect(() => {
    setShowDeadline(Boolean(deadlineTime));
  }, [deadlineTime]);

  const showDeadlineHandler = () => setShowDeadline(true);
  const hideDeadlineHandler = () => {
    setShowDeadline(false);
    onTimeChange(null);
  };

  return (
    <div className={`flex flex-col md:flex-row md:items-center ${className}`}>
      {isShowDeadline ? (
        <div className='w-full'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-blackText text-h4 font-medium'>Время выполнения</h2>
            <Icon
              icon='trash'
              size={20}
              color='#4A4B57'
              onClick={hideDeadlineHandler}
              className='cursor-pointer'
            />
          </div>
          <div className='flex flex-col md:flex-row md:items-center mb-4'>
            <p className='mb-4 text-blackText text-body_text font-medium md:mr-2 md:mb-0'>
              Необходимо закрыть задачу до
            </p>
            <WhenTime
              whenTime={deadlineTime || '00:00'}
              onChangeHandler={onTimeChange}
              stepMinutes={5}
              className='max-w-fit'
            />
          </div>
          <div className='flex items-center'>
            <AtomIcon variant='warningInfo' className='mr-2' />
            <p className='text-blackText text-body_text font-medium'>
              Учитывайте время постановки задачи и время на её выполнение.
            </p>
          </div>
        </div>
      ) : (
        <>
          <Button
            dense
            icon='plus'
            type='action'
            color='default'
            view='outlined'
            text='Время выполнения'
            onClick={showDeadlineHandler}
            className='mb-4 md:mr-4 md:mb-0'
          />
          <p className='text-textareaBlack text-inner_text'>
            К дате выполнения задачи будет добавлено выбранное время.
          </p>
        </>
      )}
    </div>
  );
};
