import { TASK_TYPE } from '@const/task';
import { storageDb } from '@api/storageApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { getDateStringToISOString } from '@helpers/index';
import { TAnalyticsAdminsInitialState } from '@redux/Analytics/types';
import { TMeta, TAnalyticsAdmin, EAnalyticsChartsType, TGeneral } from '@models/Analytics';

export const reducers = {
  setAnalyticsStart(state: TAnalyticsAdminsInitialState, action: PayloadAction<string>) {
    state.start_date = action.payload;
    storageDb.setAnalyticsStart(action.payload);
  },
  setAnalyticsEnd(state: TAnalyticsAdminsInitialState, action: PayloadAction<string>) {
    state.end_date = action.payload;
    storageDb.setAnalyticsEnd(action.payload);
  },
  setSelectedTaskType(state: TAnalyticsAdminsInitialState, action: PayloadAction<TASK_TYPE>) {
    state.selectedTaskType = action.payload;
  },
  setSelectedTaskId: (state: TAnalyticsAdminsInitialState, action: PayloadAction<number>) => {
    state.selectedTaskId = action.payload;
  },
  setSelectedAdminId: (state: TAnalyticsAdminsInitialState, action: PayloadAction<number>) => {
    state.selectedAdminId = action.payload;
  },
  setSelectedCharts: (
    state: TAnalyticsAdminsInitialState,
    action: PayloadAction<Array<EAnalyticsChartsType>>,
  ) => {
    state.selectedCharts = action.payload;
  },
  setAnalyticsDates(
    state: TAnalyticsAdminsInitialState,
    action: PayloadAction<{ startDate: Date; endDate: Date }>,
  ) {
    const { startDate, endDate } = action.payload;
    if (startDate && endDate) {
      state.analyticsStartDate = getDateStringToISOString(startDate);
      state.analyticsEndDate = getDateStringToISOString(endDate);
    }
  },
};

export const getAdminsAnalyticsReducer = {
  pending: (state: TAnalyticsAdminsInitialState) => {
    state.isAdminsLoading = true;
    state.isSuccessLoadingAdmins = false;
    state.isErrorLoadingAdmins = false;
    state.adminsList = [];
    state.generalData = null;
    state.selectedTaskId = 0;
    state.selectedCharts = [];
  },
  fulfilled: (
    state: TAnalyticsAdminsInitialState,
    action: PayloadAction<{
      adminsList: TAnalyticsAdmin[];
      generalData: TGeneral;
      selectedCharts: TMeta;
    }>,
  ) => {
    const { adminsList, generalData, selectedCharts } = action.payload;
    state.adminsList = adminsList;
    state.generalData = generalData;
    state.isAdminsLoading = false;
    state.isSuccessLoadingAdmins = true;
    state.isErrorLoadingAdmins = false;
    state.selectedTaskId = generalData.plan_actual_id[0]?.template_id || 0;
    state.selectedCharts = Object.keys(
      selectedCharts.dashboard_list,
    ) as Array<EAnalyticsChartsType>;
  },
  rejected: (state: TAnalyticsAdminsInitialState) => {
    state.isAdminsLoading = false;
    state.isSuccessLoadingAdmins = false;
    state.isErrorLoadingAdmins = true;
  },
};
