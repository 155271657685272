import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { CONFIG } from '@const/config';
import { Loader } from '@blocks/loader';
import { TaskCopy } from '@blocks/taskCopy';
import { TaskType } from '@blocks/taskBlock';
import { TFilialData } from '@models/Accounts';
import { NoteCard } from '@components/noteCard';
import { EventsBlock } from '@blocks/eventsBlock';
import { ClientsBlock } from '@blocks/clientBlock';
import { RegularBlock } from '@blocks/regularBlock';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { ErrorMessage } from '@blocks/errorMessage';
import { TTaskData, TWeekDays } from '@models/Tasks';
import { setTaskTypeToPath } from '@redux/App/slice';
import { ReportRequire } from '@blocks/reportRequire';
import { TaskPerformer } from '@blocks/taskPerformer';
import { TaskPathLinks } from '@blocks/taskPathLinks';
import { TSimpleStringObjLevel2 } from '@models/index';
import { CheckListBlock } from '@components/checklistBlock';
import { ToastsMessages } from '@components/toastsMessages';
import { saveTask, saveTasksCopy } from '@redux/Task/thunks';
import { TaskDescription } from '@components/taskDescription';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { TaskAdditionalInfo } from '@blocks/taskAdditionalInfo';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { RegularTaskDeadline } from '@blocks/regularTaskDeadline';
import { AddNoteToTaskModal } from '@components/addNoteToTaskModal';
import { ReactComponent as DeleteIcon } from '@img/taskPage/reset.svg';
import { TOnChangeHandler, TOnChangeHandlerInputTextarea } from '@shared/types';
import {
  getAccountsData,
  getNoteListData,
  getSettingsData,
  getTaskPageData,
} from '@redux/selectors';
import { ReactComponent as AddChecklistIcon } from '@img/taskPage/add_checklist.svg';
import {
  BB_INTEGRATION_EVENTS,
  CRM_EVENTS,
  EVENTS_TYPES,
  PERFORMER_ASSIGMENT_RULE,
  SEND_TIME,
  sendTime,
  TASK_DATA_KEY_NAMES,
  TELEPHONY_EVENTS,
  TYPE_OF_TEMPLATE,
  WORK_STATISTICS_EVENTS,
} from '@const/task';
import {
  addKeyInTextareaTP,
  clearErrorsTP,
  clearExecutors,
  clearSuccessTP,
  deleteAddedNote,
  deleteChecklistItem,
  editChecklistItemName,
  setAbandonedTextareaDataTP,
  setAlternativeExecutor,
  setAssigmentRule,
  setBackupRule,
  setChecklistItems,
  setClientDelayed,
  setDaysAfterVisit,
  setDaysWithoutVisiting,
  setDiscountAmount,
  setIsAddChecklist,
  setLateReplyLimit,
  setMissedCallsPercents,
  setMonthSendDay,
  setNewChecklistItemName,
  setNotRecordLimit,
  setPriorityExecutor,
  setSelectedBBIntegration,
  setSelectedClientEvent,
  setSelectedCrmType,
  setSelectedEventType,
  setSelectedTelephonyType,
  setSelectedWorkStatisticsType,
  setSelectedYclientsType,
  setSendingPeriod,
  setSendOnLastDayOfMonth,
  setSendWeekDay,
  setTaskData,
  setTaskDeadline,
  setTaskDescription,
  setTaskName,
  setTaskSendFrequency,
  setTaskSendingTime,
  setTaskStatus,
  setThresholdLowEnrolledPercentage,
  setThresholdWhatsappResponseRate,
  setWhenSend,
  sortMonthSendDay,
} from '@redux/Task/slice';

interface ITaskTemplateProps {
  /**
   * Флаг ошибки при запросе данных для страницы
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает что запрос на сохранение данных процессе
   * @param {boolean}
   */
  isSaving: boolean;
  /**
   * Флаг показывает что запрос данных для страницы в процессе
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Значение инпута для ввода нового значения фильтра по сотрудникам
   * @param {string}
   */
  newStaffValue: string;
  /**
   * Параметр для формирования ссылки на соответствующий раздел задач
   * @param {string}
   */
  taskTypeToPath: string;
  /**
   * Флаг ошибки при сохранении данных
   * @param {boolean}
   */
  isSavingError: boolean;
  /**
   * Состояние интеграции с Бьюти бот
   * @param {boolean}
   */
  isBBIntegrated: boolean;
  /**
   * Флаг успешного сохранения данных
   * @param {string}
   */
  isSavingSuccess: boolean;
  /**
   * Значение инпута для ввода нового значения фильтра по услугам
   * @param {string}
   */
  newServicesValue: string;
  /**
   * Значение инпута для ввода нового значения элемента чеклиста
   * @param {string}
   */
  newChecklistItemName: string;
  /**
   * Объект с данными выбранного филиала,
   * @param {TFilialData | null}
   */
  selectedFilial: TFilialData | null;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
  /**
   * Данные страницы
   * @param {TTaskData}
   */
  data: TTaskData;
}

export const TaskTemplate = memo(
  ({
    data,
    isError,
    isSaving,
    isLoading,
    isSuccess,
    isSavingError,
    newStaffValue,
    isBBIntegrated,
    selectedFilial,
    taskTypeToPath,
    isSavingSuccess,
    newServicesValue,
    errorToastMessage,
    categoriesDataList,
    successToastMessage,
    newChecklistItemName,
  }: ITaskTemplateProps) => {
    const dispatch = useAppDispatch();
    const { data: accounts } = useAppSelector(getAccountsData);
    const { adminsTableData } = useAppSelector(getSettingsData);
    const { tags, filteredNotesList, folderList } = useAppSelector(getNoteListData);
    const {
      filialsToCopy,
      isCopyTasksError,
      isCopyTasksSuccess,
      blurFromTextareaName,
      errorToastMessage: taskErrorMessage,
      successToastMessage: taskSuccessMessage,
    } = useAppSelector(getTaskPageData);
    const {
      id,
      staff,
      notes,
      report,
      visits,
      isActive,
      deadline,
      services,
      taskName,
      taskType,
      whenSend,
      frequency,
      notRecord,
      lateReply,
      sendingTime,
      alternativeExecutor,
      assignmentRule,
      backupRule,
      priorityExecutor,
      sendWeekDays,
      sendingPeriod,
      monthSendDays,
      daysAfterVisit,
      clientDelayed,
      isAddChecklist,
      checklistItems,
      discountAmount,
      taskDescription,
      selectedCrmType,
      selectedEventType,
      daysWithoutVisiting,
      missedCallsPercents,
      selectedClientEvent,
      sendOnLastDayOfMonth,
      selectedTelephonyType,
      selectedYclientsType,
      selectedBBIntegrationType,
      selectedWorkStatisticsType,
      thresholdWhatsappResponseRate,
      thresholdLowEnrolledPercentage,
    } = data;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const showAddNoteModal = useCallback(() => {
      setIsOpen(true);
    }, []);

    const hideAddNoteModal = useCallback(() => {
      setIsOpen(false);
    }, []);

    // Сохраняется выбранная пользователем textarea для возвращения фокуса после добавления спец ключей или обрамления текста
    const [textarea, setTextarea] = useState<EventTarget & HTMLTextAreaElement>();

    // блок обработчиков, устанавливают новые значения в state
    const setWhenSendDay = useCallback(
      (weekDay: string) => () => {
        dispatch(setSendWeekDay({ weekDay: weekDay as unknown as keyof TWeekDays }));
      },
      [dispatch],
    );

    const selectWhenSend = useCallback(
      (selectedTaskType: string) => () => {
        dispatch(setTaskTypeToPath({ taskType: selectedTaskType }));
        dispatch(setWhenSend({ whenSend: selectedTaskType }));
      },
      [dispatch],
    );

    const selectSendingPeriod = useCallback(
      (period: string) => () => {
        dispatch(setSendingPeriod({ periodName: period }));
      },
      [dispatch],
    );

    const setWhenTime = useCallback(
      (value: string) => {
        dispatch(setTaskSendingTime({ time: value }));
      },
      [dispatch],
    );

    const setFrequency = useCallback(
      (value: number) => {
        dispatch(setTaskSendFrequency({ frequency: value }));
      },
      [dispatch],
    );

    const setMonthSendDayHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setMonthSendDay({ monthDays: value }));
      },
      [dispatch],
    );

    const sortMonthSendDaysHandler = useCallback(() => {
      dispatch(sortMonthSendDay());
    }, [dispatch]);

    const setSendOnLastDayOfMonthHandler: TOnChangeHandler = useCallback(
      event => {
        const { checked } = event.currentTarget;
        dispatch(setSendOnLastDayOfMonth({ checked }));
      },
      [dispatch],
    );

    const setNoVisitsDaysHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setDaysWithoutVisiting({ days: value }));
      },
      [dispatch],
    );

    const setDaysAfterVisitsHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setDaysAfterVisit({ days: value }));
      },
      [dispatch],
    );

    const setTaskNameHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setTaskName({ taskName: value }));
      },
      [dispatch],
    );

    const setTaskDescriptionHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setTaskDescription({ taskDescription: value }));
      },
      [dispatch],
    );

    const setIsAddCheckListToggleHandler = useCallback(() => {
      dispatch(setIsAddChecklist({ isAddChecklist: !isAddChecklist }));
    }, [isAddChecklist, dispatch]);

    const setNewChecklistNameHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setNewChecklistItemName({ newValue: value }));
      },
      [dispatch],
    );

    const editCheckListItemValue = useCallback(
      (oldValue: string) => (newValue: string) => {
        dispatch(editChecklistItemName({ oldValue, newValue }));
      },
      [dispatch],
    );

    const addChecklistItem = useCallback(() => {
      dispatch(setChecklistItems());
      dispatch(setNewChecklistItemName({ newValue: '' }));
    }, [dispatch]);

    const deleteChecklistItemHandler = useCallback(
      (index: number) => (value: string) => () => {
        dispatch(deleteChecklistItem({ value, index }));
      },
      [dispatch],
    );

    const setSelectedEventTypeHandler = useCallback(
      (eventType: string) => () => {
        dispatch(setSelectedEventType({ eventType }));
      },
      [dispatch],
    );

    const setSelectedTelephonyEventHandler = useCallback(
      (telephonyEvent: string) => () => {
        dispatch(setSelectedTelephonyType({ telephonyType: telephonyEvent }));
      },
      [dispatch],
    );

    const setSelectedYclientsTypeHandler = useCallback(
      (yclientsEvent: string) => () => {
        dispatch(setSelectedYclientsType({ yclientsEventType: yclientsEvent }));
      },
      [dispatch],
    );

    const setSelectedCrmTypeHandler = useCallback(
      (crmEvent: string) => () => {
        dispatch(setSelectedCrmType({ crmEventType: crmEvent }));
      },
      [dispatch],
    );

    const setDiscountHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setDiscountAmount({ value }));
      },
      [dispatch],
    );

    const setClientDelayHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setClientDelayed({ delayAmount: value }));
      },
      [dispatch],
    );

    const setSelectedBBIntegrationTypeHandler = useCallback(
      (bbIntegrationType: string) => () => {
        dispatch(setSelectedBBIntegration({ bbIntegrationType }));
      },
      [dispatch],
    );

    const setSelectedWorkStatisticsTypeHandler = useCallback(
      (workStatisticsType: string) => () => {
        dispatch(setSelectedWorkStatisticsType({ workStatisticsType }));
      },
      [dispatch],
    );

    const setMissedCallsPercentsHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setMissedCallsPercents({ value }));
      },
      [dispatch],
    );

    const setThresholdWhatsappResponseRateHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setThresholdWhatsappResponseRate({ value }));
      },
      [dispatch],
    );

    const setThresholdLowEnrolledPercentageHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setThresholdLowEnrolledPercentage({ value }));
      },
      [dispatch],
    );

    const setClientsEvent = useCallback(
      (clientEventsType: string) => () => {
        dispatch(setSelectedClientEvent({ clientEvent: clientEventsType }));
      },
      [dispatch],
    );

    const setNotRecordLimitHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setNotRecordLimit({ limit: value }));
      },
      [dispatch],
    );

    const setLateReplyLimitHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setLateReplyLimit({ limit: value }));
      },
      [dispatch],
    );

    const taskStatusToggle = useCallback(() => {
      dispatch(setTaskStatus({ status: !isActive }));
    }, [isActive, dispatch]);

    const saveTaskHandler = useCallback(() => {
      if (!filialsToCopy.length) {
        dispatch(saveTask(data));
      } else {
        dispatch(saveTasksCopy({ data, filials: filialsToCopy }));
      }
    }, [data, dispatch, filialsToCopy]);

    // Проверяем выбраны ли опции в настройках которые зависят от интеграции с бьюти ботом
    const isDisabledWhenNoBBIntegration = useMemo(
      () =>
        (selectedEventType === EVENTS_TYPES.BB_INTEGRATION && !isBBIntegrated) ||
        (selectedEventType === EVENTS_TYPES.WORK_STATISTICS &&
          !isBBIntegrated &&
          (selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE ||
            selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE)),
      [isBBIntegrated, selectedEventType, selectedWorkStatisticsType],
    );

    // Проверяем на заполнение обязательные поля в задаче
    const isTaskNoValidToSave = useMemo(
      () => !taskName || !taskDescription,
      [taskName, taskDescription],
    );

    // Обработчик устанавливает значения при потери фокуса с textarea
    const onTextareaBlurHandler = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const {
          name,
          selectionStart: startOfSelectText,
          selectionEnd: endOfSelectText,
        } = event.currentTarget;
        setTextarea(event.currentTarget);
        dispatch(
          setAbandonedTextareaDataTP({
            name,
            selectionStart: startOfSelectText,
            selectionEnd: endOfSelectText,
          }),
        );
      },
      [dispatch],
    );

    // Callback передается в компонент <TaskAdditionalInfo />
    // добавляет спец ключ в текс 'выбранной' textarea
    const setKeyInTextarea = useCallback(
      (key: string) => () => {
        if (blurFromTextareaName) {
          dispatch(addKeyInTextareaTP({ innerText: key }));
          textarea?.focus();
        }
      },
      [blurFromTextareaName, textarea, dispatch],
    );

    const deleteAddedNoteById = useCallback(
      (noteId: string) => () => {
        dispatch(deleteAddedNote(noteId));
      },
      [dispatch],
    );

    const memoizedAddChecklistButton = useMemo(
      () => (
        <Button
          dense
          type='action'
          color='default'
          view='outlined'
          text='Добавить чек-лист'
          icon={<AddChecklistIcon />}
          disabled={isDisabledWhenNoBBIntegration}
          onClick={setIsAddCheckListToggleHandler}
          className='mr-4 mb-4 w-fit'
        />
      ),
      [isDisabledWhenNoBBIntegration, setIsAddCheckListToggleHandler],
    );

    const memoizedAddNoteButton = useMemo(
      () => (
        <Button
          dense
          icon='plus'
          type='action'
          color='default'
          view='outlined'
          text='Прикрепить заметку'
          onClick={showAddNoteModal}
          disabled={data.notes.length > 2}
          className='mr-4 mb-4'
        />
      ),
      [showAddNoteModal, data.notes.length],
    );

    // eslint-disable-next-line
    const memoizedScriptButton = useMemo(
      () => (
        <Button
          dense
          type='action'
          color='default'
          view='outlined'
          icon='property'
          text='Добавить скрипт'
          className='!flex mr-4 mb-4'
        />
      ),
      [],
    );

    const typeOfTemplate =
      whenSend === SEND_TIME.CLIENTS
        ? TYPE_OF_TEMPLATE.CLIENTS
        : whenSend === SEND_TIME.EVENT &&
          selectedEventType === EVENTS_TYPES.TELEPHONY &&
          selectedTelephonyType === TELEPHONY_EVENTS.MISSED_CALL
        ? TYPE_OF_TEMPLATE.MISSED_CALLS
        : whenSend === SEND_TIME.EVENT &&
          selectedEventType === EVENTS_TYPES.BB_INTEGRATION &&
          selectedBBIntegrationType === BB_INTEGRATION_EVENTS.BAD_FEEDBACK
        ? TYPE_OF_TEMPLATE.BAD_REVIEW
        : whenSend === SEND_TIME.EVENT && selectedEventType === EVENTS_TYPES.CRM
        ? selectedCrmType === CRM_EVENTS.RECORD_DONE
          ? TYPE_OF_TEMPLATE.CRM_CLIENT_CAME
          : TYPE_OF_TEMPLATE.CRM_RECORD_CANCELED
        : TYPE_OF_TEMPLATE.COMMON;

    const hideSuccessToastHandler = useCallback(() => {
      dispatch(clearSuccessTP());
    }, [dispatch]);

    const hideErrorToastHandler = useCallback(() => {
      dispatch(clearErrorsTP());
    }, [dispatch]);

    const setReportRequireHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      dispatch(
        setTaskData({
          data: {
            ...data,
            report: checked,
          },
        }),
      );
    };

    const noteCard = notes.map(note => (
      <div key={note.id} className='relative'>
        <NoteCard cardData={note} searchString='' className='mb-2' />
        <button
          aria-label='delete note'
          onClick={deleteAddedNoteById(note.id)}
          className='absolute top-2 right-2 bg-none border-none hover:scale-125'>
          <DeleteIcon className='fill-[#9696A0] hover:fill-auChico' />
        </button>
      </div>
    ));

    const setPriorityExecutorHandler = (priorityExecutorId: number) => {
      dispatch(
        setPriorityExecutor({
          name: adminsTableData.find(admin => admin.id === priorityExecutorId)?.name || '',
          user_id: priorityExecutorId,
        }),
      );
      if (!assignmentRule) {
        dispatch(setAssigmentRule(PERFORMER_ASSIGMENT_RULE.FIRST_ADMIN));
      }
    };

    const setAssigmentRuleHandler = (value: PERFORMER_ASSIGMENT_RULE) => {
      if (value !== PERFORMER_ASSIGMENT_RULE.ALTERNATIVE_EXECUTOR) {
        dispatch(setAlternativeExecutor(null));
        dispatch(setBackupRule(null));
      }
      dispatch(setAssigmentRule(value));
    };

    const setAlternativeExecutorHandler = (alternativeExecutorId: number) => {
      dispatch(
        setAlternativeExecutor({
          name: adminsTableData.find(admin => admin.id === alternativeExecutorId)?.name || '',
          user_id: alternativeExecutorId,
        }),
      );
      if (!backupRule) {
        dispatch(setBackupRule(PERFORMER_ASSIGMENT_RULE.FIRST_ADMIN));
      }
    };

    const setBackupRuleHandler = (value: PERFORMER_ASSIGMENT_RULE) => {
      dispatch(setBackupRule(value));
    };

    const clearTaskExecutorsHandler = () => {
      dispatch(clearExecutors());
    };

    const getAdminsDataList = useMemo(() => {
      const dataSet = new Map();

      adminsTableData.forEach(adminData => {
        if (!dataSet.has(adminData.id)) {
          dataSet.set(adminData.id, adminData.name);
        }
      });

      return { staff: Object.fromEntries(dataSet) };
    }, [adminsTableData]);

    return (
      <div className='xl:grid xl:grid-cols-[1fr_32%]'>
        <div className='flex flex-col h-full max-w-[47rem] w-full'>
          {isError ? (
            <ErrorMessage />
          ) : (
            <>
              <TaskPathLinks
                id={id}
                isLoading={isLoading}
                taskTypeToPath={taskTypeToPath}
                selectedFilialAccId={selectedFilial?.accId || ''}
                className='mb-10'
              />
              {id ? (
                <p className='font-medium text-h3_body text-darkGray tracking-[0.004em] leading-8 mb-1 min-h-[2rem]'>
                  {`#${id}`}
                </p>
              ) : null}
              <H1 className='mb-4 tracking-[-0.01em]'>
                {!isLoading ? (id ? `Задача` : 'Новая задача') : 'Задача...'}
              </H1>
              {isLoading && !isError ? (
                <Loader className='grow' />
              ) : (
                <>
                  <div className='flex items-center mb-10'>
                    <ToggleSwitch
                      checked={isActive}
                      onChangeHandler={taskStatusToggle}
                      name={TASK_DATA_KEY_NAMES.IS_ACTIVE}
                      className='mr-4'
                    />
                    <div>
                      <p className='font-medium text-blackText m-0'>Действующая задача</p>
                    </div>
                  </div>
                  <p className='mb-6 text-gray2 tracking-[0.0275em]'>
                    Обязательные поля отмечены звёздочкой
                    <span className='text-[red] ml-[0.2em]'>*</span>
                  </p>
                  <div className='flex flex-col'>
                    <TaskDescription
                      type='task'
                      nameIsRequired
                      taskName={taskName}
                      taskType={taskType}
                      descriptionIsRequired
                      checklistItems={checklistItems}
                      taskDescription={taskDescription}
                      setTaskNameHandler={setTaskNameHandler}
                      disabled={isDisabledWhenNoBBIntegration}
                      templateInputPlaceholder='Опишите задачу'
                      onTextareaBlurHandler={onTextareaBlurHandler}
                      templateInputName={TASK_DATA_KEY_NAMES.TASK_NAME}
                      setTaskDescriptionHandler={setTaskDescriptionHandler}
                      templateDescription={TASK_DATA_KEY_NAMES.TASK_DESCRIPTION}
                      textareaValueMaxLength={Number(CONFIG.TASK_DESCRIPTION_MAX_LENGTH)}
                      templateTextareaPlaceholder='Детали и описание ожидаемого результата'
                      className='mb-8 max-w-[47rem]'
                    />
                    <TaskType selectedTaskType={taskType} className='mb-8 max-w-[47rem]' />
                    <div className='mb-8'>
                      <div className='flex items-baseline'>
                        <p className='text-black text-h3Mobile mb-4 mr-2'>
                          Когда создавать задачу?
                        </p>
                        <IconWithTooltips
                          tooltips='whenSendRegularTasks'
                          className='flex items-center'
                        />
                      </div>
                      <DropdownWithIcon
                        items={sendTime}
                        selectedItem={whenSend}
                        callBack={selectWhenSend}
                        className='w-full max-w-[47rem] when_send_dropdown'
                      />
                    </div>
                    {whenSend === SEND_TIME.REGULAR ? (
                      <RegularBlock
                        frequency={frequency}
                        sendingTime={sendingTime}
                        setWhenTime={setWhenTime}
                        setFrequency={setFrequency}
                        sendWeekDays={sendWeekDays}
                        monthSendDays={monthSendDays}
                        setWhenSendDay={setWhenSendDay}
                        selectedSendingPeriod={sendingPeriod}
                        selectSendingPeriod={selectSendingPeriod}
                        sendOnLastDayOfMonth={sendOnLastDayOfMonth}
                        sortMonthSendDays={sortMonthSendDaysHandler}
                        setMonthSendDayHandler={setMonthSendDayHandler}
                        setSendOnLastDayOfMonth={setSendOnLastDayOfMonthHandler}
                        className='mb-10'
                      />
                    ) : null}
                    {whenSend === SEND_TIME.EVENT ? (
                      <EventsBlock
                        staff={staff}
                        visits={visits}
                        className='mb-8'
                        services={services}
                        isSuccess={isSuccess}
                        newStaffValue={newStaffValue}
                        clientDelayed={clientDelayed}
                        commercialTargetProps='_blank'
                        discountAmount={discountAmount}
                        isBBIntegrated={isBBIntegrated}
                        selectedCrmType={selectedCrmType}
                        newServicesValue={newServicesValue}
                        selectedEventType={selectedEventType}
                        setDiscountHandler={setDiscountHandler}
                        categoriesDataList={categoriesDataList}
                        missedCallsPercents={missedCallsPercents}
                        selectedYclientsType={selectedYclientsType}
                        selectedTelephonyType={selectedTelephonyType}
                        setClientDelayHandler={setClientDelayHandler}
                        hrefForCommercial={CONFIG.BB_LANDING_PAGE_LINK}
                        selectedFilialAccId={selectedFilial?.accId || ''}
                        selectedBBIntegrationType={selectedBBIntegrationType}
                        setSelectedCrmTypeHandler={setSelectedCrmTypeHandler}
                        selectedWorkStatisticsType={selectedWorkStatisticsType}
                        setSelectedEventTypeHandler={setSelectedEventTypeHandler}
                        setMissedCallsPercentsHandler={setMissedCallsPercentsHandler}
                        thresholdWhatsappResponseRate={thresholdWhatsappResponseRate}
                        setSelectedYclientsTypeHandler={setSelectedYclientsTypeHandler}
                        thresholdLowEnrolledPercentage={thresholdLowEnrolledPercentage}
                        setSelectedTelephonyEventHandler={setSelectedTelephonyEventHandler}
                        setSelectedBBIntegrationTypeHandler={setSelectedBBIntegrationTypeHandler}
                        setSelectedWorkStatisticsTypeHandler={setSelectedWorkStatisticsTypeHandler}
                        setThresholdWhatsappResponseRateHandler={
                          setThresholdWhatsappResponseRateHandler
                        }
                        setThresholdLowEnrolledPercentageHandler={
                          setThresholdLowEnrolledPercentageHandler
                        }
                      />
                    ) : null}
                    {whenSend === SEND_TIME.CLIENTS ? (
                      <ClientsBlock
                        staff={staff}
                        visits={visits}
                        className='mb-8'
                        services={services}
                        lateReply={lateReply}
                        notRecord={notRecord}
                        isSuccess={isSuccess}
                        sendingTime={sendingTime}
                        setWhenTime={setWhenTime}
                        newStaffValue={newStaffValue}
                        daysAfterVisit={daysAfterVisit}
                        setSelectedEvent={setClientsEvent}
                        newServicesValue={newServicesValue}
                        categoriesDataList={categoriesDataList}
                        selectedClientEvent={selectedClientEvent}
                        daysWithoutVisiting={daysWithoutVisiting}
                        setLateReplyLimit={setLateReplyLimitHandler}
                        setNotRecordLimit={setNotRecordLimitHandler}
                        setNoVisitsDaysHandler={setNoVisitsDaysHandler}
                        setDaysAfterVisitsHandler={setDaysAfterVisitsHandler}
                        selectedFilialAccId={selectedFilial?.accId || ''}
                      />
                    ) : null}
                    {whenSend === SEND_TIME.REGULAR ? (
                      <>
                        <RegularTaskDeadline
                          deadlineTime={deadline}
                          onTimeChange={deadlineValue => {
                            dispatch(setTaskDeadline(deadlineValue));
                          }}
                          className='mb-10'
                        />
                        <TaskPerformer
                          selectedFilialAccId={selectedFilial?.accId || ''}
                          categoriesDataList={getAdminsDataList}
                          isSuccess={isSuccess}
                          alternativeExecutor={alternativeExecutor}
                          assignmentRule={assignmentRule}
                          backupRule={backupRule}
                          setPriorityExecutor={setPriorityExecutorHandler}
                          setAssigmentRule={setAssigmentRuleHandler}
                          setAlternativeExecutor={setAlternativeExecutorHandler}
                          setBackupRule={setBackupRuleHandler}
                          priorityExecutor={priorityExecutor}
                          clearTaskExecutors={clearTaskExecutorsHandler}
                        />
                      </>
                    ) : null}
                    <ReportRequire
                      checked={report}
                      name={TASK_DATA_KEY_NAMES.REPORT_REQUIRED}
                      onChangeHandler={setReportRequireHandler}
                      className='my-10'
                    />
                    <TaskAdditionalInfo
                      forMobile
                      typeOfTemplate={typeOfTemplate}
                      setKeyInTextarea={setKeyInTextarea}
                      className='flex lg:hidden flex-col mb-4'
                    />
                    <div className='flex flex-wrap justify-between mb-4'>
                      <div className='flex flex-wrap'>
                        {!isAddChecklist ? memoizedAddChecklistButton : null}
                        {memoizedAddNoteButton}
                      </div>
                      {/* {memoizedScriptButton} */}
                    </div>
                  </div>
                  <AddNoteToTaskModal
                    tags={tags}
                    notes={notes}
                    isOpen={isOpen}
                    folderList={folderList}
                    closeModalHandler={hideAddNoteModal}
                    filteredNotesList={filteredNotesList}
                  />
                  {isAddChecklist ? (
                    <CheckListBlock
                      checklistItems={checklistItems}
                      addChecklistItem={addChecklistItem}
                      newChecklistItemName={newChecklistItemName}
                      editCheckListItemHandler={editCheckListItemValue}
                      deleteChecklistItemHandler={deleteChecklistItemHandler}
                      setNewChecklistNameHandler={setNewChecklistNameHandler}
                      setIsAddChecklistHandler={setIsAddCheckListToggleHandler}
                    />
                  ) : null}
                  <div className='overflow-y-auto task-drawer max-h-[46.5rem] h-fit'>
                    {noteCard}
                  </div>
                  <div className='mt-16'>
                    <Button
                      dense
                      type='action'
                      view='filled'
                      color='success'
                      icon='small-tick'
                      text='Сохранить шаблон'
                      onClick={saveTaskHandler}
                      loading={isLoading || isSaving}
                      disabled={isDisabledWhenNoBBIntegration || isTaskNoValidToSave || isSaving}
                    />
                  </div>
                  <ToastsMessages
                    errorMessage={errorToastMessage || taskErrorMessage}
                    isShowErrorToast={isSavingError || isCopyTasksError}
                    successMessage={successToastMessage || taskSuccessMessage}
                    isShowSuccessToast={isSavingSuccess || isCopyTasksSuccess}
                    clearErrorCallback={hideErrorToastHandler}
                    clearSuccessStatusCallback={hideSuccessToastHandler}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div className='hidden xl:flex flex-col ml-6'>
          {id && accounts.length > 1 ? (
            <TaskCopy
              dense
              disabled={accounts.length <= 1}
              saveTemplateHandler={saveTaskHandler}
              className='flex xl:justify-end ml-4 mb-10'
            />
          ) : null}
          <TaskAdditionalInfo
            forMobile={false}
            typeOfTemplate={typeOfTemplate}
            setKeyInTextarea={setKeyInTextarea}
            className='h-full'
          />
        </div>
      </div>
    );
  },
);
