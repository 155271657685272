import React, { forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';

import Component from './Dropdown';

const propTypes = {
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `filled` `
   */
  view: PropTypes.oneOf(["filled", "smooth", "outlined", "raised"]),
  /**
   ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    "default",
    "primary",
    "warning",
    "danger",
    "success",
    "primary_alt",
    "warning_alt",
    "danger_alt",
    "success_alt"
  ]),
  className: PropTypes.string,
  text: PropTypes.element,
  /**
   * Dense size
   */
  dense: PropTypes.bool,
  position: PropTypes.oneOf([
    "bottom-left",
    "auto",
    "auto-end",
    "auto-start",
    "bottom",
    "bottom-right",
    "left",
    "left-bottom",
    "left-top",
    "right",
    "right-bottom",
    "right-top",
    "top",
    "top-left",
    "top-right",
  ]),
  content: PropTypes.element,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
}

type TComponentPropsType = InferProps<typeof propTypes>;

interface IComponentProps extends TComponentPropsType {
  [key: string]: any;
}

export const Dropdown: React.FC<IComponentProps> = forwardRef((props, ref) => <Component {...props} ref={ref} />);