import React, { useMemo } from 'react';
//
import { log } from '@helpers/index';
import { Checkbox } from '@uikit/CheckBox';
import { INPUT_VARIANTS } from '@models/index';
import { TOnChangeHandler } from '@shared/types';
import { InputWithLabel } from '@blocks/inputWithLabel';

type TAutoComponentWithLabelProps = {
  /**
   * Название для инпута
   * @param {string}
   */
  name: string;
  /**
   * Тип поля ввода
   * @param {INPUT_VARIANTS}
   */
  type: INPUT_VARIANTS;
  /**
   * Опциональный параметр активен ли чекбокс
   * @param {boolean}
   * @default false
   */
  checked?: boolean;
  /**
   * Лейбл для поля ввода
   * @param {string}
   */
  label: string;
  /**
   * Опциональный параметр значения поля ввода
   * @param {string}
   * @default ''
   */
  value?: string;
  /**
   * Опциональный параметр обработчик события изменения поля ввода
   * @param {TOnChangeHandler}
   */
  onChangeTextInput?: TOnChangeHandler;
  /**
   * Опциональный параметр для обработчика события изменения чекбокс
   * @param {TOnChangeHandler}
   */
  onChangeCheckbox?: TOnChangeHandler;
  /**
   * Опциональный параметр для обработчика события очистки поля ввода
   * @param {() => void}
   */
  clearInput?: () => void;
  /**
   * Опциональный параметр строки классов
   * @param {string}
   * @default ''
   */
  className?: string;
  /**
   * Опциональный параметр является ли поле ввода обязательным
   * @param {boolean}
   * @default false
   */
  required?: boolean;
};

export const AutoComponentWithLabel = ({
  type,
  name,
  label,
  className,
  clearInput,
  value = '',
  checked = false,
  required = false,
  onChangeCheckbox,
  onChangeTextInput,
}: TAutoComponentWithLabelProps) => {
  const renderField = useMemo(() => {
    switch (type) {
      case INPUT_VARIANTS.CHECKBOX:
        return (
          <Checkbox
            color='success'
            name={name}
            required={required}
            checked={checked}
            onChange={onChangeCheckbox}
            label={label}
          />
        );
      case INPUT_VARIANTS.TEXT:
        return (
          <InputWithLabel
            value={value}
            onChangeHandler={onChangeTextInput || log}
            clearSearchString={clearInput || log}
            htmlFor={name}
            isRequire={required}>
            {label}
          </InputWithLabel>
        );
      default:
        return null;
    }
  }, [
    name,
    type,
    value,
    label,
    checked,
    required,
    clearInput,
    onChangeCheckbox,
    onChangeTextInput,
  ]);

  return <div className={className}>{renderField}</div>;
};
