import { TDashboardDrawerDataItem } from '@models/index';

export interface IInitialState {
  appDefaultLanguage: string;
  appSelectedLanguage: string;
  taskTypeToPath: string;
  showAnalyticsDrawer: boolean;
  analyticsDrawerData: TDashboardDrawerDataItem;
  showAppDrawer: boolean;
  appDrawerData: {
    title: string;
    description: string;
  };
  isDragging: boolean;
}

export const initialState: IInitialState = {
  appDefaultLanguage: 'rus',
  appSelectedLanguage: 'rus',
  taskTypeToPath: '',
  showAnalyticsDrawer: false,
  analyticsDrawerData: {
    title: '',
    subTitle: '',
    description: {
      how: '',
      business: '',
    },
  },
  showAppDrawer: false,
  appDrawerData: {
    title: '',
    description: '',
  },
  isDragging: false,
};
