import { apiService } from '@api/ApiService';
import { END_POINTS_URL, QUERY_PARAMS } from '@const/apiConstants';
import { TEventData } from '@helpers/myTracker/type';

export const enum MY_TRACKER_EVENTS {
  TASK_TEMPLATE_CREATE = 'task_template_create',
  TASK_TEMPLATE_UPDATE = 'task_template_edit',
  REPORT_TEMPLATE_CREATE = 'report_template_create',
  REPORT_TEMPLATE_EDIT = 'report_template_edit',
  LK_PAGE_VIEW = 'lk_page_view',
  LK_PAGE_CHANGE = 'lk_page_change',
  LISTENING_RECORDING = 'listening_recording',
  START_CONNECT_TELEPHONY = 'start_connecting_telefony',
  TELEPHONY_CONNECTED = 'telefony_connected',
  NOTE_CREATE = 'note_create',
  NOTE_DELETE = 'note_delete',
  NOTE_EDIT = 'note_edit',
  FOLDER_CREATE = 'ab_folder_create',
  LK_LOGIN = 'lk_login',
}

export const enum EDIT_TYPE {
  CONTENT = 'Контент',
  NAME = 'Название',
  TAG = 'Тег',
  VISIBILITY = 'Видимость',
  MOVE_TO_FOLDER = 'Перемещение заметки в папку',
  ATTACH_NOTE = 'Прикрепление заметки к задаче',
  UNPIN_NOTE = 'Открепление заметки от задачи',
  SCHEDULE = 'Расписание',
  ACTIVATED = 'Активирован',
  DEACTIVATED = 'Деактивирован',
}

export const REPORT_TYPE: Record<string, string> = {
  day: 'День',
  week: 'Неделя',
  month: 'Месяц',
};

export const APP_DISPLAY = {
  LK: 'ЛК',
  TG: 'Telegram',
  YCLIENTS: 'YCLIENTS',
};

export const enum PAGE_NAMES {
  ANALYTICS_ADMINS = 'Аналитика - Админы',
  ANALYTICS_CALL_DETAILS = 'Аналитика - Детализация звонков',
  CALL_DETAILS = 'Детализация звонков',
  REPORTS = 'Отчеты на телефон',
  NEW_REPORT = 'Создание нового шаблона отчета',
  EDIT_REPORT = 'Редактирование шаблона отчета',
  TASKS_ALL = 'Задачи -Все',
  TASKS_REGULAR = 'Задачи - Регулярные',
  TASKS_EVENT = 'Задачи - При событии',
  TASKS_CLIENTS = 'Задачи - Клиентские',
  NEW_TASK = 'Задачи - Новая задача',
  EDIT_TASK = 'Задачи - Редактрование задачи',
  TASK_DETAIL = 'Задачи - Подробное описание задачи',
  SETTINGS_COMMON = 'Настройки -> Общее',
  SETTINGS_TELEPHONY = 'Настройки -> Телефонии',
  TELEPHONY_CONNECT_INSTRUCTION = 'Инструкции подключения телефонии',
  SETTINGS_NOTIFICATION = 'Настройки->Уведомления',
  SETTINGS_ADMINS = 'Настройки->Админы',
  SETTINGS_BLACKLIST = 'Настройки->Черный список',
  SETTINGS_BILL_DATA = 'Настройки->Данные счёта',
  PAY = 'Оплата',
}

export const PAGE_CHANGES_TYPE = {
  PERIOD: 'Период',
  ADMIN: 'Админ',
  STRUCTURE: 'Структура страницы',
  SEARCH: 'Поиск',
};

export const INTERFACE = 'lk';

const APP_ID = process.env.REACT_APP_MY_TRACKER_APP_ID || '';

export const postEvent = <T>(data: TEventData<T>) => {
  apiService
    .post(END_POINTS_URL.SEND_CUSTOM_EVENT, data, {
      params: {
        [QUERY_PARAMS.APP_ID]: APP_ID,
      },
    })
    .then(() => {
      // console.log(response.data);
    })
    .catch(() => {
      // console.error(error.response);
    });
};
