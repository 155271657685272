import React, { memo } from 'react';
//
import { Checkbox } from '@uikit/CheckBox';
import { TFilialData } from '@models/Accounts';

type TTaskCheckboxProps = {
  /**
   * Значение параметра name в input для onChangeHandlers
   * @param {string}
   */
  name: string;
  /**
   * Текст для label для input
   * @param {string}
   */
  text: string;
  /**
   * Значение checked для input
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Callback для обработки события onChange
   */
  onChangeHandler: (filial: TFilialData) => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Опциональный параметр disabled
   * @param {boolean}
   * @default
   */
  disabled?: boolean;
  /**
   * Опциональный параметр dense
   * @param {boolean}
   * @default
   */
  dense?: boolean;
};

export const TaskCopyCheckbox = memo(
  ({
    text,
    name,
    checked,
    onChangeHandler,
    className = '',
    disabled = false,
    dense = false,
  }: TTaskCheckboxProps) => {
    return (
      <div className='py-1 px-2' style={dense ? {} : { width: 600 }}>
        <div className={`flex items-center rounded min-h-7 ${checked ? 'bg-aquaSpring' : ''}`}>
          <Checkbox
            fill
            name={name}
            label={
              <div className='text-ellipsis overflow-hidden max-w-[12rem] sm:max-w-[20rem]'>
                {text}
              </div>
            }
            color='success'
            checked={checked}
            className={className}
            onChange={onChangeHandler}
            disabled={disabled}
          />
        </div>
      </div>
    );
  },
);
