import React, { useEffect, useRef } from 'react';
//
import { preview } from '@helpers/previewMaker';

interface IPreviewComponent {
  /**
   * Описание задачи
   * @param {string}
   */
  description: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const PreviewComponent = ({ description, className = '' }: IPreviewComponent) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (description && ref.current) {
      ref.current.innerHTML = preview.getPreviewMarkUp(description);
    }
  }, [description]);

  return <div className={className} ref={ref} />;
};
