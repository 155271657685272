import { apiService } from '@api/ApiService';
import { TRequestStatus } from '@api/types';
import { END_POINTS_URL } from '@const/apiConstants';
import { TPayerData, TPayLinkResponse, TPayResponseData } from '@models/Pay';

/**
 * API страницы оплаты
 * @module payApi
 */

export const payApi = {
  /**
   * Выполняет запрос за данными страницы
   * @method getPayData
   * @return {Promise<AxiosResponse<TRequestStatus | TPayResponseData, any>>}
   */
  getPayData() {
    return apiService.get<TPayResponseData | TRequestStatus>(END_POINTS_URL.PAY);
  },
  getPayerData() {
    return apiService.get<TPayerData | TRequestStatus>(END_POINTS_URL.PAYER_DATA);
  },
  setPayerData(data: TPayerData) {
    return apiService.post<void | TRequestStatus>(END_POINTS_URL.PAYER_DATA, data);
  },
  getPayLink(months: number) {
    return apiService.get<TPayLinkResponse | TRequestStatus>(END_POINTS_URL.PAY_LINK, { months });
  },
  getInvoiceLink({
    months,
    pay_all,
    in_telegram,
  }: {
    months: number;
    pay_all: boolean;
    in_telegram: boolean;
  }) {
    return apiService.get<TPayLinkResponse | TRequestStatus>(END_POINTS_URL.PAY_INVOICE, {
      months,
      pay_all,
      in_telegram,
    });
  },
};
