import React, { memo } from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { IWithChildren } from '@models/index';

interface IButtonsProps extends IWithChildren {
  /**
   * Href в функцию для перехода при клике на кнопку
   * @param {string}
   */
  href: string;
  /**
   * Опциональный параметр targetBlank,
   * как открывать ссылку в том же окне или в новом
   */
  targetBlank?: boolean;
  /**
   * Опциональный callback срабатывает при нажатии на кнопку
   * @param {() => void}
   */
  callback?: () => void;
  /**
   * Опциональный параметр показывает иконку
   * @param {'logout' | 'phone' | 'telegramCyan'}
   */
  icon?: 'logout' | 'phone' | 'telegramCyan';
  /**
   * Параметр настраивает внешний вид кнопки
   * @param {string}
   * @default
   */
  view?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const LinkButton = memo(
  ({
    href,
    icon,
    children,
    className = '',
    targetBlank,
    view = 'outlined',
    callback = () => '',
  }: IButtonsProps) => {
    // переход на новую странице по клику опции перехода передаются в пропсах
    const changePageOnClick = () => {
      const target = targetBlank ? '_blank' : '_self';
      callback();
      const newWindow = window.open(href, target, 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    return (
      <Button
        dense
        type='action'
        view={view}
        color='default'
        text={children}
        className={className}
        onClick={changePageOnClick}
        icon={icon && <Icon variant={icon} className='h-6 w-6' />}
      />
    );
  },
);
