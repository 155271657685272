import React, { useEffect, useState } from 'react';
//
import { TOnChangeHandler } from '@shared/types';
import { ReactComponent as SuccessIcon } from '@img/settings/connected.svg';
import { TelephonyAlmostDoneMessage } from '@blocks/telephonyAlmostDoneMessage';
import { TelephonyConnectionErrorMessage } from '@blocks/telephonyConnectionErrorMessage';

type TSettingsTelephonyMessagesProps = {
  /**
   * Ссылка на webhook
   * @param {boolean}
   */
  webhook: string;
  /**
   * Флаг успешного подключения телефонии
   * @param {boolean}
   */
  isSuccess: boolean;
  /**
   * Флаг сообщает что телефония почти подключена,
   * пользователю показывается сообщение с подтверждением настройки webhook
   * @param {boolean}
   */
  isAlmostDone: boolean;
  /**
   * Ссылка на инструкцию по подключению телефонии
   * @param {string}
   */
  instructionLink: string;
  /**
   * Флаг ошибки подключения телефонии
   * @param {boolean}
   */
  isConnectionError: boolean;
  /**
   * Callback для подтверждения настройки webhook
   * @param {() => void}
   */
  webhookSetupConfirm: () => void;
  /**
   * Проверяет возможность подключения телефонии с указанными данными
   * @param {() => void}
   */
  checkConnection: () => void;
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const SettingsTelephonyMessages = ({
  webhook,
  isSuccess,
  isLoading,
  isAlmostDone,
  instructionLink,
  checkConnection,
  isConnectionError,
  webhookSetupConfirm,
  className = '',
}: TSettingsTelephonyMessagesProps) => {
  const [iAgree, setIAgree] = useState(false);

  useEffect(() => {
    if (iAgree && isConnectionError) {
      setIAgree(false);
    }
  }, [iAgree, isConnectionError]);

  // Устанавливает/снимает флаг подтверждения пользователем настройки вебхука
  const setIAgreeHandler: TOnChangeHandler = event => {
    const { checked } = event.currentTarget;
    setIAgree(checked);
  };

  return (
    <div className={`flex items-center justify-center ${className}`}>
      {isSuccess ? (
        <div className='flex flex-col items-center justify-center py-[5.75rem]'>
          <SuccessIcon className='mb-8' />
          <p className='text-black leading-6 tracking-[0.0275em]'>
            Интеграция с телефонией настроена
          </p>
        </div>
      ) : null}
      {isAlmostDone ? (
        <TelephonyAlmostDoneMessage
          iAgree={iAgree}
          webhook={webhook}
          isLoading={isLoading}
          setIAgreeHandler={setIAgreeHandler}
          webhookSetupConfirm={webhookSetupConfirm}
        />
      ) : null}
      {isConnectionError ? (
        <TelephonyConnectionErrorMessage
          isLoading={isLoading}
          checkConnection={checkConnection}
          instructionLink={instructionLink}
          className='mt-9'
        />
      ) : null}
    </div>
  );
};
