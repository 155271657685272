import React from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { getSettingsData } from '@redux/selectors';
import { TELEPHONY_CALL_TYPE_FILTER } from '@const/dashboard';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { setFirstFilter } from '@redux/Settings/slice';

interface ITelephonyFilterProps {
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const TelephonyFilter = ({ className = '' }: ITelephonyFilterProps) => {
  const dispatch = useAppDispatch();
  // const { firstFilterValue, secondFilterValue } = useAppSelector(getTelephonyData);
  const { firstFilterValue } = useAppSelector(getSettingsData);

  const setFirstFilterItem = (value: string) => () => {
    dispatch(setFirstFilter(value));
  };

  // const setSecondFilterItem = (value: string) => () => {
  //   dispatch(setSecondFilter(value));
  // };

  // TODO сократить список в выпадающем меню до минимально необходимого, убрать 'Новые входящие', 'Новые исходящие',
  // TODO ждать зеленого свистка от Ильи или Никиты
  const firstFilterItemRoRender = Object.entries(TELEPHONY_CALL_TYPE_FILTER).map(item => (
    <MenuItem key={item[0]} text={<span>{item[1]}</span>} onClick={setFirstFilterItem(item[0])} />
  ));

  // const secondFilterItemRoRender = Object.entries(TELEPHONY_CLIENT_TYPE).map(item => (
  //   <MenuItem key={item[0]} text={<span>{item[1]}</span>} onClick={setSecondFilterItem(item[0])} />
  // ));

  const firstLabel = TELEPHONY_CALL_TYPE_FILTER[firstFilterValue] || '';
  // const secondLabel = TELEPHONY_CLIENT_TYPE[secondFilterValue] || '';

  return (
    <div className='flex flex-wrap max-w-[16rem] w-full'>
      <div className='w-full max-w-[16rem]'>
        <Dropdown
          disabled={firstFilterItemRoRender.length < 1}
          view='outlined'
          color='default'
          position='bottom-left'
          className={`nav_dropdown w-full ${className}`}
          text={<span>{firstLabel}</span>}
          content={<Menu view='raised'>{firstFilterItemRoRender}</Menu>}
        />
      </div>
      {/* <div className='w-full max-w-[13rem]'>
        <Dropdown
          disabled={firstFilterItemRoRender.length < 1}
          view='outlined'
          color='default'
          position='bottom-left'
          className={`nav_dropdown w-full ${className}`}
          text={<span>{secondLabel}</span>}
          content={<Menu view='raised'>{secondFilterItemRoRender}</Menu>}
        />
      </div> */}
    </div>
  );
};
