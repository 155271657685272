import React from 'react';
//
import { Icon } from '@atoms/icon';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { CONFIG } from '@const/config';

interface IRemindTelegramIdModalProps {
  /**
   * URL для кнопки "Перейти"
   * @param {string}
   */
  link: string;
  /**
   * Текст в кнопку открытия модалки
   * @param {string}
   */
  buttonText: string;
  /**
   * Переменная состояния модалки
   * @param {boolean}
   */
  isModalOpen: boolean;
  /**
   * Тогл для модалки
   * @param {() => void}
   */
  modalToggle: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const RemindTelegramIdModal = ({
  link,
  buttonText,
  isModalOpen,
  modalToggle,
  className = '',
}: IRemindTelegramIdModalProps) => {
  const openLinkByClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div className={className}>
      <button
        onClick={modalToggle}
        className='bg-none border-none leading-5 text-green1 text-inner_text tracking-[0.018em] underline text-center w-full'>
        {buttonText}
      </button>
      <Dialog
        type='default'
        view='raised'
        color='default'
        title='Напомнить телеграм ID'
        text={
          <div className='flex flex-col items-center'>
            <Icon variant='qrCodeTelegramLink' className='mb-4 h-[13.75rem] w-[13.75rem]' />
            <p className='mb-6 text-body2 text-myrtle tracking-[0.0275em] leading-6'>
              Чтобы узнать ваш телеграм id, перейдите по ссылке в телеграм и запустите бота
            </p>
            <a
              target='_blank'
              rel='noreferrer noopener'
              href={CONFIG.PIN_BOT_LINK}
              className='text-body2 text-green1 tracking-[0.0275em] no-underline hover:text-green1 w-full'>
              {CONFIG.PIN_BOT_LINK}
            </a>
          </div>
        }
        backdropOpacity={40}
        leftButton={
          <Button
            dense
            type='action'
            text='Закрыть'
            view='outlined'
            color='default'
            onClick={modalToggle}
            className='!ml-[182%]'
          />
        }
        rightButton={
          <Button
            dense
            type='action'
            view='filled'
            color='success'
            text='Перейти'
            onClick={openLinkByClick}
          />
        }
        isOpen={isModalOpen}
        onClose={modalToggle}
      />
    </div>
  );
};
