import React from 'react';
import { useSearchParams } from 'react-router-dom';
//
import { H1 } from '@atoms/h1';
import { PATH_NAMES } from '@const/httpConst';
import { getDashboardPageData } from '@redux/selectors';
import { TableWrapper } from '@components/tableWrapper';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DashboardPathLinks } from '@blocks/dashboardPathLinks';
import { DateRangeDashboardPicker } from '@components/dateRangePicker';
import {
  setMastersDates,
  setSelectedTaskFilterName,
  setSelectedReturnMasterRateFilterName,
} from '@redux/Dashboard/slice';
import {
  DASHBOARD_KEYS,
  MASTERS_CHART_NAMES,
  MASTERS_CHART_TITLES,
  mastersTasksFilterName,
  mastersReturnMasterRateFilterNames,
} from '@const/dashboard';
import { TOnChangeHandler } from '@shared/types';

interface IMasterTableProps {
  /**
   * Id выбранного филиала
   * @param {string}
   */
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const MasterTable = ({ selectedFilialAccId, className = '' }: IMasterTableProps) => {
  const [searchParams] = useSearchParams();
  const tableName = searchParams.get('table_name');
  const dispatch = useAppDispatch();
  const {
    data,
    mastersEndDate,
    mastersStartDate,
    selectedTaskFilterName,
    selectedReturnMasterRateFilterName,
  } = useAppSelector(getDashboardPageData);

  const setSelectedReturnMasterRateFilterNameHandler = (filterName: string) => {
    dispatch(setSelectedReturnMasterRateFilterName(filterName as DASHBOARD_KEYS));
  };

  const setSelectedTaskFilterNameHandler = (filterName: string) => {
    dispatch(setSelectedTaskFilterName(filterName as DASHBOARD_KEYS));
  };

  const setDateRangeHandler = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    dispatch(setMastersDates({ startDate, endDate }));
  };

  const setSearchStringHandler: TOnChangeHandler = () => {
    // const { value } = event.currentTarget;
    // dispatch(setPhoneSearchString(value));
  };

  const startSearchHandler = () => {
    // console.log('startSearchHandler');
  };

  const clearSearchStringHandler = () => {
    // dispatch(setPhoneSearchString(''));
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <DashboardPathLinks
        className='mb-10'
        secondPageName='Мастера'
        secondPageLink={PATH_NAMES.MASTERS}
        thirdPageName={MASTERS_CHART_TITLES[tableName || '']}
        selectedFilialAccId={selectedFilialAccId}
      />
      <H1 className='mb-10 tracking-[-0.01em]'>{MASTERS_CHART_TITLES[tableName || '']}</H1>
      <div className='flex flex-wrap items-center justify-between mb-10'>
        <DateRangeDashboardPicker
          startDate={mastersStartDate}
          endDate={mastersEndDate}
          setSendingDate={setDateRangeHandler}
        />
      </div>
      {[tableName].includes(MASTERS_CHART_NAMES.RETURN_MASTER_RATE) ? (
        <TableWrapper
          isChartSetupModule
          data={data.masters}
          isChartTypeSwitcherModule
          chartDataKeyName={tableName || ''}
          selectedFilterName={selectedReturnMasterRateFilterName}
          chartFilterNames={mastersReturnMasterRateFilterNames}
          callback={setSelectedReturnMasterRateFilterNameHandler}
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
        />
      ) : null}
      {[tableName].includes(MASTERS_CHART_NAMES.TASKS) ? (
        <TableWrapper
          isChartSetupModule
          data={data.masters}
          isChartTypeSwitcherModule
          chartDataKeyName={tableName || ''}
          selectedFilterName={selectedTaskFilterName}
          chartFilterNames={mastersTasksFilterName}
          callback={setSelectedTaskFilterNameHandler}
          startSearchHandler={startSearchHandler}
          clearSearchStringHandler={clearSearchStringHandler}
          searchString=''
          setSearchStringHandler={setSearchStringHandler}
        />
      ) : null}
    </div>
  );
};
