import { createSlice } from '@reduxjs/toolkit';
//
import {
  getSettings,
  postSettings,
  getAdminsTableData,
  saveAdminsTableData,
  getNotificationsThunk,
  saveNotificationsThunk,
  getSettingsPageStaffList,
  getSmartScheduleTableData,
  getSettingsTelephonyThunk,
  testSettingsTelephony,
  saveSettingsTelephony,
  getTelephonyPageData,
} from '@redux/Settings/thunk';
import { initialState } from '@redux/Settings/state';
import {
  reducers,
  getSettingsReducer,
  postSettingsReducer,
  saveNotificationsReducer,
  getAdminsTableDataReducer,
  saveAdminsTableDataReducer,
  getNotificationsThunkReducer,
  getSettingsPageStaffListReducer,
  getSmartScheduleTableDataReducer,
  getSettingsTelephonyReducer,
  testSettingsTelephonyReducer,
  saveSettingsTelephonyReducer,
  getTelephonyPageDataReducer,
} from '@redux/Settings/reducers';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(getSettings.pending, getSettingsReducer.pending);
    builder.addCase(getSettings.fulfilled, getSettingsReducer.fulfilled);
    builder.addCase(getSettings.rejected, getSettingsReducer.rejected);
    builder.addCase(postSettings.pending, postSettingsReducer.pending);
    builder.addCase(postSettings.fulfilled, postSettingsReducer.fulfilled);
    builder.addCase(postSettings.rejected, postSettingsReducer.rejected);
    builder.addCase(getSettingsPageStaffList.pending, getSettingsPageStaffListReducer.pending);
    builder.addCase(getSettingsPageStaffList.fulfilled, getSettingsPageStaffListReducer.fulfilled);
    builder.addCase(getSettingsPageStaffList.rejected, getSettingsPageStaffListReducer.rejected);
    builder.addCase(getSmartScheduleTableData.pending, getSmartScheduleTableDataReducer.pending);
    builder.addCase(
      getSmartScheduleTableData.fulfilled,
      getSmartScheduleTableDataReducer.fulfilled,
    );
    builder.addCase(getSmartScheduleTableData.rejected, getSmartScheduleTableDataReducer.rejected);
    builder.addCase(getAdminsTableData.pending, getAdminsTableDataReducer.pending);
    builder.addCase(getAdminsTableData.fulfilled, getAdminsTableDataReducer.fulfilled);
    builder.addCase(getAdminsTableData.rejected, getAdminsTableDataReducer.rejected);
    builder.addCase(saveAdminsTableData.pending, saveAdminsTableDataReducer.pending);
    builder.addCase(saveAdminsTableData.fulfilled, saveAdminsTableDataReducer.fulfilled);
    builder.addCase(saveAdminsTableData.rejected, saveAdminsTableDataReducer.rejected);
    builder.addCase(getNotificationsThunk.pending, getNotificationsThunkReducer.pending);
    builder.addCase(getNotificationsThunk.fulfilled, getNotificationsThunkReducer.fulfilled);
    builder.addCase(getNotificationsThunk.rejected, getNotificationsThunkReducer.rejected);
    builder.addCase(saveNotificationsThunk.pending, saveNotificationsReducer.pending);
    builder.addCase(saveNotificationsThunk.fulfilled, saveNotificationsReducer.fulfilled);
    builder.addCase(saveNotificationsThunk.rejected, saveNotificationsReducer.rejected);
    builder.addCase(getSettingsTelephonyThunk.pending, getSettingsTelephonyReducer.pending);
    builder.addCase(getSettingsTelephonyThunk.fulfilled, getSettingsTelephonyReducer.fulfilled);
    builder.addCase(getSettingsTelephonyThunk.rejected, getSettingsTelephonyReducer.rejected);
    builder.addCase(testSettingsTelephony.pending, testSettingsTelephonyReducer.pending);
    builder.addCase(testSettingsTelephony.fulfilled, testSettingsTelephonyReducer.fulfilled);
    builder.addCase(testSettingsTelephony.rejected, testSettingsTelephonyReducer.rejected);
    builder.addCase(saveSettingsTelephony.pending, saveSettingsTelephonyReducer.pending);
    builder.addCase(saveSettingsTelephony.fulfilled, saveSettingsTelephonyReducer.fulfilled);
    builder.addCase(saveSettingsTelephony.rejected, saveSettingsTelephonyReducer.rejected);
    builder.addCase(getTelephonyPageData.pending, getTelephonyPageDataReducer.pending);
    builder.addCase(getTelephonyPageData.fulfilled, getTelephonyPageDataReducer.fulfilled);
    builder.addCase(getTelephonyPageData.rejected, getTelephonyPageDataReducer.rejected);
  },
});

export const settingsReducer = settingsSlice.reducer;

export const {
  addBalloonSP,
  clearPostError,
  deleteBalloonSP,
  setParamsCalcStat,
  changeAdminStatus,
  setSelectedRights,
  setSortTypeDataSP,
  clearSuccessStatus,
  setCustomerChurnTime,
  setChurnTimeAllowDelay,
  setIsChurnTimeAllowEdit,
  setSortTypeDataSPAdmins,
  setSettingsNotification,
  changeSettingsInputValue,
  clearAdminsTableStatuses,
  changeSettingsCheckboxChecked,
  clearNotificationsStatus,
  setApiKey,
  setDomain,
  setEditMode,
  setFormStep,
  setTelephonyData,
  setConnectionType,
  setTelephonyNumber,
  setSelectedOperator,
  setTextInputValue,
  setBooleanInputValue,
  setTableData,
  setFirstFilter,
  setCurrentPage,
  setSecondFilter,
  setPaginationSize,
  setPhoneSearchString,
  setSortedColumnAndOrder,
} = settingsSlice.actions;
