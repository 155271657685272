import React from 'react';
import { Icon } from '@blueprintjs/core';
//
import { THelpKeys } from '@models/index';
import { IconWithTooltips } from '@components/iconWithTooltips';

interface IKeyButtonProps {
  /**
   * Название спец ключа отображается в {}
   * @param {string}
   */
  keyName: string;
  /**
   * Описание спец ключа
   * @param {string}
   */
  keyDescription: string;
  /**
   * Опциональный параметр добавляет иконку с всплывающей подсказкой в конце строки
   * @param {boolean}
   */
  isAddInfoIcon?: boolean;
  /**
   * Опциональный параметр по переданному ключу выбирается подсказка из словаря
   * @param {THelpKeys}
   */
  tooltipVariant?: THelpKeys;
  /**
   * Callback вызывается при клике на компонент
   * @param {() => void}
   */
  callback: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const KeyButton = ({
  keyName,
  callback,
  isAddInfoIcon,
  tooltipVariant,
  keyDescription,
  className = '',
}: IKeyButtonProps) => (
  <div
    onClick={callback}
    className={`group bg bg-gray1 cursor-pointer flex items-center justify-between p-2 pr-5 rounded ${className}`}>
    <div className='max-w-[85%]'>
      <span className='group-hover:italic font-normal text-gray3'>{`{${keyName}}`}</span>
      <p className='group-hover:italic flex font-medium text-gray2 leading-5 tracking-[0.018em]'>
        <span className={isAddInfoIcon && tooltipVariant ? 'mr-2' : ''}>{keyDescription}</span>
        {isAddInfoIcon && tooltipVariant ? <IconWithTooltips tooltips={tooltipVariant} /> : null}
      </p>
    </div>
    <Icon icon='plus' iconSize={14} />
  </div>
);
