import { TReportInfo } from '@models/index';
import { PATH_NAMES } from '@const/httpConst';
import { TReportCardData, TReportData, TReportResponseData } from '@models/Report';

export const mappers = {
  responseToStore: ({
    id,
    time,
    body,
    title,
    period,
    active,
    tg_notify,
    wa_notify,
    stats_type,
    send_to_staff,
  }: TReportResponseData): TReportData => {
    const telegramRecipients = tg_notify.map(item => String(item));

    return {
      id: String(id),
      isActive: active,
      sendingTime: time,
      selectedDataCalculatingPeriod: stats_type,
      selectedReportSendPeriod: String(period),
      reportDescription: body,
      sendingDate: '',
      reportName: title || '',
      telegramRecipients,
      whatsappRecipients: wa_notify,
      selectedEveryWeekSendingPeriod: String(period),
      selectedEveryMonthSendingPeriod: String(period),
      sendToAllStaff: send_to_staff,
    };
  },
  storeToRequest: ({
    id,
    isActive,
    reportName,
    sendingTime,
    sendToAllStaff,
    reportDescription,
    telegramRecipients,
    whatsappRecipients,
    selectedReportSendPeriod,
    selectedDataCalculatingPeriod,
  }: TReportData): TReportResponseData => {
    const tg_notify = telegramRecipients ? telegramRecipients.map(item => Number(item)) : [];

    return {
      id: id ? Number(id) : 0,
      active: isActive,
      title: reportName,
      body: reportDescription,
      stats_type: selectedDataCalculatingPeriod,
      period: Number(selectedReportSendPeriod),
      time: sendingTime,
      wa_notify: whatsappRecipients,
      tg_notify,
      send_to_staff: sendToAllStaff,
    };
  },
  reportListToStore: (data: TReportCardData[], accId: string): TReportInfo[] => {
    return data.map(item => ({
      active: item.state,
      id: String(item.id),
      name: item.title,
      description: item.body,
      link: `${PATH_NAMES.ONE_REPORTS_TEMPLATE}?acc_id=${accId}&stats_id=${item.id}`,
      whenSend: item.time,
    }));
  },
};
