import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Title,
  Colors,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';
//
import { FormattedData } from '@models/index';
import { itemSort, noDataChartPlugin } from '@helpers/index';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  Title,
  Tooltip,
  Legend,
);

interface ILineChartProps {
  /**
   * Данные для графика
   * @param {any[]}
   */
  data: FormattedData;
  /**
   * Заголовок графика
   * @param {string}
   */
  chartTitle: string;
  /**
   * Опциональный параметр - высота графика
   * @param {string}
   */
  chartHeight?: string;
  /**
   * Опциональный параметр, позиция легенды на графике
   * @param {'bottom' | 'left' | 'top' | 'right' | 'center' | undefined}
   */
  chartLegendPosition?: 'bottom' | 'left' | 'top' | 'right' | 'center' | undefined;
}

export const LineChart = ({
  data,
  chartTitle,
  chartHeight,
  chartLegendPosition,
}: ILineChartProps) => {
  const height = (data?.datasets.length || 0) * 18;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      position: 'nearest' as const,
      tooltip: {
        itemSort,
        enabled: true,
      },
      legend: {
        position: `${chartLegendPosition || 'bottom'}` as const,
      },
      title: {
        display: false,
        text: chartTitle,
        position: 'top' as const,
      },
    },
  };

  return (
    <div
      className='chart-container'
      style={{
        position: 'relative',
        height: `${chartHeight || `clamp(420px, ${height}px, 1000px)`}`,
        width: `clamp(200px, 99%, 1270px)`,
      }}>
      <Line options={options} data={data} plugins={[noDataChartPlugin]} />
    </div>
  );
};
