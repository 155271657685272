import { z } from 'zod';
import {
  TFinancesDashboardData,
  TMastersDashboardData,
  TAdminsDashboardData,
} from '@api/dashboard/types';
import { TAuthResponse } from '@models/Auth';

export type TGetRequestData = {
  userId: number;
  crmUserId: number;
  accId: string;
  branchId: number;
  accessToken: string;
};

export type TShortGetRequestData = Pick<TGetRequestData, 'userId' | 'accessToken'>;

export type TRejectActionPayload = TRejectResponse | TRejectResponse2 | undefined;

export const RejectResponseSchema = z.object({
  ok: z.boolean(),
  status: z.string(),
});

export type TRejectResponse = z.infer<typeof RejectResponseSchema>;

export enum REJECT_RESPONSE_KEY {
  OK = 'ok',
  STATUS = 'status',
}

export const RejectResponse2Schema = z.object({
  detail: z.string(),
});

export type TRejectResponse2 = z.infer<typeof RejectResponse2Schema>;

export enum REJECT_RESPONSE_2_KEY {
  DETAIL = 'detail',
}

export enum AUTH_RESPONSE_KEYS {
  ACCESS_TOKEN = 'access_token',
  USER_ID = 'user_id',
  AUTH = 'auth',
}

export const AuthErrorResponseSchema = z.object({
  message: z.string(),
});

export type TAuthErrorResponse = z.infer<typeof AuthErrorResponseSchema>;

export enum AUTH_ERROR_RESPONSE_KEY {
  MESSAGE = 'message',
}

export type TFilialDataResponse = {
  name: string;
  quantity_of_templates: number;
  acc_id: string;
  branch_id: number;
  process: boolean;
  pay_period: string;
  domain: string;
  beauty_bot_integration: boolean;
  role: string;
  permissions: {
    settings: boolean;
    analytics: boolean;
    templates: boolean;
    settings_admins: boolean;
  };
};

type TWithAccessToken = Pick<TAuthResponse, 'access_token'>;

export type TBannerData = {
  image_url: string;
  info_link: string;
};

export interface IGetResponseWithDataAndAccessToken<T> extends TWithAccessToken {
  count: number;
  data: T;
  banner_data: TBannerData;
}

export type TGetResponseWithData<T> = {
  data: T;
  order: string[];
};

export enum ACCOUNT_RESPONSE_KEY {
  COUNT = 'count',
  ACCESS_TOKEN = 'access_token',
}

export enum TASKS_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export type TRequestData<T> = { data: T };

export enum TASK_RESPONSE_KEYS {
  ID = 'id',
  ACTIVE = 'active',
  TITLE = 'title',
  BODY = 'body',
  TIME = 'time',
  MARK = 'mark',
  CHECKLIST = 'checklist',
  FREQUENCY = 'frequency',
  WEEK = 'week',
  MONTH = 'month',
  EVENT = 'event',
  LAST_VISITS = 'last_visits',
  NOT_RECORD = 'not_record',
  LATE_REPLY = 'late_reply',
  SERVICES = 'services',
  STAFF = 'staff',
  VISITS = 'visits',
}

export enum TASK_EVENT_NAMES {
  MISSED_CALL = 'missed_call',
  BAD_REVIEW = 'bad_review',
  RECORD_DONE = 'record_done',
  RECORD_CANCELLED = 'record_cancelled',
}

export enum REPORT_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export enum BLACKLIST_RESPONSE_KEYS {
  DATA = 'data',
}

export type TSettingsTelephony = {
  name: string;
  token: string;
  domain: string;
  telnum: number;
};

export enum SETTINGS_RESPONSE_KEY {
  CALL_STATS = 'call_stats',
}

export enum T_SETTINGS_TELEPHONY_RESPONSE_KEYS {
  DATA = 'data',
  TELEPHONY_LIST = 'telephony_list',
}

export enum T_STAFF_LIST_RESPONSE_KEYS {
  COUNT = 'count',
  DATA = 'data',
}

export const RequestStatusSchema = z.object({
  ok: z.boolean(),
  status: z.string(),
});

export type TRequestStatus = z.infer<typeof RequestStatusSchema>;
export type TPaymentsItem = {
  payment_date: string;
  paid_till: string;
  amount: number;
};

export type TPayResponseData = {
  branch_name: string;
  branch: string;
  base_price: number;
  employees: number;
  bonuses: {
    m3bonus: number;
    m6bonus: number;
    m12bonus: number;
  };
  base_price_with_bonus: {
    m3bonus: number;
    m6bonus: number;
    m12bonus: number;
  };
  payments: TPaymentsItem[];
  process: boolean;
  pay_date: string;
  discount: number;
};

export enum PAY_RESPONSE_KEY {
  BRANCH_NAME = 'branch_name',
  BRANCH = 'branch',
  BASE_PRICE = 'base_price',
  EMPLOYERS = 'employees',
  LINKS = 'links',
  BONUSES = 'bonuses',
  PAYMENTS = 'payments',
  PROCESS = 'process',
  PAY_DATE = 'pay_date',
  INN = 'inn',
  LINK = 'link',
}

export type TGetPayLinkResponse = {
  ok: boolean;
  link: string;
};

export type TStatisticsTableData = {
  answers: number;
  date: string;
  recipients: number;
  sign_up: number;
  summ: number;
  text: string;
  time: string;
};

export interface IStatisticsTableRequestData extends TGetRequestData {
  page: number;
  sortType: string;
  linePerPage: number;
  sortCollumName: string;
}

export type TDashboardNames = 'finance' | 'admins' | 'masters' | 'main';

export enum DASHBOARD_TYPES {
  FINANCE = 'finance',
  ADMINS = 'admins',
  MASTERS = 'masters',
  MAIN = 'main',
}

export type TDashboardList = {
  service_category: boolean;
  'all_amount.masters': boolean;
  amount_service_rate: boolean;
  op_records: boolean;
  'earned_by_out_calls.service_category': boolean;
  'earned_by_out_calls.masters': boolean;
  'earned_by_out_calls.admins': boolean;
  masters: boolean;
  return_new_client_rate: boolean;
  general_client_rate: boolean;
  churn_by_master: boolean;
  created: boolean;
  'filters.tasks_completed': boolean;
  'filters.completion_time': boolean;
  'filters.tasks_missed': boolean;
  all_out_calls: boolean;
  'filters.missed_calls': boolean;
  'filters.new_clients': boolean;
  'filters.all_records': boolean;
  wa_average_time: boolean;
};

export type IDashboardRequestData = {
  type: TDashboardNames;
  startDate: string;
  endDate: string;
  force?: boolean;
};

export type TDashboardDataItem = Record<string, string | number>;

export type TStackedBarData = {
  [p: string]: string | number | null;
  date: string;
  value: number | null;
  name: string | null;
};

export type TAll_in_calls = {
  date: string;
  value: number;
  name: string | null;
  filters: {
    [key: string]: number;
    all_records: number;
    missed_calls: number;
    new_clients: number;
  };
};

// export type TAdminsDashboardData = {
//   all_in_calls: TAll_in_calls[];
//   all_out_calls: TStackedBarData[];
//   wa_average_time: TStackedBarData[];
// };

export type TEaredByOutCallsData = {
  date: string;
  value: number;
  filters: {
    [key: string]: { [key: string]: string | number | { [key: string]: string | number }[] }[];
    service_category: {
      title: string;
      amount: number;
    }[];
    masters: {
      name: string;
      total: number;
      services: {
        id: number;
        title: string;
        cost: number;
      }[];
    }[];
  };
};

export type TOp_records = {
  date: string;
  value: number;
};

// export type TFinanceDashboardData = {
//   all_amount: {
//     date: string;
//     value: number;
//     filters: {
//       service_category: TDashboardDataItem[] | null;
//       masters: TDashboardDataItem[] | null;
//     };
//   }[];
//   amount_service_rate: TDashboardDataItem[];
//   op_records: TOp_records[];
//   earned_by_out_calls: TEaredByOutCallsData[];
// };

export type TChurn_by_master = {
  date: string;
  masters: TDashboardDataItem[];
};

export type TIndicator_changes_days = {
  date: string;
  n: number;
};

export type TReturn_master_rate = {
  date: string;
  masters: TDashboardDataItem[];
  filters: {
    [key: string]: TDashboardDataItem[] | null;
    return_new_client_rate: TDashboardDataItem[] | null;
    general_client_rate: TDashboardDataItem[] | null;
  };
};

export type TMastersTasks = {
  date: string;
  created: number;
  filters: {
    [key: string]: number | null;
    completion_time: number | null;
    tasks_completed: number | null;
    tasks_missed: number | null;
  };
};

// export type TMastersDashboardData = {
//   churn_by_master: TChurn_by_master[];
//   return_master_rate: TReturn_master_rate[];
//   tasks: TMastersTasks[];
// };

export type TDashboardData = {
  [key: string]: TAdminsDashboardData | TFinancesDashboardData | TMastersDashboardData;
  admins: TAdminsDashboardData;
  finance: TFinancesDashboardData;
  masters: TMastersDashboardData;
};

export enum DASHBOARD_RESPONSE_DATA_KEY_NAMES {
  OK = 'ok',
  DATA = 'data',
  META = 'meta',
}

export type TSaveDashboardResponse = {
  startDate: string;
  endDate: string;
  dashboard_list: Record<string, boolean>;
};

export type TTelephonyData = {
  date: string;
  type: string;
  phone: string;
  name: string;
  status: string;
  link: string;
  record: string;
};
