import React from 'react';
//
import { LABEL_TEXT } from '@data/dict';
import { THelpKeys } from '@models/index';
import { Checkbox } from '@uikit/CheckBox';
import { TOnChangeHandler } from '@shared/types';

interface ICheckboxWithLabelProps {
  /**
   * Значение параметра name в input для onChangeHandlers
   * @param {string}
   */
  name: string;
  /**
   * Значение checked для input
   * @param {boolean}
   */
  checked: boolean;
  /**
   * Вариант текста для метки, берется по ключу из словаря
   * @param {THelpKeys}
   */
  variant: THelpKeys;
  /**
   * Callback для обработки события onChange
   * @param {TOnChangeHandler}
   */
  onChangeHandler: TOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const CheckboxWithLabel = ({
  name,
  checked,
  variant,
  className = '',
  onChangeHandler,
}: ICheckboxWithLabelProps) => (
  <Checkbox
    color='success'
    name={name}
    checked={checked}
    className={className}
    onChange={onChangeHandler}
    label={LABEL_TEXT[variant]}
  />
);
